import uniqBy from 'lodash/uniqBy';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from 'reselect';

import {getGroups} from "../../common/selectors";
import {defaultColorName} from "../../common/theme";
import {removeNoteFilter, setNoteFilter} from '../actions';
import NoteFilter from '../components/NoteFilter';
import {getNoteFilter, getNoteFilterOptions} from '../selectors';

const colorMap = {
  "accent": "Accent",
  "banner": "Banner",
  "text": "Title",
  "berry": "Berry",
  "mint": "Mint",
  "pineapple": "Pineapple",
  "roc": "Roc",
  "teal": "Teal",
};


export const toOption = (value, groups, noteField) => {
  let label = value.charAt(0).toUpperCase() + value.slice(1);
  if (noteField === 'visibility' && value !== "public" && value !== "private") {
    const possibleGroups = groups.find(group => group.key === value);

    //if we know about this group
    if (possibleGroups && possibleGroups.name) {
      label = possibleGroups.name;
    } else {
      console.error("Unknown group for note", value);
    }
  }
  if (noteField === 'color') {
    if (value === 'default') {
      value = defaultColorName;
    }
    label = colorMap[value];
  }
  return ({label: label, value});
};

export const toOptions = (state, props) => {
  const groups = getGroups(state);
  const {noteField,} = props;

  return uniqBy(getNoteFilterOptions(state, props).map(value => toOption(value, groups, noteField)), 'value');
};

export const onChange = (value, noteField, dispatch) => {
  const newValue = noteField === 'color' && value === defaultColorName ? 'default' : value;

  if (value === null) {
    dispatch(removeNoteFilter(noteField));
  } else {
    dispatch(setNoteFilter(noteField, newValue));
  }
  if (window.generalcode && window.generalcode.refreshNoteLocations) {
    setTimeout(window.generalcode.refreshNoteLocations, 0);
  }
};


export const valueOrDefault = (state, props) => {
  const field = props.noteField;
  const filter = getNoteFilter(state, props);
  if (field === 'color' && filter === 'default') {
    return defaultColorName;
  }
  return filter;
};

const mapStateToProps = createStructuredSelector({
  options: toOptions,
  noteFilter: getNoteFilter,
  groups: getGroups,
  value: valueOrDefault,
});

const mapDispatchToProps = (dispatch, {noteField}) => ({
  onChange: (value) => onChange(value, noteField, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(NoteFilter);
