/*
 * Copyright 2018 General Code
 */

import {connect} from 'react-redux';
import compose from 'recompose/compose';
import setDisplayName from 'recompose/setDisplayName';
import withProps from 'recompose/withProps';
import {bindActionCreators} from 'redux';
import {createStructuredSelector} from 'reselect';
import Picker from '../../common/components/Picker';
import {defaultColorName, withStyles} from "../../common/theme";

import {updateColor} from '../actions';
import {getColor} from '../selectors';
import './ColorPickerContainer.css';


const colorConfig = (scheme, color, hide) => {
  return ({
    display: hide || !scheme[color] ? 'none' : 'flex',
    borderLeftColor: `${scheme[color]}`,
  });
};

const style = ({palette: {customer = {}, site = {}}}) => ({
  accent: colorConfig(customer, 'accent'),
  banner: colorConfig(customer, 'banner', customer.banner === customer.accent),
  title: colorConfig(customer, 'title', customer.title === customer.banner || customer.title === customer.accent),
  teal: colorConfig(site, 'teal'),
  mint: colorConfig(site, 'mint'),
  berry: colorConfig(site, 'berry'),
  pineapple: colorConfig(site, 'pineapple'),
  roc: colorConfig(site, 'roc'),
});

const buildColor = (color, name, normal) => ({
  [color]: {
    primary: color === defaultColorName ? "Default" : normal,
    secondary: name,
    short: name,
  }
});
const custom = (color, name) => buildColor(color,name,"Custom");
const standard = (color, name) => buildColor(color,name,"Standard");

const order = [];
if(defaultColorName !== "teal") {
  order.push("accent");
  order.push("banner");
  order.push("title");
}
order.push("teal");
order.push("mint");
order.push("berry");
order.push("pineapple");
order.push("roc");
  
const staticProps = {
  type: "color",
  label: "Color",
  tooltip: "Select what color is shown as an accent on the side of this note",
  display: {
    order,
    defaultValue: defaultColorName,
    options: {
      ...custom("accent", "Accent"),
      ...custom("banner", "Banner"),
      ...custom("title", "Title"),
      ...standard("berry", "Berry"),
      ...standard("mint", "Mint"),
      ...standard("pineapple", "Pineapple"),
      ...standard("roc", "Roc"),
      ...standard("teal", "Teal"),
    },
  },
};

/* if set to be the default color actually show as if we were that actual options
 * if set to be the color that is the default color actually set default
 * this way customers don't have to know that default exists
 * but when they change their customizations the colors just follow
 */
const resolveDefault = color => color === "default" ? defaultColorName : color;
const unresolveDefault = color => color === defaultColorName ? "default" : color;

const mapStateToProps = createStructuredSelector({
  value: (state, props) => resolveDefault(getColor(state, props)),
});

const mapDispatchToProps = (dispatch, {id}) => bindActionCreators({
  updateValue: color => updateColor(id, unresolveDefault(color)),
}, dispatch);

const ColorPickerContainer = compose(setDisplayName("ColorPickerContainer"),withStyles(style),withProps(staticProps), connect(mapStateToProps, mapDispatchToProps))(Picker);
export default ColorPickerContainer;
