import {List, Map, Record} from "immutable";
import {isEqual} from "lodash";
import {handleActions} from "redux-actions";
import {pushState, replaceState} from "../../common/history";
import {custId, customer} from "../../common/utils/server-data";
import * as actions from '../actions';

const PubDocType = Record({
  id: null,
  title: null,
  description: null,
  count: 0,
  sortType: null,
  privateCount: 0
});

const PubDoc = Record({
  id: null,
  created: null,
  date: null,
  dateObj: null,
  year: null,
  yearNum: 0,
  description: null,
  extension: null,
  origExtension: null,
  title: null,
  titleLc: null,
  updated: null,
  categoryId: null,
  isUncategorized: false,
  createdBy: null,
  updatedBy: null,
  private: false,
  type: null,
  url: null
});

const newPubDoc = (pubDoc) => {
  let dateObj = null;
  let year = "No Date";
  let yearNum = 0;
  if (pubDoc.date) {
    dateObj = new Date(`${pubDoc.date} 00:00`);
    yearNum = dateObj.getFullYear();
    year = yearNum.toString();
  }
  return PubDoc({
    ...pubDoc,
    dateObj,
    year,
    yearNum,
    titleLc: pubDoc.title.toLowerCase(),
    isUncategorized: pubDoc.category && pubDoc.category.title === "(Uncategorized)"}
  );
};

const Category = Record({
  id: null,
  title: null,
  typeId: null,
  description: null,
  private: false,
  groupByType: null,
  sortByFirstType: null,
  sortBySecondType: null
});

const FilterOptions = Record({
  categoryOptions: List(),
  yearOptions: List(),
  monthOptions: List(),
  dayOptions: List()
});

const PubDocState = Record({
  pubDocTypes: List(),
  pubDocTypeId: null,
  documents: List(),
  documentsLoading: false,
  suggestedPubDocTypes: Map(),
  typeEditOpen: false,
  typeEditId: null,
  categoryEditOpen: false,
  categoryEditId: null,
  displayedCategoryIds: List(),
  categories: List(),
  filterOptions: Map(),
  selectedFilters: FilterOptions(),
  documentEditOpen: false,
  documentCategoryEditId: null,
  documentEditId: null,
  documentEditLoading: false,
  categoryEditLoading: false,
  typeEditLoading: false,
  documentMoveOpen: false,
  documentMoveId: null,
  pubDocsSidebarName: null,
  selectedDocs: List(),
  bulkUploaderLoading: false,
  bulkUploadedDocuments: null
});

const initialState = PubDocState({});

const initializeState = (state, {payload: {loadedState, initial}}) => {
  if (initial) {
    updateWindowHistory(state, loadedState.selectedFilters, loadedState.filterOptions, true);
  }
  if (!isEqual(state.get("selectedFilters"), loadedState.selectedFilters)) {
    return state.set("selectedFilters", loadedState.selectedFilters);
  } else {
    return state;
  }
};

const handleAddPubDocTypeSuccess = (state, {payload: {response}}) => {
  window.location.href = "/" + response.custId + "/documents/" + encodeURIComponent(response.title);
  return state.set("typeEditLoading", false);
};

const handleDeletePubDocTypeSuccess = (state, {payload: {response}}) => {
  const oldPubDocTypes = state.get("pubDocTypes");
  const deletedIndex = oldPubDocTypes.findIndex(pdt => pdt.id === response.id);
  const nextIndex = oldPubDocTypes.size === 1 ? -1 : (deletedIndex + 1 < oldPubDocTypes.size ? deletedIndex + 1 : 0);
  if (nextIndex !== -1) {
    window.location.href = "/" + response.custId + "/documents/" + encodeURIComponent(oldPubDocTypes.get(nextIndex).title);
  } else {
    window.location.href = "/" + response.custId;
  }
};

const handleLoadPubDocsStart = (state) => state.set("documentsLoading", true);

const handleLoadPubDocsSuccess = (state, {payload: {response}}) =>
  updateWindowHistory(state, state.selectedFilters, response.filterOptions, false)
    .set("documents", List(response.documents.map(pd => newPubDoc(pd))))
    .set("filterOptions", FilterOptions(response.filterOptions));

const handleLoadPubDocsFinally = (state) => state.set("documentsLoading", false);

const handleLoadPubDocsCategoriesSuccess = (state, {payload: {response, pubDocTypeId}}) => state
  .set("categories", List(response.map(cat => Category(cat))))
  .set("displayedCategoryIds", response.filter(category => category.typeId === pubDocTypeId).map(category => category.id));

const handleSetPubDocTypeEditOpen = (state, {payload: {open, typeEditId}}) =>  state
  .set("typeEditOpen", open).set("typeEditId", typeEditId);

const handleSetPubDocCategoryEditOpen = (state, {payload: {categoryEditOpen, categoryEditId}}) => state
  .set("categoryEditOpen", categoryEditOpen).set("categoryEditId", categoryEditId);

const handleSetPubDocEditOpen = (state, {payload: {open, documentId, categoryId}}) => state
  .set("documentEditOpen", open).set("documentEditId", documentId).set("documentCategoryEditId", categoryId);

const handleSetDocumentChecked = (state, {payload: {documentId}}) => {
  return state.update("selectedDocs", selectedDocs => selectedDocs.push(documentId));
};

const handleSetDocumentUnchecked = (state, {payload: {documentId}}) => {
  return state.set("selectedDocs", state.get("selectedDocs").filter(s => s !== documentId));
};

const handleSetPubDocMoveOpen = (state, {payload: {open, documentId}}) => state.set("documentMoveOpen", open)
  .set("documentMoveId", documentId);

const handleAddPubDocCategorySuccess = (state, {payload: {response}}) =>
  updateWindowHistory(state, state.selectedFilters, response.filterOptions, false)
    .update("categories", categories => categories.push(Category(response.category)))
    .update("displayedCategoryIds", displayedCategoryIds => List(displayedCategoryIds).concat(response.category.id))
    .set("filterOptions", FilterOptions(response.filterOptions))
    .set("categoryEditOpen", false).set("categoryEditId", null)
    .set("categoryEditLoading", false);

const handleEditPubDocCategorySuccess = (state, {payload: {response}}) => {
  let resultIndex = state.get("categories").findIndex(c => c.id === response.category.id);
  if (resultIndex !== -1) {
    return state.setIn(["categories", resultIndex], Category(response.category)).set("filterOptions", FilterOptions(response.filterOptions))
      .set("categoryEditOpen", false).set("categoryEditId", null).set("categoryEditLoading", false);
  } else {
    return updateWindowHistory(state, state.selectedFilters, response.filterOptions, false)
      .update("categories", categories => categories.push(Category(response.category)))
      .set("filterOptions", FilterOptions(response.filterOptions))
      .set("categoryEditOpen", false).set("categoryEditId", null)
      .set("categoryEditLoading", false);
  }
};

const handleDeletePubDocCategorySuccess = (state, {payload: {response}}) =>
  updateWindowHistory(state, state.selectedFilters, response.filterOptions, false)
    .update("categories", categories => categories.filter(c => c.get("id") !== response.category.id))
    .set("filterOptions", FilterOptions(response.filterOptions));

const handleLoadFiltersSuccess = (state, {payload: {response}}) =>
  updateWindowHistory(state, state.selectedFilters, response, false)
    .set("filterOptions", FilterOptions(response));

const handleEditPubDocStart = (state) => state.set("documentEditLoading", true);
const handleEditPubDocFinish = (state) => state.set("documentEditLoading", false);

const handleEditPubDocCategoryStart = (state) => state.set("categoryEditLoading", true);
const handleEditPubDocCategoryFinish = (state) => state.set("categoryEditLoading", false);

const handleEditPubDocTypeStart = (state) => state.set("typeEditLoading", true);

const addPubDoc = (state, response) => {
  let type = PubDocType(response.type);
  let typeResultIndex = state.get("pubDocTypes").findIndex(typeToFind => typeToFind.id === type.id);
  return updateWindowHistory(state, state.selectedFilters, response.filterOptions, false)
    .update("documents", documents => documents.push(newPubDoc(response.document)))
    .set("filterOptions", FilterOptions(response.filterOptions)).set("documentEditOpen", false)
    .set("documentEditId", null).set("documentCategoryEditId", null).setIn(["pubDocTypes", typeResultIndex], type);
};
const handleAddPubDocSuccess = (state, {payload: {response}}) => addPubDoc(state, response);

const handleEditPubDocSuccess = (state, {payload: {response}}) => {
  let type = PubDocType(response.type);
  let typeResultIndex = state.get("pubDocTypes").findIndex(typeToFind => typeToFind.id === type.id);
  let pubDoc = newPubDoc(response.document);
  let resultIndex = state.get("documents").findIndex(document => document.id === pubDoc.id);
  if (resultIndex !== -1) {
    return updateWindowHistory(state, state.selectedFilters, response.filterOptions, false)
      .setIn(["documents", resultIndex], pubDoc).set("filterOptions", FilterOptions(response.filterOptions))
      .set("documentEditOpen", false).set("documentEditId", null).set("documentCategoryEditId", null)
      .setIn(["pubDocTypes", typeResultIndex], type);
  } else {
    return updateWindowHistory(state, state.selectedFilters, response.filterOptions, false)
      .update("documents", documents => documents.push(pubDoc))
      .set("filterOptions", FilterOptions(response.filterOptions)).set("documentEditOpen", false)
      .set("documentEditId", null).set("documentCategoryEditId", null)
      .setIn(["pubDocTypes", typeResultIndex], type);
  }
};

const handleDeletePubDocSuccess = (state, {payload: {response}}) => {
  let type = PubDocType(response.type);
  let typeResultIndex = state.get("pubDocTypes").findIndex(typeToFind => typeToFind.id === type.id);
  return updateWindowHistory(state, state.selectedFilters, response.filterOptions, false)
    .set("documents", state.get("documents").filter(d => d.get("id") !== response.document.id))
    .set("filterOptions", FilterOptions(response.filterOptions))
    .setIn(["pubDocTypes", typeResultIndex], type);
};

const handleDeleteMultiplePubDocsSuccess = (state, {payload: {response}}) => {
  let type = PubDocType(response.type);
  let typeResultIndex = state.get("pubDocTypes").findIndex(typeToFind => typeToFind.id === type.id);
  return updateWindowHistory(state, state.selectedFilters, response.filterOptions, false)
    .set("documents", state.get("documents").filter(d => !response.documents.map(d => d.id).includes(d.get("id"))))
    .set("filterOptions", FilterOptions(response.filterOptions)).setIn(["pubDocTypes", typeResultIndex], type)
    .set("selectedDocs", List());
};

const updateFilterState = (state, {payload: {selectedFilters}}) => updateWindowHistory(state, selectedFilters, state.filterOptions, false);

const handleMovePublicDocumentsSuccess = (state, {payload: {response}}) => {
  window.location.href = "/" + response.custId + "/documents/" + encodeURIComponent(response.title);
};

const handleBulkAddPubDocsStart = (state) => state.set("bulkUploaderLoading", true).set("bulkUploadedDocuments", []);
const handleBulkAddPubDocsFinally = (state) => state.set("bulkUploaderLoading", false);
const handleBulkUploadedClear = (state) => state.set("bulkUploadedDocuments", null);
const handleBulkAddPubDocSuccess = (state, {payload: {response}}) => {
  let bulkUploadedDocuments = state.get("bulkUploadedDocuments");
  if (bulkUploadedDocuments === null) {
    bulkUploadedDocuments = [];
  }
  bulkUploadedDocuments.push(newPubDoc(response.document));
  return addPubDoc(state, response).set("bulkUploadedDocuments", bulkUploadedDocuments);
};

const updateWindowHistory = (state, selectedFilters, filterOptions, initial) => {
  let {pubDocTypes, pubDocTypeId} = state;
  let typeName = "";
  let selectedType = pubDocTypes.filter(pdt => pdt.id === pubDocTypeId);
  if (selectedType.size > 0) {
    typeName = selectedType.get(0).title;
  }
  let queryParams = [];
  let newSelectedFilters = {
    category: null,
    day: null,
    month: null,
    year: null
  };
  for (let filterKey in selectedFilters) {
    let filterValue = selectedFilters[filterKey];
    if (filterValue != null && filterValue !== "") {
      let options = !filterOptions ? [] : filterOptions[filterKey + "Options"];
      if (!options || options.includes(filterValue)) {
        newSelectedFilters[filterKey] = filterValue;
        queryParams.push(encodeURIComponent(filterKey) + "=" + encodeURIComponent(filterValue));
      }
    }
  }
  let pageName = `${(customer && customer.name) ? customer.name : "eCode360®"} ${typeName} Public Documents`;
  let pageUrl = `/${custId}/documents/${encodeURIComponent(typeName.replace(/ /g, "_"))}${((queryParams.length > 0) ? "?" + 
    queryParams.join("&") : "")}${window.location.hash}`;
  if (initial) {
    replaceState({selectedFilters: newSelectedFilters}, pageName, pageUrl);
  } else {
    pushState({selectedFilters: newSelectedFilters}, pageName, pageUrl);
  }
  if (!isEqual(state.get("selectedFilters"), newSelectedFilters)) {
    //only update the state if the filters are actually different to keep from recreating identical but change triggering states
    return state.set("selectedFilters", newSelectedFilters);
  } else {
    return state;
  }
};

const reducer = handleActions({
  [actions.initializeState]: initializeState,
  [actions.addPubDocTypeSuccess]: handleAddPubDocTypeSuccess,
  [actions.deletePubDocTypeSuccess]: handleDeletePubDocTypeSuccess,
  [actions.loadPubDocsStart]: handleLoadPubDocsStart,
  [actions.loadPubDocsSuccess]: handleLoadPubDocsSuccess,
  [actions.loadPubDocsFinally]: handleLoadPubDocsFinally,
  [actions.setPubDocTypeEditOpen]: handleSetPubDocTypeEditOpen,
  [actions.editPubDocTypeSuccess]: handleAddPubDocTypeSuccess,
  [actions.setPubDocCategoryEditOpen]: handleSetPubDocCategoryEditOpen,
  [actions.addPubDocCategoryStart]: handleEditPubDocCategoryStart,
  [actions.addPubDocCategorySuccess]: handleAddPubDocCategorySuccess,
  [actions.addPubDocCategoryFinally]: handleEditPubDocCategoryFinish,
  [actions.editPubDocCategoryStart]: handleEditPubDocCategoryStart,
  [actions.editPubDocCategorySuccess]: handleEditPubDocCategorySuccess,
  [actions.editPubDocCategoryFinally]: handleEditPubDocCategoryFinish,
  [actions.deletePubDocCategorySuccess]: handleDeletePubDocCategorySuccess,
  [actions.loadPubDocsCategoriesSuccess]: handleLoadPubDocsCategoriesSuccess,
  [actions.loadFiltersSuccess]: handleLoadFiltersSuccess,
  [actions.setPubDocEditOpen]: handleSetPubDocEditOpen,
  [actions.setDocumentMoveOpen]: handleSetPubDocMoveOpen,
  [actions.addPubDocStart]: handleEditPubDocStart,
  [actions.addPubDocSuccess]: handleAddPubDocSuccess,
  [actions.addPubDocFinally]: handleEditPubDocFinish,
  [actions.editPubDocStart]: handleEditPubDocStart,
  [actions.editPubDocSuccess]: handleEditPubDocSuccess,
  [actions.editPubDocFinally]: handleEditPubDocFinish,
  [actions.deletePubDocSuccess]: handleDeletePubDocSuccess,
  [actions.addPubDocLinkedStart]: handleEditPubDocStart,
  [actions.addPubDocLinkedSuccess]: handleAddPubDocSuccess,
  [actions.addPubDocLinkedFinally]: handleEditPubDocFinish,
  [actions.editPubDocLinkedStart]: handleEditPubDocStart,
  [actions.editPubDocLinkedSuccess]: handleEditPubDocSuccess,
  [actions.editPubDocLinkedFinally]: handleEditPubDocFinish,
  [actions.updateFilterStateAction]: updateFilterState,
  [actions.deleteMultipleDocumentsSuccess]: handleDeleteMultiplePubDocsSuccess,
  [actions.editPubDocTypeStart]: handleEditPubDocTypeStart,
  [actions.movePublicDocumentsSuccess]: handleMovePublicDocumentsSuccess,
  [actions.bulkAddPubDocsStart]: handleBulkAddPubDocsStart,
  [actions.bulkAddPubDocsFinally]: handleBulkAddPubDocsFinally,
  [actions.bulkAddPubDocSuccess]: handleBulkAddPubDocSuccess,
  [actions.clearBulkUploadedDocuments]: handleBulkUploadedClear,
  [actions.setDocumentChecked]: handleSetDocumentChecked,
  [actions.setDocumentUnchecked]: handleSetDocumentUnchecked
}, initialState);

export {PubDocState, initialState, PubDocType};
export default reducer;
