import {ModuleKey} from "../ModuleKey";
import {List} from 'immutable';
import {hasPubDocPrivateViewPermission} from "../../common/selectors";
import createCachedSelector from 're-reselect';

const getSuggestedPubDocTypes = (state) => state[ModuleKey].suggestedPubDocTypes ? state[ModuleKey].suggestedPubDocTypes : Map();
const getPubDocsSidebarName = (state) => state[ModuleKey].pubDocsSidebarName;
const getPubDocTypes = (state) => state[ModuleKey].pubDocTypes ? state[ModuleKey].pubDocTypes : List();
const getPubDocTypeId = (state) => state[ModuleKey].pubDocTypeId;
const pubDocTypeSelector = (extract) => (state) => extract(getPubDocTypes(state)
  .filter(pubDocType => pubDocType.id === getPubDocTypeId(state)).get(0));
const getPubDocTypeTitle = pubDocTypeSelector(pubDocType => pubDocType && pubDocType.title);
const getPubDocTypeDescription = pubDocTypeSelector(pubDocType => pubDocType && pubDocType.description);
const getPubDocs = (state) => state[ModuleKey].documents;
const getPubDocsLoading = (state) => state[ModuleKey].documentsLoading;
const getDisplayedCategoryIds = (state) => state[ModuleKey].displayedCategoryIds;
const getAllCategories = (state) => state[ModuleKey].categories;
const getCategories = (state) => getAllCategories(state)
  .filter(category => category.typeId === getPubDocTypeId(state))
  .sort((c1, c2) => c1.title.toLowerCase().localeCompare(c2.title.toLowerCase(), undefined, { numeric: true }));
const getTypeEditOpen = (state) => state[ModuleKey].typeEditOpen;
const getTypeEditId = (state) => state[ModuleKey].typeEditId;
const getCategoryEditId = (state) => state[ModuleKey].categoryEditId;
const getCategoryEditOpen = (state) => state[ModuleKey].categoryEditOpen;
const getFilterOptions = (state) => state[ModuleKey].filterOptions;
const getSelectedFilters = (state) => state[ModuleKey].selectedFilters;
const getDocumentEditOpen = (state) => state[ModuleKey].documentEditOpen;
const getDocumentCategoryEditId = (state) => state[ModuleKey].documentCategoryEditId;
const getDocumentEditId = (state) => state[ModuleKey].documentEditId;
const getSelectedDocs = (state) => state[ModuleKey].selectedDocs;
const getDocumentEditLoading = (state) => state[ModuleKey].documentEditLoading;
const getCategoryEditLoading = (state) => state[ModuleKey].categoryEditLoading;
const getTypeEditLoading = (state) => state[ModuleKey].typeEditLoading;
const getDocumentMoveOpen = (state) => state[ModuleKey].documentMoveOpen;
const getDocumentMoveId = (state) => state[ModuleKey].documentMoveId;
const getFilteredCategories = (state) => {
  const categoryFilter = getSelectedFilters(state).category === null ? "" : getSelectedFilters(state).category;
  return getCategories(state)
    .filter(category => getDisplayedCategoryIds(state).includes(category.id)
      && (category.title === categoryFilter || categoryFilter === '')
      && !(category.private && !hasPubDocPrivateViewPermission(state))
    );
};
const getBulkUploaderLoading = (state) => state[ModuleKey].bulkUploaderLoading;
const getBulkUploadedDocuments = (state) => state[ModuleKey].bulkUploadedDocuments;

const getCategory = (_, props) => {
  return props.category;
};

const getCategoryId = (_, props) => {
  return props.category.id;
};

const getFilteredDocuments = createCachedSelector([getCategory, getPubDocs, getSelectedFilters],
  (category, documents, selectedFilters) => {
    const yearFilter = selectedFilters.year == null ? "" : selectedFilters.year;
    const monthFilter = selectedFilters.month == null ? "" : selectedFilters.month;
    const dayFilter = selectedFilters.day == null ? "" : selectedFilters.day;
    return documents.filter(document => {
      const documentDate = document.dateObj;
      return document.categoryId === category.id
        && (yearFilter === "" ||  (documentDate === null ? yearFilter === "-1" : documentDate.getFullYear() === parseInt(yearFilter, 10)))
        && (monthFilter === "" ||  (documentDate === null ? monthFilter === "-1" : (documentDate.getMonth() + 1) === parseInt(monthFilter, 10)))
        && (dayFilter === "" ||  (documentDate === null ? dayFilter === "-1" : documentDate.getDate() === parseInt(dayFilter, 10)))
        && (hasPubDocPrivateViewPermission || !document.private);
    }).sort((document1, document2) => documentComparator(category, document1, document2));
  })(getCategoryId);


const documentComparator = (category, document1, document2) => {
  let sortVal = 0;
  const firstYear = document1.yearNum;
  const secondYear = document2.yearNum;
  //The default numbers for null dates are the smallest possible date in javascript
  const document1Date = document1.dateObj ? document1.dateObj : -8640000000000000;
  const document2Date = document2.dateObj ? document2.dateObj : -8640000000000000;
  if (category.groupByType === "Year_Ascending") {
    sortVal = firstYear - secondYear;
    if (sortVal !== 0) {
      return sortVal;
    }
  } else if (category.groupByType === "Year_Descending") {
    sortVal = secondYear - firstYear;
    if (sortVal !== 0) {
      return sortVal;
    }
  }
  sortVal = getSortValue(category.sortByFirstType, document1, document1Date, document2, document2Date);
  if (sortVal === 0) {
    sortVal = getSortValue(category.sortBySecondType, document1, document1Date, document2, document2Date);
  }
  return sortVal;
};

const getSortValue = (sortByType, document1, document1Date, document2, document2Date) => {
  if (sortByType === "Alphabetical_Ascending") {
    return new Intl.Collator([], { numeric: true, sensitivity: 'base' }).compare(document1.titleLc, document2.titleLc);
  } else if (sortByType === "Alphabetical_Descending") {
    return new Intl.Collator([], { numeric: true, sensitivity: 'base' }).compare(document2.titleLc, document1.titleLc);
  } else if (sortByType === "Date_Ascending") {
    return document1Date - document2Date;
  } else if (sortByType === "Date_Descending") {
    return document2Date - document1Date;
  }
};


export {
  getSuggestedPubDocTypes,
  getTypeEditOpen,
  getPubDocs,
  getPubDocsLoading,
  getPubDocTypeId,
  getPubDocTypeTitle,
  getPubDocTypeDescription,
  getPubDocTypes,
  getTypeEditId,
  getCategoryEditOpen,
  getDisplayedCategoryIds,
  getAllCategories,
  getCategories,
  getFilteredCategories,
  getCategoryEditId,
  getFilterOptions,
  getSelectedFilters,
  getDocumentEditOpen,
  getDocumentCategoryEditId,
  getDocumentEditId,
  getDocumentEditLoading,
  getCategoryEditLoading,
  getTypeEditLoading,
  getDocumentMoveOpen,
  getDocumentMoveId,
  getPubDocsSidebarName,
  getSelectedDocs,
  getFilteredDocuments,
  getBulkUploaderLoading,
  getBulkUploadedDocuments
};
