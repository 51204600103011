/*
 * Copyright 2018 General Code
 */

import debounce from 'lodash/debounce';
import React from 'react';
import * as PropTypes from 'prop-types';
import './Options.css';
import Button from "../../common/components/Button";
import IconButton from "../../common/components/IconButton";
import If from "../../common/containers/If";
import TextField from '../../common/components/TextField';
import {Clear as ClearIcon} from "../../common/components/icons";
import Options from "./Options";

const debounceWait = 750;

class AdditionalOptionContent extends React.Component {

  static propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    option: PropTypes.object.isRequired,
    ia2FileTypes: PropTypes.string,
    isFinalized: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired,
    updateResponse: PropTypes.func.isRequired,
    addOptionFile: PropTypes.func.isRequired,
    removeOptionFile: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {option} = props;
    this.state = {
      responseValue: option.responseValue === null ? "" : option.responseValue
    };
    this.handleResponseTextUpdate = debounce(this.handleResponseTextUpdate, debounceWait);
  }

  handleResponseTextChange = (questionId, optionId, type) => (event) => {
    const value = event.target.value;
    this.setState({responseValue: value});
    this.handleResponseTextUpdate(questionId, optionId, type, value);
  };

  handleResponseTextUpdate = (questionId, optionId, type, responseValue) => {
    const {updateResponse} = this.props;
    updateResponse(questionId, optionId, type, responseValue);
  };

  handleResponseFileUpload = (questionId, optionId) => (event) => {
    const {addOptionFile} = this.props;
    const file = event.target.files[0];
    addOptionFile(questionId, optionId, file);
  };

  handleResponseFileDelete = (questionId, optionId, optionFileId) => () => {
    const {removeOptionFile} = this.props;
    removeOptionFile(questionId, optionId, optionFileId);
  };

  render() {
    const {questionId, option, isFinalized, ia2FileTypes, isLocked} = this.props;
    const {responseValue} = this.state;
    return (
      <div className={"additionalOptionContent" + (option.type === "CustomerFileUpload" ? " inline" : "")}>
        <If test={option.type === "CustomerInput"}>
          <TextField id={"option_field_" + option.id} className="optionText" variant="outlined" margin="dense"
            disabled={!option.selected || isFinalized} value={!option.selected ? "" : responseValue} onChange={this.handleResponseTextChange(option.questionId, option.id, option.type)}/>
        </If>
        <If test={option.type === "CustomerFullText"}>
          <TextField id={"option_field_" + option.id} className="optionText" variant="outlined" rows={4}
            disabled={!option.selected || isFinalized} value={!option.selected ? "" : responseValue} onChange={this.handleResponseTextChange(option.questionId, option.id, option.type)} multiline/>
        </If>
        <If test={option.type === "CustomerFileUpload"}>
          <div className="optionFileContainer">
            <input
              id={"option_field_" + option.id}
              className="optionFile"
              type="file"
              name="responseFile"
              disabled={!option.selected || isFinalized || isLocked}
              onChange={this.handleResponseFileUpload(option.questionId, option.id)}
              accept={ia2FileTypes ? ia2FileTypes : "*"}
            />
            <label htmlFor={"option_field_" + option.id}>
              <Button variant="contained" color="primary" component="span" disabled={!option.selected || isLocked}>
                Upload
              </Button>
            </label>
          </div>
          <If test={option.selected && option.files}>
            <div className="optionFileList">
              {option.files.map((file) => (
                <div key={file.id}>
                  <a href={"/user/file/" + file.filePath} target="_blank" rel="noopener noreferrer">{file.originalFilename}</a>
                  <IconButton aria-label="Remove file" onClick={this.handleResponseFileDelete(option.questionId, option.id, file.id)}>
                    <ClearIcon/>
                  </IconButton>
                </div>
              ))}
            </div>
          </If>
        </If>
        <If test={option.type === "WithOptions"}>
          <div className="optionSubOptions">
            <Options questionId={questionId} optionListId={option.id} optionListMode={option.optionListMode} options={option.subOptions} />
          </div>
        </If>
      </div>
    );
  }
}
export default AdditionalOptionContent;