import React from 'react';
import BoxedSection from '../../common/components/BoxedSection';
import Button from '../../common/components/Button';
import Checkbox from '../../common/components/Checkbox';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '../../common/components/dialog';
import DropZone from '../../common/components/DropZone';
import FormControlLabel from '../../common/components/FormControlLabel';
import TextField from '../../common/components/TextField';
import PrimaryButton from '../../common/components/PrimaryButton';
import {If} from '../../common/containers';
import {jsonEquals} from "../../common/utils";
import './CustomBannerSection.css';
import {Cropper} from 'react-image-cropper';

const BANNER_WIDTH = 940;
const BANNER_HEIGHT = 106;

const DEFAULT_CROPPED_AREA = {
  x: 0,
  y: 0,
  height: BANNER_HEIGHT,
  width: BANNER_WIDTH
};

class CustomBannerSection extends React.PureComponent {

  constructor(props) {
    super(props);

    const initialBannerState = {
      useBanner: (props.customization && props.customization.banner),
      website: props.customization.website,
    };

    this.state = {
      banner: initialBannerState,
      lastAppliedBanner: initialBannerState,
      bannerImagePreview: null,
      bannerImageFull: null,
      bannerCroppedArea: DEFAULT_CROPPED_AREA,
      bannerCropperOpen: false,
      applyBannerConfirmOpen: false,
    };
  }

  handleUseBannerChange = (event) => {
    this.setState({banner: {...this.state.banner, useBanner: event.target.checked}});
  };
  handleBannerFileChange = (files) => {
    if (files.length > 0) {
      const dataUrl = window.URL.createObjectURL(files[0]);
      this.loadImageUrlIntoState(dataUrl);
    }
  };
  handleBannerFileRejected = (rejectedFiles, event) => {
    const html = event.dataTransfer.getData('text/html');
    if (html.startsWith("<img")) {
      const url = (/src=['"\s](.*?)['"\s]/).exec(html)[1];
      if (url) {
        const proxiedUrl = '/external/resource?url=' + url;
        this.loadImageUrlIntoState(proxiedUrl);
      }
    }
  };
  loadImageUrlIntoState = (url) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = url;
    image.onload = (e) => {
      let height = e.target.height;
      let width = e.target.width;
      if (width / height < BANNER_WIDTH / BANNER_HEIGHT) {
        width = height * BANNER_WIDTH / BANNER_HEIGHT;
      } else {
        height = width * BANNER_HEIGHT / BANNER_WIDTH;
      }
      const canvas = document.createElement("canvas");
      canvas.height = height;
      canvas.width = width;
      canvas.getContext('2d').drawImage(image, 0, 0);
      this.setState({
        bannerImageFull: canvas.toDataURL(),
        bannerCroppedArea: DEFAULT_CROPPED_AREA,
      });
    };
    this.setState({
      bannerImageFull: null,
      bannerCroppedArea: {x: 0, y: 0, height: BANNER_HEIGHT, width: BANNER_WIDTH},
      bannerCropperOpen: true,
    });
  };
  handleBannerCropperOpen = () => {
    this.setState({bannerCropperOpen: true});
  };
  handleBannerCropperClose = () => {
    this.setState({
      bannerImagePreview: this.cropper.crop(),
      bannerCroppedArea: {...this.cropper.values().display},
      bannerCropperOpen: false,
    });
  };
  handleApplyBannerConfirmOpen = () => {
    this.setState({applyBannerConfirmOpen: true});
  };
  handleWebsiteChange = (event) => {
    const http = "http://";
    const https = "https://";
    let url = event.target.value.trim();
    if (url !== "" && !url.startsWith(http) && !url.startsWith(https) && !http.startsWith(url) && !https.startsWith(url)) {
      url = http + url;
    }
    this.setState({banner: {...this.state.banner, website: url}});
  };
  handleApplyBannerConfirmClose = () => {
    this.setState({applyBannerConfirmOpen: false});
  };
  handleApplyBannerConfirmOk = () => {
    this.props.applyBanner(this.state.banner, this.state.bannerImagePreview);
    this.setState({
      lastAppliedBanner: this.state.banner,
      bannerImagePreview: null,
      applyBannerConfirmOpen: false,
    });
  };

  render() {
    const {banner, lastAppliedBanner, bannerImagePreview, bannerImageFull, bannerCroppedArea, bannerCropperOpen, applyBannerConfirmOpen} = this.state;
    return (
      <BoxedSection
        title="Banner Customization"
        instructions="Customize your Code with a banner image displayed at the top of every page:"
        help={{topic: 'administration', subtopic: 'managecustomizations'}}
        actions={[
          {
            action: this.handleApplyBannerConfirmOpen,
            label: "Apply Banner Changes",
            disabled: jsonEquals(banner, lastAppliedBanner) && bannerImagePreview === null,
          },
        ]}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="adminSettingsPage-useBanner"
              checked={banner.useBanner}
              onChange={this.handleUseBannerChange}
              style={{height: 'auto'}}
            />
          }
          label="Use custom banner"
        />

        <If test={banner.useBanner}>
          <If test={bannerImagePreview}>
            <img
              id="croppedBanner"
              src={bannerImagePreview}
              onClick={this.handleBannerCropperOpen}
              alt="Cropped Banner"
            />
          </If>
          <div id="bannerDropzoneContainer">
            <DropZone
              accept="image/*"
              multiple={false}
              className="bannerDropzone"
              onDrop={this.handleBannerFileChange}
              onDropRejected={this.handleBannerFileRejected}
              disabled={!banner}
              disabledClassName="disabled"
            >
              <span><strong>Drop</strong> banner image here or <strong>click</strong> to select image.</span>
              <ul>
                <li><strong>*</strong> {BANNER_WIDTH} pixels wide by {BANNER_HEIGHT} high</li>
                <li><strong>*</strong> RGB or LAB color space preferred</li>
                <li><strong>*</strong> Accepted formats: JPEG, PNG, GIF</li>
              </ul>
            </DropZone>
          </div>
          <Dialog
            open={bannerCropperOpen}
            onClose={this.handleBannerCropperClose}
            aria-labelledby="bannerCropperTitle"
            classes={{paper: "bannerCropperPaper"}}
            disableBackdropClick={true}
          >
            <DialogTitle id="bannerCropperTitle">Banner Image</DialogTitle>
            <DialogContent>
              <div style={{marginTop: '5px'}}>
                <Cropper
                  src={bannerImageFull ? bannerImageFull : ""}
                  ref={ref => {this.cropper = ref;}}
                  ratio={BANNER_WIDTH / BANNER_HEIGHT}
                  originX={bannerCroppedArea.x}
                  originY={bannerCroppedArea.y}
                  height={bannerCroppedArea.height}
                  width={bannerCroppedArea.width}
                  styles={{root: {width: 900}}}
                  onImgLoad={() => window.dispatchEvent(new Event('resize'))}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={this.handleBannerCropperClose}>Ok</PrimaryButton>
            </DialogActions>
          </Dialog>
          <TextField
            id="adminSettingsPage-bannerWebsite"
            label="Link to municipality website"
            value={banner.website}
            onChange={this.handleWebsiteChange}
            margin="normal"
            fullWidth
          />
        </If>
        <Dialog
          open={applyBannerConfirmOpen}
          actions={[]}
          onClose={this.handleApplyBannerConfirmClose}
          disableBackdropClick={true}
          aria-labelledby="bannerConfirmTitle"
        >
          <DialogTitle id="bannerConfirmTitle">Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>Applied banner changes will immediately be visible on your eCode</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleApplyBannerConfirmClose}>Cancel</Button>
            <PrimaryButton onClick={this.handleApplyBannerConfirmOk}>Ok</PrimaryButton>
          </DialogActions>
        </Dialog>
      </BoxedSection>
    );
  }
}

export default CustomBannerSection;
