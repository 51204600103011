import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
// noinspection ES6CheckImport

import {toJS} from "../../common/utils";

import MenubarSearchComponent from "../components/MenubarSearchComponent";
import { hasAdvancedSearchPermission, hasMulticodePermission, getSearchSuggestionWaitTime } from "../../common/selectors";
import {
  getQuery,
  getScope,
  getSelectedStructures,
  getSelectedCustIds,
  getSelectedCustomerFilters,
  getSearchedCodeCount,
  isSearchFieldActive
} from "../selectors";
import {
  setQuery,
  setScopeAndGetResults,
  submitNewSearch,
  setCodeFinderOpenAndLog,
  resetSelectedCustomers,
  resetSelectedStructures,
  setAdvancedSearchOpen,
  getSuggestions,
  setSearchFieldActive
} from "../actions";


const mapStateToProps = createStructuredSelector({
  query: getQuery,
  scope: getScope,
  selectedStructures: getSelectedStructures,
  selectedCustIds: getSelectedCustIds,
  selectedCustomerFilters: getSelectedCustomerFilters,
  searchedCodeCount: getSearchedCodeCount,
  searchSuggestionWaitTime: getSearchSuggestionWaitTime,
  isSearchFieldActive: isSearchFieldActive,
  hasAdvancedSearchPermission: hasAdvancedSearchPermission,
  hasMulticodePermission: hasMulticodePermission
});

const mapDispatchToProps = (dispatch) => ({
  setQuery: (query) => {
    dispatch(setQuery(query));
  },
  setScope: (sortOrder) => {
    dispatch(setScopeAndGetResults(sortOrder));
  },
  submitSearch: () => {
    dispatch(submitNewSearch(false, false));
  },
  setCodeFinderOpen: (isCodeFinderOpen) => {
    dispatch(setCodeFinderOpenAndLog(dispatch, isCodeFinderOpen));
  },
  resetSelectedCustomers: () => {
    dispatch(resetSelectedCustomers());
  },
  resetSelectedStructures: () => {
    dispatch(resetSelectedStructures());
  },
  setAdvancedSearchOpen: (isAdvancedSearchOpen) => {
    dispatch(setAdvancedSearchOpen(isAdvancedSearchOpen));
  },
  getSearchSuggestions: (query) => {
    dispatch(getSuggestions(query));
  },
  setSearchFieldActive: (isSearchFieldActive) => {
    dispatch(setSearchFieldActive(isSearchFieldActive));
  }
});
export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(MenubarSearchComponent);
