/*
 * Copyright 2018 General Code
 */

import React from 'react';
import * as PropTypes from 'prop-types';
import './Options.css';
import Radio from "../../common/components/Radio";
import If from "../../common/containers/If";
import AdditionalOptionContentContainer from "../containers/AdditionalOptionContentContainer";

class SingleSelectOption extends React.Component {

  static propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    option: PropTypes.object.isRequired,
    isFinalized: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired,
    createResponse: PropTypes.func.isRequired,
    deleteResponse: PropTypes.func.isRequired
  };

  handleChange = (questionId, optionId, type) => (event) => {
    const {createResponse, deleteResponse} = this.props;
    if (event.target.checked) {
      createResponse(questionId, optionId, type);
    } else {
      deleteResponse(questionId, optionId);
    }
  };

  render() {
    const {questionId, option, isFinalized, isLocked} = this.props;
    const topLevel = !option.parentOptionId;
    return (
      <div id={"option-" + option.id} className={"singleSelect questionOption" + (option.error ? " error" : "") + (topLevel && option.selected ? " selected" : "")}>
        <If test={option.error}>
          <div className="questionError">{option.error}</div>
        </If>
        <div className="questionFlexDiv">
          <div className="questionRadioButtonDiv">
            <Radio className="questionOptionInput questionOptionRadio"
              value={option.id.toString()}
              checked={option.selected}
              onChange={this.handleChange(option.questionId, option.id, option.type)}
              disabled={isFinalized || isLocked}
            />
          </div>
          <div className="questionOptionTextDiv">
            <label className="questionOptionLabel" htmlFor={"option-" + option.id} dangerouslySetInnerHTML={{__html: option.prompt}}/>
          </div>
        </div>
        <AdditionalOptionContentContainer questionId={questionId} option={option} />
      </div>
    );
  }
}
export default SingleSelectOption;