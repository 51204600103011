import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {branch, compose, renderNothing} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {getColor, isOpen, isEditing, getTitle, getVisibility, getAllNoteFilters} from "../selectors";
import {toggle, updateContent} from "../actions";
import {toJS} from "../../common/utils";
import Note from '../components/Note';

const mapStateToProps = createStructuredSelector({
  title: getTitle,
  color: getColor,
  open: isOpen,
  editing: isEditing,
  visibility: getVisibility,
  filters: getAllNoteFilters,
});


const mapDispatchToProps = {
  toggle,
  updateContent,
};

//if there is no content then don't bother rendering -- the note is likely loading
const NoteOrNothing = branch(({title}) => !title && title !== '', renderNothing)(Note);

const NoteContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(NoteOrNothing);

NoteContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default NoteContainer;

