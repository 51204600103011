import * as PropTypes from 'prop-types';
import React from 'react';
import MenubarSearchComponent from "../../search/containers/MenubarSearchContainer";
import DomContainer from "./DomContainer";
import {menuButtons, menuSearchAreaEl, menubarSearchContainerEl, menubarContainerEl} from './domNodes';

class Menubar extends React.Component {
  static propTypes = {
    isSticky: PropTypes.bool.isRequired,
    style: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {hasSearch: (menuSearchAreaEl !== null)};
    if(menubarSearchContainerEl) {
      menubarSearchContainerEl.remove();
      menubarSearchContainerEl.id = "";
    }

    if (menubarContainerEl) {
      menubarContainerEl.id = "";
      menubarContainerEl.remove();
    }
  }

  render() {
    const {isSticky, style} = this.props;
    const {hasSearch} = this.state;
    return (
      <div id="menubarContainer" className={`${isSticky ? "isSticky" : "isNotSticky"}`} style={style}>
        <div id="menubar">
          <div id="menu">
            <DomContainer key="menubar-buttons" id="menuButtons" children={menuButtons}/>
            {hasSearch && <MenubarSearchComponent key="menubar-search"/>}
          </div>
        </div>
      </div>
    );
  }
}

export default Menubar;

