import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from 'reselect';

import {getHelpTopics, getHelpVideos, isPrintLoading} from '../selectors';
import {toJS} from "../utils";
import PageTitle from "../components/PageTitle";

const mapStateToProps = createStructuredSelector({
  isPrintLoading: isPrintLoading,
  helpTopics: getHelpTopics,
  helpVideos: getHelpVideos
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PageTitle);
