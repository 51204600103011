import * as PropTypes from 'prop-types';
import React from "react";
import {Sticky, StickyContainer} from "react-sticky";
import CodePage from './CodePage';
import Menubar from './Menubar';
import './App.css';
import SearchResultsPageContainer from "../../search/containers/SearchResultsPageContainer";


const App = ({bodyTop, isSearchResultsPage}) => (
  <StickyContainer key="main-sticky-container" id="mainStickyContainer" className="clearfix">
    <div id="menubarStickyWrapper">
      <Sticky key="menubar-sticky" id="menubarSticky" topOffset={-bodyTop}>
        {({style, isSticky, wasSticky}) => {
          if (isSticky !== wasSticky) {
            if (isSticky) {
              document.body.classList.add("menubar-is-sticky");
            } else {
              document.body.classList.remove("menubar-is-sticky");
            }
          }
          return <Menubar key="main-menubar" isSticky={isSticky} style={style}/>
        }}
      </Sticky>
    </div>
    {isSearchResultsPage ? <SearchResultsPageContainer key="search-results-page"/> : <CodePage key="code-page"/>}
  </StickyContainer>
);

App.propTypes = {
  bodyTop: PropTypes.number.isRequired,
  isSearchResultsPage: PropTypes.bool.isRequired,
};
export default App;
