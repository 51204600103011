const SEARCH_RESULTS = "SEARCH_RESULTS";
const CHARTER = "CHARTER";
const CODE = "CODE";
const INDEX = "INDEX";
const ARCHIVES = "ARCHIVES";
const NOTES = "NOTES";
const NEW_LAWS = "NEW_LAWS";
const PAST_LAWS = "PAST_LAWS";
const PUBLIC_DOCUMENTS = "PUBLIC_DOCUMENTS";
const PUBLIC_DOCUMENTS_LANDING = "PUBLIC_DOCUMENTS_LANDING";
const QUESTIONS = "QUESTIONS";
const ADMIN = "ADMIN";
const HELP = "HELP";
const OTHER = "OTHER";
const ABOUT = "ABOUT";
const HOME = "HOME";
const NAVIGATION = "NAVIGATION";
const TOOLBAR = "TOOLBAR";
const SEARCH_HELP = "SEARCH_HELP";
const USERS_HELP = "USERS_HELP";
const ADMINISTRATION_HELP = "ADMINISTRATION_HELP";
const LAWS_HELP = "LAWS_HELP";
const PUBLIC_DOCUMENTS_HELP = "PUBLIC_DOCUMENTS_HELP";
const FAQ_HELP = "FAQ_HELP";
const SUPPORT_HELP = "SUPPORT_HELP";

export {
  SEARCH_RESULTS,
  CHARTER,
  CODE,
  INDEX,
  ARCHIVES,
  NOTES,
  NEW_LAWS,
  PAST_LAWS,
  PUBLIC_DOCUMENTS,
  PUBLIC_DOCUMENTS_LANDING,
  QUESTIONS,
  ADMIN,
  HELP,
  OTHER,
  ABOUT,
  HOME,
  NAVIGATION,
  TOOLBAR,
  SEARCH_HELP,
  USERS_HELP,
  ADMINISTRATION_HELP,
  LAWS_HELP,
  PUBLIC_DOCUMENTS_HELP,
  FAQ_HELP,
  SUPPORT_HELP
};