import React from "react";
import * as PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel, MenuItem,
  Select,
  Switch,
  TextField
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {If} from "../../common/containers";
import {CircularProgress} from "../../common/components/progress";
import './PubDocCategoryEditDialog.css';

class PubDocCategoryEditDialog extends React.Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    categoryEditOpen: PropTypes.bool.isRequired,
    categoryEditId: PropTypes.number,
    hasPubDocPrivateViewPermission: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      name: "",
      description: "",
      isPrivate: false,
      groupByType: "",
      sortByFirstType: "Alphabetical_Ascending",
      sortBySecondType: "None"
    };
  }

  handleSaveClick = () => {
    const {name, description, isPrivate, groupByType, sortByFirstType, sortBySecondType} = this.state;
    const {custId, typeId, typeTitle, categoryEditId} = this.props;
    if (categoryEditId) {
      this.props.editCategory(custId, name, description, typeId, typeTitle, categoryEditId, isPrivate, groupByType, sortByFirstType, sortBySecondType);
    } else {
      this.props.addCategory(custId, name, description, typeId, typeTitle, isPrivate, groupByType, sortByFirstType, sortBySecondType);
    }
  }

  handleCancelClick = () => {
    this.setState({name: "", description: ""});
    this.props.onCancel();
  };

  handleNameChange = (event) => {
    const value = event.target.value;
    this.setState({name: value});
  };

  handleDescriptionChange = (event) => {
    const value = event.target.value;
    this.setState({description: value});
  };

  handleChangePrivate = (event) => {
    this.setState({isPrivate: event.target.checked});
  }

  handleGroupByTypeChange = (event) => {
    this.setState({groupByType: event.target.value});
  }

  handleSortByFirstTypeChange = (event) => {
    this.setState({sortByFirstType: event.target.value});
  }

  handleSortBySecondTypeChange = (event) => {
    this.setState({sortBySecondType: event.target.value});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEditOpen = prevProps.categoryEditOpen;
    const { categoryEditOpen, categoryEditId, categories } = this.props;
    if (categoryEditOpen && !prevEditOpen) {
      if (categoryEditId) {
        const currCategory = categories.filter(category => category.id === categoryEditId)[0];
        this.setState({title: "Edit a Category"});
        this.setState({name: currCategory.title ? currCategory.title : ""});
        this.setState({description: currCategory.description ? currCategory.description : ""});
        this.setState({isPrivate: currCategory.private ? currCategory.private : false});
        this.setState({groupByType: currCategory.groupByType ? currCategory.groupByType : ""});
        this.setState({sortByFirstType: currCategory.sortByFirstType ? currCategory.sortByFirstType : "Alphabetical_Ascending"});
        this.setState({sortBySecondType: currCategory.sortBySecondType ? currCategory.sortBySecondType : "None"});
      } else {
        this.setState({title: "Add a Category"});
        this.setState({name: ""});
        this.setState({description: ""});
        this.setState({isPrivate: false});
        this.setState({groupByType: "Ungrouped"});
        this.setState({sortByFirstType: "Alphabetical_Ascending"});
        this.setState({sortBySecondType: "None"});
      }
    }
  }

  render() {
    const {categoryEditOpen, hasPubDocPrivateViewPermission, categoryEditLoading} = this.props;
    const {title, name, description, isPrivate, groupByType, sortByFirstType, sortBySecondType} = this.state;
    return (
      <div>
        <Dialog
          open={categoryEditOpen}
          onClose={this.handleCancelClick}
          maxWidth={"md"}
          fullWidth={true}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent className={`pubDocCategoryEditDialogContent ${!categoryEditLoading ? "" : "loading"}`}>
            <If test={categoryEditLoading}>
              <div className="spinner">
                <CircularProgress size={80}/>
              </div>
            </If>
            <If test={!categoryEditLoading}>
              <TextField
                label="Title"
                value={!name ? "" : name}
                onChange={this.handleNameChange}
                className={"pubDocCategoryEditTitle"}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Description"
                value={!description ? "" : description}
                onChange={this.handleDescriptionChange}
                className={"pubDocCategoryEditDescription"}
                variant="outlined"
                rows={6}
                multiline
              />
              <div className={"pubDocCategoryEditPrivateSelector"}>
                <If test={hasPubDocPrivateViewPermission}>
                  <FormControlLabel control={
                    <Switch checked={isPrivate} onChange={this.handleChangePrivate}/>
                  }
                  label="Private?"/>
                </If>
              </div>
              <FormControl className={"pubDocCategoryEditForm"}>
                <InputLabel>Group By:</InputLabel>
                <Select value={groupByType} onChange={this.handleGroupByTypeChange}>
                  <MenuItem value="Ungrouped">Ungrouped</MenuItem>
                  <MenuItem value="Year_Ascending">By Year (Ascending)</MenuItem>
                  <MenuItem value="Year_Descending">By Year (Descending)</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={"pubDocCategoryEditForm"}>
                <InputLabel>First Sort By:</InputLabel>
                <Select value={sortByFirstType} onChange={this.handleSortByFirstTypeChange}>
                  <MenuItem value="Alphabetical_Ascending">Alphabetical Ascending</MenuItem>
                  <MenuItem value="Alphabetical_Descending">Alphabetical Descending</MenuItem>
                  <MenuItem value="Date_Ascending">Date Ascending</MenuItem>
                  <MenuItem value="Date_Descending">Date Descending</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={"pubDocCategoryEditForm"}>
                <InputLabel>Then Sort By:</InputLabel>
                <Select value={sortBySecondType} onChange={this.handleSortBySecondTypeChange}>
                  <MenuItem value="None">No Sorting</MenuItem>
                  <MenuItem value="Alphabetical_Ascending">Alphabetical Ascending</MenuItem>
                  <MenuItem value="Alphabetical_Descending">Alphabetical Descending</MenuItem>
                  <MenuItem value="Date_Ascending">Date Ascending</MenuItem>
                  <MenuItem value="Date_Descending">Date Descending</MenuItem>
                </Select>
              </FormControl>
            </If>
          </DialogContent>
          <If test={!categoryEditLoading}>
            <DialogActions>
              <Button variant="contained" onClick={this.handleCancelClick} color={"default"}>Cancel</Button>
              <Button variant="contained" onClick={this.handleSaveClick} color="primary" disabled={name.length < 1 || !name.trim() || categoryEditLoading}>Save</Button>
            </DialogActions>
          </If>
        </Dialog>
      </div>
    );
  }
}
export default PubDocCategoryEditDialog;