import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {CircularProgress} from "../../common/components/progress";
import If from "../../common/containers/If";
import {hasDocumentAdminPermission} from "../../common/selectors";
import PubDocCategoryContainer from "../containers/PubDocCategoryContainer";
import PubDocCategoryEditDialogContainer from "../containers/PubDocCategoryEditDialogContainer";
import PubDocEditDialogContainer from "../containers/PubDocEditDialogContainer";
import PubDocMoveDialogContainer from "../containers/PubDocMoveDialogContainer";
import PubDocBulkUploaderDialogContainer from "../containers/PubDocBulkUploaderDialogContainer";
import PubDocToolbarContainer from "../containers/PubDocToolbarContainer";
import PubDocsPageTitleContainer from "../containers/PubDocsPageTitleContainer";
import './PubDocType.css';

class PubDocType extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    pubDocTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pubDocTypeTitle: PropTypes.string,
    pubDocTypeDescription: PropTypes.string,
    documents: PropTypes.array.isRequired,
    documentsLoading: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    filterOptions: PropTypes.object.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    hasBulkUploadPermission: PropTypes.bool.isRequired,
    loadPubDocs: PropTypes.func.isRequired,
    loadPubDocCategories: PropTypes.func.isRequired,
    setCategoryEditOpen: PropTypes.func.isRequired,
    setDocumentEditOpen: PropTypes.func.isRequired,
    setDocumentMoveOpen: PropTypes.func.isRequired
  };

  state = {
    documentToolbarMenuOpen: false,
    bulkUploaderOpen: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    this.props.loadPubDocs(this.props.custId, this.props.pubDocTypeId);
    this.props.loadPubDocCategories(this.props.custId, this.props.pubDocTypeId);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  closeEditCategoryDialog = () => {
    this.props.setCategoryEditOpen(false);
  }

  listenToScroll = () => {
    const stickyToolbar = document.getElementsByClassName("appBarSticky");
    const toolbarHeight = stickyToolbar.length > 0 ? stickyToolbar[0].offsetHeight : 0;
    const filteredCategory = this.props.categories.filter(category => {
      if (document.getElementById("category-" + category.id) != null) {
        return document.getElementById("category-" + category.id).getBoundingClientRect().top < toolbarHeight;
      }
      return null;
    });

    const categoryBar = document.getElementsByClassName("gpsCategoryBar");
    if (categoryBar.length > 0) {
      if (filteredCategory.length > 0) {
        categoryBar[0].style.display = "block";
        document.getElementById("gpsCategoryText").textContent = "Current Category: " + filteredCategory[filteredCategory.length - 1].title;
      } else {
        categoryBar[0].style.display = "none";
      }
    }
  }

  closeEditDocumentDialog = () => {
    this.props.setDocumentEditOpen(false, null, null);
  }

  handleCloseMove = () => {
    this.setState({documentToolbarMenuOpen: false});
    this.props.setDocumentMoveOpen(false, null);
  }

  openBulkUploaderDialog = () => {
    this.setState({bulkUploaderOpen: true});
  };

  closeBulkUploaderDialog = () => {
    this.setState({bulkUploaderOpen: false});
  }

  shouldShowToolbar = () => {
    const {filterOptions, hasDocumentAdminPermission} = this.props;
    return hasDocumentAdminPermission ||
      (((filterOptions.categoryOptions ? filterOptions.categoryOptions.length : 0) > 1)
      || ((filterOptions.yearOptions ? filterOptions.yearOptions.length : 0) > 1)
      || ((filterOptions.monthOptions ? filterOptions.monthOptions.length : 0) > 1)
      || ((filterOptions.dayOptions ? filterOptions.dayOptions.length : 0) > 1));
  }

  render() {
    const { bulkUploaderOpen } = this.state;
    const { custId, pubDocTypeId, pubDocTypeTitle, pubDocTypeDescription, documents, documentsLoading, categories,
      filterOptions, hasBulkUploadPermission } = this.props;
    return (
      <div id="contentContainer">
        <PubDocsPageTitleContainer />
        <div id="pageDescriptionContainer">
          <div id="pageDescription">
            <span className="pubDocTypeTitleDescription">{pubDocTypeDescription}</span>
          </div>
        </div>
        <If test={documentsLoading}>
          <div className="spinnerContainer"><CircularProgress size={80}/></div>
        </If>
        <If test={!documentsLoading}>
          <If test={this.shouldShowToolbar()}>
            <PubDocToolbarContainer filterOptions={filterOptions} documents={documents}/>
          </If>
          <div className="pubDocTypeContainer">
            <If test={documents}>
              {categories.map((category) => <PubDocCategoryContainer key={category.id} category={category} />)}
            </If>
          </div>
          <PubDocCategoryEditDialogContainer onCancel={this.closeEditCategoryDialog} custId={custId}
            typeId={pubDocTypeId} typeTitle={pubDocTypeTitle}
          />
          <PubDocEditDialogContainer onCancel={this.closeEditDocumentDialog} custId={custId} typeId={pubDocTypeId}
            setBulkUploaderOpen={this.openBulkUploaderDialog}
          />
          <PubDocMoveDialogContainer onCancel={this.handleCloseMove} />
          <If test={hasBulkUploadPermission}>
            <PubDocBulkUploaderDialogContainer open={bulkUploaderOpen} onCancel={this.closeBulkUploaderDialog} />
          </If>
        </If>
      </div>
    );
  }
}
export default PubDocType;
