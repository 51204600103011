import * as PropTypes from "prop-types";
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ListSubheader} from "../../common/components/list";
import {MenuItem, MenuList} from "../../common/components/menu";
import Paper from "../../common/components/Paper";
import TextField from "../../common/components/TextField";
import {If} from "../../common/containers";
import {FormControl, InputLabel, Select} from "@material-ui/core";
import {CircularProgress} from "../../common/components/progress";
import './PubDocTypeEditDialog.css';

class PubDocTypeEditDialog extends React.Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    suggestedPubDocTypes: PropTypes.object,
    pubDocTypes: PropTypes.array,
    typeEditOpen: PropTypes.bool.isRequired,
    typeEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addType: PropTypes.func,
    editType: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      isNameFieldActive: false,
      title: "",
      sortType: ""
    };
  }

  handleNameChange = (event) => {
    const value = event.target.value;
    this.setState({name: value});
  };

  handleDescriptionChange = (event) => {
    const value = event.target.value;
    this.setState({description: value});
  };

  handleCancelClick = () => {
    this.setState({name: "", description: "", isNameFieldActive: false});
    this.props.onCancel();
  };

  handleSaveClick = () => {
    const {custId, editType, addType, typeEditId} = this.props;
    const {name, description, sortType} = this.state;
    if (name.length > 0) {
      if (typeEditId) {
        editType(custId, name, description, typeEditId, sortType);
      } else {
        addType(custId, name, description, sortType);
      }
    }
  };

  handleNameFieldActive = (event) => {
    const {isNameFieldActive} = this.state;
    if (event.key && (event.key === 'Tab' || event.key === 'Enter')) {
      this.setState({isNameFieldActive: false});
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      if (!isNameFieldActive) {
        this.setState({isNameFieldActive: true});
      }
      if (event.key && (event.key === 'Down' || event.key === 'ArrowDown')) {
        const suggestionItems = document.getElementsByClassName("docTypeSuggestionItem");
        if (suggestionItems.length > 0) {
          suggestionItems[0].focus();
          event.preventDefault();
          event.stopPropagation();
        }
      } else if (event.key && (event.key === 'Up' || event.key === 'ArrowUp')) {
        const suggestionItems = document.getElementsByClassName("docTypeSuggestionItem");
        if (suggestionItems.length > 0) {
          suggestionItems[suggestionItems.length - 1].focus();
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  handleNameFieldInactive = () => {
    if (!document.activeElement.classList.contains("docTypeSuggestionItem")) {
      this.setState({isNameFieldActive: false});
    }
  };

  handleSuggestionClick = (defaultName, defaultDescription) => (event) => {
    const target = (event.target.classList.contains("docTypeSuggestionItem") ? event.target : event.target.parentNode);
    if (!target.classList.contains("disabled")) {
      this.setState({isNameFieldActive: false, name: defaultName, description: defaultDescription});
    }
  };

  handleKeyDown = (event) => {
    if (event.key && event.key === 'Tab') {
      this.setState({isNameFieldActive: false});
    }
  };

  handleSortTypeChange = (event) => {
    this.setState({"sortType": event.target.value});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEditOpen = prevProps.typeEditOpen;
    const { typeEditOpen, typeEditId } = this.props;
    if (typeEditOpen && !prevEditOpen) {
      const pubDocType = this.props.pubDocTypes.filter(type => {
        return type.id === typeEditId;
      })[0];
      this.setState({"name": (pubDocType && pubDocType.title) ? pubDocType.title : ""});
      this.setState({"description": (pubDocType && pubDocType.description) ? pubDocType.description : ""});
      this.setState({"sortType": (pubDocType && pubDocType.sortType) ? pubDocType.sortType : ""});
      if (typeEditId) {
        this.setState({"title": "Edit a Public Document Type"});
      } else {
        this.setState({"title": "Add a new Public Document Type"});
        this.setState({"sortType": "CATEGORY_NAME_ASC"});
      }
    }
  }

  hasPubDocType = (title) => {
    const {pubDocTypes} = this.props;
    for (let i = 0; i < pubDocTypes.length; i++) {
      if (pubDocTypes[i].title === title) {
        return true;
      }
    }
    return false;
  }

  render() {
    const {typeEditOpen, suggestedPubDocTypes, typeEditLoading} = this.props;
    const {isNameFieldActive, name, description, title, sortType} = this.state;
    return (
      <Dialog id="doc-type-edit-dialog"
        open={Boolean(typeEditOpen)}
        onClose={this.handleCancelClick}
        aria-labelledby="confirm-dialog"
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={`dialogContent pubDocTypeDialogContent ${!typeEditLoading ?  "" : "loading"}`}>
          <If test={typeEditLoading}>
            <div className="spinner">
              <CircularProgress size={80}/>
            </div>
          </If>
          <If test={!typeEditLoading}>
            <form autoComplete="off" className="suggestionForm">
              <TextField
                id="doc-type-name"
                className="docTypeName suggestionField pubDocTypeDialogName"
                label="Name"
                variant="outlined"
                size="small"
                value={!name ? "" : name}
                onChange={this.handleNameChange}
                onFocus={this.handleNameFieldActive}
                onClick={this.handleNameFieldActive}
                onKeyDown={this.handleNameFieldActive}
                onBlur={() => setTimeout(this.handleNameFieldInactive, 100)}
                aria-owns="docTypeNameSuggestContainer"
              />
              <div id="docTypeNameSuggestContainer" className={"suggestionContainer" + ((!isNameFieldActive || (!suggestedPubDocTypes || suggestedPubDocTypes.length === 0)) ? " hidden" : "")}>
                <Paper>
                  <ListSubheader component="div" className="suggestionContainerLabel" tabIndex="0">Suggested Type names...</ListSubheader>
                  <MenuList dense={true}>
                    {suggestedPubDocTypes && Object.keys(suggestedPubDocTypes).map((suggestedName) =>
                      <MenuItem key={suggestedName}
                        className={"suggestionItem docTypeSuggestionItem" + (suggestedName === name ? " selected" : "")
                        + (this.hasPubDocType(suggestedName) ? " disabled" : "")}
                        onClick={this.handleSuggestionClick(suggestedName, suggestedPubDocTypes[suggestedName])}
                        onKeyDown={this.handleKeyDown}
                      >
                        {suggestedName}
                        <If test={this.hasPubDocType(suggestedName)}>
                          <span className="typeExists">&nbsp;[Already Exists]</span>
                        </If>
                      </MenuItem>
                    )}
                  </MenuList>
                </Paper>
              </div>
            </form>
            <TextField
              id="doc-type-description"
              className="docTypeDescription pubDocTypeDialogDescription"
              label="Description"
              variant="outlined"
              rows={6}
              value={!description ? "" : description}
              onChange={this.handleDescriptionChange}
              multiline
            />
            <FormControl>
              <InputLabel>Sorting Type</InputLabel>
              <Select value={sortType} onChange={this.handleSortTypeChange}>
                <MenuItem value="CATEGORY_NAME_ASC">By Category Name (Ascending)</MenuItem>
                <MenuItem value="CATEGORY_NAME_DESC">By Category Name (Descending)</MenuItem>
              </Select>
            </FormControl>
          </If>
        </DialogContent>
        <If test={!typeEditLoading}>
          <DialogActions>
            <Button variant="contained" onClick={this.handleCancelClick} color={"default"} >Cancel</Button>
            <Button variant="contained" onClick={this.handleSaveClick} color="primary" disabled={name.length < 1 || !name.trim() || typeEditLoading}>Save</Button>
          </DialogActions>
        </If>
      </Dialog>
    );
  }
}
export default PubDocTypeEditDialog;
