import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {If} from '../../common/containers';
import AddReferenceDialogContainer from '../containers/AddReferenceDialogContainer';
import {getReferenceGroup, getReferenceGroupNames} from '../../common/ReferenceGroupFactory';
import Dropdown from '../../common/editor/components/Dropdown/Dropdown'
import DropdownOption from '../../common/editor/components/Dropdown/DropdownOption'
import Option from '../../common/editor/components/Option'

import './AddReferenceButton.css';
import ReferenceIcon from './assets/ReferenceIcon';

class AddReferenceButton extends Component {

  state = {
    optionsExpanded: false,
    selectedGroupName: '',
  };
  onExpandEvent = () => {
    this.signalExpanded = !this.state.optionsExpanded;
  };
  expandCollapse = () => {
    this.setState({optionsExpanded: this.signalExpanded,});
    this.signalExpanded = false;
  };
  onOptionsExpand = () => {
    this.setState({optionsExpanded: true});
  };
  onOptionsCollapse = () => {
    this.setState({optionsExpanded: false});
  };
  onOpenDialog = (selectedGroupName) => {
    this.setState({selectedGroupName});
  };
  onCloseDialog = () => {
    this.setState({selectedGroupName: ''});
  };

  constructor(props) {
    super(props);
    getReferenceGroupNames().forEach((groupName) => props.fetchRootReferenceNodes(groupName));
  }

  componentWillMount() {
    this.props.modalHandler.registerCallBack(this.expandCollapse);
  }

  componentWillUnmount() {
    this.props.modalHandler.deregisterCallBack(this.expandCollapse);
  }

  render() {
    const {id, allNodes} = this.props;
    const {optionsExpanded, selectedGroupName} = this.state;
    const availableGroups = getReferenceGroupNames().filter(groupName => allNodes[groupName] && allNodes[groupName].length > 0);
    return (
      <If test={availableGroups.length > 0}>
        <div
          className="addReferenceWrapper"
          aria-haspopup="true"
          aria-expanded={selectedGroupName !== ''}
          aria-label="Find and add a reference"
          title="Find and add a reference"
        >
          <If test={availableGroups.length === 1}>
            <Option
              className="addReferenceButton"
              value="unordered-list-item"
              onClick={() => this.onOpenDialog(availableGroups[0])}
            >
              <ReferenceIcon className="addReferenceIcon"/>Add Reference
            </Option>
          </If>
          <If test={availableGroups.length > 1}>
            <Dropdown
              className="addReferenceDropdown"
              onChange={this.onOpenDialog}
              expanded={optionsExpanded}
              doExpand={this.onOptionsExpand}
              doCollapse={this.onOptionsCollapse}
              onExpandEvent={this.onExpandEvent}
            >
              <DropdownOption key={-1}><ReferenceIcon className="addReferenceIcon"/>Add Reference</DropdownOption>
              {availableGroups.map((groupName, index) =>
                <DropdownOption key={index} value={groupName}>{getReferenceGroup(groupName).friendlyName}</DropdownOption>
              )}
            </Dropdown>
          </If>
          {availableGroups.map((groupName, index) =>
            <AddReferenceDialogContainer
              id={id}
              groupName={groupName}
              selectedGroupName={selectedGroupName}
              onClose={this.onCloseDialog}
              key={index}
            />
          )}
        </div>
      </If>
    );
  }
}

AddReferenceButton.propTypes = {
  fetchRootReferenceNodes: PropTypes.func.isRequired,
  allNodes: PropTypes.object.isRequired,
};

export default AddReferenceButton;

