import * as PropTypes from 'prop-types';
import React from 'react';
import Checkbox from "../../common/components/Checkbox";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "../../common/components/expansionPanel";
import FormControlLabel from "../../common/components/FormControlLabel";
import {ExpandMoreIcon} from '../../common/components/icons';

class CheckboxListFacet extends React.Component {

  static propTypes = {
    facet: PropTypes.object.isRequired,
    facetSelections: PropTypes.array.isRequired,
    addFacetSelection: PropTypes.func.isRequired,
    removeFacetSelection: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {facet, facetSelections} = props;
    this.state = {
      defaultExpanded: facet.options.some(option => facetSelections.indexOf(`${facet.value}:${option.value}`) >= 0),
    };
  }

  handleCheckboxChange = (selection, addFacetSelection, removeFacetSelection) => (e) => {
    if (e.target.checked) {
      addFacetSelection(selection);
    } else {
      removeFacetSelection(selection);
    }
  };

  render() {
    const {facet, facetSelections, addFacetSelection, removeFacetSelection} = this.props;
    const {defaultExpanded} = this.state;
    return (
      <ExpansionPanel defaultExpanded={defaultExpanded} className="filterExpansionPanel">
        <ExpansionPanelSummary
          className="filterExpansionPanelSummary"
          classes={{expanded: "filterExpansionPanelSummaryExpanded", content: "filterExpansionPanelSummaryContent", expandIcon: "filterExpansionPanelButton"}}
          expandIcon={<ExpandMoreIcon />}
        >
          <div>
            <span className="cardTitleSmall">Filter By {facet.name}</span>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="filterExpansionPanelDetails">
          {facet.options.map((option) => {
            const selection = `${facet.value}:${option.value}`;
            return (
              <div className="checkboxListFacetOption" key={option.name}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={facetSelections.indexOf(selection) >= 0}
                        onChange={this.handleCheckboxChange(selection, addFacetSelection, removeFacetSelection)}
                        value={selection}
                        className="checkboxListFacetOptionCheckbox"
                      />
                    }
                    label={<span dangerouslySetInnerHTML={{__html: `${option.name} (${option.count.toLocaleString()})`}} />}
                  />
                </div>
              </div>
            );
          })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default CheckboxListFacet;
