import * as PropTypes from "prop-types";
import React, {Fragment} from "react";
import PubDocTypeEditDialogContainer from "../../pub-docs/containers/PubDocTypeEditDialogContainer";
import NavigationItem from "./NavigationItem";
import NavigationSubItem from "./NavigationSubItem";
import ECodeIcon from "./ECodeIcon";
import {SEARCH, CODE as CODE_PAGE, LAWS, NOTES, PUBLIC_DOCUMENTS, QUESTIONS, ADMIN, HELP, ZONING, HOME} from "../utils/page-types";
import {
  CHARTER,
  CODE as CODE_SUB_PAGE,
  INDEX,
  ARCHIVES,
  NEW_LAWS,
  PAST_LAWS,
  PUBLIC_DOCUMENTS_LANDING,
  HELP as HELP_SUB_PAGE,
  ABOUT,
  NAVIGATION, TOOLBAR, SEARCH_HELP, USERS_HELP, ADMINISTRATION_HELP, LAWS_HELP, PUBLIC_DOCUMENTS_HELP, FAQ_HELP,  SUPPORT_HELP
} from "../utils/page-sub-types";

class NavigationRail extends React.PureComponent {

  static propTypes = {
    custId: PropTypes.string,
    archiveId: PropTypes.string,
    codeId: PropTypes.string,
    pageType: PropTypes.string,
    pageSubType: PropTypes.string,
    pubDocsOnly: PropTypes.bool.isRequired,
    codeLabel: PropTypes.string.isRequired,
    charterGuid: PropTypes.string,
    mapUrl: PropTypes.string,
    zoningGuid: PropTypes.string,
    noteCount: PropTypes.number.isRequired,
    lawCount: PropTypes.number.isRequired,
    pastLawCount: PropTypes.number,
    displayedQuestionIds: PropTypes.array,
    pubDocsLabel: PropTypes.string.isRequired,
    pubDocTypes: PropTypes.array,
    pubDocTypeId: PropTypes.number,
    searchResultsUrl: PropTypes.string,
    hasViewPermission: PropTypes.bool.isRequired,
    hasIndexViewPermission: PropTypes.bool.isRequired,
    hasArchivesViewPermission: PropTypes.bool.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    hasPubDocTypeAddPermission: PropTypes.bool.isRequired,
    hasPubDocTypeDeletePermission: PropTypes.bool.isRequired,
    hasPubDocTypeEditPermission: PropTypes.bool.isRequired,
    hasPubDocPrivateViewPermission: PropTypes.bool.isRequired,
    hasLawViewPermission: PropTypes.bool.isRequired,
    hasDashboardPermission: PropTypes.bool.isRequired,
    setPubDocTypeAddOpen: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { expandedPage: null };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(e) {
    const { expandedPage } = this.state;
    if (expandedPage && this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.setState({expandedPage: null});
    }
  }

  setExpanded = (pageId) => () => {
    const pageGridElement = document.getElementById("page-grid");
    if (pageId) {
      pageGridElement.classList.remove("rail-collapsed");
      pageGridElement.classList.add("rail-expanded");
    } else {
      pageGridElement.classList.remove("rail-expanded");
      pageGridElement.classList.add("rail-collapsed");
    }
    this.setState({ expandedPage: (pageId ? pageId : null) });
  }

  buildGuidUrl = (guid) => {
    if (!guid) return null;
    const { archiveId } = this.props;
    if (archiveId) {
      return `/${archiveId}/${guid}`;
    } else {
      return `/${guid}`;
    }
  }

  openPubDocTypeAddDialog = () => this.props.setPubDocTypeAddOpen(true);
  closePubDocTypeAddDialog = () => this.props.setPubDocTypeAddOpen(false);

  render() {
    const { custId, archiveId, codeId, pageType, pageSubType, pubDocsOnly, codeLabel, charterGuid, mapUrl, zoningGuid,
      noteCount, lawCount, pastLawCount, analysisId, displayedQuestionIds, pubDocsLabel, pubDocTypes, pubDocTypeId,
      searchResultsUrl, hasViewPermission, hasIndexViewPermission, hasArchivesViewPermission, hasDocumentAdminPermission,
      hasPubDocTypeAddPermission, hasPubDocTypeDeletePermission, hasPubDocTypeEditPermission,
      hasPubDocPrivateViewPermission, hasLawViewPermission, hasDashboardPermission
    } = this.props;
    const { expandedPage } = this.state;
    return (
      <Fragment>
        <div id="rail" className={expandedPage ? "expanded" : "collapsed"} ref={this.wrapperRef}>
          <div className="items">
            <NavigationItem label="Home"
              iconName="home"
              iconSize={32}
              href={`/${codeId ? codeId + "/home" : ""}`}
              selected={pageType === HOME}
            />
            <NavigationItem label={codeLabel}
              iconName="library_books"
              hidden={Boolean(pubDocsOnly || !custId || !hasViewPermission)}
              href={`/${codeId}`}
              selected={pageType === CODE_PAGE}
              expanded={expandedPage === CODE_PAGE}
              expandRail={this.setExpanded(CODE_PAGE)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem label={codeLabel} href={`/${codeId}`} selected={pageSubType === CODE_SUB_PAGE} isTitle={true} />
              <NavigationSubItem label="Charter"
                hidden={Boolean(!charterGuid)}
                href={this.buildGuidUrl(charterGuid)}
                selected={pageSubType === CHARTER}
              />
              <NavigationSubItem label="Index"
                hidden={Boolean(!hasIndexViewPermission)}
                href={`/index/${custId}${archiveId ? "/code" + archiveId : ""}/`}
                selected={pageSubType === INDEX}
              />
              <NavigationSubItem label="Archives"
                hidden={Boolean(!hasArchivesViewPermission)}
                href={`/archives/${custId}`}
                selected={pageSubType === ARCHIVES}
              />
            </NavigationItem>
            <NavigationItem label="Zoning"
              title="Zoning specific resources"
              iconName="map"
              hidden={Boolean(!custId || !hasViewPermission || (!mapUrl && !zoningGuid))}
              expanded={expandedPage === ZONING}
              expandRail={this.setExpanded(ZONING)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem label="Zoning" isTitle={true} isLink={false}/>
              <NavigationSubItem label="Zoning Map" href={mapUrl} openNewTab={true} />
              <NavigationSubItem label="Zoning Chapter" href={this.buildGuidUrl(zoningGuid)} />
            </NavigationItem>
            <NavigationItem label="Laws"
              title="Laws, Ordinances, and other Enactments"
              iconName="gavel"
              hidden={Boolean(pubDocsOnly || !codeId || !hasViewPermission || !hasLawViewPermission)}
              selected={pageType === LAWS}
              count={lawCount + pastLawCount}
              expanded={expandedPage === LAWS}
              expandRail={this.setExpanded(LAWS)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem label="New Laws"
                href={`/laws/${codeId}`}
                selected={pageSubType === NEW_LAWS}
                count={lawCount}
              />
              <NavigationSubItem label="Past Laws"
                hidden={(pastLawCount === null)}
                href={`/past-laws/${codeId}`}
                selected={pageSubType === PAST_LAWS}
                count={pastLawCount}
              />
            </NavigationItem>
            <NavigationItem label="Notes"
              title="View Notes and Annotations that have added to the Code"
              iconName="sticky_note_2"
              hidden={Boolean(!custId || !hasViewPermission || archiveId || noteCount === 0)}
              href={`/note/${custId}`}
              selected={pageType === NOTES}
              count={noteCount}
            />
            <NavigationItem label={pubDocsLabel}
              title="Documents posted and shared for easy access"
              iconName="inventory_2"
              hidden={Boolean(!custId || !hasViewPermission || archiveId
                || (pubDocTypes.length === 0 && !hasDocumentAdminPermission && !hasPubDocTypeAddPermission
                  && !hasPubDocTypeDeletePermission && !hasPubDocTypeEditPermission))}
              href={`/${custId}/document/types`}
              selected={pageType === PUBLIC_DOCUMENTS}
              expanded={expandedPage === PUBLIC_DOCUMENTS}
              expandRail={this.setExpanded(PUBLIC_DOCUMENTS)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem
                label="Public Documents"
                href={`/${custId}/document/types`}
                isTitle={true}
                selected={pageSubType === PUBLIC_DOCUMENTS_LANDING}
              />
              <NavigationSubItem label="Add a Type"
                hidden={Boolean(!hasPubDocTypeAddPermission)}
                onClick={this.openPubDocTypeAddDialog}
              />
              {pubDocTypes.map((type) => (
                <NavigationSubItem key={type.id}
                  label={type.title}
                  hidden={Boolean(!hasDocumentAdminPermission
                    && ((hasPubDocPrivateViewPermission ? type.count : (type.count - type.privateCount)) < 1))}
                  href={`/${custId}/documents/${encodeURIComponent(type.title)}`}
                  selected={pubDocTypeId === type.id}
                  count={(hasPubDocPrivateViewPermission ? type.count : (type.count - type.privateCount))}
                />
              ))}
            </NavigationItem>
            <NavigationItem label="Questions"
              title="Editorial and Legal Analysis Questions"
              iconName="question_answer"
              hidden={Boolean(!custId || !hasViewPermission || archiveId || !analysisId)}
              href={`/${custId}/questions`}
              selected={pageType === QUESTIONS}
              expanded={expandedPage === QUESTIONS}
              expandRail={this.setExpanded(QUESTIONS)}
              collapseRail={this.setExpanded(null)}
              count={displayedQuestionIds ? displayedQuestionIds.length : 0}
            />

            <NavigationItem label="Admin"
              title="Access administrative tools"
              iconName="build"
              hidden={Boolean(!custId || !hasDashboardPermission)}
              href={`/dashboard/${custId}`}
              selected={pageType === ADMIN}
            />

            <NavigationItem label="Help"
              title="Get help using eCode360"
              iconName="help_center"
              href={"/help" + (custId ? ("/" + custId) : "")}
              selected={pageType === HELP}
              expanded={expandedPage === HELP}
              expandRail={this.setExpanded(HELP)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem label={"Help"}
                isTitle={true}
                isLink={false}
              />
              <NavigationSubItem label={"Welcome"}
                href={"/help" + (custId ? ("/" + custId) : "")}
                selected={pageSubType === HELP_SUB_PAGE}
              />
              <NavigationSubItem label={"Basic Navigation"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/navigation"}
                selected={pageSubType === NAVIGATION}
              />
              <NavigationSubItem label={"Toolbar"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/tools"}
                selected={pageSubType === TOOLBAR}
              />
              <NavigationSubItem label={"Searching"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/search"}
                selected={pageSubType === SEARCH_HELP}
              />
              <NavigationSubItem label={"Tools: Municipal Users"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/users"}
                selected={pageSubType === USERS_HELP}
              />
              <NavigationSubItem label={"Tools: Administrators"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/administration"}
                selected={pageSubType === ADMINISTRATION_HELP}
              />
              <NavigationSubItem label={"New Laws"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/laws"}
                selected={pageSubType === LAWS_HELP}
              />
              <NavigationSubItem label={"Public Documents"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/publicdocuments"}
                selected={pageSubType === PUBLIC_DOCUMENTS_HELP}
              />
              <NavigationSubItem label={"FAQ"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/faq"}
                selected={pageSubType === FAQ_HELP}
              />
              <NavigationSubItem label={"Request Support"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/support"}
                selected={pageSubType === SUPPORT_HELP}
              />
              <NavigationSubItem label="About"
                href={"/about" + (custId ? ("/" + custId) : "")}
                selected={pageSubType === ABOUT}
              />
            </NavigationItem>

            <NavigationItem label="Search"
              title="Results from the recent search"
              iconName="search"
              hidden={Boolean(pageType !== SEARCH && !searchResultsUrl)}
              href={searchResultsUrl ? searchResultsUrl : (codeId ? `/${codeId}/search` : "search?codeFinder=true")}
              selected={pageType === SEARCH}
              expanded={expandedPage === SEARCH}
              expandRail={this.setExpanded(SEARCH)}
              collapseRail={this.setExpanded(null)}
            />

            <a href="http://www.generalcode.com" id="gcLogo">
              <ECodeIcon/>
            </a>

          </div>
        </div>
        <PubDocTypeEditDialogContainer onCancel={this.closePubDocTypeAddDialog} />
      </Fragment>
    );
  }
}
export default NavigationRail;
