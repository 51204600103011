import React from 'react';
import CheckboxListFacet from './CheckboxListFacet';
import './FacetList.css';

const hasFacets = (facets, facetSelections) => {
  return facets.length > 0 && facets.reduce((result, facet) => result || isFacetDisplayable(facet, facetSelections), false);
};

const isFacetDisplayable = (facet, facetSelections) => {
  return facet.options.length > 1 || facetSelections.reduce((result, selection) => result || selection.indexOf(`${facet.value}:`) === 0, false);
};

const FacetList = ({facets, facetSelections, addFacetSelection, removeFacetSelection}) => {
  return !hasFacets(facets, facetSelections) ? null : (
    <div className="facetListContainer">
      {facets.map((facet, i) => !isFacetDisplayable(facet, facetSelections) ? null :
        <CheckboxListFacet
          key={i}
          facet={facet}
          facetSelections={facetSelections}
          addFacetSelection={addFacetSelection}
          removeFacetSelection={removeFacetSelection}
        />
      )}
    </div>
  );
};

export default FacetList;