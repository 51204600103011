import React from 'react';
import {Treebeard} from 'react-treebeard';
import {palette} from '../theme/theme';

const Tree = ({style = {}, ...props}) => {
  const treeStyle = {
    tree: {
      base: {
        listStyle: 'none',
        backgroundColor: '#fff',
        margin: 0,
        padding: 0,
        color: palette.site.gray,
        fontFamily: '"freight-sans-pro",sans-serif',
        fontSize: '16px',
        ...style.tree ? style.tree.base : {},
      },
      node: {
        base: {
          position: 'relative',
          ...style.node ? style.node.base : {},
        },
        link: {
          cursor: 'pointer',
          position: 'relative',
          padding: '0px 5px',
          display: 'block',
          whiteSpace: 'nowrap',
          ...style.node ? style.node.link : {},
        },
        activeLink: {
          background: 'rgba(0, 126, 255, 0.1)',
          //border: '1px solid #333',
          borderRadius: '3px',
          ...style.node ? style.node.activeLink : {},
        },
        toggle: {
          height: 14,
          width: 14,
          ...style.node ? style.node.toggle : {},
          base: {
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'top',
            marginLeft: '-5px',
            height: '24px',
            width: '24px',
            ...style.node && style.node.toggle ? style.node.toggle.base : {},
          },
          wrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            margin: '-7px 0 0 -7px',
            height: '14px',
            ...style.node && style.node.toggle ? style.node.toggle.wrapper : {},
          },
          arrow: {
            fill: palette.site.lightGray,
            strokeWidth: 0,
            ...style.node && style.node.toggle ? style.node.toggle.arrow : {},
          }
        },
        header: {
          base: {
            display: 'inline-block',
            verticalAlign: 'top',
            color: palette.site.gray,
            ...style.node && style.node.header ? style.node.header.base : {},
          },
          connector: {
            width: '2px',
            height: '12px',
            borderLeft: 'solid 2px black',
            borderBottom: 'solid 2px black',
            position: 'absolute',
            top: '0px',
            left: '-21px',
            ...style.node && style.node.header ? style.node.header.connector : {},
          },
          title: {
            lineHeight: '24px',
            verticalAlign: 'middle',
            ...style.node && style.node.header ? style.node.header.title: {},
          }
        },
        subtree: {
          listStyle: 'none',
          paddingLeft: '19px',
          margin: '0px',
          ...style.node ? style.node.subtree : {},
        },
        loading: {
          color: palette.site.pineapple,
          ...style.node ? style.node.loading : {},
        }
      }
    }
  };
  return <Treebeard {...props} style={treeStyle}/>;
};

export default Tree;
