import {List, Map, Record, Set} from "immutable";
import defaultTo from 'lodash/defaultTo';
import {handleActions} from 'redux-actions';
import * as actions from '../actions';
import * as referencesActions from '../actions/references';

const Customization = Record({
  accentBorderColor: null,
  accentColor: null,
  accentHighlightColor: null,
  accentLineColor: null,
  accentTextColor: null,
  adminX: 0,
  adminY: 0,
  banner: false,
  bannerBackgroundColor: null,
  cname: null,
  seal: false,
  website: null,
  mapUrl: null,
  websiteHeight: 0,
  websiteOffsetX: 0,
  websiteOffsetY: 0,
  websiteWidth: 0,
  showCountyLine: false,
  lawTitleField: null,
  translate: false,
  ia2: false,
  codeReviewPhase: null,
  created: null,
  updated: null,
  bannerBackgroundColorLight: false,
  customerName: "",
  pubdocsDateDisabled: false,
  legrefLinkEnabled: false,
  sidebarLabel: "",
  pubDocsOnlyText: "",
  allowRemoteAuth: false,
  codifiedLawViewEnabled: false,
  ia2ImportEnabled: false,
  mixedContentEnabled: false
});

const Customer = Record({
  id: null,
  county: null,
  custId: null,
  customization: null,
  featureLevel: null,
  govtype: null,
  municipality: null,
  name: null,
  population: null,
  solo: false,
  state: null,
  warning: null,
  zipcode: null,
});

const ReferenceNode = Record({
  type: null,
  key: null,
  title: null,
  context: null,
  name: null,
  index: null,
  children: List([]),
  active: false,
  toggled: false,
  loading: false,
});

const ReferenceMatch = Record({
  type: null,
  key: null,
  title: null,
  context: null,
});

const State = Record({
  errorTitles: List(),
  errorMessages: List(),
  errorRedirectUrl: null,
  groups: Set(),
  username: null,
  archiveId: null,
  custId: null,
  customerName: null,
  customer: null,
  bodyTop: 0,
  searchSuggestionWaitTime: 0,
  isDisplayDateAdmin: false,
  displayDate: null,
  analysisId: null,
  questions: Map(),
  reviewers: Map(),
  dueWarningDays: 0,
  dueWarningIntervalHours: 24,
  displayedQuestionIds: null,
  needsReviewFilter: null,
  assignedFilter: null,
  assignedToFilter: null,
  deferrableFilter: null,
  statusFilter: null,
  filterText: "",
  ia2FileTypes: null,
  pubDocsFileTypes: null,
  termsOfUseUrl: null,
  isPrintLoading: false,
  helpTopics: Map(),
  helpVideos: Map(),
  referenceMatches: List(),
  areReferenceMatchesLoading: false,
  referenceNodes: List(),
  activeReferenceNodeIndex: null,
  grantedPermissions: List(),
  showDiscussionText: "",
  hideDiscussionText: "",
  multicodeStates: List()
});

const Group = Record({
  key: null,
  name: null,
});

export const initialState = State({});

const loadServerData = (state, {
  payload: {
    groups = [],
    username,
    archiveId,
    custId,
    customerName,
    customer,
    searchSuggestionWaitTime,
    analysisId,
    questions,
    reviewers,
    dueWarningDays,
    dueWarningIntervalHours,
    displayedQuestionIds,
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    statusFilter,
    filterText,
    ia2FileTypes,
    pubDocsFileTypes,
    termsOfUseUrl,
    helpTopics,
    helpVideos,
    grantedPermissions,
    showDiscussionText,
    hideDiscussionText,
    multicodeStates
  }
}) => state
  .set('groups', groups ? groups.map(group => Group(group)) : Set())
  .set('username', username)
  .set('custId', custId)
  .set('customerName', customerName)
  .set('customer', customer ? Customer({...customer, customization: customer.customization ? Customization(customer.customization) : null}) : null)
  .set('archiveId', archiveId)
  .set('searchSuggestionWaitTime', searchSuggestionWaitTime)
  .set('analysisId', analysisId)
  .set('questions', Map(questions))
  .set('reviewers', Map(reviewers))
  .set('dueWarningDays', dueWarningDays)
  .set('dueWarningIntervalHours', dueWarningIntervalHours)
  .set('displayedQuestionIds', (displayedQuestionIds ? List(displayedQuestionIds) : null))
  .set('needsReviewFilter', needsReviewFilter)
  .set('assignedFilter', assignedFilter)
  .set('assignedToFilter', assignedToFilter)
  .set('deferrableFilter', deferrableFilter)
  .set('statusFilter', statusFilter)
  .set('filterText', defaultTo(filterText, ""))
  .set('ia2FileTypes', ia2FileTypes)
  .set('pubDocsFileTypes', pubDocsFileTypes)
  .set('termsOfUseUrl', termsOfUseUrl)
  .set('helpTopics', helpTopics)
  .set('helpVideos', helpVideos)
  .set("showDiscussionText", showDiscussionText)
  .set("hideDiscussionText", hideDiscussionText)
  .set('grantedPermissions', grantedPermissions)
  .set('multicodeStates', multicodeStates ? List(multicodeStates) : List());

const registerError = (state, {payload: {error}}) => {
  let newState = state.set('errorRedirectUrl', error.redirectUrl)
    .update('errorMessages', errorMessages => errorMessages.push(error.message));
  if (error.title) {
    newState = newState.update('errorTitles', errorTitles => errorTitles.push(error.title));
  }
  return newState;
};
const dismissErrors = (state) => state.set('errorTitles', List()).set('errorMessages', List()).set('errorRedirectUrl', null);
const setBodyTop = (state, {payload: {bodyTop}}) => state.set('bodyTop', bodyTop);
const setPrintLoading = (state, {payload: {isLoading}}) => {
  return state.set('isPrintLoading', isLoading);
};

const getRefNodePath = (index) => {
  const indices = index.split('-');
  const depth = indices.length;
  for (let i = 1; i < depth; i++) {
    indices.splice((i*2)-1, 0, 'children');
  }
  return ['referenceNodes', ...indices];
};

const removeActiveReferenceNode = (state) => {
  const activeNodeIndex = state.get('activeReferenceNodeIndex');
  if (activeNodeIndex) {
    state = state.setIn([...getRefNodePath(activeNodeIndex), 'active'], false);
  }
  return state.remove('activeReferenceNodeIndex');
};

const setReferenceMatchesLoading = (state) => state.set('areReferenceMatchesLoading', true);

const setReferenceMatches = (state, {payload: {matches}}) => state.set('referenceMatches', List(matches.map(ReferenceMatch)));

const setReferenceMatchesDoneLoading = (state) => state.set('areReferenceMatchesLoading', false);

const setReferenceNodes = (state, {payload: {nodes}}) => state.set('referenceNodes', List(nodes.map(ReferenceNode)));

const toggleReferenceNode = (state, {payload: {node, toggled}}) => {
  const {index, children} = node;
  const activeNodeIndex = state.get('activeReferenceNodeIndex');
  if (activeNodeIndex) {
    state = state.setIn([...getRefNodePath(activeNodeIndex), 'active'], false);
  }
  return state
    .set('activeReferenceNodeIndex', index)
    .updateIn([...getRefNodePath(index)], (node) => node
      .set('toggled', toggled && children)
      .set('active', true)
      .set('loading', children && children.length === 0));
};

const setReferenceNodeChildren = (state, {payload: {node, children}}) => state
  .setIn([...getRefNodePath(node.index), 'children'], List(children.map(ReferenceNode)))
  .setIn([...getRefNodePath(node.index), 'loading'], false);

const reducer = handleActions({
  [actions.loadServerData]: loadServerData,
  [actions.registerError]: registerError,
  [actions.dismissErrors]: dismissErrors,
  [actions.setBodyTop]: setBodyTop,
  [actions.setPrintLoading]: setPrintLoading,
  [referencesActions.fetchRootReferenceNodesSuccess]: setReferenceNodes,
  [referencesActions.clearActiveReferenceNode]: removeActiveReferenceNode,
  [referencesActions.fetchReferenceMatchesStart]: setReferenceMatchesLoading,
  [referencesActions.fetchReferenceMatchesSuccess]: setReferenceMatches,
  [referencesActions.fetchReferenceMatchesFinally]: setReferenceMatchesDoneLoading,
  [referencesActions.toggleReferenceNodeSuccess]: toggleReferenceNode,
  [referencesActions.fetchReferenceNodeChildrenSuccess]: setReferenceNodeChildren,
}, initialState);

export default reducer;