import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from 'reselect';

import {dismissErrors} from '../actions';
import {getErrorMessages, getErrorTitles, getErrorRedirectUrl} from '../selectors';
import {toJS} from "../utils";
import ErrorDialog from "../components/ErrorDialog";

const mapStateToProps = createStructuredSelector({
  errorTitles: getErrorTitles,
  errorMessages: getErrorMessages,
  errorRedirectUrl: getErrorRedirectUrl
});

const mapDispatchToProps = (dispatch) => ({
  dismissErrors: () => dispatch(dismissErrors())
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(ErrorDialog);
