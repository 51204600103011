import {updateContent} from "./content";

const trigger = '';
const separator = ' ';
const addReference = (id, {text, url, targetOption}) => async (dispatch, getState) => {
  const {getSelectedBlock, Modifier, EditorState} = await import('../../common/draftjs/dynamic');
  const state = getState().notes;
  const editorState = state.getIn(['notes', id, 'content']);
  let currentContent = editorState
    .getCurrentContent()
    .createEntity('REFERENCE', 'IMMUTABLE', { text: `${trigger}${text}`, url, targetOption});
  let entityKey = currentContent.getLastCreatedEntityKey();

  const selectedBlock = getSelectedBlock(editorState);
  const selectedBlockText = selectedBlock.getText();
  let focusOffset = editorState.getSelection().focusOffset;
  const referenceIndex = (selectedBlockText.lastIndexOf(separator + trigger, focusOffset) || 0) + 1;
  let spaceAlreadyPresent = false;
  if (selectedBlockText.length === referenceIndex + 1) {
    focusOffset = selectedBlockText.length;
  }
  if (selectedBlockText[focusOffset] === ' ') {
    spaceAlreadyPresent = true;
  }
  let updatedSelection = editorState.getSelection().merge({
    anchorOffset: referenceIndex,
    focusOffset,
  });
  let newEditorState = EditorState.acceptSelection(editorState, updatedSelection);
  let contentState = Modifier.replaceText(
    currentContent,
    updatedSelection,
    `${trigger}${text}`,
    newEditorState.getCurrentInlineStyle(),
    entityKey,
  );
  newEditorState = EditorState.push(newEditorState, contentState, 'insert-characters');

  if (!spaceAlreadyPresent) {
    // insert a blank space after reference
    updatedSelection = newEditorState.getSelection().merge({
      anchorOffset: referenceIndex + text.length + trigger.length,
      focusOffset: referenceIndex + text.length + trigger.length,
    });
    newEditorState = EditorState.acceptSelection(newEditorState, updatedSelection);
    contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      updatedSelection,
      ' ',
      newEditorState.getCurrentInlineStyle(),
      undefined,
    );
  }
  return dispatch(updateContent(id, EditorState.push(newEditorState, contentState, 'insert-characters')));
};

export {addReference};
