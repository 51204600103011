import * as PropTypes from "prop-types";
import React from "react";
import If from "../../common/containers/If";
import HelpButton from "./HelpButton";
import VideoButton from "./VideoButton";
import './PageTitle.css';

class ContextHelp extends React.PureComponent {

  static propTypes = {
    pageId: PropTypes.string.isRequired,
    helpTopics: PropTypes.object,
    helpVideos: PropTypes.object
  };

  render() {
    const {pageId, helpTopics, helpVideos} = this.props;
    return (
      <If test={(helpTopics && helpTopics[pageId]) || (helpVideos && helpVideos[pageId])}>
        <div className="moreActions">
          <If test={(helpVideos && helpVideos[pageId])}>
            <VideoButton url={helpVideos ? helpVideos[pageId] : ""}/>
          </If>
          <If test={(helpTopics && helpTopics[pageId])}>
            <HelpButton topic={helpTopics ? helpTopics[pageId] : ""}/>
          </If>
        </div>
      </If>
    );
  }
}
export default ContextHelp;
