import {ModuleKey} from '../ModuleKey';
import {createSelector} from 'reselect';

const getLawMap = (state) => state[ModuleKey].laws;
const getPastLawMap = (state) => state[ModuleKey].pastLaws;
const getEditingId = state => state[ModuleKey].editingId;

const getLaws = createSelector(
  getLawMap,
  lawMap => lawMap.toList()
);

const getPastLaws = createSelector(
  getPastLawMap,
  pastLawMap => pastLawMap.toList()
);

const getEditingLaw = createSelector(
  [getEditingId, getLawMap, getPastLawMap],
  (editingId,laws,pastLaws) => editingId === null ? null
    : (laws.get(editingId) ? laws.get(editingId) : pastLaws.get(editingId))
);


export {
  getLaws,
  getPastLaws,
  getEditingLaw,
  getEditingId,
};
