import * as PropTypes from 'prop-types';
import React from "react";
import Paper from '@material-ui/core/Paper';
import If from '../containers/If';
import './BoxedSection.css';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import HelpButton from './HelpButton';
import PrimaryButton from './PrimaryButton';

const BoxedSection = ({title = null, help: {topic = null, subtopic = null} = {}, instructions = null, actions = [], children, ...props}) => (
  <section {...props} className={`boxedSection ${title && title.trim() !== "" ? `boxedSection-${title.replace(/ /g, "")}` : ""}`}>
    <Paper elevation={1} className="boxedSection-paper" square={true}>
      <If test={title !== null}>
        <h2 className="customerTitleColor">{title}</h2>
      </If>
      <div className="box">
        <div className="boxButtons">
          <If test={topic !== null}>
            <HelpButton topic={`${topic}#${subtopic}`}/>
          </If>
        </div>
        <If test={instructions !== null}>
          <div className="boxInstructions">{instructions}</div>
        </If>
        <div className="boxContent">
          {children}
        </div>
        <If test={actions.length > 0}>
          <ButtonGroup className="boxActions">
            {actions.map(({action, label, disabled}, index) => (
              index !== actions.length - 1 ?
                <Button key={index} className="boxAction" onClick={action} disabled={disabled}>{label}</Button> :
                <PrimaryButton key={index} className="boxAction" onClick={action} disabled={disabled}>{label}</PrimaryButton>
            ))}
          </ButtonGroup>
        </If>
      </div>
    </Paper>
  </section>);

BoxedSection.propTypes = {
  title: PropTypes.string,
  help: PropTypes.shape({
    topic: PropTypes.string,
    subtopic: PropTypes.string
  }),
  instructions: PropTypes.string,
  actions: PropTypes.array,
  children: PropTypes.any,
};

export default BoxedSection
