/*
 * Copyright 2018 General Code
 */

import React from 'react';
import * as PropTypes from 'prop-types';
import './Options.css';
import FormGroup from "../../common/components/FormGroup";
import RadioGroup from "../../common/components/RadioGroup";
import If from "../../common/containers/If";
import MultiSelectOptionContainer from "../containers/MultiSelectOptionContainer";
import SingleSelectOptionContainer from "../containers/SingleSelectOptionContainer";
import RequiredOptionContainer from "../containers/RequiredOptionContainer";

class Options extends React.Component {
  static propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    optionListId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    optionListMode: PropTypes.string,
    options: PropTypes.object
  };
  render() {
    const {questionId, optionListId, optionListMode, options} = this.props;
    return (
      <div className="questionOptions">
        <If test={options && Object.values(options).length > 0}>
          <If test={optionListMode === "SINGLE_SELECT"}>
            <div className="optionListLabel">Pick one option from list below:</div>
            <RadioGroup name={"option_list_" + optionListId}>
              { Object.values(options).map((option) => (
                <SingleSelectOptionContainer key={option.id} questionId={questionId} option={option} />
              )) }
            </RadioGroup>
          </If>
          <If test={optionListMode === "MULTI_SELECT"}>
            <div className="optionListLabel">Pick any option from the list below:</div>
            <FormGroup name={"option_list_" + optionListId}>
              { Object.values(options).map((option) => (
                <MultiSelectOptionContainer key={option.id} questionId={questionId} option={option} />
              )) }
            </FormGroup>
          </If>
          <If test={optionListMode === "ALL_REQUIRED"}>
            <FormGroup name={"option_list_" + optionListId}>
              { Object.values(options).map((option) => (
                <RequiredOptionContainer key={option.id} questionId={questionId} option={option} />
              )) }
            </FormGroup>
          </If>
        </If>
      </div>
    );
  }
}
export default Options;