import React from 'react';
import ApiKeyAdminSection from "./ApiKeyAdminSection";
import CustomColorSection from './CustomColorSection';
import CustomBannerSection from './CustomBannerSection';
import LawAdminSection from './LawAdminSection';
import EnabledFeaturesSection from './EnabledFeaturesSection';
import GeneralCodeAdminSection from './GeneralCodeAdminSection';
import './SettingsPage.css';

const SettingsPage = (props) => {

  document.addEventListener("keydown", (e) => {
    if(e.code === 'KeyE' && e.ctrlKey && e.shiftKey && e.altKey) {
      props.testApiError();
    }
  });

  const {isCustomizationAdmin, featureLawViewJson, isFeatureAdmin, isGcAdmin, apiKeyView, contentApiAdminValue,
    searchApiAdminValue, codeApiAdminValue, isDisplayDateAdmin, isIa2Admin, remoteAuthEnabled, isCodeReviewAdmin,
    isEreaderAdmin, isMixedContentAdmin} = props;
  const featureLawView = JSON.parse(featureLawViewJson);
  return (
    <div id="adminSettingsPage">
      {isCustomizationAdmin === "true" &&
        <CustomColorSection {...props}/>
      }
      {isCustomizationAdmin === "true" &&
        <CustomBannerSection {...props}/>
      }
      {(isCustomizationAdmin === "true" || isFeatureAdmin === "true") && featureLawView["currentLevel"] !== "NOBODY" &&
        <LawAdminSection {...props}/>
      }
      {isFeatureAdmin === "true" &&
        <EnabledFeaturesSection {...props}/>
      }
      {isGcAdmin === "true" && (isCustomizationAdmin === "true" || isFeatureAdmin === "true"
        || isDisplayDateAdmin === "true" || isIa2Admin === "true" || isCodeReviewAdmin === "true"
        || isEreaderAdmin === "true" || remoteAuthEnabled === "true" || isMixedContentAdmin === "true") &&
        <GeneralCodeAdminSection {...props}/>
      }
      {(apiKeyView === "true" && (contentApiAdminValue === "true" || searchApiAdminValue === "true"
        || codeApiAdminValue === "true")) &&
        <ApiKeyAdminSection {...props}/>
      }
    </div>
  );
};

export default SettingsPage;
