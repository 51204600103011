/*
 * Copyright 2019 General Code
 */

import {Record} from 'immutable';
import {handleActions} from 'redux-actions';
import * as actions from '../actions';


const Settings = Record({
  displayDate: "",
  isDisplayDateAdmin: false,
  mapUrl: "",
  oldDisplayDate: "",
  oldMapUrl: "",
  zoningStructure: null,
  zoningStructureLoading: false,
  nameOverride: ""
});

const initialState = Settings({});

const setDisplayDate = (state, {payload: {displayDate}}) => state.set("displayDate", displayDate);
const displayDateUpdated = (state) => state.set("oldDisplayDate", state.displayDate);

const setMapUrl = (state, {payload: {mapUrl}}) => state.set("mapUrl", mapUrl);
const mapUrlUpdated = (state) => state.set("oldMapUrl", state.mapUrl);

const initSettings = (state, {payload}) => state.merge(payload);

const zoningStructureLoading = (state) => state.set("zoningStructureLoading", true);
const zoningStructureNotLoading = (state) => state.set("zoningStructureLoading", false);

const clearZoningStructure = (state, {payload: {structureTaxonomyList}}) =>
  structureTaxonomyList === null ? state : state.set("zoningStructure", null);

const setZoningStructure = (state, {payload: {structureTaxonomy}}) =>
  structureTaxonomy === null ? state : state.set("zoningStructure", {
    guid: structureTaxonomy.structureGuid,
    text: structureTaxonomy.structureTitle,
    url: "/" + structureTaxonomy.structureGuid
  });

const reducer = handleActions({
  [actions.displayDateUpdated]: displayDateUpdated,
  [actions.initSettings]: initSettings,
  [actions.mapUrlUpdated]: mapUrlUpdated,
  [actions.setDisplayDate]: setDisplayDate,
  [actions.setMapUrl]: setMapUrl,
  [actions.clearZoningChapterStart]: zoningStructureLoading,
  [actions.clearZoningChapterSuccess]: clearZoningStructure,
  [actions.clearZoningChapterFinally]: zoningStructureNotLoading,
  [actions.setZoningChapterStart]: zoningStructureLoading,
  [actions.setZoningChapterSuccess]: setZoningStructure,
  [actions.setZoningChapterFinally]: zoningStructureNotLoading
}, initialState);

export const State = Settings;
export {initialState};
export default reducer;
