import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {getBodyTop, getReviewers} from "../../common/selectors";

import {toJS} from "../../common/utils";
import {clearFilters, filterQuestions} from "../actions";
import QuestionFilters from "../components/QuestionFilters";
import {
  getAssignedFilter,
  getAssignedToFilter,
  getDeferrableFilter,
  getDisplayedQuestionIds,
  getFilterText,
  getNeedsReviewFilter,
  getQuestions,
  getStatusFilter
} from "../selectors";

const mapStateToProps = createStructuredSelector({
  filters: createStructuredSelector({
    needsReviewFilter: getNeedsReviewFilter,
    assignedFilter: getAssignedFilter,
    assignedToFilter: getAssignedToFilter,
    deferrableFilter: getDeferrableFilter,
    statusFilter: getStatusFilter,
    filterText: getFilterText,
  }),
  reviewers: getReviewers,
  questions: getQuestions,
  displayedQuestionIds: getDisplayedQuestionIds,
  bodyTop: getBodyTop
});

const mapDispatchToProps = (dispatch) => ({
  filterQuestions: ({
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    statusFilter,
    filterText
  }) => {
    dispatch(filterQuestions({
      needsReviewFilter,
      assignedFilter,
      assignedToFilter,
      deferrableFilter,
      statusFilter,
      filterText
    }));
  },
  clearFilters: () => dispatch(clearFilters())
});

const QuestionFiltersContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(QuestionFilters);
export default QuestionFiltersContainer;