import {createStructuredSelector} from "reselect";
import {compose} from "recompose";
import {connect} from "react-redux";
import {toJS} from "../../common/utils";
import NotePageControls from "../components/NotePageControls";
import {getCustId, getNoteLocationData} from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  noteLocationData: getNoteLocationData,
  custId: getCustId
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(NotePageControls);
