/*
 * Copyright 2018 General Code
 */

import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import QuestionsPageActionsContainer from "../containers/QuestionsPageActionsContainer";
import PageTitleContainer from "../../common/containers/PageTitleContainer";

class QuestionsPageTitle extends Component {

  static propTypes = {
    displayedQuestionCount: PropTypes.number,
    displayedQuestionIds: PropTypes.array,
    isNewNav: PropTypes.bool.isRequired,
    setPrintLoading: PropTypes.func,
    displayAllQuestions: PropTypes.func
  };

  handlePrint = () => {
    const {setPrintLoading, displayAllQuestions} = this.props;
    setPrintLoading(true);
    displayAllQuestions();
    this.waitToPrint();
  };

  waitToPrint = () => {
    const {displayedQuestionCount, displayedQuestionIds, setPrintLoading} = this.props;
    if (displayedQuestionCount < displayedQuestionIds.length) {
      setTimeout(this.waitToPrint, 500);
    } else {
      setPrintLoading(false);
      window.print();
    }
  };

  render() {
    const {isNewNav} = this.props;
    return isNewNav ?
      (<PageTitleContainer pageTitle="Questions"/>) :
      (<PageTitleContainer pageTitle="Questions" pageId="questions">
        <QuestionsPageActionsContainer />
      </PageTitleContainer>);
  }
}
export default QuestionsPageTitle;