import * as PropTypes from "prop-types";
import React, {Fragment} from "react";
import ConfirmDialog from "./ConfirmDialog";
import DropZone from "./DropZone";
import If from "../containers/If";
import IconButton from "./IconButton";
import {Clear as ClearIcon} from "./icons";
import "./FileUploader.css";

const MAX_FILE_SIZE = 100000000;

class FileUploader extends React.Component {

  static propTypes = {
    files: PropTypes.array.isRequired,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    showInlinePreview: PropTypes.bool,
    setFiles: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      errorFilenames: [],
      fileSizeError: false
    };
  }

  checkFileAlreadyUploaded = (file) => {
    for (const existingFile of this.props.files) {
      if (existingFile.name === file.name && existingFile.lastModified === file.lastModified
        && existingFile.size === file.size && existingFile.type === file.type) {
        return true;
      }
    }
    return false;
  };

  handleFileChange = (uploadedFiles) => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      const { files, setFiles } = this.props;
      let newFiles = files;
      let errorFiles = [];
      for (const file of uploadedFiles) {
        if (file.size <= MAX_FILE_SIZE) {
          if (!this.checkFileAlreadyUploaded(file)) {
            newFiles.push(file);
          }
        } else {
          errorFiles.push(file.name);
        }
      }
      setFiles(newFiles);
      this.setState({
        errorFilenames: errorFiles,
        fileSizeError: (errorFiles.length > 0)
      });
    }
  };

  handleFileRejected = (uploadedFiles) => {
    this.setState({
      errorFilenames: uploadedFiles.map(file => file.name),
      fileSizeError: false
    });
  };

  removeFile = (index) => (e) => {
    e.stopPropagation();
    const { files, setFiles } = this.props;
    if (files.length > index) {
      setFiles(files.filter((file, i) => i !== index));
    }
  };

  dismissError = () => {
    this.setState({ errorFilenames: [], fileSizeError: false });
  };

  render() {
    const { files, multiple = false, accept = null, className = "", disabled = false, showInlinePreview = false,
      previewAreaHeight = null, maxInlinePreviewHeight = null, setFiles, children, ...otherProps } = this.props;
    const { errorFilenames, fileSizeError } = this.state;
    return (
      <Fragment>
        <DropZone { ...otherProps }
          accept={accept}
          multiple={multiple}
          className={className + " fileDropzone" }
          onDrop={this.handleFileChange}
          onDropRejected={this.handleFileRejected}
          disabled={disabled}
          disabledClassName="disabled"
        >
          <If test={files && files.length > 0}>
            <div className={"filePreviewArea" + (multiple ? " multiple" : "")} style={{ maxHeight: (previewAreaHeight ? (isNaN(previewAreaHeight) ? previewAreaHeight : (previewAreaHeight + "px")) : "none") }}>
              { files.map((file, index) => ((showInlinePreview && file.preview) ? (
                <div key={index + file.name}>
                  <a href={file.preview} target="_blank" rel="noopener noreferrer" title="Preview file" onClick={(e) => e.stopPropagation()}>
                    <If test={file.type && file.type.indexOf("image/") !== -1}>
                      <img src={file.preview} alt={file.name + " Preview"} style={{ maxHeight: (maxInlinePreviewHeight ? (isNaN(maxInlinePreviewHeight) ? maxInlinePreviewHeight : (maxInlinePreviewHeight + "px")) : "none")}} />
                    </If>
                    <If test={file.type && file.type === "application/pdf"}>
                      <object data={file.preview} type="application/pdf" style={{ maxHeight: (maxInlinePreviewHeight ? (isNaN(maxInlinePreviewHeight) ? maxInlinePreviewHeight : (maxInlinePreviewHeight + "px")) : "none")}}>
                        <div>No online PDF viewer installed</div>
                      </object>
                    </If>
                    <If test={!file.type || (file.type !== "application/pdf" && file.type.indexOf("image/") === -1)}>
                      <iframe title={file.name} allowTransparency="true" frameBorder="0" scrolling="no" src={file.preview} style={{ maxHeight: (maxInlinePreviewHeight ? (isNaN(maxInlinePreviewHeight) ? maxInlinePreviewHeight : (maxInlinePreviewHeight + "px")) : "none")}}>
                        <div>Your browser doesn't support iframes</div>
                      </iframe>
                    </If>
                  </a>
                  <IconButton className="removeFileButton" aria-label="Remove file" title="Remove file" onClick={this.removeFile(index)}>
                    <ClearIcon/>
                  </IconButton>
                </div>
              ) : (
                <div key={index + file.name}>
                  <a href={file.preview} target="_blank" rel="noopener noreferrer" title="Preview file" onClick={(e) => e.stopPropagation()}>{ file.name }</a>
                  <IconButton className="removeFileButton" aria-label="Remove file" title="Remove file" onClick={this.removeFile(index)}>
                    <ClearIcon/>
                  </IconButton>
                </div>
              )))}
            </div>
          </If>
          { children }
        </DropZone>
        <ConfirmDialog title={"Failed To Upload"}
          className="fileUploader-errorDialog"
          open={errorFilenames.length > 0}
          maxWidth={"md"}
          onConfirm={this.dismissError}
        >
          <If test={fileSizeError}>
            <div>The following files were too big to upload:</div>
          </If>
          <If test={!fileSizeError}>
            <div>Failed to upload the following files:</div>
          </If>
          <div>
            <ol>{errorFilenames.map((errorFilename, index) =>  <li key={index + errorFilename}>{errorFilename}</li>)}</ol>
          </div>
        </ConfirmDialog>
      </Fragment>

    );
  };
}
export default FileUploader;