/*
 * Copyright 2018 General Code
 */

import React from 'react';
import * as PropTypes from 'prop-types';
import Button from './Button';

const SubmitButton = ({type = 'submit', color = 'primary', size = 'small', variant = 'contained', className='', ...props}) => <Button {...props} className={`primaryButton ${className}`} primary="true" type={type} variant={variant} color={color} size={size}/>;

SubmitButton.propTypes = {
  /** Color of the button.  Defaults to 'secondary' which is a grey color */
  color: PropTypes.oneOf(['primary', 'secondary', 'other']),
  /** Size of the button.  Defaults to 'small' which has some padding and is suitable for use in a dialog */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default SubmitButton;
