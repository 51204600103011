/*
 * Copyright 2018 General Code
 */
import React, {Fragment} from 'react';
import * as PropTypes from 'prop-types';
import IconButton from '../../common/components/IconButton';
import './QuestionIcon.css';
import {Collapse, Expand} from "../../common/components/icons";

const QuestionIcon = ({toggleOpen, isOpen}) => (
  <Fragment>
    <IconButton className="questionIcon" onClick={toggleOpen} title={isOpen ? "Collapse question" : "Expand question"}>
      {isOpen ?
        <Collapse title="Collapse question"/> :
        <Expand title="Expand question"/>
      }
    </IconButton>
  </Fragment>
);

QuestionIcon.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default QuestionIcon;
