import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import SelectCodeReferenceDialog from '../components/SelectCodeReferenceDialog';
import {clearActiveReferenceNode, fetchReferenceMatches, toggleReferenceNode} from '../actions/references';
import {getReferenceMatches, getReferenceNodes, getAreMatchesLoading} from '../selectors/references';
import {compose} from 'recompose';
import {toJS} from "../utils";

const mapStateToProps = createStructuredSelector({
  nodes: getReferenceNodes,
  matches: getReferenceMatches,
  areMatchesLoading: getAreMatchesLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReferenceMatches: (query) => dispatch(fetchReferenceMatches(query)),
  toggleReferenceNode: (node, toggled) => dispatch(toggleReferenceNode(node, toggled)),
  clearActiveReferenceNode: () => dispatch(clearActiveReferenceNode()),
});
export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(SelectCodeReferenceDialog);
