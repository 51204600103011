import debounce from "lodash/debounce";
import * as PropTypes from 'prop-types';
import React from "react";
import Chip from "../../common/components/Chip";
import ConfirmDialog from "../../common/components/ConfirmDialog";
import IconButton from "../../common/components/IconButton";
import {Menu, MenuItem} from "../../common/components/menu";
import Divider from "../../common/components/Divider";
import {Search as SearchIcon, AccountCircle as AccountCircleIcon, Warning as WarningIcon} from "../../common/components/icons";
import Icon from "../../common/components/Icon";
import Button from "../../common/components/Button";
import If from "../../common/containers/If";
import {customer} from "../../common/utils/server-data";
import "./MenubarSearchComponent.css";
import ClipLoader from "react-spinners/ClipLoader";
import AutoSuggestContainer from "../containers/AutoSuggestContainer";

class SearchBar extends React.PureComponent {

  static propTypes = {
    query: PropTypes.string.isRequired,
    selectedStructures: PropTypes.object.isRequired,
    selectedCustIds: PropTypes.array.isRequired,
    selectedCustomerFilters: PropTypes.array.isRequired,
    searchedCodeCount: PropTypes.number.isRequired,
    hasAdvancedSearchPermission: PropTypes.bool.isRequired,
    hasMulticodePermission: PropTypes.bool.isRequired,
    searchSuggestionWaitTime: PropTypes.number.isRequired,
    isSearchFieldActive: PropTypes.bool.isRequired,
    custId: PropTypes.string,
    username: PropTypes.string,
    myNotesCount: PropTypes.number,
    warningMessage: PropTypes.string,
    setQuery: PropTypes.func.isRequired,
    submitSearch: PropTypes.func.isRequired,
    setCodeFinderOpen: PropTypes.func.isRequired,
    resetSelectedCustomers: PropTypes.func.isRequired,
    resetSelectedStructures: PropTypes.func.isRequired,
    getSearchSuggestions: PropTypes.func.isRequired,
    setSearchFieldActive: PropTypes.func.isRequired
  };

  state = {
    menuButton: null,
    displayWarning: false
  };

  constructor(props) {
    super(props);
    this.handleGetSearchSuggestions = debounce(this.handleGetSearchSuggestions, props.searchSuggestionWaitTime);
    this.handleGetSearchSuggestions(props.query);
  }

  componentDidMount() {
    window.addEventListener("click", this.handleWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  handleWindowClick = (event) => {
    const {isSearchFieldActive, setSearchFieldActive} = this.props;
    if (isSearchFieldActive && !this.ref.contains(event.target)) {
      setSearchFieldActive(false);
    }
  };

  handleOpenCodeFinderDialog = () => {
    this.props.setCodeFinderOpen(true);
  };

  handleMulticodeChipClick = (event) => {
    if (event.buttons === 1 && event.target.tagName !== "svg" && event.target.tagName !== "path") {
      this.handleOpenCodeFinderDialog();
    }
  };

  handleMulticodeChipKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleOpenCodeFinderDialog();
    }
  };

  handleMulticodeChipDelete = () => {
    const {submitSearch, resetSelectedCustomers} = this.props;
    resetSelectedCustomers();
    submitSearch();
  };

  handleSelectedChipDelete = () => {
    const {submitSearch, resetSelectedStructures} = this.props;
    resetSelectedStructures();
    if(window.location.href.indexOf("/search") !== -1)
      submitSearch();
  };

  handleSearch = (event) => {
    const {submitSearch} = this.props;
    event.preventDefault();
    submitSearch(event.target.value);
  };

  handleOpenAdvancedSearchDialog = (event) => {
    const {setAdvancedSearchOpen} = this.props;
    event.preventDefault();
    setAdvancedSearchOpen(true);
  };

  handleGetSearchSuggestions = () => {
    const {query, getSearchSuggestions} = this.props;
    getSearchSuggestions(query);
  };

  handleQueryChange = (event) => {
    const {setQuery} = this.props;
    setQuery(event.target.value);
    this.handleGetSearchSuggestions(event.target.value);
  };

  handleSearchFieldActive = (event) => {
    const {isSearchFieldActive, setSearchFieldActive} = this.props;
    if (event.key && event.key === 'Tab') {
      setSearchFieldActive(false);
    } else {
      if (!isSearchFieldActive) {
        setSearchFieldActive(true);
      }
      if (event.key && event.key === 'ArrowDown'){
        const quickJumpItems = document.getElementsByClassName("quickJumpItem");
        if (quickJumpItems.length > 0) {
          quickJumpItems[0].focus();
          event.preventDefault();
        }
      }
    }
  };

  render() {
    const {query, selectedStructures, selectedCustIds, selectedCustomerFilters, searchedCodeCount,
      hasAdvancedSearchPermission, hasMulticodePermission, custId, username, myNotesCount, warningMessage} = this.props;
    const {menuButton, displayWarning} = this.state;
    const chips = [];
    const titles = Object.values(selectedStructures);
    if ((selectedCustomerFilters && selectedCustomerFilters.length > 0) || (selectedCustIds && (selectedCustIds.length > 1 || (selectedCustIds.length === 1 && (!customer || selectedCustIds[0] !== customer.custId)))))  {
      chips.push(
        <Chip clickable
          key="codesChip"
          id="multicodeChip"
          className={"clickable muiChip menubarScopeChip"}
          label={"Multicode" + (searchedCodeCount ? " (" + searchedCodeCount.toLocaleString() + ")" : "")}
          deleteIcon={!searchedCodeCount ? <Icon id="menubarMulticodeChipLoadIcon"><ClipLoader/></Icon> : null}
          onDelete={this.handleMulticodeChipDelete}
          onMouseDown={this.handleMulticodeChipClick}
          onKeyDown={this.handleMulticodeChipKeyPress}
        />
      );
    }
    if(selectedStructures && titles.length > 0) {
      chips.push(
        <Chip key="selectedChip"
          className={"muiChip menubarScopeChip"}
          label={"Selected (" + (titles.length.toLocaleString()) + ")"}
          onDelete={this.handleSelectedChipDelete}
          title={titles.join(" + ")}
        />
      );
    }
    return (
      <div id="search-bar">
        <div id="search-bar-wrapper">
          <div className="content-area">
            <div id="menu">
              <div id="menuButtons">
                <div id="menubarExpand"><span id="menubarExpandLabel">&nbsp;</span></div>
              </div>
              <div id="menuSearchArea" className="menuButton searchArea">
                <div id="menubarSearchContainer" ref={ref => this.ref = ref}>
                  <form id="menubarSearchForm" method="GET" autoComplete="off" onSubmit={this.handleSearch}>
                    <div id="menubarScopeChipContainer">{ chips }</div>
                    <input id="menubarSearchField"
                      name="query"
                      type="text"
                      placeholder="Enter search term..."
                      value={query}
                      aria-label="Enter search term"
                      aria-owns="autoSuggestContainer"
                      onChange={this.handleQueryChange}
                      onFocus={this.handleSearchFieldActive}
                      onClick={this.handleSearchFieldActive}
                      onKeyDown={this.handleSearchFieldActive}
                    />
                    <AutoSuggestContainer/>
                    <IconButton
                      id="menubarSearchButton"
                      aria-label="Submit Search"
                      onClick={this.handleSearch}
                    >
                      <SearchIcon/>
                    </IconButton>
                  </form>
                  <If test={hasAdvancedSearchPermission || hasMulticodePermission}>
                    <div className="menubarSearchButtons">
                      <If test={hasAdvancedSearchPermission}>
                        <Button variant="contained" color="default" size="small" className="menubarSearchButton-advanced menubarSearchButton" onClick={this.handleOpenAdvancedSearchDialog}>Advanced</Button>
                      </If>
                      <If test={hasMulticodePermission}>
                        <Button variant="contained" color="default" size="small" className="menubarSearchButton-multicode menubarSearchButton" onClick={this.handleOpenCodeFinderDialog}>Multicode</Button>
                      </If>
                    </div>
                  </If>
                  <div id="search-bar-icons">
                    <If test={warningMessage}>
                      <div id="customer-warnings" className="search-bar-icon">
                        <IconButton id="customer-warnings-button" title="Display Customer Warning" onClick={() => this.setState({displayWarning: true})}>
                          <WarningIcon title="Display Customer Warning"/>
                        </IconButton>
                      </div>
                      <ConfirmDialog title="Customer Warning" open={displayWarning} onConfirm={() => this.setState({displayWarning: false})}>
                        {warningMessage}
                      </ConfirmDialog>
                    </If>
                    <div id="account-actions" className="search-bar-icon">
                      <If test={username}>
                        <IconButton id="account-actions-button" aria-label="Account Actions" onClick={(e) => this.setState({menuButton: e.currentTarget})}>
                          <AccountCircleIcon/>
                        </IconButton>
                        <Menu
                          id="account-actions-menu"
                          anchorEl={menuButton}
                          open={menuButton !== null}
                          onClose={() => this.setState({menuButton: null})}
                          keepMounted={true}
                          MenuListProps={{"aria-labelledby": "account-actions"}}
                        >
                          <MenuItem component="a" href={"/user/settings" + (custId ? ("/" + custId) : "")}>User Settings</MenuItem>
                          <If test={myNotesCount}>
                            <MenuItem component="a" href={"/" + (custId ? (custId + "/") : "") + "note/all"}>My Notes</MenuItem>
                          </If>
                          <Divider />
                          <MenuItem component="a" href={"/logout/" + (custId ? custId : "") + "?url=" + encodeURIComponent(window.location.pathname + "?" + window.location.search)} rel="nofollow">Logout</MenuItem>
                        </Menu>
                      </If>
                      <If test={!username && !window.location.pathname.startsWith("/login")}>
                        <Button component="a" variant="contained" color="default" size="small" href={"/login/" + (custId ? custId : "") + "?url=" + encodeURIComponent(window.location.pathname + "?" + window.location.search)} rel="nofollow">Login</Button>
                      </If>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SearchBar;
