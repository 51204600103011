import React from 'react';
import * as PropTypes from 'prop-types';
import Button from '../../common/components/Button';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '../../common/components/dialog';
import PrimaryButton from '../../common/components/PrimaryButton';
import TextField from '../../common/components/TextField';

class SetPasswordDialog extends React.Component {
  static propTypes = {
    userName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    onOkClick: PropTypes.func.isRequired,
  };

  state = {
    dialogOpen: false,
    newPassword: '',
  };

  handleDialogOpen = () => {
    this.setState({dialogOpen: true});
  };

  handleDialogClose = () => {
    this.setState({dialogOpen: false, newPassword: ''});
  };

  handleNewPasswordChange = (event) => {
    this.setState({newPassword: event.target.value});
  };

  render() {
    return (
      <div style={{height: '100%', width: '100%'}}>
        <div style={{height: '100%', width: '100%'}} onClick={this.handleDialogOpen} />
        <Dialog
          open={this.state.dialogOpen}
          actions={[]}
          aria-labelledby={'set-password-' + this.props.userId}
          disableBackdropClick={true}
        >
          <DialogTitle id={'set-password-' + this.props.userId}>New Password [{this.props.userName}]</DialogTitle>
          <DialogContent>
            <DialogContentText>Passwords must be at least 6 characters long.</DialogContentText>
            <TextField
              label="New Password"
              value={this.state.newPassword}
              onChange={this.handleNewPasswordChange}
              margin="normal"
              type="password"
              autoFocus={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>Cancel</Button>
            <PrimaryButton onClick={() => this.props.onOkClick(this)} disabled={this.state.newPassword.length < 6}>Ok</PrimaryButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default SetPasswordDialog;
