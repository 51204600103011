export const ActionTypes = {
  ADD_MESSAGE: 'ADD_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE'
};

export const addMessage = (id, type, body) => {
  return {
    type: ActionTypes.ADD_MESSAGE,
    messageToAdd: {
      id: id,
      type: type,
      body: body
    }
  };
};

export const removeMessage = (id) => {
  return {
    type: ActionTypes.REMOVE_MESSAGE,
    messageId: id
  };
};

let nextMsgId = 0;

export function createNewMessage(dispatch, type, body) {
  const id = nextMsgId++;
  dispatch(addMessage(id, type, body));

  if (type === MessageTypes.SUCCESS) {
    setTimeout(() => {
      dispatch(removeMessage(id));
    }, 5000);
  }
}

export const MessageTypes = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};

