import React from "react";
import PropTypes from 'prop-types'
import Message from './Message';

import './MessageList.css';

const MessageList = ({messages, onRemoveClick}) => (
  <div id="messageList">
    {messages.map((message) => (
      <Message key={message.id} {...message} onClick={() => onRemoveClick(message.id)} />
    ))}
  </div>
);

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      body: PropTypes.element.isRequired
    }).isRequired
  ).isRequired
};

export default MessageList

