import * as PropTypes from "prop-types";
import React, {Component} from 'react';
import {Card} from "../../common/components/card";
import {MenuItem} from "../../common/components/menu";
import Select from "../../common/components/Select";

class SortComponent extends Component {

  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    hasNaturalOrderSort: PropTypes.bool.isRequired,
    hasDateSort: PropTypes.bool.isRequired,
    sortOrder: PropTypes.string,
    isSearchLoading: PropTypes.bool,
    setSortOrder: PropTypes.func
  };

  handleEvent = (preventDefault, handler) => (event) => {
    if (preventDefault) event.preventDefault();
    handler(event.target.value);
  };

  render() {
    const {id, className, sortOrder, isSearchLoading, hasNaturalOrderSort, hasDateSort, setSortOrder} = this.props;
    if (isSearchLoading || (!hasNaturalOrderSort && !hasDateSort)) return null;
    return (
      <Card id={id} className={`sidebarItem sidebarCard resultSortContainer${className ? className : ""}`}>
        <div><span className="cardTitleSmall">Sort By</span></div>
        <div>
          <Select
            value={sortOrder}
            onChange={this.handleEvent(false, setSortOrder)}
            inputProps={{id: "sortInput", name: "sortOrder"}}
            MenuProps={{anchorOrigin: {vertical: "bottom", horizontal: "left"}, getContentAnchorEl: null}}
          >
            <MenuItem value="relevance">Relevance</MenuItem>
            { hasNaturalOrderSort ? <MenuItem value="natural">Natural Order</MenuItem> : null }
            { hasDateSort ? <MenuItem value="date_asc">Date (Ascending)</MenuItem> : null }
            { hasDateSort ? <MenuItem value="date_desc">Date (Descending)</MenuItem> : null }
          </Select>
        </div>
      </Card>
    );
  }
}
export default SortComponent;
