import * as PropTypes from "prop-types";
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import PubDocEditDialog from "../components/PubDocEditDialog";
import {
  getCategories,
  getDocumentCategoryEditId,
  getDocumentEditId,
  getDocumentEditLoading,
  getDocumentEditOpen,
  getPubDocs,
  getPubDocTypeTitle
} from "../selectors";
import {addPubDoc, addPubDocLinked, editPubDoc, editPubDocLinked} from "../actions";
import {getCustId, getPubDocsFileTypes, hasBulkUploadPermission} from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  documentEditOpen: getDocumentEditOpen,
  categories: getCategories,
  documents: getPubDocs,
  documentCategoryEditId: getDocumentCategoryEditId,
  documentEditId: getDocumentEditId,
  custId: getCustId,
  pubDocTypeTitle: getPubDocTypeTitle,
  pubDocsFileTypes: getPubDocsFileTypes,
  documentEditLoading: getDocumentEditLoading,
  hasBulkUploadPermission: hasBulkUploadPermission
});

const mapDispatchToProps = (dispatch) => ({
  addDocument: (custId, categoryId, typeId, name, description, date, file, isPrivate, documentType) => {
    dispatch(addPubDoc(custId, categoryId, typeId, name, description, date, file, isPrivate, documentType));
  },
  editDocument: (custId, documentId, categoryId, typeId, name, description, date, file, isPrivate, documentType) => {
    dispatch(editPubDoc(custId, documentId, categoryId, typeId, name, description, date, file, isPrivate, documentType));
  },
  addDocumentLinked: (custId, categoryId, typeId, name, description, date, isPrivate, documentType, url) => {
    dispatch(addPubDocLinked(custId, categoryId, typeId, name, description, date, isPrivate, documentType, url));
  },
  editDocumentLinked: (custId, documentId, categoryId, typeId, name, description, date, isPrivate, documentType, url) => {
    dispatch(editPubDocLinked(custId, documentId, categoryId, typeId, name, description, date, isPrivate, documentType, url));
  }
});

const PubDocEditDialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocEditDialog);
PubDocEditDialogContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  documentCategoryEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  documentEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setBulkUploaderOpen: PropTypes.func.isRequired
};
export default PubDocEditDialogContainer;