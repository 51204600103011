/*
 * Copyright 2018 General Code
 */

import React from 'react';
import * as PropTypes from 'prop-types';
import './Reference.css';
import If from "../../common/containers/If";
import ReferenceBreadcrumb from "./ReferenceBreadcrumb.js";

class Reference extends React.Component {
  render() {
    const {questionId, reference, preText, postText, selectedText, displayHtml} = this.props;
    return (
      <div className="questionReference">
        <div className="questionReferenceLabel">
          <a href={"/" + reference.guid + "#question-" + reference.guid + "-"  + questionId} className="titleLink" target="_blank" rel="noopener noreferrer">
            <If test={reference.structure}>
              <span className="titleNumber">{reference.structure.content.indexNum}&nbsp;</span>
              <If test={reference.structure.content.type !== "litem"}>
                <span className="titleTitle">{reference.structure.content.title}</span>
              </If>
            </If>
            <If test={!reference.structure}>
              <span className="titleTitle">{reference.guid}</span>
            </If>
          </a>
        </div>
        <div className="questionReferenceBreadcrumbs">
          <ReferenceBreadcrumb structure={reference.structure.parent}/>
        </div>
        <div className="questionReferenceContent">
          <If test={!!reference.displayHtml}>
            <span className="questionReferenceHtml" dangerouslySetInnerHTML={{__html: displayHtml}}/>
          </If>
          <If test={!reference.displayHtml && !!reference.preText}>
            <span className="questionPreText" dangerouslySetInnerHTML={{__html: preText}}/>
          </If>
          <If test={!reference.displayHtml && !!reference.selectedText}>
            <span className="questionSelectedText highlight" dangerouslySetInnerHTML={{__html: selectedText}}/>
            <span className="questionPostText" dangerouslySetInnerHTML={{__html: postText}}/>
          </If>
        </div>
      </div>
    );
  }
}
Reference.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  reference: PropTypes.object,
  preText: PropTypes.string,
  postText: PropTypes.string,
  selectedText: PropTypes.string,
  displayHtml: PropTypes.string
};
export default Reference;