/*
 * Copyright 2018 General Code
 */

import {List, Map, Record} from 'immutable';
import {handleActions} from 'redux-actions';
import * as actions from '../actions';
import {getLocalFilteredNotes} from '../selectors';
import {getReferenceGroupNames} from "../../common/ReferenceGroupFactory";


const ReferenceGroup = Record({
  matches: List(),
  areMatchesLoading: false,
  nodes: List(),
  activeNodeIndex: null,
});

const refGroups = {};
getReferenceGroupNames().forEach((name) => refGroups[name] = ReferenceGroup());

const State = Record({
  notes: Map(),
  noteFilters: Map(),
  showHelp: false,
  realIdMapping: Map(),
  ...refGroups,
});

const initialState = State({});

const Person = Record({
  name: null,
  email: null,
});

const Note = Record({
  id: null,
  color: "default",
  content: null,
  createdBy: Person(),
  createdOn: null,
  guid: -1,
  html: null,
  editable: false,
  title: "",
  updatedBy: Person(),
  updatedOn: null,
  version: null,
  visibility: "private",
  open: true,
  editing: false,
  displayMode: true,
  saving: false,
  previous: null,
});

const ReferenceNode = Record({
  type: null,
  key: null,
  title: null,
  context: null,
  name: null,
  index: null,
  children: List([]),
  active: false,
  toggled: false,
  loading: false,
});

const ReferenceMatch = Record({
  type: null,
  key: null,
  title: null,
  context: null,
});

const getRefNodePath = (groupName, index) => {
  const indices = index.split('-');
  const depth = indices.length;
  for (let i = 1; i < depth; i++) {
    indices.splice((i*2)-1, 0, 'children');
  }
  return [groupName, 'nodes', ...indices];
};

const setReferenceNodes = (state, {payload: {groupName, nodes}}) => state
  .setIn([groupName, 'nodes'], List(nodes.map(ReferenceNode)));

const toggleReferenceNode = (state, {payload: {groupName, node, toggled}}) => {
  const {index, children} = node;
  const {activeNodeIndex} = state[groupName];
  if (activeNodeIndex) {
    state = state.setIn([...getRefNodePath(groupName, activeNodeIndex), 'active'], false);
  }
  return state
    .setIn([groupName, 'activeNodeIndex'], index)
    .updateIn([...getRefNodePath(groupName, index)], (node) => node
      .set('toggled', toggled && children)
      .set('active', true)
      .set('loading', children && children.length === 0));
};

const setReferenceNodeChildren = (state, {payload: {groupName, node, children}}) => state
  .setIn([...getRefNodePath(groupName, node.index), 'children'], List(children.map(ReferenceNode)))
  .setIn([...getRefNodePath(groupName, node.index), 'loading'], false);

const removeActiveReferenceNode = (state, {payload: {groupName}}) => {
  const {activeNodeIndex} = state[groupName];
  if (activeNodeIndex) {
    state = state.setIn([...getRefNodePath(groupName, activeNodeIndex), 'active'], false);
  }
  return state.removeIn([groupName, 'activeNodeIndex']);
};

const removeReferenceNodes = (state, {payload: {groupName}}) => state
  .removeIn([groupName, 'nodes'])
  .removeIn([groupName, 'activeNodeIndex']);

const setReferenceMatches = (state, {payload: {groupName, matches}}) => state
  .setIn([groupName, 'matches'], List(matches.map(ReferenceMatch)));

const setReferenceMatchesLoading = (state, {payload: {groupName}}) => state
  .setIn([groupName, 'areMatchesLoading'], true);

const setReferenceMatchesDoneLoading = (state, {payload: {groupName}}) => state
  .setIn([groupName, 'areMatchesLoading'], false);

const removeReferenceMatches = (state, {payload: {groupName}}) => state
  .removeIn([groupName, 'matches']);

const setNoteFilter = (state, {payload: {field, value}}) => state.setIn(['noteFilters', field], value);
const removeNoteFilter = (state, {payload: {field}}) => state.removeIn(['noteFilters', field]);


const toggleNote = (state, {payload: {id}}) => state.setIn(['notes', id, 'open'], !state.getIn(['notes', id, 'open'], true));
const editNoteStart = (state, {payload: {id}}) => state.updateIn(['notes', id], note => note.set('previous', note).set('editing', true).set('open', true).set('html', null));
const editNoteCancel = (state, {payload: {id}}) => {
  if (id.startsWith('new-')) {
    setTimeout(() => document.getElementById(id).remove(), 0);
    return state.deleteIn(['notes', id]);
  } else {
    return state.updateIn(['notes', id], note => note.previous.set('editing', false).set('open', note.previous.displayMode === 'expanded'));
  }
};

const addNote = (state, {payload: {guid, id, date}}) => state
  .setIn(['notes', id], new Note({id, guid, date, open: true, editing: true, html: null}));

const deleteNote = (state, {payload: {id}}) =>  {
  state = state.deleteIn(['notes', id]);
  if (!state.get('noteFilters').isEmpty() && getLocalFilteredNotes(state).size === 0) {
    state = state.set('noteFilters', Map());
  }
  let noteElement = document.getElementById(`note-${id}`);
  if (!noteElement) noteElement = document.getElementById(`${id}`);
  if (noteElement) {
    setTimeout(noteElement.remove(), 0);
  }
  if (window.generalcode && window.generalcode.refreshNoteLocations && state.get('notes').size === 0) {
    setTimeout(window.generalcode.refreshNoteLocations, 0);
  }
  return state;
};

const saveNoteStart = (state, {payload: {id}}) => state
  .updateIn(['notes', id], note => note.set('saving', true));
const saveNoteSuccess = (state, {payload: {id, real, version}}) => state
  .updateIn(['notes', id], note => note.set('editing', false).set('saving', false).set('previous', null).set('open', note.displayMode === 'expanded').set('version', version)).setIn(['realIdMapping', id],real);
const saveNoteFail = (state, {payload: {id, error}}) => state
  .updateIn(['notes', id], note => note.set('editing', false).set('saving', false).set('previous', null).set('open', note.displayMode === 'expanded'));
const saveNoteFinally = (state, {payload: {id}}) => state;

const initNote = (state, {payload: {id, color, content, html, createdBy, createdOn, editable, title, updatedBy, updatedOn, version, visibility, displayMode, guid}}) => {
  return state
    .setIn(['notes', id], Note({id, color, content, html, createdBy: Person(createdBy), createdOn, editable, title, version, visibility, updatedBy: Person(updatedBy), updatedOn, displayMode, guid, open: displayMode === "expanded"}));
};

const updateProperty = prop => (state, {payload: {id, ...props}}) => state
  .setIn(['notes', id, prop], props[prop])
  .removeIn(['noteFilters', prop]);
const updateTitle = updateProperty('title');
const updateContent = updateProperty('content');
const updateColor = updateProperty('color');
const updateVisibility = updateProperty('visibility');
const updateDisplayMode = updateProperty('displayMode');



const reducer = handleActions({
  [actions.fetchRootReferenceNodesSuccess]: setReferenceNodes,
  [actions.toggleReferenceNodeSuccess]: toggleReferenceNode,
  [actions.fetchReferenceNodeChildrenSuccess]: setReferenceNodeChildren,
  [actions.clearActiveReferenceNode]: removeActiveReferenceNode,
  [actions.clearReferenceNodes]: removeReferenceNodes,
  [actions.fetchReferenceMatchesStart]: setReferenceMatchesLoading,
  [actions.fetchReferenceMatchesSuccess]: setReferenceMatches,
  [actions.fetchReferenceMatchesFinally]: setReferenceMatchesDoneLoading,

  [actions.clearReferenceMatches]: removeReferenceMatches,

  [actions.setNoteFilter]: setNoteFilter,
  [actions.removeNoteFilter]: removeNoteFilter,

  [actions.addNote]: addNote,
  [actions.editNoteStart]: editNoteStart,
  [actions.editNoteCancel]: editNoteCancel,
  [actions.deleteNoteSuccess]: deleteNote,
  [actions.saveNoteStart]: saveNoteStart,
  [actions.saveNoteSuccess]: saveNoteSuccess,
  [actions.saveNoteFail]: saveNoteFail,
  [actions.saveNoteFinally]: saveNoteFinally,
  [actions.toggle]: toggleNote,

  [actions.initNote]: initNote,
  [actions.updateTitle]: updateTitle,
  [actions.updateContent]: updateContent,
  [actions.updateColor]: updateColor,
  [actions.updateDisplayMode]: updateDisplayMode,
  [actions.updateVisibility]: updateVisibility,
}, initialState);

export {State, initialState, Note};
export default reducer;
