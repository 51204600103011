import debounce from 'lodash/debounce';
import * as PropTypes from 'prop-types';
import React from "react";
import AutoSizer from "../../common/components/AutoSizer";
import Button from "../../common/components/Button";
import ButtonGroup from "../../common/components/ButtonGroup";
import {Card, CardContent} from "../../common/components/card";
import Chip from "../../common/components/Chip";
import {Dialog, DialogActions, DialogContent} from "../../common/components/dialog";
import FormControl from "../../common/components/FormControl";
import IconButton from "../../common/components/IconButton";
import {Add as AddIcon, Clear as ClearIcon, Remove as RemoveIcon} from "../../common/components/icons";
import InputLabel from "../../common/components/InputLabel";
import PrimaryButton from "../../common/components/PrimaryButton";
import {CircularProgress} from "../../common/components/progress";
import RangeSlider from "../../common/components/RangeSlider";
import {ReactSelect} from "../../common/components/reactSelect";
import TextField from "../../common/components/TextField";
import VirtualizedList from "../../common/components/VirtualizedList";
import ContextHelpContainer from "../../common/containers/ContextHelpContainer";
import If from "../../common/containers/If";
import {custId} from "../../common/utils/server-data";
import './CodeFinderDialog.css';

const debounceWait = 300;

const columns = [
  {id: "govtype", label: "Type", isNumeric: false, disablePadding: false},
  {id: "name", label: "Name", isNumeric: false, disablePadding: false, linkUrl: "/{custId}"},
  {id: "county", label: "County", isNumeric: false, disablePadding: false},
  {id: "state", label: "State", isNumeric: false, disablePadding: false},
  {id: "population", label: "Population", isNumeric: true, disablePadding: false}
];

const governmentTypeMapping = {
  "Authority": "Authorities",
  "Borough": "Boroughs",
  "Charter Township": "Charter Townships",
  "City": "Cities",
  "County": "Counties",
  "Municipality": "Municipalities",
  "Town": "Towns",
  "Town/Village": "Towns/Villiages",
  "Township": "Townships",
  "Tribal Government": "Tribal Governments",
  "Village": "Villages"
};

const stateMapping = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming",
};

class CodeFinderDialog extends React.PureComponent {

  static propTypes = {
    isCodeFinderOpen: PropTypes.bool.isRequired,
    foundCustomers: PropTypes.array.isRequired,
    defaultSelectedCustIds: PropTypes.array.isRequired,
    defaultSelectedCustomerFilters: PropTypes.array.isRequired,
    customerSearchOptions: PropTypes.object.isRequired,
    isCodeFinderLoading: PropTypes.bool.isRequired,
    query: PropTypes.string.isRequired,
    isSearchResultsPage: PropTypes.bool.isRequired,
    isSearchLoading: PropTypes.bool.isRequired,
    setCodeFinderOpen: PropTypes.func.isRequired,
    findCustomers: PropTypes.func.isRequired,
    clearFoundCustomers: PropTypes.func.isRequired,
    setSelectedCustomers: PropTypes.func.isRequired,
    submitSearch: PropTypes.func.isRequired,
    findCustomerSearchOptions: PropTypes.func.isRequired,
    updateWindowHistory: PropTypes.func.isRequired,
    setQuery: PropTypes.func.isRequired,
    resetSearchedCodeCount: PropTypes.func.isRequired,
    resetSelectedStructures: PropTypes.func.isRequired
  };

  getInitialState() {
    const {query, customers, defaultSelectedCustIds, defaultSelectedCustomerFilters} = this.props;
    return {
      name: "",
      state: null,
      counties: [],
      milesOf: null,
      zipCode: "",
      minPop: null,
      maxPop: null,
      governmentTypes: [],
      governmentSubType: null,
      selectedCustomerFilters: defaultSelectedCustomerFilters ? defaultSelectedCustomerFilters : [],
      selectedCustomers: defaultSelectedCustIds ? defaultSelectedCustIds.filter(selectedCustId => customers[selectedCustId])
        .map(selectedCustId => customers[selectedCustId]) : [],
      sortDirection: "asc",
      sortBy: "name",
      isLoading: false,
      tempQuery: query,
      tempState: "",
      tempCounty: "",
      tempGovType: "",
      tempGovSubType: "",
      tempMilesOf: ""
    };
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.handleFindCustomerSearchOptions = debounce(this.handleFindCustomerSearchOptions, debounceWait);
    this.handleFindCustomers = debounce(this.handleFindCustomers, debounceWait);
  }

  getCustomerFilterState = () => {
    const {name, state, counties, governmentTypes, governmentSubType, milesOf, zipCode, minPop, maxPop} = this.state;
    let customerFilterState = {
      name: name,
      state: state,
      counties: counties,
      governmentTypes: governmentTypes,
      governmentSubType: governmentSubType,
      milesOf: milesOf,
      zipCode: zipCode,
      minPop: minPop,
      maxPop: maxPop
    };
    customerFilterState.label = this.getCustomerFilterLabel(customerFilterState);
    customerFilterState.json = JSON.stringify(customerFilterState);
    return customerFilterState;
  };

  handleFindCustomers = () => {
    const {name, state, counties, governmentTypes, governmentSubType, milesOf, zipCode, minPop, maxPop} = this.state;
    this.props.findCustomers(
      name,
      state,
      counties,
      governmentTypes,
      governmentSubType,
      minPop,
      maxPop,
      (milesOf !== null ? zipCode : ""),
      (zipCode ? milesOf : null)
    );
    this.setState({isLoading: false});
  };

  handleFindCustomerSearchOptions = () => {
    const {name, state, counties, governmentTypes, governmentSubType} = this.state;
    this.props.findCustomerSearchOptions(
      name,
      state,
      counties,
      governmentTypes,
      governmentSubType
    );
  };

  setFilterChanges = (stateChanges) => () => {
    stateChanges.isLoading = true;
    this.setState(stateChanges, () => {
      this.handleFindCustomers();
      this.handleFindCustomerSearchOptions();
    });
  };

  handleQueryChange = (event) => this.setState({tempQuery: event.target.value});

  sortSelectValues = (name, searchOptions) => {
    const value = this.state[name].toUpperCase ? this.state[name].toUpperCase() : this.state[name];
    if (!searchOptions) {
      return [];
    } else if (!value) {
      return searchOptions.sort((a, b) => isNaN(a) - isNaN(b) || a - b || a > b || -(a < b));
    } else {
      return searchOptions.sort((option1, option2) => {
        if (option1.value.toUpperCase().startsWith(value) && option2.value.toUpperCase().startsWith(value)) {
          return 0;
        } else if (option1.value.toUpperCase().startsWith(value)) {
          return -1;
        } else if (option2.value.toUpperCase().startsWith(value)) {
          return 1;
        } else if (option1.label.toUpperCase().startsWith(value) && option2.label.toUpperCase().startsWith(value)) {
          return 0;
        } else if (option1.label.toUpperCase().startsWith(value)) {
          return -1;
        } else if (option2.label.toUpperCase().startsWith(value)) {
          return 1;
        } else if (option1.label.toUpperCase().includes(value) && option2.label.toUpperCase().includes(value)) {
          return 0;
        } else if (option1.label.toUpperCase().includes(value)) {
          return -1;
        } else if (option2.label.toUpperCase().includes(value)) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  };

  handleFieldTempChange = (name) => (value) => {
    if (name) {
      this.setState({[name]: value});
    }
  };

  clearStringValue = (name) => () => {
    this.setState({[name]: ""});
  };

  handleTextFieldChange = (fieldName) => (event) => {
    this.setState({[fieldName]: event.target.value, isLoading: true}, () => {
      this.handleFindCustomers();
      this.handleFindCustomerSearchOptions();
    });
  };

  handleMultiFieldChange = (fieldName) => (values) => {
    this.setState({
      [fieldName]: values.filter(value => value && value.value).map(value => (value ? value.value : [])),
      isLoading: true
    }, () => {
      this.handleFindCustomers();
      this.handleFindCustomerSearchOptions();
    });
  };

  handleGovSubTypeFieldChange = (value) => {
    this.setState({governmentSubType: (value ? value.value : null), isLoading: true}, () => {
      this.handleFindCustomers();
      this.handleFindCustomerSearchOptions();
    });
  };

  handleStateFieldChange = (value) => {
    this.setState({state: (value ? value.value : null), governmentSubType: null, isLoading: true}, () => {
      this.handleFindCustomers();
      this.handleFindCustomerSearchOptions();
    });
  };

  handleMilesOfFieldChange = (value) => {
    const {milesOf, zipCode} = this.state;
    this.setState({
      milesOf: (value ? value.value : ""),
      isLoading: (milesOf !== null && zipCode)
    }, () => {
      if (milesOf !== null && zipCode) {
        this.handleFindCustomers();
        this.handleFindCustomerSearchOptions();
      }
    });
  };

  handleZipCodeFieldChange = (event) => {
    const {milesOf, zipCode} = this.state;
    this.setState({
      zipCode: event.target.value,
      milesOf: milesOf ? milesOf : 0,
      isLoading: (!!zipCode)
    }, () => {
      if (milesOf !== null && zipCode) {
        this.handleFindCustomers();
        this.handleFindCustomerSearchOptions();
      }
    });
  };

  handlePopulationFieldChange = (values) => {
    this.setState({minPop: values[0], maxPop: values[1], isLoading: true}, () => {
      this.handleFindCustomers();
    });
  };

  addSelectedCustomer = (customerToAdd) => () => {
    if (!this.state.selectedCustomers.some(selectedCustomer => selectedCustomer.custId === customerToAdd.custId)) {
      this.setState({selectedCustomers: this.state.selectedCustomers.concat([customerToAdd])});
    }
  };

  removeSelectedCustomer = (customerToRemove) => () => {
    this.setState({selectedCustomers: this.state.selectedCustomers.filter(selectedCustomer => selectedCustomer.custId !== customerToRemove.custId)});
  };

  addSelectedCustomerFilter = (customerFilterToAdd) => () => {
    if (!this.state.selectedCustomerFilters.some(selectedCustomerFilter => selectedCustomerFilter.label === customerFilterToAdd.label)) {
      this.setState({selectedCustomerFilters: this.state.selectedCustomerFilters.concat([customerFilterToAdd])});
    }
  };

  removeSelectedCustomerFilter = (customerFilterToRemove) => () => {
    this.setState({selectedCustomerFilters: this.state.selectedCustomerFilters.filter(selectedCustomerFilter => selectedCustomerFilter.label !== customerFilterToRemove.label)});
  };

  handleDialogOpen = () => {
    this.setState(this.getInitialState());
    this.handleFindCustomerSearchOptions();
    this.handleFindCustomers();
  };

  handleDialogCancel = () => {
    const {setCodeFinderOpen, updateWindowHistory} = this.props;
    setCodeFinderOpen(false);
    updateWindowHistory({isCodeFinderOpen: false});
    this.props.clearFoundCustomers();
  };

  handleDialogSearch = () => {
    const {setCodeFinderOpen, setSelectedCustomers, resetSelectedStructures,
      resetSearchedCodeCount, submitSearch, setQuery, isSearchResultsPage, clearFoundCustomers} = this.props;
    const {tempQuery, selectedCustomers, selectedCustomerFilters} = this.state;
    if(selectedCustomerFilters.length > 0 || selectedCustomers.length !== 1 || selectedCustomers[0].custId !== custId) {
      resetSelectedStructures();
    }
    setQuery(tempQuery);
    if (isSearchResultsPage) {
      setCodeFinderOpen(false);
      clearFoundCustomers();
    }
    setSelectedCustomers(selectedCustomers.map(selectedCustomer => selectedCustomer.custId), selectedCustomerFilters);
    resetSearchedCodeCount();
    submitSearch();
  };

  handleDialogSubmit = (event) => {
    event.preventDefault();
    this.handleDialogSearch();
  };

  isCustomerSelected = (customer) => this.state.selectedCustomers.some(selectedCustomer => selectedCustomer.custId === customer.custId);

  isCustomerFilterSelected = (customerFilter) => this.state.selectedCustomerFilters.some(selectedCustomerFilter => selectedCustomerFilter.label === customerFilter.label);

  getCustomerFilterLabel = (filterState) => {
    let filterLabel = "All ";
    if (filterState.governmentSubType) {
      filterLabel += filterState.governmentSubType + " ";
    }
    if (filterState.governmentTypes && filterState.governmentTypes.length > 0) {
      filterLabel += filterState.governmentTypes.map(governmentType => (governmentTypeMapping[governmentType] ? governmentTypeMapping[governmentType] : governmentType)).join(" and ");
    } else {
      filterLabel += "Codes";
    }
    if (filterState.name) {
      filterLabel += " named “" + filterState.name + "”";
    }
    if (filterState.counties && filterState.counties.length > 0) {
      filterLabel += " in " + filterState.counties.map(county => county.charAt(0).toUpperCase() + county.slice(1) + " County").join(" or ");
    }
    if (filterState.state) {
      filterLabel += " in " + (stateMapping[filterState.state] ? stateMapping[filterState.state] : filterState.state);
    }
    if (filterState.milesOf !== null && filterState.zipCode) {
      filterLabel += " within " + filterState.milesOf + " miles of Zip Code " + filterState.zipCode;
    }
    if (filterState.minPop && filterState.maxPop) {
      filterLabel += " with a population between " + filterState.minPop + " and " + filterState.maxPop;
    } else if (filterState.minPop) {
      filterLabel += " with a population greater than " + filterState.minPop;
    } else if (filterState.maxPop) {
      filterLabel += " with a population less than " + filterState.maxPop;
    }
    return filterLabel;
  };

  sortDescending = (a, b, sortBy) => {
    if (b[sortBy] < a[sortBy]) {
      return -1;
    } else if (b[sortBy] > a[sortBy]) {
      return 1;
    } else {
      return 0;
    }
  };

  handleSortChange = (columnId) => () => {
    const {sortBy, sortDirection} = this.state;
    if (sortBy === columnId) {
      if (sortDirection === "asc") {
        this.setState({sortDirection: "desc"});
      } else {
        this.setState({sortDirection: "asc"});
      }
    } else {
      this.setState({sortBy: columnId, sortDirection: "asc"});
    }
  };

  formatUrl = (url, customer) => {
    Object.keys(customer).forEach(function (key) {
      if (customer.hasOwnProperty(key)) {
        url = url.replace(new RegExp("{" + key + "}", 'g'), customer[key]);
      }
    });
    return url;
  };

  customerRow = (customers) => ({ index, style }) => {
    const customer = customers[index];
    return (
      <div key={index} role="row" className="row" style={style}>
        <div role="cell" className="cell selectCell">
          <If test={!this.isCustomerSelected(customer)}>
            <IconButton aria-label={"Add " + customer.name} onClick={this.addSelectedCustomer(customer)}>
              <AddIcon title={"Add " + customer.name}/>
            </IconButton>
          </If>
          <If test={this.isCustomerSelected(customer)}>
            <IconButton className="removeIcon" aria-label={"Remove " + customer.name} onClick={this.removeSelectedCustomer(customer)}>
              <RemoveIcon title={"Remove " + customer.name}/>
            </IconButton>
          </If>
        </div>
        {
          columns.map((column, j) => {
            if (column.id === "id") {
              return null;
            } else {
              return (
                <div key={index + "_" + j} role="cell" className={"cell " + column.id + "Cell"}>
                  {
                    column.linkUrl ?
                      <a className="cellLink" href={this.formatUrl(column.linkUrl, customer)} target="_blank" rel="noopener noreferrer">
                        {customer.hasOwnProperty(column.id) ? customer[column.id] : ""}
                      </a>
                      :
                      customer.hasOwnProperty(column.id) ? customer[column.id] : ""
                  }
                </div>
              );
            }
          })
        }
      </div>
    );
  };

  render() {
    const {isCodeFinderOpen, isCodeFinderLoading, foundCustomers, customerSearchOptions, isSearchLoading, multicodeStates} = this.props;
    const {name, state, counties, milesOf, zipCode, minPop, maxPop, governmentTypes, governmentSubType,
      selectedCustomers, selectedCustomerFilters, isLoading, sortBy, sortDirection, tempQuery} = this.state;
    const customers = foundCustomers.sort(sortDirection === 'desc' ? (a, b) => this.sortDescending(a, b, sortBy) : (a, b) => -this.sortDescending(a, b, sortBy));
    const customerFilterState = this.getCustomerFilterState();

    const stateOptions = [{value: "", label: "", className: "Select-option"}];
    stateOptions.push(...this.sortSelectValues("tempState", customerSearchOptions.states
      .filter(stateOption => stateOption && stateOption.trim() !== "")
      .map((stateOption) => ({value: stateOption, label: stateMapping[stateOption] ? stateMapping[stateOption] : stateOption, className: "Select-option"})))
    );
    if (state && stateOptions.some(stateOption => stateOption.value !== state)) {
      stateOptions.push({value: state, label: stateMapping[state] ? stateMapping[state] : state, className: "Select-option"});
    }

    const countyOptions = [{value: "", label: "", className: "Select-option"}];
    countyOptions.push(...this.sortSelectValues("tempCounty",
      customerSearchOptions.counties.filter(countyOption => countyOption && countyOption.trim() !== "")
        .map((countyOption) => ({value: countyOption, label: countyOption, className: "Select-option"})))
    );
    counties.forEach(county => {
      if (countyOptions.some(countyOption => countyOption.value !== county)) {
        countyOptions.push({value: county, label: county, className: "Select-option"});
      }
    });

    const govTypeOptions = [{value: "", label: "", className: "Select-option"}];
    govTypeOptions.push(...this.sortSelectValues("tempGovType",
      customerSearchOptions.govTypes.filter(govTypeOption => govTypeOption && govTypeOption.trim() !== "")
        .map((govTypeOption) => ({value: govTypeOption, label: govTypeOption, clearableValue: true, className: "Select-option"})))
    );
    governmentTypes.forEach(govType => {
      if (govTypeOptions.some(govTypeOption => govTypeOption.value !== govType)) {
        govTypeOptions.push({value: govType, label: governmentTypeMapping[govType], className: "Select-option"});
      }
    });

    const govSubTypeOptions = [{value: "", label: "", className: "Select-option"}];
    govSubTypeOptions.push(...this.sortSelectValues("tempGovSubType",
      customerSearchOptions.govSubTypes.map((govSubTypeOption) => ({value: govSubTypeOption, label: govSubTypeOption, className: "Select-option"})))
    );
    if (governmentSubType && govSubTypeOptions.some(govSubTypeOption => govSubTypeOption.value !== governmentSubType)) {
      govSubTypeOptions.push({value: governmentSubType, label: governmentSubType, className: "Select-option"});
    }

    return (
      <div>
        <Dialog fullScreen
          id="codeFinder"
          open={isCodeFinderOpen}
          onClose={this.handleDialogCancel}
          PaperProps={{id: "codeFinderPaper"}}
          onEntering={this.handleDialogOpen}
        >
          <DialogContent id="codeFinderContent">
            <div id="codeFinderLeftColumn">
              <div className="heading">
                <span>Find Codes</span>
                <ContextHelpContainer pageId="multicodeSearchModal" />
              </div>
              <Card className={"card"}>
                <CardContent>
                  <form id="codeFinderInputForm" className="dialogSection" autoComplete="off">
                    <FormControl className="formControl textField">
                      <TextField
                        id="codeFinderMunicipalityNameInput"
                        label="Municipality Name"
                        name="codeName"
                        className="selectField"
                        value={name}
                        onChange={this.handleTextFieldChange("name")}
                      />
                      <div className="inputClear">
                        <If test={name}>
                          <IconButton aria-label="Clear value" onClick={this.setFilterChanges({name: ""})}>
                            <ClearIcon/>
                          </IconButton>
                        </If>
                      </div>
                    </FormControl>
                    <FormControl className="formControl">
                      <ReactSelect
                        clearable={false}
                        id="codeFinderStateInput"
                        className="selectField"
                        name="codeState"
                        value={state}
                        options={stateOptions}
                        onInputChange={this.handleFieldTempChange("tempState")}
                        onClose={this.clearStringValue("tempState")}
                        onChange={this.handleStateFieldChange}
                        placeholder=""
                        textFieldProps={{
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                      />
                      <label id="codeFinderStateLabel" htmlFor="codeFinderStateInput" className="Select-label">State</label>
                      <div className="inputClear">
                        <If test={state}>
                          <IconButton aria-label="Clear value" onClick={this.setFilterChanges({state: null, governmentSubType: null})}>
                            <ClearIcon/>
                          </IconButton>
                        </If>
                      </div>
                    </FormControl>
                    <FormControl className="formControl">
                      <ReactSelect
                        multi={true}
                        clearable={false}
                        id="codeFinderCountyInput"
                        className="selectField"
                        name="codeCounties"
                        value={counties}
                        onInputChange={this.handleFieldTempChange("tempCounty")}
                        onClose={this.clearStringValue("tempCounty")}
                        onChange={this.handleMultiFieldChange("counties")}
                        options={countyOptions}
                        placeholder=""
                        menuIsOpen={true}
                        textFieldProps={{
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                      />
                      <label id="codeFinderCountyLabel" htmlFor="codeFinderCountyInput" className="Select-label">Counties</label>
                      <div className="inputClear">
                        <If test={counties && counties.length > 0}>
                          <IconButton aria-label="Clear value" onClick={this.setFilterChanges({counties: []})}>
                            <ClearIcon/>
                          </IconButton>
                        </If>
                      </div>
                    </FormControl>
                    <FormControl className="formControl">
                      <ReactSelect
                        multi={true}
                        clearable={false}
                        id="codeFinderGovTypeInput"
                        className="selectField"
                        name="codeGovernmentTypes"
                        value={governmentTypes}
                        onInputChange={this.handleFieldTempChange("tempGovType")}
                        onClose={this.clearStringValue("tempGovType")}
                        onChange={this.handleMultiFieldChange("governmentTypes")}
                        options={govTypeOptions}
                        placeholder=""
                        textFieldProps={{
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                      />
                      <label id="codeFinderGovTypeLabel" htmlFor="codeFinderGovTypeInput" className="Select-label">Government Type</label>
                      <div className="inputClear">
                        <If test={governmentTypes && governmentTypes.length > 0}>
                          <IconButton aria-label="Clear value" onClick={this.setFilterChanges({governmentTypes: []})}>
                            <ClearIcon/>
                          </IconButton>
                        </If>
                      </div>
                    </FormControl>
                    <If test={multicodeStates.includes(state)}>
                      <FormControl className="formControl">
                        <ReactSelect
                          clearable={false}
                          id="codeFinderGovSubTypeInput"
                          className="selectField"
                          name="codeGovernmentSubType"
                          value={governmentSubType}
                          onInputChange={this.handleFieldTempChange("tempGovSubType")}
                          onClose={this.clearStringValue("tempGovSubType")}
                          onChange={this.handleGovSubTypeFieldChange}
                          options={govSubTypeOptions}
                          placeholder=""
                          textFieldProps={{
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                        />
                        <label id="codeFinderGovSubTypeLabel" htmlFor="codeFinderGovSubTypeInput" className="Select-label">Class</label>
                        <div className="inputClear">
                          <If test={governmentSubType}>
                            <IconButton aria-label="Clear value" onClick={this.setFilterChanges({governmentSubType: null})}>
                              <ClearIcon/>
                            </IconButton>
                          </If>
                        </div>
                      </FormControl>
                    </If>
                    <FormControl className="formControl">
                      <div id="codeFinderZipCodeControl">
                        <ReactSelect
                          clearable={false}
                          id="codeFinderMilesOfInput"
                          className="selectField"
                          name="codeMilesOf"
                          value={milesOf}
                          onChange={this.handleMilesOfFieldChange}
                          onInputChange={this.handleFieldTempChange("tempMilesOf")}
                          onClose={this.clearStringValue("tempMilesOf")}
                          options={this.sortSelectValues("tempMilesOf", [
                            {value: "", label: "", className: "Select-option"},
                            {value: "0", label: "0 miles", className: "Select-option"},
                            {value: "5", label: "5 miles", className: "Select-option"},
                            {value: "10", label: "10 miles", className: "Select-option"},
                            {value: "25", label: "25 miles", className: "Select-option"},
                            {value: "50", label: "50 miles", className: "Select-option"},
                            {value: "100", label: "100 miles", className: "Select-option"},
                            {value: "150", label: "150 miles", className: "Select-option"},
                            {value: "200", label: "200 miles", className: "Select-option"},
                            {value: "250", label: "250 miles", className: "Select-option"}
                          ])}
                          placeholder=""
                        />
                        <label id="codeFinderMilesOfLabel" htmlFor="codeFinderMilesOfInput" className="Select-label">Within</label>
                        <div id="codeFinderOfLabel">of</div>
                        <TextField
                          id="codeFinderZipCodeInput"
                          className="textField"
                          label="Zip Code"
                          name="codeZipCode"
                          value={zipCode}
                          onChange={this.handleZipCodeFieldChange}
                        />
                      </div>
                      <div className="inputClear">
                        <If test={milesOf !== null || zipCode}>
                          <IconButton aria-label="Clear value" onClick={this.setFilterChanges({milesOf: null, zipCode: ""})}>
                            <ClearIcon/>
                          </IconButton>
                        </If>
                      </div>
                    </FormControl>
                    <FormControl id="codeFinderPopulationControl" className="formControl">
                      <div id="codeFinderPopulationContent">
                        <InputLabel id="codeFinderPopulationLabel" htmlFor="codeFinderPopulationInput">Population</InputLabel>
                        <RangeSlider id="codeFinderPopulationInput"
                          className="codeFinderSliderField"
                          pushable={0}
                          min={Math.floor(customerSearchOptions.minPop / 500) * 500}
                          max={Math.ceil(customerSearchOptions.maxPop / 500) * 500}
                          step={500}
                          name="codePopulation"
                          value={[minPop, maxPop]}
                          onChange={this.handlePopulationFieldChange}
                        />
                      </div>
                      <div className="inputClear">
                        <If test={minPop !== null || maxPop !== null}>
                          <IconButton aria-label="Clear value" onClick={this.setFilterChanges({minPop: null, maxPop: null})}>
                            <ClearIcon/>
                          </IconButton>
                        </If>
                      </div>
                    </FormControl>
                  </form>
                </CardContent>
              </Card>
            </div>
            <div id="codeFinderRightColumn">
              <div className="heading"><span>Add Codes</span></div>
              <Card id="codeFinderSelectCodes" className="card dialogSection">
                <CardContent>
                  <div id="codeFinderSelectCodesContent">
                    <If test={isLoading || isCodeFinderLoading}>
                      <div className="spinnerContainer">
                        <CircularProgress size={54}/>
                      </div>
                    </If>
                    <If test={!isLoading && !isCodeFinderLoading}>
                      <div role="table" id="customerTable" aria-label="Found Customers" aria-describedby="customerTableLabel">
                        <div id="customerTableLabel">
                          <If test={!this.isCustomerFilterSelected(customerFilterState) && foundCustomers.length > 0}>
                            <IconButton aria-label={"Add " + customerFilterState.label} onClick={this.addSelectedCustomerFilter(customerFilterState)}>
                              <AddIcon title={"Add " + customerFilterState.label}/>
                            </IconButton>
                            <span className="subHeading">Add {customerFilterState.label} ({foundCustomers.length})</span>
                          </If>
                          <If test={this.isCustomerFilterSelected(customerFilterState)}>
                            <IconButton className="removeIcon" aria-label={"Remove " + customerFilterState.label} onClick={this.removeSelectedCustomerFilter(customerFilterState)}>
                              <RemoveIcon title={"Remove " + customerFilterState.label}/>
                            </IconButton>
                            <span className="subHeading">Remove {customerFilterState.label} ({foundCustomers.length})</span>
                          </If>
                        </div>
                        <div role="rowgroup" id="customerTableHeader">
                          <div role="row" className="row">
                            <div className="cell selectCell">Add</div>
                            {
                              columns.map((column, i) => (
                                <div key={i} role="columnheader" className={`cell ${column.id}Cell`} aria-label={column.label} title={`Sort by ${column.label}`} onClick={this.handleSortChange(column.id)}>
                                  <span className="cellLabel">{column.label}</span>
                                  <If test={sortBy === column.id}>
                                    <If test={sortDirection === "desc"}>
                                      <i className="material-icons">arrow_upward</i>
                                    </If>
                                    <If test={sortDirection === "asc"}>
                                      <i className="material-icons">arrow_downward</i>
                                    </If>
                                  </If>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        <div role="rowgroup" id="customerTableData">
                          <AutoSizer>
                            {({ height, width }) => (
                              <VirtualizedList
                                height={height}
                                width={width}
                                itemCount={foundCustomers.length}
                                itemSize={48}
                                selectedCustomers={selectedCustomers}
                              >
                                {this.customerRow(customers)}
                              </VirtualizedList>
                            )}
                          </AutoSizer>
                        </div>
                      </div>
                    </If>
                  </div>
                </CardContent>
              </Card>
              <div id="codeFinderSelectedCodeContainer" className="dialogSection muiChipContainer">
                <span id="codeFinderSelectedCodesLabel" className="subHeading">Codes to search:</span>
                <If test={selectedCustomerFilters.length === 0 && selectedCustomers.length === 0}>
                  <span>None</span>
                </If>
                <If test={selectedCustomerFilters.length > 0 || selectedCustomers.length > 0}>
                  {selectedCustomerFilters.map((selectedCustomerFilter, i) => {
                    return (
                      <Chip clickable key={i}
                        className="clickable wrappable muiChip selectedCode"
                        label={selectedCustomerFilter.label}
                        onDelete={this.removeSelectedCustomerFilter(selectedCustomerFilter)}
                        onClick={this.setFilterChanges({
                          name: selectedCustomerFilter.name,
                          state: selectedCustomerFilter.state,
                          counties: selectedCustomerFilter.counties,
                          governmentTypes: selectedCustomerFilter.governmentTypes,
                          governmentSubType: selectedCustomerFilter.governmentSubType,
                          milesOf: selectedCustomerFilter.milesOf,
                          zipCode: selectedCustomerFilter.zipCode,
                          minPop: selectedCustomerFilter.minPop,
                          maxPop: selectedCustomerFilter.maxPop
                        })}
                      />
                    );
                  })}
                  {selectedCustomers.map((customer, i) => {
                    return (
                      <Chip
                        key={i}
                        className="wrappable muiChip selectedCode"
                        label={customer.name}
                        onDelete={this.removeSelectedCustomer(customer)}
                      />
                    );
                  })}
                </If>
              </div>
            </div>
          </DialogContent>
          <form onSubmit={this.handleDialogSubmit}>
            <DialogActions id="codeFinderActions">
              <div className="heading"><span>Search Codes</span></div>
              <div className="content">
                <TextField
                  id="codeFinderSearchField"
                  className="searchFieldContainer"
                  placeholder="Enter search term..."
                  variant="outlined"
                  InputLabelProps={{
                    id: "codeFinderSearchLabel",
                    shrink: true,
                  }}
                  value={tempQuery}
                  onChange={this.handleQueryChange}
                  disabled={isSearchLoading}
                  autoComplete="off"
                />
                <ButtonGroup>
                  <Button onClick={this.handleDialogCancel} disabled={isSearchLoading}>Cancel</Button>
                  <PrimaryButton type="submit"
                    onClick={this.handleDialogSearch}
                    disabled={!tempQuery || (selectedCustomers.length === 0 && selectedCustomerFilters.length === 0) || isSearchLoading}
                  >
                    <If test={isSearchLoading}>
                      <CircularProgress size={24}/>
                    </If>
                    <If test={!isSearchLoading}>
                      Search
                    </If>
                  </PrimaryButton>
                </ButtonGroup>
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
export default CodeFinderDialog;
