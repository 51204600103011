/*
 * Copyright 2019 General Code
 */

import {ModuleKey} from '../ModuleKey';


const getState = state => state[ModuleKey];

const getMapUrl = state => getState(state).mapUrl;
const getOldMapUrl = state => getState(state).oldMapUrl;

const isDisplayDateAdmin = state => getState(state).isDisplayDateAdmin;
const getDisplayDate = state => getState(state).displayDate;
const getNameOverride = state => getState(state).nameOverride;
const getOldDisplayDate = state => getState(state).oldDisplayDate;
const getZoningStructure = state => getState(state).zoningStructure;
const isZoningStructureLoading = state => getState(state).zoningStructureLoading;

export {
  getDisplayDate,
  getMapUrl,
  getNameOverride,
  getOldDisplayDate,
  getOldMapUrl,
  getZoningStructure,
  isDisplayDateAdmin,
  isZoningStructureLoading
};

