import * as PropTypes from 'prop-types';
import React from 'react';

import {ReactSelect} from '../../common/components/reactSelect';
import {If} from '../../common/containers';

import './NoteFilter.css';


const NoteFilter = ({options, noteField, placeholder, value, onChange}) =>
  <If test={options.length > 1 || value}>
    <div className={`noteFilterSelectContainer ${noteField}FilterSelectContainer`}>
      <ReactSelect
        options={options}
        simpleValue={true}
        value={value}
        onChange={onChange}
        clearable={true}
        searchable={false}
        placeholder={placeholder}
      />
    </div>
  </If>;

NoteFilter.propTypes = {
  placeholder: PropTypes.string.isRequired,
  noteField: PropTypes.string.isRequired,
  preselect: PropTypes.string,
};

export default NoteFilter;

