import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import './Picker.css';

class Picker extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    updateValue: PropTypes.func.isRequired,
    display: PropTypes.shape({
      order: PropTypes.arrayOf(PropTypes.string).isRequired,
      options: PropTypes.object.isRequired,
    }).isRequired,
    className: PropTypes.string,
    classes: PropTypes.object,
  };
  state = {
    anchorEl: null,
  };


  onOpen = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  onClose = () => {
    this.setState({anchorEl: null});
  };

  onPick = (event, value) => {
    this.props.updateValue(value);
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const {id, type, className, label, value, tooltip, display: {order, options, defaultValue}, classes = {}} = this.props;
    const {anchorEl} = this.state;
    let safeValue;
    if(!options[value] && defaultValue && options[defaultValue]) {
      safeValue = defaultValue;
    } else {
      safeValue = value;
    }

    return (
      <div className={classNames(['picker', className])}>
        <Tooltip title={tooltip} enterDelay={300} open={anchorEl ? false : null}>
          <div>
            <Typography className={classNames(['pickerLabel',`pickerLabel-${type}`])} variant={'caption'}>{label}</Typography>
            <Paper elevation={2} square={false}>
              <List
                component="nav"
                className={classNames(["pickerList", `pickerList-${type}`])}
              >
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls={`pickerMenu-${type}-${id}`}
                  aria-label={label}
                  className={classNames([
                    'pickerButton',
                    'pickerItem',
                    `pickerItem-${type}`,
                    `pickerButton-${type}`,
                    classes[safeValue],
                  ])}
                  dense={true}
                  onClick={this.onOpen}>
                  <ListItemText
                    classes={{
                      primary: 'pickerButtonPrimary',
                      secondary: 'pickerButtonSecondary',
                    }}
                    primary={options[safeValue].primary}
                    secondary={options[safeValue].secondary}
                  />
                </ListItem>
              </List>
            </Paper>
          </div>
        </Tooltip>

        <Menu
          id={`pickerMenu-${type}-${id}`}
          className={classNames(["pickerMenu", `pickerMenu-${type}`])}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.onClose}
          MenuListProps={{dense: true, disablePadding: true}}
        >
          {
            order.map(option => (
              <MenuItem
                key={option}
                className={classNames(['pickerItem', `pickerItem-${type}`, classes[option]])}
                selected={option === safeValue}
                onClick={e => this.onPick(e, option)}
              >{options[option].short}</MenuItem>
            ))
          }
        </Menu>
      </div>
    );
  }
}

export default Picker;
