import * as PropTypes from 'prop-types';
import React, {Component} from "react";
import {
  AppBar,
  Button, DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Toolbar
} from "@material-ui/core";
import If from "../../common/containers/If";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmDialog from "../../common/components/ConfirmDialog";
import Dialog from "@material-ui/core/Dialog";
import {custId} from "../../common/utils/server-data";
import pubDocTypeId from "../utils/type-id";

class PubDocToolbar extends Component {

  static propTypes = {
    filterOptions: PropTypes.object.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    selectedDocs: PropTypes.array.isRequired,
    documents: PropTypes.array.isRequired,
    selectedFilters: PropTypes.object
  }

  state = {
    openMultipleDeleteDialog: false,
    downloadErrorOpen: false,
    documentToolbarMenuOpen: false
  }

  openToolbarDocumentMenu = (event) => {
    this.setState({documentToolbarMenuOpen: true, documentToolbarAnchorEl: event.currentTarget});
  }

  closeToolbarDownloadMenu = () => {
    this.setState({documentToolbarMenuOpen: false, documentToolbarAnchorEl: null});
  }

  handleCategoryFilterChange = (event) => {
    const {updateFilterState, selectedFilters} = this.props;
    updateFilterState(custId, pubDocTypeId, {
      category: event.target.value,
      year: selectedFilters.year,
      month: selectedFilters.month,
      day: selectedFilters.day
    });
  }

  handleYearFilterChange = (event) => {
    const {updateFilterState, selectedFilters} = this.props;
    updateFilterState(custId, pubDocTypeId, {
      category: selectedFilters.category,
      year: event.target.value,
      month: selectedFilters.month,
      day: selectedFilters.day
    });
  }

  handleMonthFilterChange = (event) => {
    const {updateFilterState, selectedFilters} = this.props;
    updateFilterState(custId, pubDocTypeId, {
      category: selectedFilters.category,
      year: selectedFilters.year,
      month: event.target.value,
      day: selectedFilters.day
    });
  }

  handleDayFilterChange = (event) => {
    const {updateFilterState, selectedFilters} = this.props;
    updateFilterState(custId, pubDocTypeId, {
      category: selectedFilters.category,
      year: selectedFilters.year,
      month: selectedFilters.month,
      day: event.target.value
    });
  }

  openEditDocumentDialog = (documentId) => () => {
    this.setState({typeAnchorEl: null});
    this.props.setDocumentEditOpen(true, documentId, null);
  }

  handleMove = () => {
    this.props.setDocumentMoveOpen(true, null);
  }

  handleOpenMultipleDeleteDialog = () => {
    this.setState({openMultipleDeleteDialog: true, documentToolbarMenuOpen: false, documentToolbarAnchorEl: null});
  }

  handleCloseMultipleDeleteDialog = () => {
    this.setState({openMultipleDeleteDialog: false});
  }

  handleBatchDelete = () => {
    this.setState({openMultipleDeleteDialog: false});
    this.props.deleteMultipleDocuments(this.props.custId, this.props.selectedDocs);
  }

  handleCloseDownloadError = () => {
    this.setState({downloadErrorOpen: false});
  }

  handleDownloadPubDocs = () => {
    const {custId, documents, selectedDocs} = this.props;
    const sizeOfList = documents.filter(currDoc => {
      return selectedDocs.includes(currDoc.id) && (currDoc.type === 'AUDIO_LINK' || currDoc.type === 'VIDEO_LINK');
    }).length;
    if (sizeOfList >= 1) {
      this.setState({downloadErrorOpen: true});
    } else {
      window.location.href = `/api/${custId}/pub-doc/${selectedDocs}/download`;
    }
  }

  render() {
    const { filterOptions, hasDocumentAdminPermission, selectedDocs, documents, selectedFilters } = this.props;
    const {documentToolbarMenuOpen, documentToolbarAnchorEl, openMultipleDeleteDialog, downloadErrorOpen} = this.state;
    const categoryFilter = selectedFilters.category == null ? "" : selectedFilters.category;
    const yearFilter = selectedFilters.year == null ? "" : selectedFilters.year;
    const monthFilter = selectedFilters.month == null ? "" : selectedFilters.month;
    const dayFilter = selectedFilters.day == null ? "" : selectedFilters.day;
    const monthList = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September",
      "October", "November", "December"];
    monthList[-1] = "(Undated)";
    return (
      <AppBar className="appBarSticky pubDocAppBar" position="sticky">
        <Toolbar className={"pubDocToolbar"}>
          <div className={"pubDocToolbarFiltersContainer"}>
            <If test={(filterOptions.categoryOptions ? filterOptions.categoryOptions.length : 0) > 1}>
              <FormControl className={"pubDocCategoryForm"} fullWidth>
                <InputLabel shrink id="categoryLabel">Category</InputLabel>
                <Select displayEmpty value={categoryFilter} onChange={this.handleCategoryFilterChange}>
                  <MenuItem value={''}>(All)</MenuItem>
                  { filterOptions.categoryOptions ? filterOptions.categoryOptions.map((category) =>
                    <MenuItem key={category} value={category}><div className={"menuItemEllipsis"}>{category}</div></MenuItem>
                  ) : null }
                </Select>
              </FormControl>
            </If>
            <If test={(filterOptions.yearOptions ? filterOptions.yearOptions.length : 0) > 1}>
              <FormControl className={"pubDocYearForm"}>
                <InputLabel shrink id="yearLabel">Year</InputLabel>
                <Select displayEmpty value={yearFilter} onChange={this.handleYearFilterChange}>
                  <MenuItem value={""}>(All)</MenuItem>
                  { filterOptions.yearOptions ? filterOptions.yearOptions.map((year) =>
                    <MenuItem key={year} value={year}>{ year !== "-1" ? year : "(Undated)" }</MenuItem>
                  ) : null }
                </Select>
              </FormControl>
            </If>
            <If test={(filterOptions.monthOptions ? filterOptions.monthOptions.length : 0) > 1}>
              <FormControl className={"pubDocMonthForm"}>
                <InputLabel shrink id="monthLabel">Month</InputLabel>
                <Select displayEmpty value={monthFilter} onChange={this.handleMonthFilterChange}>
                  <MenuItem value={""}>(All)</MenuItem>
                  { filterOptions.monthOptions ? filterOptions.monthOptions.map((month) =>
                    <MenuItem key={month} value={month}>{month ? monthList[month] : "(Undated)"}</MenuItem>
                  ) : null }
                </Select>
              </FormControl>
            </If>
            <If test={(filterOptions.dayOptions ? filterOptions.dayOptions.length : 0) > 1}>
              <FormControl className={"pubDocDayForm"}>
                <InputLabel shrink id="dayLabel">Day</InputLabel>
                <Select displayEmpty value={dayFilter} onChange={this.handleDayFilterChange}>
                  <MenuItem value={''}>(All)</MenuItem>
                  { filterOptions.dayOptions ? filterOptions.dayOptions.map((day) =>
                    <MenuItem key={day} value={day}>{day !== "-1" ? day : "(Undated)"}</MenuItem>
                  ) : null }
                </Select>
              </FormControl>
            </If>
          </div>
          <div className={"pubDocToolbarButtonContainer"}>
            <If test={hasDocumentAdminPermission}>
              <Button className={"pubDocToolbarButton"} variant="contained" onClick={this.openEditDocumentDialog(null)}>Add Document</Button>
            </If>
            <If test={selectedDocs.length > 0 && hasDocumentAdminPermission}>
              <Button className={"pubDocToolbarButton pubDocActionsButton"} variant="contained" onClick={this.openToolbarDocumentMenu}>
                Actions<ExpandMoreIcon />
              </Button>
              <Menu
                open={documentToolbarMenuOpen}
                anchorEl={documentToolbarAnchorEl}
                onClose={this.closeToolbarDownloadMenu}
              >
                <MenuItem onClick={this.handleMove}>Move</MenuItem>
                <MenuItem onClick={this.handleOpenMultipleDeleteDialog}>Delete</MenuItem>
                <MenuItem onClick={this.handleDownloadPubDocs}>Download</MenuItem>
              </Menu>
            </If>
          </div>
        </Toolbar>
        <AppBar className="gpsCategoryBar pubDocToolbarCategorySticky" position="static">
          <Toolbar variant="dense">
            <p id="gpsCategoryText">{categoryFilter}</p>
          </Toolbar>
        </AppBar>
        <ConfirmDialog
          title="Delete Public Document(s)?"
          open={openMultipleDeleteDialog}
          onCancel={this.handleCloseMultipleDeleteDialog}
          onConfirm={this.handleBatchDelete}
        >
          <div className={"pubDocModalText"}>Are you sure you want to delete the following Public Documents(s)?</div>
          <ul className={"pubDocModalList"}>
            {documents.filter(currDoc => {
              return selectedDocs.includes(currDoc.id);
            }).map((currDoc) => <li className={"pubDocModalListItem"} key={currDoc.id}>{currDoc.title}</li>)}
          </ul>
        </ConfirmDialog>
        <Dialog
          open={downloadErrorOpen}
          onClose={this.handleCloseDownloadError}
        >
          <DialogTitle>
            Error downloading document(s)
          </DialogTitle>
          <DialogContent>
            <div className={"pubDocModalText"}>Downloads are not supported for linked Public Documents. Please unselect the following documents and try again:</div>
            <ul className={"pubDocModalList"}>
              {documents.filter(currDoc => {
                return selectedDocs.includes(String(currDoc.id)) && (currDoc.type === 'AUDIO_LINK' || currDoc.type === 'VIDEO_LINK');
              }).map((currDoc) => <li className={"pubDocModalListItem"} key={currDoc.id}>{currDoc.title}</li>)}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.handleCloseDownloadError}>Close</Button>
          </DialogActions>
        </Dialog>
      </AppBar>
    );
  }
}

export default PubDocToolbar;