import React from 'react';
import BoxedSection from '../../common/components/BoxedSection';
import Button from '../../common/components/Button';
import ColorPicker from '../../common/components/ColorPicker';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '../../common/components/dialog';
import PrimaryButton from '../../common/components/PrimaryButton';
import {jsonEquals} from "../../common/utils";
import './CustomColorSelection.css';

const COLOR_OPTIONS = {
  'bannerBackgroundColor': 'Banner Background',
  'accentColor': 'Accent Color',
  'accentTextColor': 'Title Color'
};

const DEFAULT_COLORS = {
  bannerBackgroundColor: '#0B5940',
  accentColor: '#49A942',
  accentTextColor: '#005D85'
};

class CustomColorSection extends React.PureComponent {

  constructor(props) {
    super(props);

    const initialColorState = {};
    for (let colorKey in COLOR_OPTIONS) {
      initialColorState[colorKey] = '#' + props[colorKey];
    }

    this.state = {
      colors: initialColorState,
      lastAppliedColors: initialColorState,
      applyColorConfirmOpen: false,
    };
  }

  handleColorSelect = (colorProperty, colorValue) => {
    this.setState({colors: {...this.state.colors, [colorProperty]: colorValue}});
  };
  handleChangeColorsToDefault = () => {
    this.setState({colors: DEFAULT_COLORS});
  };
  handleResetColor = () => {
    this.setState({colors: this.state.lastAppliedColors});
  };
  handleApplyColorConfirmOpen = () => {
    this.setState({applyColorConfirmOpen: true});
  };
  handleApplyColorConfirmClose = () => {
    this.setState({applyColorConfirmOpen: false});
  };
  handleApplyColorConfirmOk = () => {
    this.props.applyColor(this.state.colors);
    this.setState({lastAppliedColors: this.state.colors, applyColorConfirmOpen: false});
  };

  render() {
    const {colors, lastAppliedColors, applyColorConfirmOpen} = this.state;
    return (
      <BoxedSection
        title="Color Customization"
        instructions="Customize how your Code looks by applying custom colors:"
        help={{topic: 'administration', subtopic: 'managecustomizations'}}
        actions={[
          {
            action: this.handleChangeColorsToDefault,
            label: "Change Colors to Default",
            disabled: jsonEquals(colors, DEFAULT_COLORS),
          },
          {
            action: this.handleResetColor,
            label: "Reset Color Changes",
            disabled: jsonEquals(colors, lastAppliedColors),
          },
          {
            action: this.handleApplyColorConfirmOpen,
            label: "Apply Color Changes",
            disabled: jsonEquals(colors, lastAppliedColors),
          },
        ]}
      >
        <div id="colorPreviewPane">
          <div id="previewPage">
            <div id="previewHeader" style={{backgroundColor: colors.bannerBackgroundColor}}/>
            <div id="previewContent">
              <ul id="previewNav">
                <li style={{borderColor: colors.accentColor}}>Page</li>
                <li>Page</li>
                <li>Page</li>
              </ul>
              <div id="previewTitle">
                <div style={{color: colors.accentTextColor, fontWeight: 'bold'}}>Title</div>
                <div id="previewBullet" style={{backgroundColor: colors.accentColor}}/>
                <span>bullet</span>
              </div>
            </div>
          </div>
        </div>
        <div id="colorInputPane">
          {Object.keys(COLOR_OPTIONS).map((id) => (
            <div key={id} className="colorSelection">
              <ColorPicker color={colors[id]} id={id} onColorSelect={this.handleColorSelect}/>
              <label>{COLOR_OPTIONS[id]}</label>
            </div>
          ))}
        </div>
        <Dialog
          open={applyColorConfirmOpen}
          onClose={this.handleApplyColorConfirmClose}
          disableBackdropClick={true}
          aria-labelledby="colorConfirmTitle"
        >
          <DialogTitle id="colorConfirmTitle">Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>Applied color changes will immediately be visible on your eCode</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleApplyColorConfirmClose}>Cancel</Button>
            <PrimaryButton onClick={this.handleApplyColorConfirmOk}>Ok</PrimaryButton>
          </DialogActions>
        </Dialog>
      </BoxedSection>
    );
  }
}

export default CustomColorSection;
