import * as React from 'react';
import If from '../containers/If';
import './AuditLine.css';

const getAuditText = (e) => {
  let line = '';
  if (e.createdOn) {
    line = `Created on ${e.createdOn}`;
    if (e.createdBy && e.createdBy.name) {
      line = `${line} by ${e.createdBy.name}`;
    } else if (e.createdBy && e.createdBy.email) {
      line = `${line} by ${e.createdBy.email}`;
    }
  }
  if (e.updatedOn) {
    line = `${line ? `${line}; ` : ""}Last modified on ${e.updatedOn}`;
    if (e.updatedBy && e.updatedBy.name) {
      line = `${line} by ${e.updatedBy.name}`;
    } else if (e.updatedBy && e.updatedBy.email) {
      line = `${line} by ${e.updatedBy.email}`;
    }
  }
  return line;
};

const AuditLine = ({entity, className="", condensedMode=false}) => {
  if (!entity || (!entity.createdOn && !entity.updatedOn)) return null;
  return (
    <div className={`auditLine ${className}`}>
      <If test={condensedMode}>
        <span>{entity.updatedOn || entity.createdOn}</span>
      </If>
      <If test={!condensedMode}>
        <span>{getAuditText(entity)}</span>
      </If>
    </div>
  );
};

export default AuditLine;
