import React from 'react';
import BoxedSection from '../../common/components/BoxedSection';
import Button from "../../common/components/Button";
import ButtonGroup from "../../common/components/ButtonGroup";
import Checkbox from '../../common/components/Checkbox';
import {Copy as CopyIcon} from '../../common/components/icons';
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "../../common/components/dialog";
import {Delete as DeleteIcon} from '../../common/components/icons';
import {Edit as EditIcon} from '../../common/components/icons';
import If from "../../common/containers/If";
import PrimaryButton from '../../common/components/PrimaryButton';
import IconButton from '../../common/components/IconButton';
import {Save as SaveIcon} from '../../common/components/icons';
import {Table, TableCell, TableHead, TableBody, TableRow} from "../../common/components/table";
import TextField from '../../common/components/TextField';
import {Warning as WarningIcon} from '../../common/components/icons';
import './ApiKeyAdminSection.css';

class ApiKeyAdminSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      apiKeyCreate: props['apiKeyCreate'] === 'true',
      apiKeyModify: props['apiKeyModify'] === 'true',
      contentApiAdmin: props['contentApiAdminValue'] === 'true',
      searchApiAdmin: props['searchApiAdminValue'] === 'true',
      codeApiAdmin: props['codeApiAdminValue'] === 'true',
      apiKeys: (props.hasOwnProperty("apiKeysJson") ? JSON.parse(props["apiKeysJson"]) : []),
      labelEditId: null,
      labelEditValue: "",
      apiKeyDeleteId: null,
      apiKeyCreateSecretKey: null
    };
  }

  addApiKey = (id, key, secretKey, label, contentEnabled, searchEnabled, codeEnabled) => {
    const newApiKey = {
      id: id,
      key: key
    };
    if (label !== null) newApiKey.label = label;
    if (contentEnabled !== null) newApiKey.contentEnabled = contentEnabled;
    if (searchEnabled !== null) newApiKey.searchEnabled = searchEnabled;
    if (codeEnabled !== null) newApiKey.codeEnabled = codeEnabled;
    this.setState(state => {
      const newApiKeyList = state.apiKeys.concat(newApiKey);
      return { apiKeys: newApiKeyList, apiKeyCreateSecretKey: secretKey };
    });
    this.showApiKeyCreateSuccessDialog(secretKey);
  };

  handleCreateApiKey = (label, contentEnabled, searchEnabled, codeEnabled) => () => {
    const newApiKey = {};
    newApiKey.label = label;
    newApiKey.contentEnabled = contentEnabled;
    newApiKey.searchEnabled = searchEnabled;
    newApiKey.codeEnabled = codeEnabled;
    this.props.createApiKey(this.addApiKey, label, contentEnabled, searchEnabled, codeEnabled);
  };

  removeApiKey = (id) => {
    this.setState(state => {
      return { apiKeys: state.apiKeys.filter(apiKey => apiKey.id !== id) };
    });
  };

  handleDeleteApiKey = (id) => () => {
    this.setState({apiKeyDeleteId: id});
  };

  updateApiKey = (id, label, contentEnabled, searchEnabled, codeEnabled) => {
    this.setState(state => {
      return {
        apiKeys: state.apiKeys.map((apiKey) => {
          return apiKey.id === id ? {
            id: id,
            key: apiKey.key,
            label: (label === null ? apiKey.label : label),
            contentEnabled: (contentEnabled === null ? apiKey.contentEnabled : contentEnabled),
            searchEnabled: (searchEnabled === null ? apiKey.searchEnabled : searchEnabled),
            codeEnabled: (codeEnabled === null ? apiKey.codeEnabled : codeEnabled)
          } : apiKey;
        })
      };
    });
  };

  handleContentEnabledChange = (id) => (event) => {
    this.props.updateApiKey(this.updateApiKey, id, null, event.target.checked, null, null);
  };

  handleSearchEnabledChange = (id) => (event) => {
    this.props.updateApiKey(this.updateApiKey, id, null, null, event.target.checked, null);
  };

  handleCodeEnabledChange = (id) => (event) => {
    this.props.updateApiKey(this.updateApiKey, id, null, null, null, event.target.checked);
  };

  handleEditApiKeyLabel = (id, label) => (event) => {
    if (event.type === "click" || (event.type === "keypress" && event.key === "Enter")) {
      event.preventDefault();
      this.setState({labelEditId: id, labelEditValue: label});
    }
  };

  handleApiKeyLabelChange = (event) => {
    this.setState({ labelEditValue: event.target.value });
  };

  handleApiKeyLabelSave = (id, label) => (event) => {
    event.preventDefault();
    if (label !== this.state.labelEditValue) {
      this.props.updateApiKey(this.updateApiKey, id, this.state.labelEditValue, null, null, null);
    }
    this.setState({ labelEditId: null, labelEditValue: "" });
  };

  handleDeleteConfirmation = (confirm) => () => {
    if (confirm) this.props.deleteApiKey(this.removeApiKey, this.state.apiKeyDeleteId);
    this.setState({apiKeyDeleteId: null});
  };

  selectSnippetText = () => {
    const node = document.getElementById("adminSettingsPage-apiAdmin-snippet-key");
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      console.warn("Could not select text in node: Unsupported browser.");
    }
  };

  copyToClipboard = () => {
    document.getElementById("adminSettingsPage-apiAdmin-snippetContainer").focus();
    document.execCommand("copy");
    this.props.createSuccessMessage("API key copied to clipboard");
  };

  showApiKeyCreateSuccessDialog = (key) => {
    this.setState({apiKeyCreateSecretKey: key});
  };

  closeApiKeyCreateSuccessDialog = () => {
    this.setState({apiKeyCreateSecretKey: null});
  };

  render() {
    const {apiKeyCreate, apiKeyModify, contentApiAdmin, searchApiAdmin, codeApiAdmin, apiKeys, labelEditId,
      labelEditValue, apiKeyDeleteId, apiKeyCreateSecretKey} = this.state;
    return (
      <BoxedSection
        id="adminSettingsPage-apiAdmin"
        title="API Key Management"
        instructions="Manage the API keys that give access to API endpoints"
        help={{topic: 'apikey', subtopic: 'manage'}}
        actions={apiKeyCreate ? [
          {
            action: this.handleCreateApiKey(),
            label: "Create API key"
          }
        ] : []}
      >
        <If test={apiKeys.length === 0}>
          <div><span>No API keys added.</span></div>
        </If>
        <If test={apiKeys.length > 0}>
          <Table id="adminSettingsPage-apiAdmin-table" className="lawTable reactTable">
            <colgroup>
              <col width="50px"/><col/>
              <If test={contentApiAdmin}><col width="72px"/></If>
              <If test={searchApiAdmin}><col width="72px"/></If>
              <If test={codeApiAdmin}><col width="72px"/></If>
              <If test={apiKeyModify}><col width="30px"/></If>
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <span id="adminSettingsPage-apiAdmin-keyColumn-label">Key</span>
                </TableCell>
                <TableCell>
                  <label id="adminSettingsPage-apiAdmin-labelColumn-label" htmlFor="adminSettingsPage-apiAdmin-labelColumn-field">Label</label>
                </TableCell>
                <If test={contentApiAdmin}>
                  <TableCell align="center">
                    <span id="adminSettingsPage-apiAdmin-contentColumn-label">Content API</span>
                  </TableCell>
                </If>
                <If test={searchApiAdmin}>
                  <TableCell align="center">
                    <span id="adminSettingsPage-apiAdmin-searchColumn-label">Search API</span>
                  </TableCell>
                </If>
                <If test={codeApiAdmin}>
                  <TableCell align="center">
                    <span id="adminSettingsPage-apiAdmin-codeColumn-label">Code API</span>
                  </TableCell>
                </If>
                <If test={apiKeyModify}><TableCell/></If>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiKeys.map((apiKey) =>
                <TableRow key={apiKey.id}>
                  <TableCell><div className="adminSettingsPage-apiAdmin-keyColumn-value">{apiKey.key}</div></TableCell>
                  <If test={apiKeyModify}>
                    <TableCell>{labelEditId === apiKey.id ?
                      <form className="adminSettingsPage-apiAdmin-labelColumn-form" onSubmit={this.handleApiKeyLabelSave(apiKey.id, apiKey.label)} autoComplete="off">
                        <TextField id="adminSettingsPage-apiAdmin-labelColumn-field"
                          className="adminSettingsPage-apiAdmin-labelColumn-field"
                          value={labelEditValue}
                          onChange={this.handleApiKeyLabelChange}
                          margin="normal"
                          autoFocus />
                        <IconButton aria-label="Save" className="adminSettingsPage-apiAdmin-labelColumn-save" type="submit">
                          <SaveIcon/>
                        </IconButton>
                      </form> :
                      <div className="adminSettingsPage-apiAdmin-labelColumn-container" aria-label="Edit"
                        tabIndex="0"
                        onClick={this.handleEditApiKeyLabel(apiKey.id, apiKey.label)}
                        onKeyPress={this.handleEditApiKeyLabel(apiKey.id, apiKey.label)}>
                        <div className="adminSettingsPage-apiAdmin-labelColumn-value">{apiKey.label}</div>
                        <EditIcon className="adminSettingsPage-apiAdmin-labelColumn-edit"/>
                      </div>
                    }</TableCell>
                  </If>
                  <If test={!apiKeyModify}>
                    <TableCell>
                      <div className="adminSettingsPage-apiAdmin-labelColumn-container">
                        <div className="adminSettingsPage-apiAdmin-labelColumn-value">{apiKey.label}</div>
                      </div>
                    </TableCell>
                  </If>
                  <If test={contentApiAdmin}>
                    <TableCell>
                      <Checkbox id={"adminSettingsPage-apiAdmin-contentEnabled-" + apiKey.id}
                        checked={apiKey.contentEnabled}
                        onChange={this.handleContentEnabledChange(apiKey.id)}
                        style={{height: 'auto'}}
                        disabled={!apiKeyModify}
                      />
                    </TableCell>
                  </If>
                  <If test={searchApiAdmin}>
                    <TableCell>
                      <Checkbox id={"adminSettingsPage-apiAdmin-searchEnabled-" + apiKey.id}
                        checked={apiKey.searchEnabled}
                        onChange={this.handleSearchEnabledChange(apiKey.id)}
                        style={{height: 'auto'}}
                        disabled={!apiKeyModify}
                      />
                    </TableCell>
                  </If>
                  <If test={codeApiAdmin}>
                    <TableCell>
                      <Checkbox id={"adminSettingsPage-apiAdmin-codeEnabled-" + apiKey.id}
                        checked={apiKey.codeEnabled}
                        onChange={this.handleCodeEnabledChange(apiKey.id)}
                        style={{height: 'auto'}}
                        disabled={!apiKeyModify}
                      />
                    </TableCell>
                  </If>
                  <If test={apiKeyModify}>
                    <TableCell>
                      <IconButton aria-label="Delete" onClick={this.handleDeleteApiKey(apiKey.id)}>
                        <DeleteIcon/>
                      </IconButton>
                    </TableCell>
                  </If>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </If>

        <Dialog
          id="adminSettingsPage-apiAdmin-deleteConfirmDialog"
          open={apiKeyDeleteId !== null}
          onClose={this.handleDeleteConfirmation(false)}
          maxWidth={'md'}
          fullWidth={true}
          disableBackdropClick={true}
        >
          <DialogTitle>
            <span>Delete Confirmation</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this API key?</DialogContentText>
            <div className="adminSettingsPage-apiAdmin-dialogActions">
              <ButtonGroup>
                <Button onClick={this.handleDeleteConfirmation(false)}>Cancel</Button>
                <PrimaryButton onClick={this.handleDeleteConfirmation(true)}>Delete</PrimaryButton>
              </ButtonGroup>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          id="adminSettingsPage-apiAdmin-createSuccessDialog"
          open={apiKeyCreateSecretKey !== null}
          onClose={this.closeApiKeyCreateSuccessDialog}
          maxWidth={'md'}
          fullWidth={true}
          disableBackdropClick={true}
        >
          <DialogTitle>
            <span>API Key Created!</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Your API secret key:</DialogContentText>
            <div id="adminSettingsPage-apiAdmin-snippetContainer" className="adminSettingsPage-apiAdmin-snippetContainer" tabIndex="0" onFocus={this.selectSnippetText}>
              <div id="adminSettingsPage-apiAdmin-snippet-key" className="adminSettingsPage-apiAdmin-snippet">{apiKeyCreateSecretKey}</div>
              <IconButton className="adminSettingsPage-apiAdmin-copy" aria-label="Copy" onClick={this.copyToClipboard}>
                <CopyIcon/>
              </IconButton>
            </div>
            <div className="adminSettingsPage-apiAdmin-keyWarning">
              <WarningIcon className="warningIcon"/>
              <div>This is the last time you will be able to see your secret key so make sure to copy it to somewhere safe.</div>
            </div>
            <div className="adminSettingsPage-apiAdmin-dialogActions">
              <ButtonGroup>
                <PrimaryButton onClick={this.closeApiKeyCreateSuccessDialog}>Close</PrimaryButton>
              </ButtonGroup>
            </div>
          </DialogContent>
        </Dialog>
      </BoxedSection>
    );
  }
}

export default ApiKeyAdminSection;
