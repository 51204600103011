import {connect} from 'react-redux';
import {compose} from 'recompose';

import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";
import QuestionsPageTitle from "../components/QuestionsPageTitle";
import {displayAllQuestions} from "../actions";
import {setPrintLoading} from "../../common/actions";
import {
  getDisplayedQuestionCount,
  getDisplayedQuestionIds
} from "../selectors";
import { isNewNav } from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  displayedQuestionCount: getDisplayedQuestionCount,
  displayedQuestionIds: getDisplayedQuestionIds,
  isNewNav: isNewNav
});

const mapDispatchToProps = (dispatch) => ({
  setPrintLoading: (isLoading) => {
    dispatch(setPrintLoading(isLoading));
  },
  displayAllQuestions: () => {
    dispatch(displayAllQuestions());
  }
});

const QuestionsPageTitleContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(QuestionsPageTitle);
export default QuestionsPageTitleContainer;