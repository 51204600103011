import * as PropTypes from "prop-types";
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import PubDocCategoryEditDialog from "../components/PubDocCategoryEditDialog";
import {getFilteredCategories, getCategoryEditId, getCategoryEditLoading, getCategoryEditOpen} from "../selectors";
import {addPubDocCategory, editPubDocCategory} from "../actions";
import {hasPubDocPrivateViewPermission} from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  categoryEditOpen: getCategoryEditOpen,
  categoryEditId: getCategoryEditId,
  categories: getFilteredCategories,
  hasPubDocPrivateViewPermission: hasPubDocPrivateViewPermission,
  categoryEditLoading: getCategoryEditLoading
});

const mapDispatchToProps = (dispatch) => ({
  addCategory: (custId, name, description, typeId, typeTitle, isPrivate, groupByType, sortByFirstType, sortBySecondType) => {
    dispatch(addPubDocCategory(custId, name, description, typeId, typeTitle, isPrivate, groupByType, sortByFirstType, sortBySecondType));
  },
  editCategory: (custId, name, description, typeId, typeTitle, categoryId, isPrivate, groupByType, sortByFirstType, sortBySecondType) => {
    dispatch(editPubDocCategory(custId, name, description, typeId, typeTitle, categoryId, isPrivate, groupByType, sortByFirstType, sortBySecondType));
  }
});

const PubDocCategoryEditDialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocCategoryEditDialog);
PubDocCategoryEditDialogContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  custId: PropTypes.string.isRequired,
  typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  typeTitle: PropTypes.string.isRequired
};

export default PubDocCategoryEditDialogContainer;