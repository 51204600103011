import * as PropTypes from "prop-types";
import React from "react";
import If from "../containers/If";

class NavigationSubItem extends React.PureComponent {

  static displayName = 'NavigationSubItem';
  static propTypes = {
    hidden: PropTypes.bool,
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
    openNewTab: PropTypes.bool,
    selected: PropTypes.bool,
    title: PropTypes.string,
    count: PropTypes.number,
    isTitle: PropTypes.bool
  };

  render() {
    const { hidden, label, href, openNewTab, onClick, selected, title, count, isTitle, isLink } = this.props;
    if (hidden || (!href && !onClick && isLink)) return null;
    return (
      <div className={"item" + (selected ? " selected" : "") + (href ? " item-link" : "")}>
        <a className={"item-label" + (href ? "" : " item-label-not-link")}
          href={selected ? null : href}
          onClick={onClick}
          title={title ? title : label}
          target={(href && openNewTab) ? "_blank" : null}
          rel={(href && openNewTab) ? "noopener noreferrer" : null}
        >
          <span className={`item-label-text${isTitle? " nav-item-title-text" : ""}`}>
            {label}<If test={count != null}> ({count})</If>
          </span>
        </a>
      </div>
    );
  }
}
export default NavigationSubItem;
