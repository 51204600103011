import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {createStructuredSelector} from "reselect";
import {getCustId, getPubDocsOnly, getTermsOfUseUrl} from "../../common/selectors";
import {custId} from "../../common/utils/server-data";
import {createNewMessage, MessageTypes} from "../../message-queue/index";
import {
  dataUrlToBlob,
  deleteJson,
  get,
  getErrorMessage,
  getJson,
  patchJson,
  post,
  postFormData
} from '../../common/utils/index';
import {
  getDisplayDate,
  getMapUrl,
  getNameOverride,
  getOldDisplayDate,
  getOldMapUrl,
  getZoningStructure,
  isDisplayDateAdmin,
  isZoningStructureLoading
} from "../selectors";
import { getCustomization } from "../../common/selectors";
import {setDisplayDate, setMapUrl, setZoningChapter, updateDisplayDate, updateMapUrl} from "../actions";
import {fetchRootReferenceNodes} from "../../common/actions/references";
import SettingsPage from '../components/SettingsPage';
import {registerError} from "../../common/actions";

export const refreshCustomizationCss = () => {
  const customizationCss = document.getElementById("customizationCss");
  const href = `/customization/${custId}/css?ts=${Date.now()}`;
  if (customizationCss) {
    customizationCss.href = href;
  } else {
    const link = document.createElement('link');
    link.setAttribute('id', 'customizationCss');
    link.setAttribute('type', 'text/css');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', href);
    document.getElementsByTagName('head')[0].appendChild(link);
  }
};

export const setWebsiteLink = (website) => {
  const websiteLink = document.getElementById("websiteLink");
  const href = website;
  if (websiteLink) {
    websiteLink.href = href;
  } else {
    const anchor = document.createElement('a');
    anchor.setAttribute('id', 'websiteLink');
    anchor.setAttribute('href', href);
    const topElement = document.getElementById('top');
    topElement.insertBefore(anchor, topElement.childNodes[0]);
  }
};

export const setPrivateWarning = (isPrivateMode) => {
  const warnings = isPrivateMode &&
    <div id="warningSpacer">
      <div id="warning">This Private eCode is only available online to logged-in users.</div>
    </div>;
  ReactDOM.render(warnings, document.getElementById('warnings'));
};

export const setCustomerName = (customerName) => {
  const muniName = document.getElementById("muniName");
  muniName.innerHTML = '';
  muniName.appendChild(document.createTextNode(customerName));
};

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  mapUrl: getMapUrl,
  oldMapUrl: getOldMapUrl,
  isDisplayDateAdmin: isDisplayDateAdmin,
  displayDate: getDisplayDate,
  oldDisplayDate: getOldDisplayDate,
  termsOfUseUrl: getTermsOfUseUrl,
  zoningStructure: getZoningStructure,
  zoningStructureLoading: isZoningStructureLoading,
  nameOverride: getNameOverride,
  isPubDocsOnly: getPubDocsOnly,
  customization: getCustomization
});

const mapDispatchToProps = dispatch => ({

  fetchRootReferenceNodes: () => dispatch(fetchRootReferenceNodes()),

  setMapUrl: mapUrl => dispatch(setMapUrl(mapUrl)),
  updateMapUrl: () => dispatch(updateMapUrl()),

  setDisplayDate: displayDate => dispatch(setDisplayDate(displayDate)),
  updateDisplayDate: () => dispatch(updateDisplayDate()),

  createSuccessMessage: (message) => {
    createNewMessage(dispatch, MessageTypes.SUCCESS, <span>{message}</span>);
  },

  setZoningChapter: guid => dispatch(setZoningChapter(guid)),

  applyColor: (colors) => {
    const postData = [];
    for (let colorKey in colors) {
      postData.push({
        type: colorKey,
        value: colors[colorKey].substring(1)
      });
    }
    post(`/${custId}/customization`, postData)
      .then(() => {
        refreshCustomizationCss();
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Color customizations have been applied</span>);
      })
      .catch((error) => {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  applyBanner: (banner, bannerImagePreview) => {
    const postData = [];
    postData.push({type: 'banner', value: banner.useBanner});
    postData.push({type: 'website', value: banner.website});

    const promises = [];
    promises.push(post(`/${custId}/customization`, postData));

    if (banner.useBanner && bannerImagePreview) {
      const formData = new FormData();
      formData.append('file', dataUrlToBlob(bannerImagePreview));
      promises.push(postFormData(`/${custId}/customization/banner`, formData));
    }

    Promise.all(promises)
      .then(() => {
        refreshCustomizationCss();
        setWebsiteLink(banner.website);
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Banner customizations have been applied</span>);
      })
      .catch((error) => {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setLawsEnabled: (lawsEnabled) => {
    const data = {permission: "LAW/ALL/VIEW", level: lawsEnabled ? null : "USER"};
    const modeString = lawsEnabled ? 'No Login Required' : 'Login Required';

    patchJson(`/api/${custId}/features`, data)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>New Laws view changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setLawsCciEnabled: (lawsCciEnabled) => {
    const data = {permission: "LAW/CCI/VIEW", level: lawsCciEnabled ? null : "USER"};
    const modeString = lawsCciEnabled ? 'No Login Required' : 'Login Required';

    patchJson(`/api/${custId}/features`, data)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>New Law Indicators view changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },


  setCodifiedLawsEnabled: (codifiedLawsEnabled) => {
    const postData = [{type: "codifiedLawViewEnable", value: codifiedLawsEnabled}];
    const modeString = codifiedLawsEnabled ? 'enabled' : 'disabled';

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Past Law View has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setLegrefLinkEnabled: (legrefLinkEnabled) => {
    const postData = [{type: "legrefLinkEnable", value: legrefLinkEnabled}];
    const modeString = legrefLinkEnabled? 'enabled' : 'disabled';

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Legref links has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setPubDocsDateDisabled: (pubdocsDateDisabled) => {
    const postData = [{type: "pubdocsDateDisabled", value: pubdocsDateDisabled}];
    const modeString = pubdocsDateDisabled ? 'enabled' : 'disabled';

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Pubdocs Dates Disabled has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setLawsTitleField: (lawsTitleField) => {
    const postData = [{type: "lawTitleField", value: lawsTitleField}];
    post(`/${custId}/customization`, postData)
      .then(() => {
        refreshCustomizationCss();
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Law title field change been applied</span>);
      })
      .catch((error) => {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setEnableGroupNotes: (isGroupNotesEnabled) => {
    const data = {permission: "GROUP_ANNOTATION", level: isGroupNotesEnabled ? null : "NOBODY"};
    const modeString = isGroupNotesEnabled ? 'enabled' : 'disabled';

    patchJson(`/api/${custId}/features`, data)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Private Group notes have been <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setEnableIndexView: (isIndexViewEnabled) => {
    const data = {permission: "INDEX/VIEW", level: isIndexViewEnabled ? null : "NOBODY"};
    const modeString = isIndexViewEnabled ? 'enabled' : 'disabled';

    patchJson(`/api/${custId}/features`, data)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>View of the code index page has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setEnableTranslate: (isTranslateEnabled) => {
    const postData = [{type: "translate", value: isTranslateEnabled}];
    const modeString = isTranslateEnabled ? 'enabled' : 'disabled';

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Translate has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setPrivateMode: (privateModeEnabled) => {
    const data = {permission: "VIEW", level: privateModeEnabled ? "USER" : null};
    const modeString = privateModeEnabled ? 'enabled' : 'disabled';

    patchJson(`/api/${custId}/features`, data)
      .then(response => {
        setPrivateWarning(response['currentPermission'] !== "PUBLIC");
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Private Mode <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setIa2Mode: (isIa2ModeEnabled) => {
    const postData = [{type: "ia2", value: isIa2ModeEnabled}];
    const modeString = isIa2ModeEnabled ? 'enabled' : 'disabled';

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>IA2 Mode has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setIa2Import: (isIa2ImportEnabled) => {
    const postData = [{type: "ia2ImportEnable", value: isIa2ImportEnabled}];
    const modeString = isIa2ImportEnabled ? 'enabled' : 'disabled';

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>IA2 Import has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setMixedContentEnabled: (isMixedContentEnabled) => {
    const postData = [{type: "mixedContentEnable", value: isMixedContentEnabled}];
    const modeString = isMixedContentEnabled ? 'shown' : 'hidden';

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Mixed Content is <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setAllowRemoteAuth: (isAllowRemoteAuthEnabled) => {
    const postData = [{type: "allowRemoteAuth", value: isAllowRemoteAuthEnabled}];
    const modeString = isAllowRemoteAuthEnabled ? 'enabled' : 'disabled';

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Remote authentication has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setSidebarLabel: (sidebarLabel) => {
    const postData = [{type: "sidebarLabel", value: sidebarLabel}];

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Sidebar Label has been changed to <b>{sidebarLabel}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setPubDocsOnlyText: (pubDocsOnlyText) => {
    const postData = [{type: "pubDocsOnlyText", value: pubDocsOnlyText}];

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>PubDocs Standalone Text has been changed to <b>{pubDocsOnlyText}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setCodeReviewPhase: (codeReviewPhase) => {
    const postData = [{type: "codeReviewPhase", value: codeReviewPhase}];

    post(`/${custId}/customization`, postData)
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Code Review Phase has been set to <b>{codeReviewPhase ? codeReviewPhase : "None"}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setShowCountyLine: (showCountyLine) => {
    const postData = [{type: 'showCountyLine', value: showCountyLine}];
    const modeString = showCountyLine ? 'shown' : 'hidden';

    post(`/${custId}/customization`, postData)
      .then(() => {
        refreshCustomizationCss();
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>County line is now being <b>{modeString}</b></span>);
      })
      .catch((error) => {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setBulkUpload: (bulkUpload) => {
    const actionString = bulkUpload ? 'grant' : 'revoke';
    const modeString = bulkUpload ? 'granted' : 'revoked';
    post(`/permissions/${actionString}/BULK_UPLOAD/${custId}`)
      .then(() => {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Bulk Upload permissions are <b>{modeString}</b></span>);
      })
      .catch((error) => {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setContentApiAdmin: (isContentApiAdminEnabled) => {
    const permissionName = "API/CONTENT/ADMIN";
    const actionString = isContentApiAdminEnabled ? 'grant' : 'revoke';
    const modeString = isContentApiAdminEnabled ? 'enabled' : 'disabled';
    post(`/api/${custId}/${actionString}/permission`, {permissionName: permissionName})
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Admin use of the content API has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setSearchApiAdmin: (isSearchApiAdminEnabled) => {
    const permissionName = "API/SEARCH/ADMIN";
    const actionString = isSearchApiAdminEnabled ? 'grant' : 'revoke';
    const modeString = isSearchApiAdminEnabled ? 'enabled' : 'disabled';
    post(`/api/${custId}/${actionString}/permission`, {permissionName: permissionName})
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Admin use of the search API has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  setCodeApiAdmin: (isCodeApiAdminEnabled) => {
    const permissionName = "API/CODE/ADMIN";
    const actionString = isCodeApiAdminEnabled ? 'grant' : 'revoke';
    const modeString = isCodeApiAdminEnabled ? 'enabled' : 'disabled';
    post(`/api/${custId}/${actionString}/permission`, {permissionName: permissionName})
      .then(function () {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Admin use of the search API has been changed to <b>{modeString}</b></span>);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  applyCustomerName: (customerName) => {
    const postData = [{type: "customerName", value: customerName}];

    post(`/${custId}/customization`, postData)
      .then(() => {
        setCustomerName(customerName);
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Code Name Override applied to code</span>);
      })
      .catch((error) => {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  applyCounties: (counties) => {
    const postData = [{type: "counties", value: counties.join(",")}];
    post(`/${custId}/customization`, postData)
      .then(() => {
        refreshCustomizationCss();
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Code counties have been updated</span>);
      })
      .catch((error) => {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  applyEReader: (eReaderSubscribers, eReaderOverlay) => {
    const formData = new URLSearchParams();
    formData.append('custIds', custId);
    formData.append(custId, eReaderSubscribers);

    postFormData('/ereader/subscribe', formData)
      .then(() => {
        eReaderOverlay.style.display = 'none';
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>eReader subscribers saved</span>);
      })
      .catch((error) => {
        eReaderOverlay.style.display = 'none';
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
    eReaderOverlay.style.display = 'flex';
  },

  applySendNewEReader: (eReaderSubscribers, eReaderOverlay) => {
    const formData = new URLSearchParams();
    formData.append('custIds', custId);
    formData.append(custId, eReaderSubscribers);
    formData.append('notifyNew', "true");

    postFormData('/ereader/subscribe', formData)
      .then(() => {
        eReaderOverlay.style.display = 'none';
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>eReader subscribers saved and emails sent to new users</span>);
      })
      .catch((error) => {
        eReaderOverlay.style.display = 'none';
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
    eReaderOverlay.style.display = 'flex';
  },

  applySendAllEReader: (eReaderSubscribers, eReaderOverlay) => {
    const formData = new URLSearchParams();
    formData.append('custIds', custId);
    formData.append(custId, eReaderSubscribers);

    postFormData('/ereader/subscribe', formData)
      .then(() => {
        get(`/ereader/notify/${custId}`)
          .then(() => {
            eReaderOverlay.style.display = 'none';
            createNewMessage(dispatch, MessageTypes.SUCCESS, <span>eReader subscribers saved and emails sent to all users</span>);
          })
          .catch((error) => {
            eReaderOverlay.style.display = 'none';
            createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
          });
      })
      .catch((error) => {
        eReaderOverlay.style.display = 'none';
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
    eReaderOverlay.style.display = 'flex';
  },

  createApiKey: (addApiKeyCallback, label, contentEnabled, searchEnabled, codeEnabled) => {
    const postData = {};
    if (label !== null) postData.label = label;
    if (contentEnabled !== null) postData.contentEnabled = contentEnabled;
    if (searchEnabled !== null) postData.searchEnabled = searchEnabled;
    if (codeEnabled !== null) postData.codeEnabled = codeEnabled;
    post(`/api/${custId}/key`, postData)
      .then((response) => {
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>API key created</span>);
        return response.json().then(data => {
          addApiKeyCallback(data["id"], data["key"], data["secretKey"], label, contentEnabled, searchEnabled, codeEnabled);
        });
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  deleteApiKey: (removeApiKeyCallback, id) => {
    deleteJson(`/api/${custId}/key/${id}`)
      .then(() => {
        removeApiKeyCallback(id);
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>API key deleted</span>);
      })
      .catch((error) => {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  updateApiKey: (updateApiKeyCallback, id, label, contentEnabled, searchEnabled, codeEnabled) => {
    const postData = {};
    if (label !== null) postData.label = label;
    if (contentEnabled !== null) postData.contentEnabled = contentEnabled;
    if (searchEnabled !== null) postData.searchEnabled = searchEnabled;
    if (codeEnabled !== null) postData.codeEnabled = codeEnabled;
    patchJson(`/api/${custId}/key/${id}`, postData)
      .then(() => {
        const messages = [];
        if (label !== null) { messages.push(`API key label updated to ${label}`); }
        if (contentEnabled !== null) { messages.push(`API key content API ${contentEnabled ? 'enabled' : 'disabled'}`); }
        if (searchEnabled !== null) {
          messages.push(`API key search API ${searchEnabled ? 'enabled' : 'disabled'}`);
        }
        if (codeEnabled !== null) {
          messages.push(`API key code finder API ${codeEnabled ? 'enabled' : 'disabled'}`);
        }
        createNewMessage(dispatch, MessageTypes.SUCCESS, <span>{messages.join(', ')}</span>);
        updateApiKeyCallback(id, label, contentEnabled, searchEnabled, codeEnabled);
      })
      .catch(function (error) {
        createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
      });
  },

  testApiError: () => {
    getJson("/api/test/error").then().catch(function (error) {
      dispatch(registerError(error.message, error.title, null, error));
    });
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);

