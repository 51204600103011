/*
 * Copyright 2018 General Code
 */

import React from 'react';
import Editor from '../components/Editor';
import {connect} from 'react-redux';
import branch from 'recompose/branch';
import compose from 'recompose/compose';
import renderComponent from 'recompose/renderComponent';
import * as PropTypes from 'prop-types';
import {updateContent, addReference} from "../actions";
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {getColor, isOpen, getContent, getHtml, isEditing} from "../selectors";
import {bindActionCreators} from "redux";



const mapStateToProps = createStructuredSelector({
  content: getContent,
  html: getHtml,
  steve: () => "steve",
  color: getColor,
  open: isOpen,
  editing: isEditing,
});


const mapDispatchToProps = (dispatch, {id}) => bindActionCreators({
  updateContent: content => updateContent(id, content),
  addReference: ({text, guid}) => addReference(id, {text, guid}),
}, dispatch);

const JustHtml = (props) => props && props.html && props.open ? <div className="noteHtml" dangerouslySetInnerHTML={{__html: props.html}}/> : null;


const ifContentLoaded = branch(({editing, html}) => html && !editing, renderComponent(JustHtml));
const EditorContainer = compose(connect(mapStateToProps, mapDispatchToProps),ifContentLoaded)(Editor);

EditorContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default EditorContainer;

