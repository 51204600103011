/*
 * Copyright 2018 General Code
 */

import debounce from 'lodash/debounce';
import Button from "@material-ui/core/Button";
import * as PropTypes from 'prop-types';
import React from 'react';
import Paper from '@material-ui/core/Paper';

import FormControl from '../../common/components/FormControl';
import {FilterList as FilterListIcon} from "../../common/components/icons";
import InputAdornment from "../../common/components/InputAdornment";
import InputLabel from "../../common/components/InputLabel";
import {MenuItem} from "../../common/components/menu";
import Select from "../../common/components/Select";
import TextField from "../../common/components/TextField";
import Sticky from 'react-sticky-el';
import {Chip} from "@material-ui/core";

const debounceWait = 300;

class QuestionFilters extends React.Component {

  static propTypes = {
    filters: PropTypes.shape({
      needsReviewFilter: PropTypes.bool,
      assignedFilter: PropTypes.bool,
      assignedToFilter: PropTypes.string,
      deferrableFilter: PropTypes.bool,
      statusFilter: PropTypes.string,
      filterText: PropTypes.string,
    }),
    reviewers: PropTypes.object.isRequired,
    filterQuestions: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    questions: PropTypes.object.isRequired,
    displayedQuestionIds: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    const {filters: {filterText}} = this.props;
    this.state = {filterText: filterText};
    this.handleFilterTextUpdate = debounce(this.handleFilterTextUpdate, debounceWait);
  }

  handleFilterTextChange = (event) => {
    const value = event.target.value;
    this.setState({filterText: value});
    this.handleFilterTextUpdate(value);
  };

  handleFilterTextUpdate = (filterText) => {
    const {filters, filterQuestions} = this.props;
    filterQuestions({...filters, filterText});
  };

  handleNeedsReviewChange = (event) => {
    const {filters, filterQuestions} = this.props;
    const needsReviewFilter = (event.target.value === '' ? null : event.target.value === 'true');
    filterQuestions({...filters, needsReviewFilter});
  };

  handleAssignedChange = (event) => {
    const {filters, filterQuestions} = this.props;
    const assignedFilter = (event.target.value === '' ? null : event.target.value === 'true');
    filterQuestions({...filters, assignedFilter});
  };

  handleAssignedToChange = (event) => {
    const {filters, filterQuestions} = this.props;
    const assignedToFilter = (event.target.value === '' ? null : event.target.value);
    filterQuestions({...filters, assignedToFilter});
  };

  handleDeferrableChange = (event) => {
    const {filters, filterQuestions} = this.props;
    const deferrableFilter = (event.target.value === '' ? null : event.target.value === 'true');
    filterQuestions({...filters, deferrableFilter});
  };

  handleStatusChange = (event) => {
    const {filters, filterQuestions} = this.props;
    const statusFilter = (event.target.value === '' ? null : event.target.value);
    filterQuestions({...filters, statusFilter});
  };

  handleRefresh = () => {
    const {filters, filterQuestions} = this.props;
    filterQuestions({...filters});
  };

  handleClear = () => {
    const {clearFilters} = this.props;
    this.setState({filterText: ''});
    clearFilters();
    window.scrollTo(0, 0);
  };

  handleSticky = () => {
    var isStickyDiv = document.getElementsByClassName(" sticky");
    var isCurrentlySticky = true;
    if(isStickyDiv.length > 0){
      isCurrentlySticky = false;
      this.setState({isCurrentlySticky: false});
    } else {
      this.setState({isCurrentlySticky: true});
    }

    var statusContainer = document.getElementById("statusFilterSelect");
    if(statusContainer.style.display === "none" || !isCurrentlySticky) {
      statusContainer.style.display = "inline-flex";
    } else {
      statusContainer.style.display = "none";
    }

    var needsReviewContainer = document.getElementById("needsReviewFilterSelect");
    if(needsReviewContainer.style.display === "none" || !isCurrentlySticky) {
      needsReviewContainer.style.display = "inline-flex";
    } else {
      needsReviewContainer.style.display = "none";
    }

    var assignedContainer = document.getElementById("assignedFilterSelect");
    if(assignedContainer.style.display === "none" || !isCurrentlySticky) {
      assignedContainer.style.display = "inline-flex";
    } else {
      assignedContainer.style.display = "none";
    }

    var assignedToContainer = document.getElementById("assignedToFilterSelect");
    if(assignedToContainer.style.display === "none" || !isCurrentlySticky) {
      assignedToContainer.style.display = "inline-flex";
    } else {
      assignedToContainer.style.display = "none";
    }

    var deferrableContainer = document.getElementById("deferrableFilterSelect");
    if(deferrableContainer.style.display === "none" || !isCurrentlySticky) {
      deferrableContainer.style.display = "inline-flex";
    } else {
      deferrableContainer.style.display = "none";
    }

    var filterContainer = document.getElementsByClassName("filterInputContainer")[0];
    if(filterContainer.style.display === "none" || !isCurrentlySticky) {
      filterContainer.style.display = "inline-flex";
    } else {
      filterContainer.style.display = "none";
    }

    var chip = document.getElementById("questionFilterChips");
    if(isCurrentlySticky){
      chip.removeAttribute("hidden");
    } else {
      chip.setAttribute("hidden", true);
    }
  };

  componentDidMount() {
    window.addEventListener('keypress', this.handleFind);
    window.addEventListener('keydown', this.handleFind);
  };

  handleFind(e) {
    if((e.ctrlKey && e.keyCode === 70) || (e.ctrlKey && e.keyCode === 71)){
      e.preventDefault();
      window.scrollTo(0, 0);
      document.getElementsByClassName("filterInputContainer")[0].style.display = "inline-flex";
      document.getElementById("filterInput").focus();
    }
  };

  render() {
    const {
      filters: {needsReviewFilter, assignedFilter, assignedToFilter, deferrableFilter, statusFilter},
      reviewers, questions, displayedQuestionIds, bodyTop
    } = this.props;
    const {filterText} = this.state;
    return (
      <Sticky className="questionFiltersSticky" stickyStyle={{zIndex: 1, top: bodyTop + 42}} onFixedToggle={this.handleSticky}>
        <Paper elevation={4} className={"customerAccentColor_Border"} style={{padding: 5}}>
          <div id="questionFiltersContent">
            <div id="questionFilterChips" hidden>
              <Chip className="filterChip" id="statusChip" label={"Status: " + statusFilter} hidden={statusFilter === null}/>
              <Chip className="filterChip" id="reviewChip" label={"Needs Review: " + (needsReviewFilter? "Yes":"No")} hidden={needsReviewFilter === null}/>
              <Chip className="filterChip" id="assignedChip" label={"Is Assigned: " + (assignedFilter? "Yes":"No")} hidden={assignedFilter === null}/>
              <Chip className="filterChip" id="assignedToChip" label={"Assigned To: " + assignedToFilter} hidden={assignedToFilter === null}/>
              <Chip className="filterChip" id="deferrableChip" label={"Is Deferrable: " + (deferrableFilter? "Deferrable":"Non Deferrable")} hidden={deferrableFilter === null}/>
              <Chip className="filterChip" id="filterSearchChip" label={"Search For: " + filterText} hidden={filterText === null || filterText === ""}/>
            </div>
            <div id="questionFilters">
              <FormControl id="statusFilterSelect" className="formSelectControl">
                <InputLabel id="statusFilterSelectInput-label" htmlFor="statusFilterSelectInput">
                  Status
                </InputLabel>
                <Select
                  value={!statusFilter ? "" : statusFilter}
                  onChange={this.handleStatusChange}
                  inputProps={{
                    id: 'statusFilterSelectInput',
                    name: 'status'
                  }}
                >
                  <MenuItem value=""/>
                  <MenuItem value="UNANSWERED">Unanswered</MenuItem>
                  <MenuItem value="INCOMPLETE">Incomplete</MenuItem>
                  <MenuItem value="COMPLETE">Complete</MenuItem>
                </Select>
              </FormControl>
              <FormControl id="needsReviewFilterSelect" className="formSelectControl">
                <InputLabel id="needsReviewFilterSelectInput-label" htmlFor="needsReviewFilterSelectInput">
                  Needs Review
                </InputLabel>
                <Select
                  value={needsReviewFilter === null ? "" : needsReviewFilter}
                  onChange={this.handleNeedsReviewChange}
                  inputProps={{
                    id: 'needsReviewFilterSelectInput',
                    name: 'needsReview'
                  }}
                >
                  <MenuItem value=""/>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl id="assignedFilterSelect" className="formSelectControl">
                <InputLabel id="assignedFilterSelectInput-label" htmlFor="assignedFilterSelectInput">
                  Assigned
                </InputLabel>
                <Select
                  value={assignedFilter === null ? "" : assignedFilter}
                  onChange={this.handleAssignedChange}
                  inputProps={{
                    id: 'assignedFilterSelectInput',
                    name: 'assigned'
                  }}
                >
                  <MenuItem value=""/>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl id="assignedToFilterSelect" className="formSelectControl">
                <InputLabel id="assignedToFilterSelectInput-label" htmlFor="assignedToFilterSelectInput">
                  Assigned To
                </InputLabel>
                <Select
                  value={assignedToFilter === null ? "" : assignedToFilter}
                  onChange={this.handleAssignedToChange}
                  inputProps={{
                    id: 'assignedToFilterSelectInput',
                    name: 'assignedTo'
                  }}
                >
                  <MenuItem value=""/>
                  {Object.values(reviewers).map((reviewer) => (<MenuItem key={reviewer.username} value={reviewer.username}>{reviewer.displayName}</MenuItem>))}
                </Select>
              </FormControl>
              <FormControl id="deferrableFilterSelect" className="formSelectControl">
                <InputLabel id="deferrableFilterSelectInput-label" htmlFor="deferrableFilterSelectInput">
                  Deferrable
                </InputLabel>

                <Select
                  value={deferrableFilter === null ? "" : deferrableFilter}
                  onChange={this.handleDeferrableChange}
                  inputProps={{
                    id: 'deferrableFilterSelectInput',
                    name: 'deferrable'
                  }}
                >
                  <MenuItem value=""/>
                  <MenuItem value="true">Deferrable</MenuItem>
                  <MenuItem value="false">Non Deferrable</MenuItem>
                </Select>
              </FormControl>
              <div id="questionFiltersActions">
                <Button variant="contained" color="primary" className="refreshFiltersButton" onClick={this.handleRefresh}>Refresh</Button>
                <Button variant="contained" color="primary" className="clearFiltersButton" onClick={this.handleClear}>Clear</Button>
              </div>
              <TextField
                className="filterInputContainer"
                value={filterText === null ? "" : filterText}
                onChange={this.handleFilterTextChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><label htmlFor="filterInput"><FilterListIcon
                    title="Filter the questions listing"/></label></InputAdornment>,
                  id: "filterInput",
                  title: "Filter the questions listing",
                  'aria-label': "Filter the questions listing"
                }}
              />
            </div>
          </div>
          <div className="filterLabel">
            Displaying {(displayedQuestionIds === null ? Object.keys(questions).length : displayedQuestionIds.length)} of {Object.keys(questions).length} questions
          </div>
        </Paper>
      </Sticky>
    );
  }
}
export default QuestionFilters;