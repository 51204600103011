/*
 * Copyright 2018 General Code
 */
import compose from 'recompose/compose';
import {createAction} from 'redux-actions';
import {registerError} from "../../common/actions";
import {deleteJson, getJson, putJson} from '../../common/utils/fetch';
import {getUsername} from '../../common/selectors';
import {getColor, getContent, getGuid, getNote, getRealIdMapping, getTitle, getVisibility, getDisplayMode, getVersion} from "../selectors";
import {getReferenceGroup} from "../../common/ReferenceGroupFactory";

import {updateColor, updateContent, updateDisplayMode, updateTitle, updateVisibility} from "./content";
import {addReference} from "./reference";

const removeWhiteBackground = html => html.replace(/background-color:\s*rgb\(255,255,255\)/g, '');


//add appropriate defaults and construct payload
const initNote = createAction('NOTE/INIT',
  ({
    id,
    color = "default",
    content,
    createdBy = {name: "unknown", email: "unknown@invalid.com"},
    createdOn = new Date().toDateString().split(':')[0],
    editable = false,
    guid,
    html,
    title,
    updatedBy,
    updatedOn,
    version,
    visibility = "private",
    displayMode = "expanded"
  }) => ({
    id,
    color,
    content,
    createdBy,
    createdOn,
    editable,
    guid,
    html,
    title,
    updatedBy,
    updatedOn,
    version,
    visibility,
    displayMode,
  })
);

const fetchReferenceMatchesStart = createAction('NOTE/REF_MATCHES/FETCH/START', (groupName, query) => ({groupName, query}));
const fetchReferenceMatchesSuccess = createAction('NOTE/REF_MATCHES/FETCH/SUCCESS', (groupName, matches) => ({groupName, matches}));
const fetchReferenceMatchesFail = createAction('NOTE/REF_MATCHES/FETCH/FAIL', (groupName, query, error) => ({groupName, query, error}));
const fetchReferenceMatchesFinally = createAction('NOTE/REF_MATCHES/FETCH/FINALLY', (groupName, query) => ({groupName, query}));

const fetchReferenceMatches = (groupName, query) => async (dispatch) => {
  if (!query || query.length === 0) {
    dispatch(clearReferenceMatches(groupName));
    return;
  }

  console.debug("Fetching reference matches... ", groupName, query);
  dispatch(fetchReferenceMatchesStart(groupName, query));
  try {
    const refGroup = getReferenceGroup(groupName);
    const matches = await getJson(refGroup.getMatchesUrl(query));
    console.debug('got results ...', groupName, matches);
    dispatch(fetchReferenceMatchesSuccess(groupName, matches));
  } catch (error) {
    dispatch(registerError("Problem retrieving matching references", null, [groupName, query], error));
    dispatch(fetchReferenceMatchesFail(groupName, query, error));
  } finally {
    dispatch(fetchReferenceMatchesFinally(groupName, query));
  }
};

const fetchRootReferenceNodesStart = createAction('NOTE/REF_NODES/FETCH/START', (groupName) => ({groupName}));
const fetchRootReferenceNodesSuccess = createAction('NOTE/REF_NODES/FETCH/SUCCESS', (groupName, nodes) => ({groupName, nodes}));
const fetchRootReferenceNodesFail = createAction('NOTE/REF_NODES/FETCH/FAIL', (groupName, error) => ({groupName, error}));
const fetchRootReferenceNodesFinally = createAction('NOTE/REF_NODES/FETCH/FINALLY', (groupName) => ({groupName}));

const fetchRootReferenceNodes = (groupName) => async (dispatch) => {
  console.debug("Fetching root reference nodes... ", groupName);
  dispatch(fetchRootReferenceNodesStart(groupName));
  try {
    const refGroup = getReferenceGroup(groupName);
    const nodes = await getJson(refGroup.getRootNodesUrl());
    dispatch(fetchRootReferenceNodesSuccess(groupName, nodes.map((node, index) => ({
      ...node,
      name: refGroup.getNodeName(node),
      index: `${index}`,
      children: [],
    }))));
  } catch (error) {
    dispatch(registerError("Problem retrieving references tree", null, [groupName], error));
    dispatch(fetchRootReferenceNodesFail(groupName, error));
  } finally {
    dispatch(fetchRootReferenceNodesFinally(groupName));
  }
};

const toggleReferenceNodeSuccess = createAction('NOTE/REF_NODE/TOGGLE/SUCCESS', (groupName, node, toggled) => ({groupName, node, toggled}));
const fetchReferenceNodeChildrenStart = createAction('NOTE/REF_NODE_CHILDREN/FETCH/START', (groupName, node) => ({groupName, node}));
const fetchReferenceNodeChildrenSuccess = createAction('NOTE/REF_NODE_CHILDREN/FETCH/SUCCESS', (groupName, node, children) => ({groupName, node, children}));
const fetchReferenceNodeChildrenFail = createAction('NOTE/REF_NODE_CHILDREN/FETCH/FAIL', (groupName, node, error) => ({groupName, node, error}));
const fetchReferenceNodeChildrenFinally = createAction('NOTE/REF_NODE_CHILDREN/FETCH/FINALLY', (groupName, node) => ({groupName, node}));

const toggleReferenceNode = (groupName, node, toggled) => async (dispatch) => {
  console.debug("Toggling reference node... ", groupName, node, toggled);
  dispatch(toggleReferenceNodeSuccess(groupName, node, toggled));
  if (toggled && node.children && node.children.length === 0) {
    console.debug("Fetching reference node children... ", groupName, node);
    dispatch(fetchReferenceNodeChildrenStart(groupName, node));
    try {
      const refGroup = getReferenceGroup(groupName);
      const children = await getJson(refGroup.getNodeChildrenUrl(node));
      dispatch(fetchReferenceNodeChildrenSuccess(groupName, node, children.map((child, index) => ({
        ...child,
        name: refGroup.getNodeName(child),
        index: `${node.index}-${index}`,
        children: ['document', 'section'].indexOf(child.type) >= 0 ? null : [],
      }))));
    } catch (error) {
      dispatch(registerError("Problem expanding reference item", null, [groupName, node.title, toggled], error));
      dispatch(fetchReferenceNodeChildrenFail(groupName, node, error));
    } finally {
      dispatch(fetchReferenceNodeChildrenFinally(groupName, node));
    }
  }
};

const clearActiveReferenceNode = createAction('NOTE/ACTIVE_REF_NODE/CLEAR/SUCCESS', (groupName) => ({groupName}));

const clearReferenceMatches = createAction('NOTE/REF_MATCHES/CLEAR/SUCCESS', (groupName) => ({groupName}));
const clearReferenceNodes = createAction('NOTE/REF_NODES/CLEAR/SUCCESS', (groupName) => ({groupName}));
const clearReferences = (groupName) => async (dispatch) => {
  dispatch(clearReferenceMatches(groupName));
  dispatch(clearReferenceNodes(groupName));
};

const setNoteFilter = createAction('NOTE/FILTER/SET', (field, value) => ({field, value}));
const removeNoteFilter = createAction('NOTE/FILTER/REMOVE', (field) => ({field}));

const getHelp = () => { window.open("/help", '_BLANK'); };
//title can be a dom event
const toggle = createAction('NOTE/TOGGLE', id => ({id}));
const editNote = (id) => async (dispatch, getState) => {
  const note = getNote(getState(), {id});
  if(!note.content) {
    await dispatch(loadContent(id));
  }
  return dispatch(editNoteStart(id));
};

const editNoteStart = createAction('NOTE/EDIT/START', id => ({id}));
const editNoteCancel = createAction('NOTE/EDIT/CANCEL', id => ({id}));

const addNote = (guid, id) => async (dispatch, getState) => {
  const {EditorState} = await import('../../common/draftjs/dynamic');
  dispatch(initNote({
    id,
    content: EditorState.createEmpty(),
    title: "",
    guid,
    createdBy: {name: getUsername(getState()), email: ""},
    editable: true,
  }));
  return dispatch(editNote(id));
};

const loadContent = (id) => async (dispatch, getState) => {
  const oldNote = getNote(getState(), {id});
  //The note exists from the previous cached state
  if (oldNote && oldNote.content && oldNote.content.getCurrentContent().hasText()) {
    console.debug("Note already exists: ", id, oldNote);
    return;
  }
  const {ContentState, EditorState} = await import('../../common/draftjs/dynamic');
  const htmlToBlocks = (await import('../../common/draftjs/html-to-draftjs')).default;
  const content = oldNote.html;

  const editorFromBlocks = blocks => EditorState.createWithContent(ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap));

  return dispatch(updateContent(id, content ? compose(editorFromBlocks, htmlToBlocks, removeWhiteBackground)(content) : EditorState.createEmpty())) ;
}
;


const deleteNoteStart = createAction('NOTE/DELETE/START', (id) => ({id}));
const deleteNoteSuccess = createAction('NOTE/DELETE/SUCCESS', (id) => ({id}));
const deleteNoteFail = createAction('NOTE/DELETE/FAIL', (id, error) => ({id, error}));
const deleteNoteFinally = createAction('NOTE/DELETE/FINALLY', (id) => ({id}));

const deleteNote = id => async (dispatch,getState) => {
  const state = getState();
  const realId = id.startsWith('new-') ? getRealIdMapping(state).get(id) || 0 : id;
  const version = getVersion(state, id);
  dispatch(deleteNoteStart(realId));
  try {
    window.addEventListener("unload", () => {console.debug("Unload event because of delete to note: ", id);});
    const response = await deleteJson(`/notes/${realId}`);
    if(sessionStorage) {
      try {
        sessionStorage.setItem(`note-${response.id}`, JSON.stringify({id,version:version + 1, deleted: true}));
      } catch (e) {
        console.warn(`Failed to cache update for note ${realId}`, e);
      }
    }
    return dispatch(deleteNoteSuccess(id, response));
  } catch (e) {
    dispatch(registerError("Problem deleting note", null, [id], e));
    dispatch(deleteNoteFail(id, e));
  } finally {
    dispatch(deleteNoteFinally(realId));
  }
};

const saveNoteStart = createAction('NOTE/SAVE/START', (id) => ({id}));
const saveNoteSuccess = createAction('NOTE/SAVE/SUCCESS', (id, response) => ({id, real: response.id}));
const saveNoteFail = createAction('NOTE/SAVE/FAIL', (id, error) => ({id, error}));
const saveNoteFinally = createAction('NOTE/SAVE/FINALLY', (id) => ({id}));

const saveNote = id => async (dispatch, getState) => {
  const state = getState();

  const realId = id.startsWith('new-') ? getRealIdMapping(state).get(id) || 0 : id;

  const {convertToRaw} = await import('../../common/draftjs/dynamic');
  const draftToHtml = (await import('../../common/draftjs/draftjs-to-html')).default;
  const note = {
    id: realId,
    guid: getGuid(state, {id}),
    title: getTitle(state, {id}),
    content: compose(removeWhiteBackground, draftToHtml, convertToRaw)(getContent(state, {id}).getCurrentContent()),
    color: getColor(state, {id}),
    visibility: getVisibility(state, {id}),
    displayMode: getDisplayMode(state, {id}),
    startOpen: getDisplayMode(state, {id}) === 'expanded',
  };
  dispatch(saveNoteStart(id));
  try {
    window.addEventListener("unload", () => {console.debug("Unload event because of save to note: ", id);});
    const response = await putJson(`/notes/${realId}`, note);
    if(sessionStorage) {
      try {
        sessionStorage.setItem(`note-${response.id}`, JSON.stringify(response));
      } catch (e) {
        console.warn(`Failed to cache update for note ${realId}`, e);
      }
    }
    return dispatch(saveNoteSuccess(id, response));
  } catch (error) {
    dispatch(registerError("Problem saving note", null, [realId], error));
    dispatch(saveNoteFail(id, error));
  } finally {
    dispatch(saveNoteFinally(id));
  }
};

export {
  addNote,
  editNote,
  editNoteCancel,
  editNoteStart,
  saveNote,
  saveNoteStart,
  saveNoteSuccess,
  saveNoteFail,
  saveNoteFinally,
  initNote,
  loadContent,
  updateTitle,
  updateContent,
  updateColor,
  updateVisibility,
  updateDisplayMode,
  deleteNote,
  deleteNoteStart,
  deleteNoteSuccess,
  deleteNoteFail,
  deleteNoteFinally,
  setNoteFilter,
  removeNoteFilter,
  addReference,
  fetchReferenceMatches,
  fetchReferenceMatchesStart,
  fetchReferenceMatchesSuccess,
  fetchReferenceMatchesFail,
  fetchReferenceMatchesFinally,
  fetchRootReferenceNodes,
  fetchRootReferenceNodesStart,
  fetchRootReferenceNodesSuccess,
  fetchRootReferenceNodesFail,
  fetchRootReferenceNodesFinally,
  toggleReferenceNode,
  toggleReferenceNodeSuccess,
  fetchReferenceNodeChildrenStart,
  fetchReferenceNodeChildrenSuccess,
  fetchReferenceNodeChildrenFail,
  fetchReferenceNodeChildrenFinally,
  clearReferences,
  clearActiveReferenceNode,
  clearReferenceMatches,
  clearReferenceNodes,
  toggle,
  getHelp
};
