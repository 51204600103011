import React from 'react';

import { custId, archiveId } from '../../common/utils/server-data';
import {FilterList as FilterListIcon, Edit as EditIcon, Pdf as PdfIcon} from '../../common/components/icons';
import IconButton from '../../common/components/IconButton';
import Paper from '../../common/components/Paper';
import Tooltip from '../../common/components/Tooltip';
import InputAdornment from '../../common/components/InputAdornment';
import TextField from '../../common/components/TextField';
import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '../../common/components/table';
import {If} from '../../common/containers';
import EditLawDialogContainer from "../containers/EditLawDialogContainer";
import {pushState, replaceState} from "../../common/history";
import moment from "moment";

const TitleCellType = ({row, fieldName}) => (
  <div className="titleLawCell">
    <a className="law" href={`/${custId}/laws/${row.documentId}.pdf`} target="_blank" title={row[fieldName]} rel="noopener noreferrer">
      <span>{row[fieldName]}</span>&nbsp;<PdfIcon/>
    </a>
  </div>
);

const SimpleCellType = ({row, fieldName}) => (
  <div className={`${fieldName}LawCell`}>{row[fieldName]}&nbsp;</div>
);

const DispositionCellType = ({row, fieldName}) => (
  <div className="dispositionsLawCell">
    {row[fieldName].length === 0 ? "" : row[fieldName]
      .map((disposition, i) => <a key={i} className="xref dispositionXref" href={`${archiveId ? `/${archiveId}` : ''}/${disposition.guid}`}>{disposition.name}</a>)
      .reduce((prev, curr) => [prev, ', ', curr])}&nbsp;
  </div>
);

const SuppedByCellType = ({row, fieldName}) => {
  const suppedBy = row[fieldName];
  if (!suppedBy) return (<div className="suppedByLawCell"/>);
  const linkTitle = !suppedBy.publishingIdentifier ? moment(suppedBy.updatedDate).format("YYYY-MM-DD")
    : (suppedBy.publishingIdentifier + "- Last Updated " + moment(suppedBy.importTime).format("YYYY-MM-DD"));
  return (
    <div className="suppedByLawCell">
      <a href={"/past-laws/" + (suppedBy ? suppedBy.id : "")} className="codelink">{linkTitle}</a>
    </div>
  );
};

const EditCellType = ({row, startEditing}) => (
  <div className="editLawCell">
    <IconButton aria-label="Edit" onClick={() => startEditing(row.documentId)}>
      <EditIcon/>
    </IconButton>
  </div>
);

const COLUMN_INFO = {
  title: {
    header: "Title",
    component: TitleCellType,
    sortable: true,
    defaultSort: "asc"
  },
  adopted: {
    header: "Adopted",
    component: SimpleCellType,
    sortable: true,
    defaultSort: "desc"
  },
  subject: {
    header: "Subject",
    component: SimpleCellType,
    sortable: true,
    defaultSort: "asc"
  },
  dispositions: {
    header: "Affects",
    component: DispositionCellType,
    sortable: true,
    defaultSort: "asc"
  },
  suppedBy: {
    header: "Supped By",
    component: SuppedByCellType,
    sortable: true,
    defaultSort: "asc"
  },
  edit: {
    header: " ",
    width: "61px",
    component: EditCellType
  },
};

const stateToQueryString = state => {
  const {filter, sort} = state;
  const params = [];
  if (filter !== "") params.push(`filter=${filter}`);
  if (sort.length > 0) params.push("sort=" + sort.map(s => `${s.direction === "asc" ? "+" : "-"}${s.columnName}`).join(","));
  return params.length > 0 ? `?${params.join("&")}` : window.location.pathname;
};

const queryStringToState = queryString => {
  const urlParams = new URLSearchParams(queryString);
  const state = {};
  state.filter = urlParams.has("filter") ? urlParams.get("filter") : "";
  state.sort = urlParams.has("sort")
    ? urlParams.get("sort").split(",").map(s => ({direction: s[0] === "-" ? "desc" : "asc", columnName: s.substring(1)}))
    : [];
  return state;
};

const setHistory = (state, replace) => {
  if (replace) {
    replaceState({filter: state.filter, sort: state.sort}, "", stateToQueryString(state));
  } else {
    pushState({filter: state.filter, sort: state.sort}, "", stateToQueryString(state));
  }
};

const getSortProps = (columnName, sort) => {
  const matches = sort.filter(s => s.columnName === columnName);
  return matches.length > 0 ? {active: true, direction: matches[0].direction} : {active: false};
};

const getNewSort = (columnName, currentSort, append) => {
  const matches = currentSort.filter(s => s.columnName === columnName);
  if (append) {
    if (matches.length > 0) {
      return [...currentSort.map(s => {
        if (s.columnName !== columnName) return s;
        return {columnName, direction: s.direction === "asc" ? "desc" : "asc"};
      })];
    } else {
      return [...currentSort, {columnName, direction: COLUMN_INFO[columnName].defaultSort}];
    }
  } else {
    if (matches.length > 0) {
      return [{columnName, direction: matches[0].direction === "asc" ? "desc" : "asc"}];
    } else {
      return [{columnName, direction: COLUMN_INFO[columnName].defaultSort}];
    }
  }
};

const filterRows = filter => law => {
  const f = filter.toLowerCase();
  return f === ""
    || law.title.toLowerCase().indexOf(f) >= 0
    || law.adopted.toLowerCase().indexOf(f) >= 0
    || law.dispositions.filter(d => d.name.toLowerCase().indexOf(f) >= 0).length > 0
    || law.subject.toLowerCase().indexOf(f) >= 0;
};

const compareRows = sorting => (a, b) => {
  let order = 0;
  for (let i = 0; i < sorting.length && order === 0; i++) {
    const direction = sorting[i].direction === "asc" ? 1 : -1;
    let aVal = getCellValue(sorting[i].columnName, a);
    let bVal = getCellValue(sorting[i].columnName, b);
    if (bVal && !aVal) {
      order = direction;
    } else if (aVal && !bVal) {
      order = direction * -1;
    } else if (aVal > bVal) {
      order = direction;
    } else if (bVal > aVal) {
      order = direction * -1;
    }
  }
  return order;
};

const getCellValue = (columnName, rowData) => {
  let value = rowData[columnName];
  if (Array.isArray(value) && value.length > 0) {
    return formatCellValue(value[0]);
  } else {
    return formatCellValue(value);
  }
};

const formatCellValue = (value) => {
  if (value !== null && typeof value === 'object') value = (value.sortId ? value.sortId : value.name);
  if (typeof value === "string") value = value.toUpperCase();
  return value;
};

class LawTable extends React.PureComponent {

  initialState = {
    filter: "",
    sort: [],
    lastAction: "",
  };

  constructor(props) {
    super(props);
    const {columns, isFilterable, isSortable} = props;
    this.state = {
      ...this.initialState,
      ...queryStringToState(window.location.search),
      columns: columns.split(",").map(columnName => ({name: columnName, ...COLUMN_INFO[columnName]})),
    };

    if (isFilterable === "true" || isSortable === "true") {
      setHistory(this.state, true);
      window.addEventListener("popstate", this.historyStateChanged);
    }
  }

  historyStateChanged = event => {
    const prevFilter = this.state.filter;
    const newState = {...this.state, ...(event.state || this.initialState), lastAction: ""};
    if (prevFilter !== newState.filter) this.props.filterLaws(newState.filter);
    this.setState(newState);
  };

  filterChanged = event => {
    const filter = event.target.value;
    const newState = {...this.state, filter, lastAction: "filter"};
    setHistory(newState, this.state.lastAction === "filter");
    this.setState(newState);
  };

  sortingChanged = columnName => event => {
    const prevSort = this.state.sort;
    const newSort = getNewSort(columnName, this.state.sort, event.shiftKey);
    const newState = {...this.state, sort: newSort, lastAction: "sort"};
    setHistory(newState, this.state.lastAction === "sort" && prevSort.every(ns => newSort.find(os => os.columnName === ns.columnName)));
    this.setState(newState);
  };

  render() {
    const {filter, sort, columns} = this.state;
    const {startEditing, isFilterable, isSortable, tableId} = this.props;
    const isPastLaws = (tableId === "pastLawsTable");
    const laws = (isPastLaws ? this.props.pastLaws.map(pastLaw => ({...pastLaw}))
      : this.props.laws.map(law => ({...law})));
    if (sort.length > 0) laws.sort(compareRows(sort));
    return (
      <div id={tableId + "Container"} className="lawTableContainer">
        {isFilterable === "true" &&
        <div className="lawTableTopContainer">
          <TextField
            className="lawTableSearch"
            value={filter}
            onChange={this.filterChanged}
            InputProps={{
              startAdornment: <InputAdornment position="start"><label htmlFor={isPastLaws ? "pastLawsTableSearchField" : "lawTableSearchField"}><FilterListIcon title="Filter the Laws listing"/></label></InputAdornment>,
              id: (isPastLaws ? "pastLawsTableSearchField" : "lawTableSearchField"),
              title: "Filter the Laws listing",
              'aria-label': "Filter the Laws listing"
            }}
          />

        </div>
        }
        <Paper className="lawTablePaper responsiveTable">
          <Table id={tableId} className="lawTable reactTable">
            <colgroup>
              {columns.map((column, i) =>
                <col key={i} style={column.width ? {width: column.width} : {}}/>
              )}
            </colgroup>
            <TableHead>
              <TableRow>
                {columns.map((column, i) =>
                  <TableCell key={i}>
                    {column.sortable && isSortable === "true" ?
                      <Tooltip
                        title="Sort"
                        placement="bottom-start"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          {...getSortProps(column.name, sort)}
                          onClick={this.sortingChanged(column.name)}
                        >
                          {column.header}
                        </TableSortLabel>
                      </Tooltip>
                      : column.header
                    }
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {laws
                .filter(filterRows(filter))
                .map(law => [
                  <TableRow key={law.documentId}>
                    {columns.map((column, j) =>
                      <TableCell key={j} style={law.comment ? {borderBottomWidth: "0px"} : {}} data-column-name={column.header.trim() ? column.header : null}>
                        <column.component
                          row={law}
                          fieldName={column.name}
                          startEditing={startEditing}
                        />
                      </TableCell>
                    )}
                  </TableRow>,
                  <If test={law.comment} key={`${law.documentId}-detailRow`}>
                    <TableRow className="tableDetailRow">
                      <TableCell colSpan={columns.length}>
                        <div>{law.comment}</div>
                      </TableCell>
                    </TableRow>
                  </If>
                ])}
            </TableBody>
          </Table>
        </Paper>
        <EditLawDialogContainer/>
      </div>
    );
  }
}

export default LawTable;
