/*
 * Copyright 2018 General Code
 */
import { pushState, replaceState } from "../../common/history";
import { archiveId, custId, customer } from "../../common/utils/server-data";
import { List, Record, Map, OrderedMap } from 'immutable';
import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const QuickJump = Record({
  guid: "",
  indexNum: "",
  title: "",
  fullTitle: ""
});

const Suggestions = Record({
  quickJumps: List()
});

const ContentType = Record({
  id: "",
  displayName: "",
  count: 0
});

const ContentTypeData = Record({
  displayName: "",
  selected: true
});

const CheckboxListFacet = Record({
  type: "CheckboxList",
  name: "",
  value: "",
  options: List()
});

const CheckboxListFacetOption = Record({
  name: "",
  value: "",
  count: 0
});

const RelatedTerm = Record({
  term: "",
  count: 0,
  url: "",
});

const initialContentTypeData = Map({
  code: ContentTypeData({displayName: "Code"}),
  doc: ContentTypeData({displayName: "Public Documents"}),
  law: ContentTypeData({displayName: "New Laws"})
});

const Customer = Record({
  id: "",
  county: "",
  custId: "",
  govtype: "",
  govtypesub: "",
  municipality: "",
  name: "",
  population: 0,
  state: ""
});

const Result = Record({
  custId: "",
  type: "",
  number: "",
  title: "",
  url: "",
  printUrl: "",
  parents: OrderedMap(),
  otherInfo: [],
  snippet: "",
  pdf: false,
  hits: OrderedMap(),
  affectsLinks: OrderedMap()
});

const CustomerSearchOptions = Record({
  names: [],
  states: [],
  counties: [],
  govTypes: [],
  govSubTypes: [],
  minPop: 0,
  maxPop: 0,
  zipCodes: []
});

const State = Record({
  isSearchResultsPage: false,
  isSearchFieldActive: false,
  suggestions: Suggestions(),
  query: "",
  scope: "all",
  sortOrder: "relevance",
  totalResultCount: 0,
  currentPage: 0,
  maxPages: 1,
  results: List(),
  scopeTypes: List(),
  facets: List(),
  facetSelections: List(),
  contentTypeData: initialContentTypeData,
  isSearchLoading: false,
  isCodeFinderOpen: false,
  isCodeFinderLoading: false,
  customerSearchOptions: CustomerSearchOptions(),
  foundCustomers: List(),
  selectedCustIds: List(),
  selectedCustomerFilters: List(),
  selectedStructures: Map(),
  searchedCodeCount: 0,
  customers: Map(),
  relatedTerms: List(),
  isAdvancedSearchOpen: false
});
const initialState = State({});

const setSearchFieldActive = (state, {payload: {status}}) => state.set("isSearchFieldActive", status);
const setSuggestions = (state, {payload: {suggestions}}) => state
  .set("suggestions", Suggestions({quickJumps: suggestions.quickJumps.map(qj => QuickJump(qj))}));
const clearSuggestions = state => state.set("suggestions", Suggestions());

const setQuery = (state, {payload: {query}}) =>  state.set("query", query);
const setSortOrder = (state, {payload: {sortOrder}}) => state.set("sortOrder", sortOrder);
const clearScope= (state) => state.set("scope", "all").set("scopeTypes", List());
const setScope = (state, {payload: {scope}}) => state.set("scope", scope);

const initializeSearchState = (state, {payload: {loadedState}}) => State({
  ...loadedState,
  facets: loadedState.facets ? List(loadedState.facets.map(facet => CheckboxListFacet({
    ...facet,
    options: List(facet.options.map(option => CheckboxListFacetOption(option)))
  }))) : List()
});

const clearFacets = (state) => state.set("facets", List()).set("facetSelections", List());
const addFacetSelection = (state, {payload: {selection}}) => state.update("facetSelections", selections => List(selections).push(selection));
const removeFacetSelection = (state, {payload: {selection}}) => state.update("facetSelections", selections => List(selections).filter(s => s !== selection));

const setTaxonomyFacetNodeSelected = (state, {payload: {contentType, path, value, skipParentRefresh}}) => {
  let fullPath = path.reduce((list, name) => [...list, name, "children"], []).slice(0, -1);
  state = state.setIn(["contentTypeData", contentType, "facetData", "taxonomy", "taxonomy", ...fullPath, "selected"], value);
  // Set any child nodes to the same value as this node
  state = state.getIn(["contentTypeData", contentType, "facetData", "taxonomy", "taxonomy", ...fullPath, "children"]).keySeq().toArray()
    .reduce((oldState, childName) => setTaxonomyFacetNodeSelected(oldState, {
      payload: {
        contentType,
        path: [...path, childName],
        value,
        skipParentRefresh: true
      }
    }), state);
  // Refresh any parent nodes with an updated state
  if (!skipParentRefresh) {
    for (let i = 1; i < path.length; i++) {
      const parentPath = path.slice(0, i * -1);
      fullPath = parentPath.reduce((list, name) => [...list, name, "children"], []).slice(0, -1);
      state = state.setIn(["contentTypeData", contentType, "facetData", "taxonomy", "taxonomy", ...fullPath, "selected"], calculateTaxonomyNodeState(state, contentType, parentPath));
    }
    state = state.setIn(["contentTypeData", contentType, "selected"], calculateTaxonomyNodeState(state, contentType, []));
  }
  return state;
};

const calculateTaxonomyNodeState = (state, contentType, path) => {
  let allSelected = true;
  let allUnselected = true;
  const fullPath = path.reduce((list, name) => [...list, name, "children"], []);
  state.getIn(["contentTypeData", contentType, "facetData", "taxonomy", "taxonomy", ...fullPath]).valueSeq().forEach((child) => {
    if (child.selected !== true) allSelected = false;
    if (child.selected !== false) allUnselected = false;
  });
  return allSelected ? true :
    (allUnselected ? false : "indeterminate");
};

const clearContentTypeData = (state) => state.set("contentTypeData", initialContentTypeData);

const clearResultsData = (state) => state
  .set("isSearchLoading", false)
  .set("currentPage", 0)
  .set("maxPages", 1)
  .set("results", List())
  .set("relatedTerms", List());

const setSearchLoading = (loading) => (state) => state.set("isSearchLoading", loading);
const setResults = (state, {payload: {page, maxPages, totalResultCount, scopeTypes, facets, results, searchedCodeCount, customers, relatedTerms}}) => state
  .update("customers", oldCustomers => Map(oldCustomers).merge(Map(customers)))
  .update("results", oldResults => List(oldResults).concat(List(results.map(r => Result(r)))))
  .set("isSearchLoading", false)
  .set("currentPage", page)
  .set("maxPages", maxPages)
  .set("totalResultCount", totalResultCount)
  .set("scopeTypes", List(scopeTypes.map(ct => ContentType({...ct}))))
  .set("facets", List(facets.map(facet => CheckboxListFacet({
    ...facet,
    options: List(facet.options.map(option => CheckboxListFacetOption(option)))
  }))))
  .set("searchedCodeCount", searchedCodeCount)
  .set("relatedTerms", List(relatedTerms.map(jl => RelatedTerm(jl))));


const setCodeFinderLoading = (loading) => (state) => state.set("isCodeFinderLoading", loading);
const setCodeFinderOpen = (state, {payload: {isCodeFinderOpen}}) => state.set("isCodeFinderOpen", isCodeFinderOpen);
const setFoundCustomers = (state, {payload: customers}) => {
  return state.set("foundCustomers", List(customers.map(customer => Customer({
    ...customer,
    county: ((customer.counties && customer.counties.length > 0) ? customer.counties.join(" / ") : null)
  }))));
};
const clearFoundCustomers = (state) => state.set("foundCustomers", List());
const setSelectedCustomers = (state, {payload: {selectedCustIds, selectedCustomerFilters}}) => state.set("selectedCustIds", List(selectedCustIds)).set("selectedCustomerFilters", List(selectedCustomerFilters));
const resetSelectedCustomers = (state) => state.set("selectedCustomerFilters", List()).set("selectedCustIds", (custId ? List([custId]) : List()));
const setCustomerSearchOptions = (state, {payload: {names, states, counties, govTypes, govSubTypes, minPop, maxPop, zipCodes}}) => {
  let customerSearchOptions = {
    names: (names ? names : state.customerSearchOptions.names),
    states: (states ? states : state.customerSearchOptions.states),
    counties: (counties ? counties : state.customerSearchOptions.counties),
    govTypes: (govTypes ? govTypes : state.customerSearchOptions.govTypes),
    govSubTypes: (govSubTypes ? govSubTypes : state.customerSearchOptions.govSubTypes),
    minPop: (minPop !== null ? minPop : state.customerSearchOptions.minPop),
    maxPop: (maxPop !== null ? maxPop : state.customerSearchOptions.maxPop),
    zipCodes: (zipCodes ? zipCodes : state.customerSearchOptions.zipCodes)
  };
  return state.set("customerSearchOptions", customerSearchOptions);
};

const toggleSelectedStructure = (state, {payload: {guid, title}}) => {
  if (state.get("selectedStructures").has(guid)) {
    return state.update("selectedStructures", selectedStructures => selectedStructures.delete(guid));
  } else {
    return state.update("selectedStructures", selectedStructures => selectedStructures.set(guid, title));
  }
};
const resetSelectedStructures = (state) => state.set("selectedStructures", Map());
const resetSearchedCodeCount = (state) => state.set("searchedCodeCount", 0);

const updateWindowHistory = (state, {payload: {newState}}) => {
  newState = State(state).merge(newState);
  if (newState.isSearchResultsPage) {
    const query = newState.query;
    const scope = newState.scope;
    const sortOrder = newState.sortOrder;
    const selectedCustIds = newState.selectedCustIds;
    const selectedCustomerFilters = newState.selectedCustomerFilters;
    const facetSelections = newState.facetSelections;
    const selectedGuids = newState.selectedStructures.keys ? List([...newState.selectedStructures.keys()]) : List(Object.keys(newState.selectedStructures));
    const customerString = archiveId ? `${archiveId}/` : (custId ? `${custId}/` : "");
    const historyUrl = `/${customerString}search?query=${encodeURIComponent(query)}&scope=${encodeURIComponent(scope)}&sortOrder=${encodeURIComponent(sortOrder)}${facetSelections.size > 0 ? "&selections=" + encodeURIComponent(facetSelections.join(",")) : ""}${(!custId || selectedCustomerFilters.size > 0 || selectedCustIds.size > 1 || (selectedCustIds.size === 1 && custId !== selectedCustIds.get(0))) ? "&custIds=" + encodeURIComponent(selectedCustIds.join(",")) : ""}${selectedCustomerFilters.size > 0 ? "&customerFilters=" + encodeURIComponent("{filters:[" + selectedCustomerFilters.map(selectedCustomerFilter => selectedCustomerFilter.json).join(",") + "]}") : ""}${selectedGuids.size === 0 ? "" : "&guids=" + selectedGuids.map(guid => encodeURIComponent(guid)).join(",")}`;
    const historyName = `${(customer && customer.name) ? customer.name + " " : ""}${archiveId ? "Archive " : ""}Search: ${query}`;
    if (newState.currentPage === 0) {
      pushState(newState.toJS(), historyName, historyUrl);
    } else {
      replaceState(newState.toJS(), historyName, historyUrl);
    }
    if (window && window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: historyName,
        page_location: historyUrl,
        cust_id: custId
      });
    }
  }
  return newState;
};

const setAdvancedSearchOpen = (state, {payload: {isAdvancedSearchOpen}}) => state.set("isAdvancedSearchOpen", isAdvancedSearchOpen);

const reducer = handleActions({
  [actions.setSearchFieldActive]: setSearchFieldActive,
  [actions.getSuggestionsSuccess]: setSuggestions,
  [actions.clearSuggestions]: clearSuggestions,
  [actions.setQuery]: setQuery,
  [actions.setSortOrder]: setSortOrder,
  [actions.clearScope]: clearScope,
  [actions.setScope]: setScope,
  [actions.clearFacets]: clearFacets,
  [actions.addFacetSelection]: addFacetSelection,
  [actions.removeFacetSelection]: removeFacetSelection,
  [actions.setTaxonomyFacetNodeSelected]: setTaxonomyFacetNodeSelected,
  [actions.getResultsStart]: setSearchLoading(true),
  [actions.clearResults]: clearResultsData,
  [actions.getResultsSuccess]: setResults,
  [actions.getResultsFinally]: setSearchLoading(false),
  [actions.clearContentTypeData]: clearContentTypeData,
  [actions.initializeSearchState]: initializeSearchState,
  [actions.setCodeFinderOpen]: setCodeFinderOpen,
  [actions.findCustomersStart]: setCodeFinderLoading(true),
  [actions.findCustomersSuccess]: setFoundCustomers,
  [actions.findCustomersFinally]: setCodeFinderLoading(false),
  [actions.clearFoundCustomers]: clearFoundCustomers,
  [actions.setSelectedCustomers]: setSelectedCustomers,
  [actions.resetSelectedCustomers]: resetSelectedCustomers,
  [actions.findCustomerSearchOptionsSuccess]: setCustomerSearchOptions,
  [actions.toggleSelectedStructure]: toggleSelectedStructure,
  [actions.resetSelectedStructures]: resetSelectedStructures,
  [actions.resetSearchedCodeCount]: resetSearchedCodeCount,
  [actions.updateWindowHistory]: updateWindowHistory,
  [actions.setAdvancedSearchOpen]: setAdvancedSearchOpen
}, initialState);

export {State, Result, RelatedTerm, initialState, CheckboxListFacet, CheckboxListFacetOption};
export default reducer;
