/*
 * Copyright 2018 General Code
 */

import {Menu, MenuItem} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, {Component, Fragment} from 'react';
import * as PropTypes from 'prop-types';
import {forceVisible} from "react-lazyload";
import ConfirmDialog from "../../common/components/ConfirmDialog";
import IconButton from "../../common/components/IconButton";
import If from "../../common/containers/If";
import PageActionsContainer from "../../common/containers/PageActionsContainer";

/**
 * Call forceVisible multiple times to force all lazy loaded components to be visible
 *
 * Wrap each in a timeout so React has a chance to render previously lazy children and once we have done it 4 times actually print
 * @param recurse number of times this function has been called recursively already. defaults to zero.
 */
const printPage = (recurse = 0) => {
  if (recurse < 4) {
    forceVisible();
    setTimeout(printPage, 0, recurse + 1);
  } else {
    setTimeout(() => {
      window.print();
    });
  }
};

class PubDocsPageActions extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    pubDocTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pubDocTypeTitle: PropTypes.string,
    documentsLoading: PropTypes.bool.isRequired,
    hasPubDocTypeDeletePermission: PropTypes.bool.isRequired,
    hasPubDocTypeEditPermission: PropTypes.bool.isRequired,
    deletePubDocType: PropTypes.func.isRequired,
    setEditOpen: PropTypes.func.isRequired,
    setCategoryEditOpen: PropTypes.func.isRequired
  };

  state = {
    typeAnchorEl: null,
    openDeleteDialog: false
  };

  deletePubDocType = () => {
    const {custId, pubDocTypeId, deletePubDocType} = this.props;
    deletePubDocType(custId, pubDocTypeId);
    this.setState({typeAnchorEl: null});
    this.setState({openDeleteDialog: false});
  };

  openTypeMenu = (event) => {
    this.setState({typeAnchorEl: event.currentTarget});
  };

  closeTypeMenu = () => {
    this.setState({typeAnchorEl: null});
  };

  handleOpenDelete = () => {
    this.setState({openDeleteDialog: true});
    this.setState({typeAnchorEl: null});
  }

  handleCloseDelete = () => {
    this.setState({openDeleteDialog: false});
  }

  openEditTypeDialog = () => {
    this.setState({typeAnchorEl: null});
    const {pubDocTypeId} = this.props;
    this.props.setEditOpen(true, pubDocTypeId);
  };

  openEditCategoryDialog = () => {
    this.setState({typeAnchorEl: null});
    this.props.setCategoryEditOpen(true);
  }

  render() {
    const { pubDocTypeTitle, documentsLoading, hasPubDocTypeDeletePermission, hasPubDocTypeEditPermission } = this.props;
    const {typeAnchorEl, openDeleteDialog} = this.state;
    return (
      <Fragment>
        <PageActionsContainer pageId="pubDocs" printable={true} printOverride={printPage}/>
        <If test={!documentsLoading && (hasPubDocTypeEditPermission || hasPubDocTypeDeletePermission)}>
          <IconButton
            id="pub-doc-button"
            aria-label="Open the pub doc type menu"
            className="pubDocTypeButton svgButton"
            onClick={this.openTypeMenu}
            aria-haspopup="true"
          >
            <MoreVertIcon id="pubDocMenuOpen" title="Delete this public document type"/>
          </IconButton>
          <Menu
            disableAutoFocusItem
            anchorEl={typeAnchorEl}
            className="pubDocTypeMenu"
            open={typeAnchorEl !== null}
            onClose={this.closeTypeMenu}
            MenuListProps={{
              'aria-labelledby': 'pubDocMenuOpen',
            }}
          >
            <If test={hasPubDocTypeEditPermission}>
              <MenuItem onClick={this.openEditTypeDialog}>
                Edit Type
              </MenuItem>
            </If>
            <If test={hasPubDocTypeDeletePermission}>
              <MenuItem onClick={this.handleOpenDelete}>
                Delete Type
              </MenuItem>
            </If>
            <MenuItem onClick={this.openEditCategoryDialog}>
              Add Category
            </MenuItem>
          </Menu>
          <If test={hasPubDocTypeDeletePermission}>
            <ConfirmDialog
              title="Delete Type?"
              open={openDeleteDialog}
              onCancel={this.handleCloseDelete}
              onConfirm={this.deletePubDocType}
            >
              Are you sure you want to delete Type named: {pubDocTypeTitle}?
            </ConfirmDialog>
          </If>
        </If>
      </Fragment>
    );
  }
}
export default PubDocsPageActions;