import React from 'react';
import BoxedSection from '../../common/components/BoxedSection';
import Button from '../../common/components/Button';
import ButtonGroup from '../../common/components/ButtonGroup';
import Checkbox from '../../common/components/Checkbox';
import {Dialog, DialogContent, DialogTitle} from '../../common/components/dialog';
import FormControlLabel from '../../common/components/FormControlLabel';
import PrimaryButton from '../../common/components/PrimaryButton';
import './EnabledFeaturesSection.css';

class EnabledFeaturesSection extends React.PureComponent {

  constructor(props) {
    super(props);

    const featureGroupNotes = JSON.parse(props["featureGroupNotesJson"]);
    const featureIndexView = JSON.parse(props["featureIndexViewJson"]);
    this.state = {
      privateGroupNotes: (featureGroupNotes["currentLevel"] === "USER"),
      privateGroupNotesAllowed: (featureGroupNotes["maximumLevel"] === "USER"),
      pubdocsDateDisabled: (props.customization && props.customization.pubdocsDateDisabled),
      indexView: (!!featureIndexView["currentLevel"] && featureIndexView["currentLevel"] !== "NOBODY"),
      indexViewEnabled: (!!featureIndexView["maximumLevel"] && featureIndexView["maximumLevel"] !== "NOBODY"),
      translateEnabled: (props.customization && props.customization.translate),
      translateAcknowledgementShown: false,
      translateAcknowledged: false,
      translateAcknowledgementRequiredShown: false,
    };
  }

  handleGroupNotesCheckboxChange = (event) => {
    const enabled = event.target.checked;
    this.props.setEnableGroupNotes(enabled);
    this.setState({privateGroupNotes: enabled});
  };

  handlePubDocsDateDisabledClick = (event) => {
    const pubdocsDateDisabled = event.target.checked;
    this.props.setPubDocsDateDisabled(pubdocsDateDisabled);
    this.setState({pubdocsDateDisabled});
  }

  handleIndexViewCheckboxChange = (event) => {
    const enabled = event.target.checked;
    this.props.setEnableIndexView(enabled);
    this.setState({indexView: enabled});
  };

  handleTranslateCheckboxChange = (event) => {
    const enabled = event.target.checked;
    if (enabled) {
      this.setState({translateAcknowledgementShown: true, translateAcknowledged: false, translateAcknowledgementRequiredShown: false});
    } else {
      this.props.setEnableTranslate(false);
      this.setState({translateEnabled: false});
    }
  };

  handleTranslateAcknowledgementChange = (event) => {
    const acknowledged = event.target.checked;
    this.setState({translateAcknowledged: acknowledged});
  };

  handleTranslateCancel = () => {
    this.setState({translateAcknowledgementShown: false});
  };

  handleTranslateEnable = () => {
    const acknowledged = this.state.translateAcknowledged;
    if (acknowledged) {
      this.props.setEnableTranslate(true);
      this.setState({translateAcknowledgementShown: false, translateEnabled: true});
    } else {
      this.setState({translateAcknowledgementRequiredShown: true});
    }
  };

  render() {
    const { isTranslateAdmin, termsOfUseUrl } = this.props;
    const {
      privateGroupNotes, privateGroupNotesAllowed, indexView, indexViewEnabled, translateEnabled,
      translateAcknowledgementShown, translateAcknowledged, translateAcknowledgementRequiredShown, pubdocsDateDisabled
    } = this.state;
    return (
      <BoxedSection
        title="Enabled Features"
        instructions="Enable or disable features within your Code:"
        help={{topic: 'administration', subtopic: 'managefeatures'}}
      >
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="adminSettingsPage-groupNotesEnabled"
                checked={privateGroupNotes}
                onChange={this.handleGroupNotesCheckboxChange}
                style={{padding: '4px 12px'}}
              />
            }
            label="Private Group Notes"
            disabled={!privateGroupNotesAllowed}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="adminSettingsPage-indexViewEnabled"
                checked={indexView}
                onChange={this.handleIndexViewCheckboxChange}
                style={{padding: '4px 12px'}}
              />
            }
            label="Index Page"
            disabled={!indexViewEnabled}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="adminSettingsPage-translateEnabled"
                checked={translateEnabled}
                onChange={this.handleTranslateCheckboxChange}
                style={{padding: '4px 12px'}}
              />
            }
            label="Translate"
            disabled={!isTranslateAdmin}
          />
          <Dialog
            id="translateAcknowledgementDialog"
            open={translateAcknowledgementShown}
            onClose={this.handleTranslateCancel}
            maxWidth={'md'}
            fullWidth={true}
            disableBackdropClick={true}
          >
            <DialogTitle>
              <span>Translate</span>
            </DialogTitle>
            <DialogContent>
              <div>
                As described in the <a href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">Terms and Conditions</a>, General Code is providing “Google Translate”, a free
                service provided by Google, as a convenience to assist eCode360 users in reading E-Codes in languages other than English.
              </div>
              <div id="translateAcknowledgement">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="adminSettingsPage-translateAcknowledgement"
                      checked={translateAcknowledged}
                      onChange={this.handleTranslateAcknowledgementChange}
                      style={{padding: '4px 12px'}}
                    />
                  }
                  label={<span>I have read and understood the <a href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">Terms and Conditions</a> related to the use of Google Translate.</span>}
                />
              </div>
              {translateAcknowledgementRequiredShown &&
                <div id="translateAcknowledgementRequired">
                  <span>You must check the box acknowledging that you have read the <a href="/docs/TOS.html" target="_blank">Terms and Conditions</a> before enabling this feature.</span>
                </div>
              }
              <div id="translateAcknowledgementActions">
                <ButtonGroup>
                  <Button onClick={this.handleTranslateCancel}>Cancel</Button>
                  <PrimaryButton onClick={this.handleTranslateEnable}>Enable</PrimaryButton>
                </ButtonGroup>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div>
          <FormControlLabel
            className="gcAdminSettingsList-CheckboxLabel"
            control={
              <Checkbox
                id="adminSettingsPage-pubdocsCreatedByDate"
                checked={pubdocsDateDisabled}
                onChange={this.handlePubDocsDateDisabledClick}
              />
            }
            label="Hide Created/Updated by Dates for PubDocs"
          />
        </div>
      </BoxedSection>
    );
  }
}

export default EnabledFeaturesSection;
