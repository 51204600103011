import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiIconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';


const styles = theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
    height: 30,
    width: 30,
    padding: 0,
  },
});

const IconButton = ({className, classes, ...props}) =>
  <MuiIconButton {...props} className={classNames([className, classes.icon])}/>;

export default withStyles(styles)(IconButton);

