import classNames from 'classnames';

import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import scrollToComponent from 'react-scroll-to-component';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import {buildLoadableSingle} from "../../common/components/buildLoadable";
import {putFile} from "../../common/utils/fetch";
import AddReferenceButtonContainer from '../containers/AddReferenceButtonContainer';
import {ReferenceComponent, referenceStrategy} from '../decorators/reference';
import './Editor.css';


const DraftEditor = buildLoadableSingle(import('../../common/editor/Editor'), "DraftEditor");

const useDefaultPaste = function () {};


class Editor extends Component {
  static propTypes = {
    content: PropTypes.shape({getCurrentContent: PropTypes.func.isRequired}).isRequired,
    editing: PropTypes.bool.isRequired,
    updateContent: PropTypes.func.isRequired,
  };
  static defaultProps = {
    editing: false,
  };

  scrollTarget = null;

  state = {
    suggestions: []
  };

  uploadCallBack = (file) => putFile(`/user/file`, file, {
    cache: 'no-cache',
    headers: {
      "File-name": file.name,
    },
  }).catch(error => {
    throw new Error(`Failed to upload image[${file.name}] - ${error}`);
  }).then(payload =>
    ({data: {link: payload.url}})
  ).catch(e => {
    console.error(`Failed to put note: ${e}`);
    throw e;
  });

  setRef = (ref) => {
    this.editor = ref;
  };

  scrollIntoView = () => {
    if (this.scrollTarget) {
      scrollToComponent(this.scrollTarget, {
        offset: -176,
        align: 'top',
        duration: 300,
      });
    } else {
      //If we don't have a ref keep trying until we do
      setTimeout(this.scrollIntoView, 50);
    }
  };
  setScrollTarget = (ref) => {
    this.scrollTarget = ref;
    if (this.props.editing) {
      this.scrollIntoView();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editing && !prevProps.editing) {
      this.scrollIntoView();
    }
  }

  componentDidMount() {
    if (this.props.editing) {
      this.scrollIntoView();
    }
  }

  render() {
    const {content, updateContent, editing} = this.props;
    return (
      <div className="noteEditorWrapper" ref={this.setScrollTarget}>
        <DraftEditor
          toolbarHidden={!editing}
          readOnly={!editing}
          editorState={content}
          suggestions={this.state.suggestions}
          onEditorStateChange={updateContent}
          spellCheck={true}
          editorClassName={classNames({
            "noteEditorEditing": editing,
            "noteEditorInactive": !editing,
            "noteEditor": true,
          })}
          editorRef={this.setRef}
          handlePastedText={useDefaultPaste}
          customDecorators={[
            {
              strategy: referenceStrategy,
              component: ReferenceComponent
            },
          ]}
          toolbarCustomButtons={[
            <AddReferenceButtonContainer ReferenceComponent={AddReferenceButtonContainer} id={this.props.id}/>,
          ]}
          toolbar={{
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
            },
            emoji: {
              emojis: ['🇺🇸', '😀', '😎', '☹', '👶', '👦', '👧', '👨', '👩', '👮', '👪', '👈', '👉', '✋', '👍', '👎', '✍', '🤝', '☂', '❤', '🛑', '📢', '🚮', '♿', '⚠', '🚫', '♻', '✔', '❌', '❓', '🐕', '🐈', '🐦', '🐟', '🐛', '🌲', '🌳', '🌎', '☀', '🌧', '❄', '🔥', '⚡', '🎟', '🥇', '⚽', '🎭', '🍔', '🎂', '🏕', '🏗', '🏘', '🚌', '🚑', '🚒', '🚓', '🚗', '🚲', '🚨', '🚦', '🚧', '✈', '⏰', '🌡', '🎈', '🎁', '☎', '🖨', '🔋', '📺', '💡', '📖', '📰', '💳', '✉', '📤', '📥', '📪', '🗳', '📝', '📁', '🔒', '🔑', '☠']
            },
            image: {
              uploadCallback: this.uploadCallBack,
              previewImage: true,
              alignmentEnabled: false,
              alt: {
                present: true,
              },
            },
            attachment: {
              uploadCallback: this.uploadCallBack,
            },
            options: [
              'fontFamily',
              'fontSize',
              'inline',
              'colorPicker',
              'textAlign',
              'list',
              'attachment',
              'link',
              'emoji',
              'image',
              'remove',
              'history'
            ],
          }}
        />
      </div>
    );
  }
}

const isClosed = ({open, editing}) => !(open || editing);

export default branch(isClosed, renderNothing)(Editor);
