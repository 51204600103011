import React from "react";
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import If from "../../common/containers/If";
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './RangeSlider.css';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const {value, dragging, ...otherProps} = props;
  return (
    <Tooltip
      prefixCls='rc-slider-tooltip'
      overlay={value}
      visible={dragging}
    >
      <Handle value={value} {...otherProps} />
    </Tooltip>
  );
};

class RangeSlider extends React.PureComponent {
  render() {
    const {id, min, max, value, disabled, ...props} = this.props;
    let labelText = null;
    if (value) {
      if (value.length === 1) {
        if (value[0]) {
          labelText = "Displaying " + value[0];
        } else {
          value[0] = min;
        }
      } else if (value.length > 1) {
        if (value[0] || value[value.length - 1]) {
          value[0] = value[0] ? value[0] : min;
          value[value.length - 1] = value[value.length - 1] ? value[value.length - 1] : max;
          labelText = "Displaying " + value[0] + " to " + value[value.length - 1];
        } else {
          value[0] = value[0] ? value[0] : min;
          value[value.length - 1] = value[value.length - 1] ? value[value.length - 1] : max;
        }
      }
    }
    return (
      <div id={id}>
        <Range min={min} max={max} value={value} handle={handle} disabled={disabled || (min === max)}{...props}/>
        <If test={labelText}>
          <div className="rangeValueLabel">{labelText}</div>
        </If>
      </div>
    );
  }
}
export default RangeSlider;
