import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {getPubDocsFileTypes} from "../../common/selectors";
import {toJS} from "../../common/utils";
import {bulkAddPubDocs, clearBulkUploadedDocuments} from "../actions";
import PubDocBulkUploaderDialog from "../components/PubDocBulkUploaderDialog";
import {getAllCategories, getPubDocs, getPubDocTypeId, getPubDocTypes, getBulkUploaderLoading,
  getBulkUploadedDocuments} from "../selectors";

const mapStateToProps = createStructuredSelector({
  types: getPubDocTypes,
  typeId: getPubDocTypeId,
  categories: getAllCategories,
  documents: getPubDocs,
  loading: getBulkUploaderLoading,
  uploadedDocuments: getBulkUploadedDocuments,
  pubDocsFileTypes: getPubDocsFileTypes
});

const mapDispatchToProps = (dispatch) => ({
  bulkAddPubDocs: (pubDocs) => {
    dispatch(bulkAddPubDocs(pubDocs));
  },
  clearBulkUploadedDocuments: () => {
    dispatch(clearBulkUploadedDocuments());
  }
});

const PubDocBulkUploaderDialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocBulkUploaderDialog);
PubDocBulkUploaderDialogContainer.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired
};
export default PubDocBulkUploaderDialogContainer;