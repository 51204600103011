/**
 * Notes editing component
 * @module ecode/notes
 *
 * Copyright 2018 General Code
 */
import 'nodelist-foreach-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {notes} from '../common/components/domNodes';
import * as actions from './actions';
import {initNote} from './actions';
import './components/Note.css';
import {ModuleKey} from './ModuleKey';
import reducer, {initialState} from './reducers';
import * as selectors from './selectors';
import './style/base.css';
import './style/scheme.css';

import NoteFilterContainer from "./containers/NoteFilterContainer";
import NoteContainer from "./containers/NoteContainer";
import NotePageControlsContainer from "./containers/NotePageControlsContainer";
import {replaceNodeWithReactComponent} from "../common/utils/ReactHelper";

let tmpNoteId = 0;

// const notes = [];
const renderNote = (note, Providers) => {
  const id = note.dataset.noteId;
  note.parentElement.classList.remove('noteContainer');
  ReactDOM.render(
    <Providers key={`note-container-providers-${id}`} name={`note-container-${id}`}>
      <NoteContainer id={id}/>
    </Providers>,
    note.parentElement
  );
};

const loadNote = (note, state) => {
  const id = note.dataset.noteId;
  const oldNote = state.getIn(["notes",id]);
  //The note exists from the previous cached state
  if (oldNote) {
    console.debug("Note already exists: ", id, oldNote);
    return state;
  }
  let dataset = note.dataset;
  const version = note.dataset.version;
  const noteTitle = note.querySelectorAll(".noteTitle")[0];
  const noteContent = note.querySelectorAll(".noteContent")[0];
  if (!noteTitle || !noteContent) {
    return state;
  }

  let newNote = {
    id,
    color: dataset.color,
    editable: dataset.editable === 'true',
    visibility: dataset.visibility,
    displayMode: (dataset.startOpen === 'true' || dataset.startOpen === true) ? "expanded" : "minimized",
    createdBy: {name: dataset.createdByName, email: dataset.createdByEmail},
    createdOn: dataset.createdOn,
    updatedBy: {name: dataset.updatedByName, email: dataset.updatedByEmail},
    updatedOn: dataset.updatedOn,
    guid: dataset.guid,
    title: noteTitle.innerHTML,
    html: noteContent.innerHTML,
    version,
  };

  const lastNoteString = sessionStorage.getItem(`note-${id}`);
  //If we are trying to load a note we have a newer version of in storage use that instead
  if(lastNoteString) {
    try {
      const  lastNote = JSON.parse(lastNoteString);
      const lastKnownVersion = lastNote.version;
      if (lastKnownVersion && lastKnownVersion > version) {
        console.debug("Loading note from session store", lastNote);

        //If we have a newer version that was deleted remember it
        if(lastNote.deleted) {
          return;
        }

        //merge the cached data into what we have from the html
        newNote = {
          ...newNote,
          ...lastNote,
          displayMode: lastNote.startOpen ? "expanded" : "minimized",
          html: lastNote.content,
          createdBy: {name: lastNote.createdByName, email: lastNote.createdByEmail},
          updatedBy: {name: lastNote.updatedByName, email: lastNote.updatedByEmail},
        };
      } else {
        //out of date
        sessionStorage.removeItem(`note-${id}`);
      }
    } catch (e) {
      console.warn(`Failed to deserialize ${lastNoteString}`, e);
      //invalid
      sessionStorage.removeItem(`note-${id}`);
    }
  }

  return reducer(state, initNote(newNote));
};


export const getInitialState = (loadingState) => {
  if (loadingState == null) {
    throw new Error("Trying to load a null state");
  }
  let notesState = loadingState.get(ModuleKey) || initialState;
  return loadingState.set(ModuleKey, notesState.withMutations(loadingNotesState => {
    let state = loadingNotesState;
    for (let note of Array.from(notes)) {
      state = loadNote(note, state);
    }
    return state;
  }));

};
/**
 * Given a guid and a container object this makes an editor for it
 * @param {Component} Providers - Provider wrapper to use on created elements
 * @param {function} dispatch - Dispatch function for redux
 */
const init = (Providers, dispatch) => {
  //initialize controls on page
  for (let note of Array.from(notes)) {
    renderNote(note, Providers);
  }

  //Export external interaction functions
  window.generalcode = window.generalcode || {};
  window.generalcode.addNote = async (element, guid) => {
    const id = `new-${tmpNoteId++}`;
    element.id = id;
    element.className = '';
    dispatch(actions.addNote(guid, id));
    ReactDOM.render(
      <Providers key={`note-container-providers-${id}`} name={`note-container-${id}`}>
        <NoteContainer id={id}/>
      </Providers>,
      element
    );
  };

  const noteFilters = document.querySelectorAll(".noteFilter");
  let i = 0;
  for (const noteFilter of Array.from(noteFilters)) {
    ReactDOM.render(
      <Providers key={`note-filter-providers-${i++}`} name={`note-filter-container-${i}`}>
        <NoteFilterContainer  {...(noteFilter.dataset)} />
      </Providers>,
      noteFilter
    );
  }

  const pageControlsElement = document.getElementById("notes-page-control");
  if(pageControlsElement) {
    replaceNodeWithReactComponent(pageControlsElement, <Providers key={'notes-page-control'} name={'notes-page-control'}><NotePageControlsContainer /></Providers>);
  }
};

/**
 * Name of the module for use in reducers and selectors
 * @type {string}
 */
export {ModuleKey} from './ModuleKey';

export {
  init,
  actions,
  reducer,
  selectors,
};
