import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";

import AdvancedSearchDialog from "../components/AdvancedSearchDialog";
import {getGroups} from "../../common/selectors";
import {isAdvancedSearchOpen, getQuery} from "../selectors";
import {setAdvancedSearchOpen, setQuery, submitNewSearch} from "../actions";
import {getPubDocTypes} from "../../pub-docs/selectors";

const mapStateToProps = createStructuredSelector({
  isAdvancedSearchOpen: isAdvancedSearchOpen,
  query: getQuery,
  groups: getGroups,
  pubDocTypes: getPubDocTypes
});

const mapDispatchToProps = (dispatch) => ({
  setAdvancedSearchOpen: (isAdvancedSearchOpen) => {
    dispatch(setAdvancedSearchOpen(isAdvancedSearchOpen));
  },
  setQuery: (query) => {
    dispatch(setQuery(query));
  },
  submitSearch: () => {
    dispatch(submitNewSearch(false, true));
  }
});
export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(AdvancedSearchDialog);
