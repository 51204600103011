import React, { Fragment } from "react";
import ConfirmDialog from "../../common/components/ConfirmDialog";
import PageActionsContainer from "../../common/containers/PageActionsContainer";
import {custId, searchResultsPerPage, maxSearchPrintPages} from "../../common/utils/server-data";

class SearchResultsPageActions extends React.Component {

  state = {
    printWarningDialogOpen: false
  };

  handlePrint = () => {
    const {results, maxPages, getAllPages, setPrintLoading} = this.props;
    let currentPages = Math.ceil(results.length / searchResultsPerPage);
    if (currentPages === maxPages) {
      window.print();
    } else if (maxPages > maxSearchPrintPages) {
      this.setState({printWarningDialogOpen: true});
    } else {
      setPrintLoading(true);
      getAllPages();
      this.waitToPrint();
    }
  };

  waitToPrint = () => {
    const {results, maxPages, setPrintLoading} = this.props;
    let currentPages = Math.ceil(results.length / searchResultsPerPage);
    if (currentPages < maxPages) {
      setTimeout(this.waitToPrint, 500);
    } else {
      setPrintLoading(false);
      window.print();
    }
  };

  handlePrintDialogClose = () => {
    this.setState({printWarningDialogOpen: false});
  };

  render() {
    const { printWarningDialogOpen } = this.state;
    return (
      <Fragment>
        <PageActionsContainer pageId="search"
          printable={true}
          printOverride={this.handlePrint}
          emailSubject="eCode360 Search Results"
        />
        <ConfirmDialog
          title="Print Error"
          open={printWarningDialogOpen}
          onConfirm={this.handlePrintDialogClose}
        >
          <div>
            You are trying to print too many search results. Please refine your query to a max of {searchResultsPerPage * maxSearchPrintPages} results and print again.
            You can find more information about refining your search query on the <a href={"/help/" + (custId ? custId + "/" : "") + "search"}>search help page</a>.
          </div>
        </ConfirmDialog>
      </Fragment>
    );
  }
}
export default SearchResultsPageActions;
