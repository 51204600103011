import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import { deletePubDocType, setPubDocTypeEditOpen, setPubDocCategoryEditOpen } from "../actions";
import { getPubDocsLoading, getPubDocTypeId, getPubDocTypeTitle } from "../selectors";
import PubDocsPageActions from "../components/PubDocsPageActions";
import { getCustId, hasPubDocTypeDeletePermission, hasPubDocTypeEditPermission } from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  pubDocTypeId: getPubDocTypeId,
  pubDocTypeTitle: getPubDocTypeTitle,
  documentsLoading: getPubDocsLoading,
  hasPubDocTypeDeletePermission: hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission: hasPubDocTypeEditPermission,
});

const mapDispatchToProps = (dispatch) => ({
  deletePubDocType: (custId, pubDocTypeId) => {
    dispatch(deletePubDocType(custId, pubDocTypeId));
  },
  setEditOpen: (open, typeEditId) => {
    dispatch(setPubDocTypeEditOpen(open, typeEditId));
  },
  setCategoryEditOpen: (categoryEditOpen, categoryEditId) => {
    dispatch(setPubDocCategoryEditOpen(categoryEditOpen, categoryEditId));
  }
});

const PubDocsPageActionsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocsPageActions);
export default PubDocsPageActionsContainer;