import {getCustomization} from "../common/selectors";
import {initSettings} from "./actions";
import reducer, {initialState} from './reducers';
import {ModuleKey} from './ModuleKey';
import SettingsPageContainer from "./containers/SettingsPageContainer";

export {ModuleKey} from './ModuleKey';

export const getInitialState = (loadingState) => {
  if (loadingState == null) {
    throw new Error("Trying to load a null state");
  }
  let moduleState = loadingState.get(ModuleKey) || initialState;
  const customization = getCustomization(loadingState);
  const mapUrl = customization ? customization.mapUrl : null;
  const nameOverride = customization ? customization.customerName : null;
  const adminSettingsContainer = document.getElementById("adminSettingsRoot");
  let isDisplayDateAdmin = false;
  let displayDate = "";
  let zoningStructure = null;
  if (adminSettingsContainer != null) {
    isDisplayDateAdmin = adminSettingsContainer.dataset.isDisplayDateAdmin === "true";
    displayDate = adminSettingsContainer.dataset.displayDate;
    zoningStructure = JSON.parse(adminSettingsContainer.dataset.zoningStructure);
  }
  return loadingState.set(ModuleKey, reducer(moduleState,
    initSettings({mapUrl, isDisplayDateAdmin, displayDate, zoningStructure, nameOverride})));
};

export {
  reducer,
};

export const RootComponent = SettingsPageContainer;
