import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {addFacetSelectionAndGetResults, removeFacetSelectionAndGetResults} from "../actions";

import {getFacets, getFacetSelections} from "../selectors";
import {toJS} from "../../common/utils";
import FacetList from "../components/FacetList";

const mapStateToProps = createStructuredSelector({
  facets: getFacets,
  facetSelections: getFacetSelections,
});

const mapDispatchToProps = (dispatch) => ({
  addFacetSelection: (selection) => {
    dispatch(addFacetSelectionAndGetResults(selection));
  },
  removeFacetSelection: (selection) => {
    dispatch(removeFacetSelectionAndGetResults(selection));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(FacetList);

