import React from 'react';
import {Play} from './icons';
import IconButton from './IconButton';

const VideoButton = ({url, ...props}) => (
  <IconButton
    {...props}
    aria-label="Play Video"
    href={url}
    rel="video"
    target="_blank"
  >
    <Play/>
  </IconButton>
);

export default VideoButton;

