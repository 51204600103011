/*
 * Copyright 2018 General Code
 */

import * as PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

import Button from '../../common/components/Button';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '../../common/components/dialog';
import HelpButton from '../../common/components/HelpButton';
import IconButton from '../../common/components/IconButton';
import {Delete as DeleteIcon, Edit as EditIcon} from '../../common/components/icons';
import PrimaryButton from '../../common/components/PrimaryButton';
import {If} from '../../common/containers';

import './MainControls.css';

class MainControls extends PureComponent {

  state = {
    isDeleteConfirmOpen: false,
  };

  onDeleteConfirmOpen = () => {
    this.setState({isDeleteConfirmOpen: true});
  };

  onDeleteConfirm = () => {
    this.props.deleteNote();
    this.onDeleteConfirmClose();
  };

  onDeleteConfirmClose = () => {
    this.setState({isDeleteConfirmOpen: false});
  };

  render() {
    const {editNote, editable, editing, isUnsaved} = this.props;
    const {isDeleteConfirmOpen} = this.state;

    return (
      <div className="noteMainControls">
        <If test={editable}>
          <IconButton aria-label="Delete" disabled={isUnsaved} onClick={this.onDeleteConfirmOpen} className="noteMainControls-delete">
            <DeleteIcon/>
          </IconButton>
          <IconButton aria-label="Edit" disabled={editing} onClick={editNote} className="noteMainControls-edit">
            <EditIcon/>
          </IconButton>
          <Dialog
            open={isDeleteConfirmOpen}
            onClose={this.onDeleteConfirmClose}
            disableBackdropClick={true}
          >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText>This will permanently delete this note.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onDeleteConfirmClose}>Cancel</Button>
              <PrimaryButton onClick={this.onDeleteConfirm}>Delete</PrimaryButton>
            </DialogActions>
          </Dialog>
        </If>
        <HelpButton topic="users#addnote"/>
      </div>
    );
  }
}

MainControls.propTypes = {
  editNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
};

export default MainControls;
