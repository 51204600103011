import {ModuleKey} from "../ModuleKey";

const getReferenceMatches = (state) => state[ModuleKey].referenceMatches;
const getReferenceNodes = (state) => state[ModuleKey].referenceNodes;
const getAreMatchesLoading = (state) => state[ModuleKey].areReferenceMatchesLoading;

export {
  getReferenceMatches,
  getReferenceNodes,
  getAreMatchesLoading
};
