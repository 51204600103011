import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from 'reselect';
import {setPubDocTypeEditOpen} from "../../pub-docs/actions";
import {getPubDocsSidebarName, getPubDocTypeId, getPubDocTypes} from "../../pub-docs/selectors";
import {getDisplayedQuestionIds} from "../../questions/selectors";
import {
  getCustId, getAnalysisId, getArchiveId, getCodeId, getPageType, getPageSubType, getPubDocsOnly, getCodeLabel,
  getCharterGuid, getMapUrl, getZoningGuid, getNoteCount, getLawCount, getPastLawCount, hasViewPermission,
  hasIndexViewPermission, hasArchivesViewPermission, hasLawViewPermission, hasDocumentAdminPermission,
  hasPubDocTypeAddPermission, hasPubDocTypeDeletePermission, hasPubDocTypeEditPermission, hasPubDocPrivateViewPermission,
  hasDashboardPermission, getSearchResultsUrl
} from "../selectors";
import {toJS} from "../utils";
import NavigationRail from "../components/NavigationRail";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  archiveId: getArchiveId,
  codeId: getCodeId,
  pageType: getPageType,
  pageSubType: getPageSubType,
  pubDocsOnly: getPubDocsOnly,
  codeLabel: getCodeLabel,
  charterGuid: getCharterGuid,
  mapUrl: getMapUrl,
  zoningGuid: getZoningGuid,
  noteCount: getNoteCount,
  lawCount: getLawCount,
  pastLawCount: getPastLawCount,
  analysisId: getAnalysisId,
  displayedQuestionIds: getDisplayedQuestionIds,
  pubDocsLabel: getPubDocsSidebarName,
  pubDocTypes: getPubDocTypes,
  pubDocTypeId: getPubDocTypeId,
  searchResultsUrl: getSearchResultsUrl,
  hasViewPermission: hasViewPermission,
  hasIndexViewPermission: hasIndexViewPermission,
  hasArchivesViewPermission: hasArchivesViewPermission,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  hasPubDocTypeAddPermission: hasPubDocTypeAddPermission,
  hasPubDocTypeDeletePermission: hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission: hasPubDocTypeEditPermission,
  hasPubDocPrivateViewPermission: hasPubDocPrivateViewPermission,
  hasLawViewPermission: hasLawViewPermission,
  hasDashboardPermission: hasDashboardPermission
});

const mapDispatchToProps = (dispatch) => ({
  setPubDocTypeAddOpen: (open) => {
    dispatch(setPubDocTypeEditOpen(open));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(NavigationRail);
