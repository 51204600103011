import {OTHER as OTHER_PAGE} from "./page-types";
import {OTHER as OTHER_SUB_PAGE} from "./page-sub-types";

const custId = ((window && window.SERVER_DATA && window.SERVER_DATA.custId) || null);
const archiveId = ((window && window.SERVER_DATA && window.SERVER_DATA.archiveId) || null);
const customer = ((window && window.SERVER_DATA && window.SERVER_DATA.customer) || null);
const customerName = ((window && window.SERVER_DATA && window.SERVER_DATA.customerName) || null);
const maxSearchPrintPages = ((window && window.SERVER_DATA && window.SERVER_DATA.maxSearchPrintPages) || null);
const pageType = ((window && window.SERVER_DATA && window.SERVER_DATA.pageType) || OTHER_PAGE);
const pageSubType = ((window && window.SERVER_DATA && window.SERVER_DATA.pageSubType) || OTHER_SUB_PAGE);
const searchResultsPerPage = ((window && window.SERVER_DATA && window.SERVER_DATA.searchResultsPerPage) || null);
const username = ((window && window.SERVER_DATA && window.SERVER_DATA.username) || null);
const ipAddress = ((window && window.SERVER_DATA && window.SERVER_DATA.ipAddress) || null);
const pubDocsOnly = ((window && window.SERVER_DATA && window.SERVER_DATA.pubDocsOnly) || false);
const charterGuid = ((window && window.SERVER_DATA && window.SERVER_DATA.charterGuid) || null);
const zoningGuid = ((window && window.SERVER_DATA && window.SERVER_DATA.zoningGuid) || null);
const noteCount = ((window && window.SERVER_DATA && window.SERVER_DATA.noteCount) || 0);
const myNotesCount = ((window && window.SERVER_DATA && window.SERVER_DATA.myNotesCount) || 0);
const lawCount = ((window && window.SERVER_DATA && window.SERVER_DATA.lawCount) || 0);
const pastLawCount = ((window && window.SERVER_DATA) ? window.SERVER_DATA.pastLawCount : null);
const hasDashboardAccess = ((window && window.SERVER_DATA && window.SERVER_DATA.hasDashboardAccess) || false);
const warningMessage = ((window && window.SERVER_DATA && window.SERVER_DATA.warningMessage) || null);
const searchResultsUrl = ((window && window.SERVER_DATA && window.SERVER_DATA.searchResultsUrl) || null);
const noteLocationData = ((window && window.SERVER_DATA && window.SERVER_DATA.noteLocationData) || null);
const newNav = ((window && window.SERVER_DATA && window.SERVER_DATA.newNav) || false);

export {
  custId,
  archiveId,
  customer,
  customerName,
  maxSearchPrintPages,
  pageType,
  pageSubType,
  searchResultsPerPage,
  username,
  ipAddress,
  pubDocsOnly,
  charterGuid,
  zoningGuid,
  noteCount,
  myNotesCount,
  lawCount,
  pastLawCount,
  hasDashboardAccess,
  warningMessage,
  searchResultsUrl,
  noteLocationData,
  newNav
};