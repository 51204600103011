import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import AddReferenceDialog from '../components/AddReferenceDialog';
import {addReference, clearActiveReferenceNode, fetchReferenceMatches, toggleReferenceNode} from '../actions';
import {getReferenceMatches, getReferenceNodes, getAreMatchesLoading} from '../selectors';
import {compose} from 'recompose';
import {toJS} from "../../common/utils";

const mapStateToProps = createStructuredSelector({
  nodes: getReferenceNodes,
  matches: getReferenceMatches,
  areMatchesLoading: getAreMatchesLoading,
});

const mapDispatchToProps = (dispatch, {id, groupName}) => ({
  onInsert: ({text, url, targetOption}) => dispatch(addReference(id, {text, url, targetOption})),
  fetchReferenceMatches: (query) => dispatch(fetchReferenceMatches(groupName, query)),
  toggleReferenceNode: (node, toggled) => dispatch(toggleReferenceNode(groupName, node, toggled)),
  clearActiveReferenceNode: () => dispatch(clearActiveReferenceNode(groupName)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(AddReferenceDialog);
