/*
 * Copyright 2019 General Code
 */

import React from "react";
import {createAction} from "redux-actions";
import {apiAction, registerError} from "../../common/actions";
import {custId} from "../../common/utils/server-data";
import {deleteJson} from "../../common/utils";
import {putJson} from "../../common/utils";
import {DISPLAY_DATE_SET, DISPLAY_DATE_UPDATED, INIT, MAP_URL_SET, MAP_URL_UPDATED} from "./types";
import {getDisplayDate, getMapUrl} from "../selectors";
import {createNewMessage, MessageTypes} from "../../message-queue/index";

export const initSettings = createAction(INIT, settings => ({...settings,
  oldMapUrl: settings.mapUrl,
  oldDisplayDate: settings.displayDate,
  zoningStructure: settings.zoningStructure,
  nameOverride: settings.nameOverride
}));

export const setMapUrl = createAction(MAP_URL_SET, (mapUrl) => ({mapUrl}));
export const mapUrlUpdated = createAction(MAP_URL_UPDATED);
export const updateMapUrl = () => (dispatch, getState) => dispatch(apiAction(
  {
    label: MAP_URL_SET,
    method: "POST",
    url: `/${custId}/customization`,
    onSuccess:  [
      mapUrlUpdated,
      () => createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Zoning map URL updated</span>)
    ],
    data: [{type: "mapUrl", value: getMapUrl(getState())}]
  })
);

export const setDisplayDate = createAction(DISPLAY_DATE_SET, (displayDate) => ({displayDate}));
export const displayDateUpdated = createAction(DISPLAY_DATE_UPDATED);
export const updateDisplayDate = () => (dispatch, getState) => dispatch(apiAction(
  {
    label: DISPLAY_DATE_SET,
    method: "PATCH",
    url: `/api/customer/${custId}/code/latest/display-date`,
    onSuccess: [
      displayDateUpdated,
      () => createNewMessage(dispatch, MessageTypes.SUCCESS, <span>Display date updated for latest code</span>)
    ],
    data: {displayDate: getDisplayDate(getState())}
  })
);

export const clearZoningChapterStart = createAction('TAXONOMY/ZONING/CLEAR/START', () => ({}));
export const clearZoningChapterSuccess = createAction('TAXONOMY/ZONING/CLEAR/SUCCESS', (structureTaxonomyList) => ({structureTaxonomyList}));
const clearZoningChapterFail = createAction('TAXONOMY/ZONING/CLEAR/FAIL', (error) => ({error}));
export const clearZoningChapterFinally = createAction('TAXONOMY/ZONING/CLEAR/FINALLY', () => ({}));
export const setZoningChapterStart = createAction('TAXONOMY/ZONING/SET/START', () => ({}));
export const setZoningChapterSuccess = createAction('TAXONOMY/ZONING/SET/SUCCESS', (structureTaxonomy) => ({structureTaxonomy}));
const setZoningChapterFail = createAction('TAXONOMY/ZONING/SET/FAIL', (error) => ({error}));
export const setZoningChapterFinally = createAction('TAXONOMY/ZONING/SET/FINALLY', () => ({}));
export const setZoningChapter = (guid) => async (dispatch) => {
  console.debug("Setting the Zoning Chapter...");
  dispatch(clearZoningChapterStart());
  try {
    const deleteResult = await deleteJson(`/api/customer/${custId}/taxonomy/ZONING`);
    dispatch(clearZoningChapterSuccess(deleteResult));
  } catch (error) {
    dispatch(registerError("Problem clearing the Zoning Chapter...", null, [], error));
    dispatch(clearZoningChapterFail(error));
  } finally {
    dispatch(clearZoningChapterFinally());
  }

  if (guid) {
    console.debug("Setting the Zoning Chapter...");
    dispatch(setZoningChapterStart());
    try {
      const addResult = await putJson(`/api/customer/${custId}/structure/${guid}/taxonomy/ZONING`);
      dispatch(setZoningChapterSuccess(addResult));
    } catch (error) {
      dispatch(registerError("Problem setting the Zoning Chapter...", null, [], error));
      dispatch(setZoningChapterFail(error));
    } finally {
      dispatch(setZoningChapterFinally());
    }
  }
};

