import debounce from "lodash/debounce";
import * as PropTypes from 'prop-types';
import React from 'react';
import Chip from "../../common/components/Chip";
import IconButton from "../../common/components/IconButton";
import {Search as SearchIcon} from "../../common/components/icons";
import Icon from "../../common/components/Icon";
import Button from "../../common/components/Button";
import If from "../../common/containers/If";
import {customer} from "../../common/utils/server-data";
import './MenubarSearchComponent.css';
import ClipLoader from 'react-spinners/ClipLoader';
import AutoSuggestContainer from "../containers/AutoSuggestContainer";


class MenubarSearchComponent extends React.PureComponent {
  static propTypes = {
    query: PropTypes.string.isRequired,
    selectedStructures: PropTypes.object.isRequired,
    selectedCustIds: PropTypes.array.isRequired,
    selectedCustomerFilters: PropTypes.array.isRequired,
    searchedCodeCount: PropTypes.number.isRequired,
    hasAdvancedSearchPermission: PropTypes.bool.isRequired,
    hasMulticodePermission: PropTypes.bool.isRequired,
    searchSuggestionWaitTime: PropTypes.number.isRequired,
    isSearchFieldActive: PropTypes.bool.isRequired,
    setQuery: PropTypes.func.isRequired,
    submitSearch: PropTypes.func.isRequired,
    setCodeFinderOpen: PropTypes.func.isRequired,
    resetSelectedCustomers: PropTypes.func.isRequired,
    resetSelectedStructures: PropTypes.func.isRequired,
    getSearchSuggestions: PropTypes.func.isRequired,
    setSearchFieldActive: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.handleGetSearchSuggestions = debounce(this.handleGetSearchSuggestions, props.searchSuggestionWaitTime);
    this.handleGetSearchSuggestions(props.query);
  }

  componentDidMount() {
    window.addEventListener("click", this.handleWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  handleWindowClick = (event) => {
    const {isSearchFieldActive, setSearchFieldActive} = this.props;
    if (isSearchFieldActive && !this.ref.contains(event.target)) {
      setSearchFieldActive(false);
    }
  };

  handleOpenCodeFinderDialog = () => {
    this.props.setCodeFinderOpen(true);
  };

  handleMulticodeChipClick = (event) => {
    if (event.buttons === 1 && event.target.tagName !== "svg" && event.target.tagName !== "path") {
      this.handleOpenCodeFinderDialog();
    }
  };

  handleMulticodeChipKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleOpenCodeFinderDialog();
    }
  };

  handleMulticodeChipDelete = () => {
    const {submitSearch, resetSelectedCustomers} = this.props;
    resetSelectedCustomers();
    submitSearch();
  };

  handleSelectedChipDelete = () => {
    const {submitSearch, resetSelectedStructures} = this.props;
    resetSelectedStructures();
    if(window.location.href.indexOf("/search") !== -1)
      submitSearch();
  };

  handleSearch = (event) => {
    const {submitSearch} = this.props;
    event.preventDefault();
    submitSearch(event.target.value);
  };

  handleOpenAdvancedSearchDialog = (event) => {
    const {setAdvancedSearchOpen} = this.props;
    event.preventDefault();
    setAdvancedSearchOpen(true);
  };

  handleGetSearchSuggestions = () => {
    const {query, getSearchSuggestions} = this.props;
    getSearchSuggestions(query);
  };

  handleQueryChange = (event) => {
    const {setQuery} = this.props;
    setQuery(event.target.value);
    this.handleGetSearchSuggestions(event.target.value);
  };

  handleSearchFieldActive = (event) => {
    const {isSearchFieldActive, setSearchFieldActive} = this.props;
    if (event.key && event.key === 'Tab') {
      setSearchFieldActive(false);
    } else {
      if (!isSearchFieldActive) {
        setSearchFieldActive(true);
      }
      if (event.key && event.key === 'ArrowDown'){
        const quickJumpItems = document.getElementsByClassName("quickJumpItem");
        if (quickJumpItems.length > 0) {
          quickJumpItems[0].focus();
          event.preventDefault();
        }
      }
    }
  };

  render() {
    const {query, selectedStructures, selectedCustIds, selectedCustomerFilters, searchedCodeCount,
      hasAdvancedSearchPermission, hasMulticodePermission} = this.props;
    const chips = [];
    const titles = Object.values(selectedStructures);
    if((selectedCustomerFilters && selectedCustomerFilters.length > 0) || (selectedCustIds && (selectedCustIds.length > 1 || (selectedCustIds.length === 1 && (!customer || selectedCustIds[0] !== customer.custId)))))  {
      chips.push(
        <Chip clickable
          key="codesChip"
          id="multicodeChip"
          className={"clickable muiChip menubarScopeChip"}
          label={"Multicode" + (searchedCodeCount ? " (" + searchedCodeCount.toLocaleString() + ")" : "")}
          deleteIcon={!searchedCodeCount ? <Icon id="menubarMulticodeChipLoadIcon"><ClipLoader/></Icon> : null}
          onDelete={this.handleMulticodeChipDelete}
          onMouseDown={this.handleMulticodeChipClick}
          onKeyDown={this.handleMulticodeChipKeyPress}
        />
      );
    }
    if(selectedStructures && titles.length > 0) {
      chips.push(
        <Chip key="selectedChip"
          className={"muiChip menubarScopeChip"}
          label={"Selected (" + (titles.length.toLocaleString()) + ")"}
          onDelete={this.handleSelectedChipDelete}
          title={titles.join(" + ")}
        />
      );
    }
    return (
      <div id="menuSearchArea" className="menuButton searchArea">
        <div id="menubarSearchContainer" ref={ref => this.ref = ref}>
          <form id="menubarSearchForm" method="GET" autoComplete="off" onSubmit={this.handleSearch}>
            <div id="menubarScopeChipContainer">{ chips }</div>
            <input id="menubarSearchField"
              name="query"
              type="text"
              placeholder="Enter search term..."
              value={query}
              aria-label="Enter search term"
              aria-owns="autoSuggestContainer"
              onChange={this.handleQueryChange}
              onFocus={this.handleSearchFieldActive}
              onClick={this.handleSearchFieldActive}
              onKeyDown={this.handleSearchFieldActive}
            />
            <AutoSuggestContainer/>
            <IconButton
              id="menubarSearchButton"
              aria-label="Submit Search"
              onClick={this.handleSearch}
            >
              <SearchIcon/>
            </IconButton>
          </form>
          <If test={hasAdvancedSearchPermission || hasMulticodePermission}>
            <div className="menubarSearchButtons">
              <If test={hasAdvancedSearchPermission}>
                <Button variant="contained" color="default" size="small" className="menubarSearchButton-advanced menubarSearchButton" onClick={this.handleOpenAdvancedSearchDialog}>Advanced</Button>
              </If>
              <If test={hasMulticodePermission}>
                <Button variant="contained" color="default" size="small" className="menubarSearchButton-multicode menubarSearchButton" onClick={this.handleOpenCodeFinderDialog}>Multicode</Button>
              </If>
            </div>
          </If>
        </div>
      </div>
    );
  }
}
export default MenubarSearchComponent;
