import React from 'react';
import {Code as CodeIcon, PublicDocument as PublicDocumentIcon, NewLaw as NewLawIcon, Note as NoteIcon} from "../../common/components/icons";
import If from "../../common/containers/If";

class ScopeType extends React.PureComponent {

  render() {
    const {isSelected, id, name, title, count, setScope} = this.props;
    return (
      <div id={id + "Scope"} className={"navItem clearfix" + (isSelected ? " selected customerAccentColor_Border" : ((count < 1) ? " disabled" : ""))}>
        <div className={"navItemLabel" + (!isSelected && count >= 1 ? " navItemLink" : "") + (id === "all" ? " truncated" : "")}
          onClick={()=>{if(!isSelected && count >= 1)setScope(id);}} title={title}>
          <If test={id === "code"}>
            <div className="navItemIcon"><CodeIcon/></div>
          </If>
          <If test={id === "doc"}>
            <div className="navItemIcon"><PublicDocumentIcon/></div>
          </If>
          <If test={id === "law"}>
            <div className="navItemIcon"><NewLawIcon/></div>
          </If>
          <If test={id === "note"}>
            <div className="navItemIcon"><NoteIcon/></div>
          </If>
          <div className="navItemName">{name}</div>
          <div className="navItemCount">{"(" + count.toLocaleString() + ")"}</div>
        </div>
      </div>
    );
  }
}
export default ScopeType;
