/*
 * Copyright 2018 General Code
 */

import {connect} from 'react-redux';
import compose from 'recompose/compose';
import setDisplayName from 'recompose/setDisplayName';
import withProps from 'recompose/withProps';
import {bindActionCreators} from 'redux';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import Picker from '../../common/components/Picker';

import {updateDisplayMode} from '../actions';
import {getDisplayMode} from '../selectors';

const mapStateToProps = createStructuredSelector({
  value: getDisplayMode,
});

const mapDispatchToProps = (dispatch, {id}) => bindActionCreators({
  updateValue: displayMode => updateDisplayMode(id, displayMode),
}, dispatch);

const staticProps = {
  type: "display",
  label: "Display Mode",
  tooltip: "Select this note is initially shown; just the title or expanded to show the text as well",
  display: {
    order: ["expanded","minimized"],
    options: {
      expanded: {
        primary: "Expanded",
        secondary: "Title & Text",
        short: "Expanded - includes note text",
      },
      minimized: {
        primary: "Minimized",
        secondary: "Title only",
        short: "Minimized - title only",
      },
    },
  },
};

const DisplayModePickerContainer = compose(setDisplayName("DisplayModePickerContainer"),withProps(staticProps), connect(mapStateToProps, mapDispatchToProps))(Picker);
export default DisplayModePickerContainer;
