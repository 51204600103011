import {compose} from "recompose";
import {connect} from "react-redux";
import {toJS} from "../../common/utils";
import {createStructuredSelector} from "reselect";
import {deletePubDocCategory, setDocumentChecked, setDocumentUnchecked, setPubDocCategoryEditOpen, setPubDocEditOpen} from "../actions";
import * as PropTypes from "prop-types";
import PubDocCategoryLabel from "../components/PubDocCategoryLabel";
import {getCustId, hasPubDocCategoryAdminPermission, hasPubDocPrivateViewPermission, hasDocumentAdminPermission} from "../../common/selectors";
import {getSelectedDocs} from "../selectors";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  hasPubDocCategoryAdminPermission: hasPubDocCategoryAdminPermission,
  hasPubDocPrivateViewPermission: hasPubDocPrivateViewPermission,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  selectedDocs: getSelectedDocs
});

const mapDispatchToProps = (dispatch, {category}) => ({
  deleteCategory: (custId) => {
    dispatch(deletePubDocCategory(custId, category.id));
  },
  setCategoryEditOpen: (categoryEditOpen, categoryEditId) => {
    dispatch(setPubDocCategoryEditOpen(categoryEditOpen, categoryEditId));
  },
  setDocumentEditOpen: (documentEditOpen, documentId) => {
    dispatch(setPubDocEditOpen(documentEditOpen, documentId, category.id));
  },
  setDocumentChecked: (documentId) => {
    dispatch(setDocumentChecked(documentId));
  },
  setDocumentUnchecked: (documentId) => {
    dispatch(setDocumentUnchecked(documentId));
  },
});

const PubDocCategoryLabelContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocCategoryLabel);
PubDocCategoryLabelContainer.propTypes = {
  category: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired
};
export default PubDocCategoryLabelContainer;