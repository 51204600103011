import React from "react";
import NavigationRailContainer from "./containers/NavigationRailContainer";
import reducer, {initialState} from './reducers';
import {loadServerData, setBodyTop} from './actions';
import './preload';
import './style/scheme.css';
import './style/print.css';
import {replaceNodeWithReactComponent} from "./utils/ReactHelper";

const getInitialState = (loadingState) => {
  const commonState = loadingState.get(ModuleKey) || initialState;
  const serverData = window && window.SERVER_DATA ? window.SERVER_DATA : {};
  return loadingState.set(ModuleKey, reducer(commonState, loadServerData(serverData)));
};

const init = (Providers, dispatch) => {
  const top = window.getComputedStyle(document.body).getPropertyValue('top');
  dispatch(setBodyTop(top === "auto" || top === "inherit" ? 0 : parseInt(top, 10)));
  const railElement = document.getElementById("rail");
  if (railElement) {
    replaceNodeWithReactComponent(railElement, <Providers key="nav-rail-providers" name="nav-rail"><NavigationRailContainer/></Providers>);
  }
};

export const ModuleKey = 'common';
export {reducer, getInitialState, init};

