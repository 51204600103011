import {List} from "immutable";
import {createAction} from "redux-actions";
import {logEvent, registerError, registerNonFatalError} from "../../common/actions";
import { custId, archiveId } from "../../common/utils/server-data";
import {getJson} from "../../common/utils";
import * as selectors from '../selectors';

let currentPromise = null;

//These aren't really constant because objects can be mutated but please treat it as such
const AUDIT_CATEGORIES = {
  SEARCH: "Search",
  CUSTOMER_LOCATOR: "CustomerLocator"
};
const AUDIT_ACTIONS = {
  MULTICODE: "MulticodeSearch",
  ADVANCED: "AdvancedSearch",
  ADVANCED_MULTICODE: "AdvancedMulticodeSearch",
  SELECTED: "SelectedSearch",
  CUSTOMER_LOCATOR_OPEN: "CustomerLocatorOpen"
};

const setSearchFieldActive = createAction('SEARCH/SEARCH_FIELD_ACTIVE/SET', (status) => ({status}));
const getSuggestionsStart = createAction('SEARCH/SUGGESTIONS/GET/START', (query) => ({query}));
const getSuggestionsSuccess = createAction('SEARCH/SUGGESTIONS/GET/SUCCESS', (suggestions) => ({suggestions}));
const getSuggestionsFail = createAction('SEARCH/SUGGESTIONS/GET/FAIL', (query, error) => ({query, error}));
const getSuggestionsFinally = createAction('SEARCH/SUGGESTIONS/GET/FINALLY', (query) => ({query}));
const clearSuggestions = createAction('SEARCH/SUGGESTIONS/CLEAR');

const getSuggestions = (query) => async (dispatch) => {
  dispatch(getSuggestionsStart(query));
  try {
    if (!query || query.length === 0 || !custId) {
      dispatch(clearSuggestions());
    } else {
      const suggestions = await getJson(`/api/${custId ? custId + "/" : ""}suggestions?query=${encodeURIComponent(query)}`);
      dispatch(getSuggestionsSuccess(suggestions));
    }
  } catch (error) {
    dispatch(registerNonFatalError("There was a problem finding suggestions", null, [query], error));
    dispatch(getSuggestionsFail(query, error));
  } finally {
    dispatch(getSuggestionsFinally(query));
  }
};

const getResultsStart = createAction('SEARCH/RESULTS/GET/START');
const getResultsSuccess = createAction('SEARCH/RESULTS/GET/SUCCESS', (response) => (response));
const getResultsFinally = createAction('SEARCH/RESULTS/GET/FINALLY');
const clearResults = createAction('SEARCH/RESULTS/CLEAR');
const initializeSearchState = createAction('SEARCH/STATE/INIT',(loadedState = {}) => ({loadedState}));

const setSortOrderAndGetResults = (sortOrder) => async(dispatch) => {
  await dispatch(setSortOrder(sortOrder));
  await dispatch(clearResults());
  dispatch(getResultsPage(0));
};

const setScopeAndGetResults = (scope) => async(dispatch) => {
  await dispatch(setScope(scope));
  await dispatch(clearResults());
  await dispatch(clearFacets());
  dispatch(getResultsPage(0));
};

const addFacetSelectionAndGetResults = (selection) => async(dispatch) => {
  await dispatch(addFacetSelection(selection));
  await dispatch(clearResults());
  dispatch(getResultsPage(0));
};

const removeFacetSelectionAndGetResults = (selection) => async(dispatch) => {
  await dispatch(removeFacetSelection(selection));
  await dispatch(clearResults());
  dispatch(getResultsPage(0));
};

const submitNewSearch = (isFromMulticode, isFromAdvanced) => async (dispatch, getState) => {
  await dispatch(clearScope());
  await dispatch(clearFacets());
  await dispatch(clearResults());
  const state = getState();
  const selectedGuids = selectors.getSelectedStructures(state) && selectors.getSelectedStructures(state).keys ?
    List([...selectors.getSelectedStructures(state).keys()]) : List(Object.keys(selectors.getSelectedStructures(state)));
  const custIds = selectors.getSelectedCustIds(state);
  const customerFilters = selectors.getSelectedCustomerFilters(state);

  //search is either from multicode or has multiple customers
  const isMulticode = custIds.size > 1 || customerFilters.size > 0 || isFromMulticode;

  //NOTE: Multiple audit entries for combo searches is desired for reporting purposes
  if (isFromAdvanced) {
    dispatch(logEvent(AUDIT_CATEGORIES.SEARCH, AUDIT_ACTIONS.ADVANCED, {custIds: custIds, customerFilters: customerFilters}));
  }
  if (isMulticode) {
    dispatch(logEvent(AUDIT_CATEGORIES.SEARCH, AUDIT_ACTIONS.MULTICODE, {custIds: custIds, customerFilters: customerFilters}));
  }

  //search is a multicode advanced search
  if(isMulticode && isFromAdvanced) {
    dispatch(logEvent(AUDIT_CATEGORIES.SEARCH, AUDIT_ACTIONS.ADVANCED_MULTICODE, {custIds: custIds, customerFilters: customerFilters}));
  }

  //search is a selected search - NOTE: this will currently never also be a multicode or advanced search but in the future could be
  if (selectedGuids.size > 0) {
    dispatch(logEvent(AUDIT_CATEGORIES.SEARCH, AUDIT_ACTIONS.SELECTED, {selected: selectedGuids}));
  }

  // Log the GA4 search event
  const query = selectors.getQuery(state);
  const scope = selectors.getScope(state);
  const sortOrder = selectors.getSortOrder(state);
  window.generalcode.logAnalyticsEvent("view_search_results", {
    "search_term": query,
    "q_scope": scope,
    "q_sortOrder": sortOrder
  });

  dispatch(getResultsPage(0));
};

const getAllResultsPage = () => async (dispatch) => {
  dispatch(getResultsPage(-1));
};

const getNextResultsPage = () => async (dispatch, getState) => {
  const currentPage = selectors.getCurrentPage(getState());
  dispatch(getResultsPage(currentPage ? currentPage + 1 : 1));
};

const getResultsPage = (page = 0) => async (dispatch, getState) => {
  const state = getState();
  const maxPages = selectors.getMaxPages(state);

  if (!selectors.isSearchLoading(state) && maxPages > page) {

    const isSearchResultsPage = selectors.isSearchResultsPage(state);
    const query = selectors.getQuery(state);
    const scope = selectors.getScope(state);
    const facetSelections = selectors.getFacetSelections(state);
    const sortOrder = selectors.getSortOrder(state);
    const custIds = selectors.getSelectedCustIds(state);
    const customerFilters = selectors.getSelectedCustomerFilters(state).map(filter => filter.json);
    const selectedGuids = selectors.getSelectedStructures(state) && selectors.getSelectedStructures(state).keys ?
      List([...selectors.getSelectedStructures(state).keys()]) : List(Object.keys(selectors.getSelectedStructures(state)));

    await dispatch(getResultsStart());

    let promise;
    try {
      const customerString = archiveId ? `${archiveId}/` : (custId ? `${custId}/` : "");
      if (isSearchResultsPage) {
        if (!custId || custIds.size === 0 || customerFilters.size > 0 || custIds.size > 1 || (custIds.size === 1 && custId !== custIds.get(0))) {
          promise = getJson(`/api/${customerString}search-results/multi/${page}?query=${encodeURIComponent(query)}&scope=${encodeURIComponent(scope)}&sortOrder=${encodeURIComponent(sortOrder)}${facetSelections.size > 0 ? "&selections=" + encodeURIComponent(facetSelections.join(",")) : ""}${custIds.size > 0 ? "&custIds=" + encodeURIComponent(custIds.join(",")) : ""}${customerFilters.size > 0 ? "&customerFilters=" + encodeURIComponent("{filters:[" + customerFilters.join(",") + "]}") : ""}${selectedGuids.size > 0 ? "&guids=" + selectedGuids.map(guid => encodeURIComponent(guid)).join(",") : ""}`);
        } else {
          promise = getJson(`/api/${customerString}search-results/${page}?query=${encodeURIComponent(query)}&scope=${encodeURIComponent(scope)}&sortOrder=${encodeURIComponent(sortOrder)}${facetSelections.size > 0 ? "&selections=" + encodeURIComponent(facetSelections.join(",")) : ""}${selectedGuids.size > 0 ? "&guids=" + selectedGuids.map(guid => encodeURIComponent(guid)).join(",") : ""}`);
        }
        currentPromise = promise;
        const response = await promise;
        if (currentPromise === promise) {
          dispatch(getResultsSuccess(response));
        }
      } else {
        window.location.href = `/${customerString}search?query=${encodeURIComponent(query)}&scope=${encodeURIComponent(scope)}&sortOrder=${encodeURIComponent(sortOrder)}${facetSelections.size > 0 ? "&selections=" + encodeURIComponent(facetSelections.join(",")) : ""}${(!custId || custIds.size > 1 || customerFilters.size > 0 || (custIds.size === 1 && custId !== custIds.get(0))) ? "&custIds=" + encodeURIComponent(custIds.map(custId => custId).join(",")) : ""}${customerFilters.size > 0 ? "&customerFilters=" + encodeURIComponent("{filters:[" + customerFilters.map(customerFilter => customerFilter).join(",") + "]}") : ""}${selectedGuids.size > 0 ? "&guids=" + selectedGuids.map(guid => encodeURIComponent(guid)).join(",") : ""}`;
      }
    } catch (error) {
      dispatch(registerError("There was a problem getting search results", null, [query], error));
    } finally {
      if (currentPromise === promise) {
        await dispatch(getResultsFinally());
        dispatch(updateWindowHistory());
      }
    }
  }
};

const setQuery = createAction('SEARCH/QUERY/SET', (query) => ({query}));
const setSortOrder = createAction('SEARCH/SORT_ORDER/SET', (sortOrder) => ({sortOrder}));
const clearScope = createAction('SEARCH/SCOPE/CLEAR');
const setScope = createAction('SEARCH/SCOPE/SET', (scope) => ({scope}));
const clearFacets = createAction('SEARCH/FACET/CLEAR');
const addFacetSelection = createAction('SEARCH/FACET_SELECTION/ADD', (selection) => ({selection}));
const removeFacetSelection = createAction('SEARCH/FACET_SELECTION/REMOVE', (selection) => ({selection}));
const setTaxonomyFacetNodeSelected = createAction('SEARCH/TAXONOMY_FACET_NODE_SELECTED/SET', (contentType, path, value) => ({contentType, path, value}));
const clearContentTypeData = createAction('SEARCH/CONTENT_TYPE_DATA/CLEAR');

const updateWindowHistory = createAction('SEARCH/HISTORY/UPDATE', (newState = {}) => ({newState}));

const setCodeFinderOpen = createAction('SEARCH/CODE_FINDER/OPEN', (isCodeFinderOpen) =>  ({isCodeFinderOpen}));
const setCodeFinderOpenAndLog =  (isCodeFinderOpen) => async (dispatch) =>{
  if (isCodeFinderOpen) dispatch(logEvent(AUDIT_CATEGORIES.CUSTOMER_LOCATOR, AUDIT_ACTIONS.CUSTOMER_LOCATOR_OPEN, {}));
  dispatch(setCodeFinderOpen(isCodeFinderOpen));
};

const findCustomersStart = createAction('SEARCH/CODE_FINDER/RESULTS/GET/START');
const findCustomersSuccess = createAction('SEARCH/CODE_FINDER/RESULTS/GET/SUCCESS', (response) => (response));
const findCustomersFail = createAction('SEARCH/CODE_FINDER/RESULTS/GET/FAIL', (error) => (error));
const findCustomersFinally = createAction('SEARCH/CODE_FINDER/RESULTS/GET/FINALLY');
const clearFoundCustomers = createAction('SEARCH/CODE_FINDER/RESULTS/CLEAR');
const setSelectedCustomers = createAction('SEARCH/CODE_FINDER/SELECTED/SET', (selectedCustIds, selectedCustomerFilters) => ({selectedCustIds, selectedCustomerFilters}));
const resetSelectedCustomers = createAction('SEARCH/CODE_FINDER/SELECTED/RESET');
const findCustomers = (name, state, counties, govtypes, govtypesub, minPop, maxPop, zipcode, distance) => async (dispatch) => {
  await dispatch(findCustomersStart());
  let promise;
  try {
    promise = getJson(`/ajax/code/info?${name ? "name=" + encodeURIComponent(name) + "&" : ""}${state ? "state=" + encodeURIComponent(state) + "&" : ""}${(counties && counties.length > 0) ? "county=" + encodeURIComponent(counties.join(",")) + "&" : ""}${(govtypes && govtypes.length > 0) ? "govtype=" + encodeURIComponent(govtypes.join(",")) + "&" : ""}${govtypesub ? "govtypesub=" + encodeURIComponent(govtypesub) + "&" : ""}${minPop ? "minPop=" + encodeURIComponent(minPop) + "&" : ""}${maxPop ? "maxPop=" + encodeURIComponent(maxPop) + "&" : ""}${zipcode ? "zipcode=" + encodeURIComponent(zipcode) + "&" : ""}${distance ? "distance=" + encodeURIComponent(distance) + "&" : ""}`);
    currentPromise = promise;
    const response = await promise;
    if (currentPromise === promise) {
      dispatch(findCustomersSuccess(response));
    }
  } catch (error) {
    dispatch(registerError("There was a problem finding codes", null, [name, state, counties, govtypes, govtypesub, minPop, maxPop, zipcode, distance], error));
    dispatch(findCustomersFail(error));
  } finally {
    if (currentPromise === promise) {
      await dispatch(findCustomersFinally());
    }
  }
};
const findCustomerSearchOptionsSuccess = createAction('SEARCH/CODE_FINDER/OPTIONS/GET/SUCCESS', (response) => (response));
const findCustomerSearchOptionsFail = createAction('SEARCH/CODE_FINDER/OPTIONS/GET/FAIL', (error) => (error));
const findCustomerSearchOptions = (name, state, counties, govtypes, govtypesub) => async (dispatch) => {
  try {
    const response = await getJson(`/ajax/code/options?${name ? "name=" + encodeURIComponent(name) + "&" : ""}${state ? "state=" + encodeURIComponent(state) + "&" : ""}${(counties && counties.length > 0) ? "county=" + encodeURIComponent(counties.join(",")) + "&" : ""}${(govtypes && govtypes.length > 0) ? "govtype=" + encodeURIComponent(govtypes.join(",")) + "&" : ""}${govtypesub ? "govtypesub=" + encodeURIComponent(govtypesub) + "&" : ""}`);
    dispatch(findCustomerSearchOptionsSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem finding codes", null, [name, state, counties, govtypes, govtypesub], error));
    dispatch(findCustomerSearchOptionsFail(error));
  }
};

const setAdvancedSearchOpen = createAction('SEARCH/ADVANCED/OPEN', (isAdvancedSearchOpen) => ({isAdvancedSearchOpen}));

const resetSearchedCodeCount = createAction('SEARCH/SEARCHED_CODE_COUNT/RESET');

const toggleSelectedStructure = createAction('COMMON/GUIDS/TOGGLE', (guid, title) => ({guid, title}));
const resetSelectedStructures = createAction('COMMON/GUIDS/RESET', () => {
  const selectionBoxes = document.querySelectorAll('.selected');
  selectionBoxes.forEach(box => {
    box.classList.remove('selected');
    const boxInput = box.getElementsByTagName('input')[0];
    if(boxInput) boxInput.checked = false;
  });
});

export {
  setSearchFieldActive,
  getSuggestions,
  getSuggestionsStart,
  getSuggestionsSuccess,
  getSuggestionsFail,
  getSuggestionsFinally,
  clearSuggestions,
  initializeSearchState,
  setScopeAndGetResults,
  setSortOrderAndGetResults,
  addFacetSelectionAndGetResults,
  removeFacetSelectionAndGetResults,
  submitNewSearch,
  getAllResultsPage,
  getNextResultsPage,
  getResultsStart,
  getResultsSuccess,
  getResultsFinally,
  clearResults,
  setQuery,
  setSortOrder,
  clearScope,
  setScope,
  clearFacets,
  addFacetSelection,
  removeFacetSelection,
  setTaxonomyFacetNodeSelected,
  clearContentTypeData,
  updateWindowHistory,
  setCodeFinderOpen,
  setCodeFinderOpenAndLog,
  findCustomersStart,
  findCustomers,
  findCustomersSuccess,
  findCustomersFinally,
  clearFoundCustomers,
  setSelectedCustomers,
  resetSelectedCustomers,
  findCustomerSearchOptions,
  findCustomerSearchOptionsSuccess,
  toggleSelectedStructure,
  resetSelectedStructures,
  resetSearchedCodeCount,
  setAdvancedSearchOpen
};
