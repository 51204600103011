import React, {Component} from 'react';
import {If} from '../../common/containers';
import './Note.css';

class NotePageControls extends Component {

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
    this.listenToScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    const searchBar = document.getElementById("search-bar");
    const searchBarHeight = searchBar ? searchBar.offsetHeight : 0;
    const warningBanner = document.getElementById("warnings");
    const warningBannerHeight = warningBanner ? warningBanner.offsetHeight : 0;
    const breadcrumbsBar = document.getElementById("breadcrumbs-bar");
    const breadcrumbsBarHeight = breadcrumbsBar ? breadcrumbsBar.offsetHeight : 0;
    let noteTitles = Array.from(document.getElementsByClassName("notesTitle"));
    const filteredNotes = noteTitles.filter(currNote => {
      // 16px is added to match the css for #codeContent .notesTitle
      return currNote.getBoundingClientRect().top <= (searchBarHeight + warningBannerHeight + breadcrumbsBarHeight + 16);
    });
    noteTitles.forEach(note => {
      if (document.getElementById("note-controls-" + note.id)) {
        document.getElementById("note-controls-" + note.id).classList.remove("selectedNotePageControl");
      }
    });
    for (var i = (filteredNotes.length - 1); i > -1; i--) {
      const topNoteTitle = document.getElementById("note-controls-" + filteredNotes[i].id);
      if (topNoteTitle) {
        topNoteTitle.classList.add("selectedNotePageControl");
        topNoteTitle.scrollIntoView({block: "nearest", inline: "nearest"});
        break;
      }
    }
  }

  render() {
    const {noteLocationData} = this.props;
    return (
      <If test={noteLocationData.length > 0}>
        <div id="notes-page-control" className="page-control-widget paper page-control-scrollable-widget">
          <div className="page-control-content scrollable">
            <ul className="notePageControlsList">
              <li className="noteLocationHeader">
                <span>Note Locations</span>
              </li>
              {noteLocationData.map(location => (
                <li key={location.guid} id={`note-controls-${location.guid}`}>
                  <a className="link titleLink" href={`#${location.guid}`}>
                    <If test={location.indexNum}>
                      <span className="titleNumber">{location.indexNum}</span>
                    </If>
                    <span className="titleTitle">{location.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </If>
    );
  }
}

export default NotePageControls;