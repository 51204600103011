const childArray = (el) => el ? Array.from(el.children) : [];
export const menubarContainerEl = document.getElementById("menubarContainer");

export const menuSearchAreaEl = document.getElementById("menuSearchArea");

export const menubarSearchContainerEl = document.getElementById("menubarSearchContainer");

export const mainContainerEl = document.getElementById("mainContainer");

export const drawerContainerEl = document.getElementById("drawerContainer");
export const drawerContainer = childArray(drawerContainerEl);

export const mainEl = document.getElementById("main");
export const main = childArray(mainEl);

export const menuButtonsEl = document.getElementById("menuButtons");
export const menuButtons = childArray(menuButtonsEl);

const noteEls = document.querySelectorAll(".noteContainer");
export const notes = Array.from(noteEls);

const questionEls = document.querySelectorAll(".questionContainer");
export const questions = Array.from(questionEls);
