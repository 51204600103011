import createCachedSelector from 're-reselect';
import {Map} from 'immutable';
import {ModuleKey} from '../ModuleKey';
import {getReferenceGroupNames} from "../../common/ReferenceGroupFactory";


const getNotes = (state) => state[ModuleKey].notes;
const getRealIdMapping = (state) => state[ModuleKey].realIdMapping;
const getId = (state, props) => props.id;
const isUnsaved = (state, props) => props.id.startsWith('new-') && !state[ModuleKey].realIdMapping.get(props.id);


const getAllNoteFilters = (state) => state[ModuleKey].noteFilters;
const getNoteFilter = (state, props) => state[ModuleKey].noteFilters.get(props.noteField);
const getNoteFilterOptions = (state, props) => {
  const {notes, noteFilters} = state[ModuleKey];
  const {noteField} = props;
  return notes
    .toSetSeq()
    .filter(note => {
      for (const field of noteFilters.keySeq()) {
        if (field !== noteField && note.get(field) !== noteFilters.get(field)) return false;
      }
      return true;
    })
    .map(note => note.get(noteField))
    .toSet()
    .sort()
    .toArray();
};

const getLocalFilteredNotes = (state) => {
  const {notes, noteFilters} = state;
  return notes
    .toSetSeq()
    .filter(note => {
      for (const field of noteFilters.keySeq()) {
        if (note.get(field) !== noteFilters.get(field)) return false;
      }
      return true;
    })
    .toSet();
};

const getAllReferenceNodes = (state) => Map(getReferenceGroupNames().reduce((map, groupName) => {
  map[groupName] = state[ModuleKey][groupName].nodes;
  return map;
}, {}));
const getReferenceNodes = (state, props) => state[ModuleKey][props.groupName].nodes;
const getReferenceMatches = (state, props) => state[ModuleKey][props.groupName].matches;
const getAreMatchesLoading = (state, props) => state[ModuleKey][props.groupName].areMatchesLoading;

const selectorCreator = (extract) => createCachedSelector(
  [
    getNotes,
    getId,
  ],
  (notes, id) => extract(notes.get(id)),
)(
  getId
);

const getNote = selectorCreator(note => note);
const getContent = selectorCreator(note => note && note.content);
const getColor = selectorCreator(note => note && note.color);
const getCreatedBy = selectorCreator(note => note && note.createdBy);
const getCreatedOn = selectorCreator(note => note && note.createdOn);
const getGuid = selectorCreator(note => note && note.guid);
const getHtml = selectorCreator(note => note && note.html);
const getDisplayMode = selectorCreator(note => note && note.displayMode);
const getTitle = selectorCreator(note => note && note.title);
const getUpdatedBy = selectorCreator(note => note && note.updatedBy);
const getUpdatedOn = selectorCreator(note => note && note.updatedOn);
const getVersion = selectorCreator(note => note && note.version);
const getVisibility = selectorCreator(note => note && note.visibility);
const isEditable = selectorCreator(note => note && note.editable);
const isEditing = selectorCreator(note => note && note.editing);
const isOpen = selectorCreator(note => note && note.open);

const isAnyEditing = (state) => state[ModuleKey].notes.some(note => note.editing);


export {
  getNotes,
  getNote,
  getColor,
  getContent,
  getCreatedBy,
  getCreatedOn,
  getGuid,
  getHtml,
  getDisplayMode,
  getRealIdMapping,
  getAllNoteFilters,
  getNoteFilter,
  getNoteFilterOptions,
  getLocalFilteredNotes,
  getAllReferenceNodes,
  getReferenceNodes,
  getReferenceMatches,
  getAreMatchesLoading,
  getTitle,
  getUpdatedBy,
  getUpdatedOn,
  getVersion,
  getVisibility,
  isEditable,
  isEditing,
  isAnyEditing,
  isOpen,
  isUnsaved,
};
