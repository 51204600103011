import * as PropTypes from "prop-types";
import React from "react";
import If from "../../common/containers/If";
import ContextHelpContainer from "../containers/ContextHelpContainer";
import './PageTitle.css';
import ReactDialogTitle from '@material-ui/core/DialogTitle';

class DialogTitle extends React.PureComponent {

  static propTypes = {
    pageId: PropTypes.string,
    pageTitle: PropTypes.string,
    additionalContent: PropTypes.any
  };

  render() {
    const {pageId, pageTitle, additionalContent} = this.props;
    return (
      <ReactDialogTitle id={pageId + "Title"}>
        <span>{pageTitle}</span>
        <ContextHelpContainer pageId={pageId} />
        <If test={additionalContent}>
          <div>{additionalContent}</div>
        </If>
      </ReactDialogTitle>
    );
  }
}
export default DialogTitle;
