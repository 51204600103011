/*
 * Copyright 2018 General Code
 */

import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '../../common/components/Paper';
import {withStyles} from '../../common/theme';
import {If} from '../../common/containers';
import EditorContainer from '../containers/EditorContainer';
import EditControlsContainer from '../containers/EditControlsContainer';
import MetadataContainer from '../containers/MetadataContainer';
import NoteIcon from './NoteIcon';
import NoteHeader from './Header';
import './Note.css';

const style = theme => ({
  note: {

    borderLeftColor: theme.palette.customer.default,

  },
  default: {
    borderLeftColor: theme.palette.site.default
  },
  accent: {
    borderLeftColor: theme.palette.customer.accent
  },
  banner: {
    borderLeftColor: theme.palette.customer.banner
  },
  title: {
    borderLeftColor: theme.palette.customer.title
  },
  teal: {
    borderLeftColor: theme.palette.site.teal
  },
  mint: {
    borderLeftColor: theme.palette.site.mint
  },
  berry: {
    borderLeftColor: theme.palette.site.berry
  },
  pineapple: {
    borderLeftColor: theme.palette.site.pineapple
  },
  roc: {
    borderLeftColor: theme.palette.site.roc
  },
});

const isDisplayable = (note, filters = {}) => {
  for (const field of Object.keys(filters)) {
    if (note[field] !== filters[field] && !note.editing) return false;
  }
  return true;
};

const Note = ({id, toggle, open, editing, color, visibility, filters, classes, ...props}) =>
  (
    <If test={isDisplayable({color, visibility, editing}, filters)}>
      <aside className={classNames({
        "noteContainer": true,
        "noteContainerMaximized": open,
        "noteContainerMinimized": !open,
      })}>
        <NoteIcon open={open} editing={editing} toggle={() => toggle(id)}/>
        <Paper square={true} elevation={1} className={classNames(["note", classes.note, classes[color]])}>
          <NoteHeader {...props} id={id} open={open}/>
          <EditorContainer {...props} id={id}/>
          <MetadataContainer {...props} id={id}/>
          <EditControlsContainer {...props} id={id}/>
        </Paper>
      </aside>
    </If>
  );

Note.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,

};

export default withStyles(style)(Note);
