/*
 * Copyright 2018 General Code
 */

import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import Button from "../../common/components/Button";
import ButtonGroup from "../../common/components/ButtonGroup";
import ConfirmDialog from "../../common/components/ConfirmDialog";
import {Dialog, DialogActions, DialogContent} from "../../common/components/dialog";
import InfiniteScroll from "../../common/components/InfiniteScroll";
import PrimaryButton from "../../common/components/PrimaryButton";
import {If} from "../../common/containers";
import {withCookies, Cookies} from "react-cookie";
import {custId} from "../../common/utils/server-data";
import AnalysisDueContainer from "../containers/AnalysisDueContainer";
import QuestionContainer from "../containers/QuestionContainer";
import './Question.css';

class Analysis extends Component {

  static propTypes = {
    analysis: PropTypes.object.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    displayedQuestionCount: PropTypes.number,
    displayedQuestionIds: PropTypes.array.isRequired,
    hasFinalizePermission: PropTypes.bool.isRequired,
    hasCompletePermission: PropTypes.bool.isRequired,
    hasPrintPermission: PropTypes.bool.isRequired,
    hasStatusReportPermission: PropTypes.bool.isRequired,
    clearAnalysisMessage: PropTypes.func.isRequired,
    checkAnalysis: PropTypes.func.isRequired,
    displayMoreQuestions: PropTypes.func.isRequired,
    finalizeAnalysis: PropTypes.func.isRequired,
    completeAnalysis: PropTypes.func.isRequired,
    expandQuestion: PropTypes.func.isRequired,
    collapseQuestion: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      confirmOpen: false,
      loadExpanded: false,
      loadCollapsed: false,
    };
  }


  componentDidMount() {
    const {analysis, checkAnalysis, cookies} = this.props;
    if (cookies.get(`autoCheck-` + custId, {'path':'/'})) {
      checkAnalysis(analysis.id);
    }
  }


  handleDialogClose = () => {
    const {clearAnalysisMessage, analysis} = this.props;
    clearAnalysisMessage(analysis.id);
  };

  handleAnalysisCheckClick = () => {
    const {checkAnalysis, analysis} = this.props;
    checkAnalysis(analysis.id);
  };

  handleAnalysisSubmitClick = () => {
    const {finalizeAnalysis, analysis} = this.props;
    finalizeAnalysis(analysis.id);
  };

  handleErrorLinkClick = (jumpLinkHash) => () => {
    if (window.generalcode && window.generalcode.scrollToHash) {
      window.location.hash = jumpLinkHash;
      window.generalcode.scrollToHash();
    }
  };

  handleDisplayMoreQuestions = () => {
    const {displayMoreQuestions} = this.props;
    displayMoreQuestions();
  };

  handleExpandAll = () => {
    this.setState({loadExpanded: true, loadCollapsed: false});
    const {analysis, expandQuestion} = this.props;
    analysis.questionIds.forEach(q => {
      expandQuestion(q);
    });
  };

  handleCollapseAll = () => {
    this.setState({loadCollapsed: true, loadExpanded: false});
    const {analysis, collapseQuestion} = this.props;
    analysis.questionIds.forEach(q => {
      collapseQuestion(q);
    });
  };

  shouldExpandEnable = () => {
    const {analysis, questions} = this.props;
    let enable = false;
    analysis.questionIds.forEach(id => {
      let q = questions[id];
      if (!q.isOpen) {
        enable = true;
      }
    });
    return enable;
  };

  shouldCollapseEnable = () => {
    const {analysis, questions} = this.props;
    let enable = false;
    analysis.questionIds.forEach(id => {
      let q = questions[id];
      if (q.isOpen) {
        enable = true;
      }
    });
    return enable;
  };

  cancelComplete = () => {
    this.setState({confirmOpen: false});
  };

  handleComplete = () => {
    this.setState({confirmOpen: true});
  };

  handleCompleteAnalysis = () => {
    this.cancelComplete();
    const {analysis, completeAnalysis} = this.props;
    completeAnalysis(analysis.id);
  };

  handleDownload = () => {
    window.location.href = window.location.href.replace(/\/$/, "") + "/print";
  }

  handleToggled = () => {
    this.setState({loadCollapsed: false, loadExpanded: false});
  }

  render() {
    const {analysis, displayedQuestionCount, displayedQuestionIds, hasCompletePermission, hasFinalizePermission,
      hasPrintPermission, hasStatusReportPermission} = this.props;
    const {confirmOpen} = this.state;
    return (
      <div className="analysis">
        <Dialog open={!!analysis.message} maxWidth={"sm"} onClose={this.handleDialogClose}>
          <DialogContent>
            <div className="analysisMessage">{analysis.message}</div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup>
              <PrimaryButton type="submit" onClick={this.handleDialogClose}>OK</PrimaryButton>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
        <If test={hasCompletePermission}>
          <ConfirmDialog
            title="Complete Analysis?"
            open={confirmOpen}
            onCancel={this.cancelComplete}
            onConfirm={this.handleCompleteAnalysis}
          >
            Completing this analysis will remove it from view. Complete analysis?
          </ConfirmDialog>
        </If>
        <div className="analysisHeader">
          <div className="analysisLabelContainer">
            <div className="analysisLabel">
              {analysis.created} Analysis
              <If test={hasStatusReportPermission}>
                <a id="analysisStatusLink" href="questions/status" title="Open analysis status report">
                  <span className="material-icons">summarize</span>
                </a>
              </If>
            </div>
            <If test={!analysis.finalized}>
              <AnalysisDueContainer dueDate={analysis.dueDate}/>
            </If>
            <div className="analysisMessage">
              <If test={Object.keys(analysis.errorLinks).length > 0}>
                <div className="error">
                  Could not finalize the analysis.&nbsp;Please review the following questions/options:&nbsp;
                  {Object.keys(analysis.errorLinks)
                    .map((linkKey, i) => (<span key={linkKey}><a href={analysis.errorLinks[linkKey]}
                      onClick={this.handleErrorLinkClick(analysis.errorLinks[linkKey])}>{linkKey}</a>{(i !== Object.keys(
                      analysis.errorLinks).length - 1) ? ", " : ""}</span>))}
                </div>
              </If>
              <If test={(analysis.questionIds.length === 0)}>
                <div className="info">There are no pending questions for this analysis.</div>
              </If>
            </div>
          </div>
        </div>
        <div className="questionExpansionControls">
          <Button onClick={this.handleExpandAll}
            disabled={!this.shouldExpandEnable()}
            className="expandAllButton"
            title="Expand all questions."
            variant="contained"
            color="primary"
          >
            Expand All
          </Button>
          <Button onClick={this.handleCollapseAll}
            disabled={!this.shouldCollapseEnable()}
            className="collapseAllButton"
            title="Collapse all questions."
            variant="contained"
            color="primary"
          >
            Collapse All
          </Button>
          <div className="finalizeButtonContainer">
            <If test={!analysis.finalized && hasFinalizePermission}>
              <Button onClick={this.handleAnalysisCheckClick}
                title="Check this analysis for errors."
                variant="contained"
                color="primary"
              >
                Check
              </Button>
              <Button onClick={this.handleAnalysisSubmitClick}
                disabled={!analysis.checked || Object.keys(analysis.errorLinks).length > 0}
                title={(!analysis.checked ? "This analysis must be checked before it can be submitted."
                  : (Object.keys(analysis.errorLinks).length > 0 ? "All errors in this analysis must be corrected before it can be submitted."
                    : "Submit this analysis to General Code."))}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </If>
            <If test={analysis.finalized}>
              <If test={hasCompletePermission}>
                <Button
                  className="completeButton"
                  onClick={this.handleComplete}
                  title="Complete this analysis."
                  variant="contained"
                  color="primary"
                >
                  Complete
                </Button>
              </If>
            </If>
            <If test={hasPrintPermission}>
              <Button onClick={this.handleDownload} className="downloadIa2Button" title="Download analysis" variant="contained"
                color="primary">Download</Button>
            </If>
          </div>
          <If test={analysis.finalized}>
            <div className="finalizedDateContainer">Finalized: {analysis.finalized}
            </div>
          </If>
        </div>
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={this.handleDisplayMoreQuestions}
          hasMore={displayedQuestionCount < displayedQuestionIds.length}
          threshold={1000}
        >
          {displayedQuestionIds.slice(0, displayedQuestionCount).map((questionId) => (
            <div key={questionId} id={"question-" + questionId}>
              <QuestionContainer questionId={questionId} loadCollapsed={this.state.loadCollapsed}
                loadExpanded={this.state.loadExpanded} toggled={this.handleToggled} />
            </div>
          ))}
        </InfiniteScroll>
        <If test={!analysis.finalized && analysis.questionIds.length !== 0 && displayedQuestionIds.length === 0}>
          <div className="info">There are no questions matching the specified criteria for this analysis.</div>
        </If>
      </div>
    );
  }
}

export default withCookies(Analysis);