import * as PropTypes from "prop-types";
import React from 'react';
import BoxedSection from '../../common/components/BoxedSection';
import Button from '../../common/components/Button';
import ButtonGroup from '../../common/components/ButtonGroup';
import Checkbox from '../../common/components/Checkbox';
import {MenuItem} from "../../common/components/menu";
import {ReactSelect} from "../../common/components/reactSelect";
import TextField from '../../common/components/TextField';
import PrimaryButton from '../../common/components/PrimaryButton';
import './GeneralCodeAdminSection.css';
import If from "../../common/containers/If";
import Select from "../../common/components/Select";
import FormControlLabel from '../../common/components/FormControlLabel';
import FormControl from '../../common/components/FormControl';
import InputLabel from "../../common/components/InputLabel";
import SelectCodeReferenceDialogContainer from "../../common/containers/SelectCodeReferenceDialogContainer";

class GeneralCodeAdminSection extends React.PureComponent {
  static propTypes = {
    featureViewJson: PropTypes.string,

    isOverridden: PropTypes.bool,
    maximumLevelFriendly: PropTypes.string,

    showCountyLineValue: PropTypes.string,
    bulkUploadValue: PropTypes.string,

    customerName: PropTypes.string,

    allCounties: PropTypes.string,
    muniCounties: PropTypes.string,
    counties: PropTypes.string,

    eReaderSubscribers: PropTypes.string,

    isIa2Admin: PropTypes.string,
    ia2Value: PropTypes.string,
    ia2ImportEnabled: PropTypes.string,

    codifiedLawViewEnabled: PropTypes.string,

    isCodeReviewAdmin: PropTypes.string,
    codeReviewPhase: PropTypes.string,

    allowRemoteAuth: PropTypes.string,

    isMixedContentAdmin: PropTypes.string,
    mixedContentEnabled: PropTypes.string,

    apiAdminEnable: PropTypes.string,
    contentApiAdminValue: PropTypes.string,
    searchApiAdminValue: PropTypes.string,
    codeApiAdminValue: PropTypes.string,

    mapUrl: PropTypes.string,
    zoningStructure: PropTypes.object,
    zoningStructureLoading: PropTypes.bool,

    setMapUrl: PropTypes.func,
    setZoningChapter: PropTypes.func,

    sidebarLabel: PropTypes.string,
    pubDocsOnlyText: PropTypes.string
  };

  constructor(props) {
    super(props);
    props.fetchRootReferenceNodes();
    const featureView = JSON.parse(props["featureViewJson"]);
    const allCounties = this.convertCountiesList(JSON.parse(props['allCounties']), true);
    const municipalCounties = this.convertCountiesList(JSON.parse(props['muniCounties']), true);
    const counties = this.convertCountiesList(JSON.parse(props['counties']), false);
    const zoningGuid = !props['zoningStructure'] ? null : props['zoningStructure'].get('guid');
    const zoningStructure = !zoningGuid ? null : {
      'guid': zoningGuid, 'text': props['zoningStructure'].get('text')
    };
    this.state = {
      isGcAdmin: (props['isGcAdmin'] === 'true'),
      isCustomizationAdmin: (props['isCustomizationAdmin'] === 'true'),
      isFeatureAdmin: (props['isFeatureAdmin'] === 'true'),
      canGrantPermissions: (props['canGrantPermissions'] === 'true'),

      privateView: featureView["isOverridden"],
      privateViewDefault: featureView["maximumLevelFriendly"],

      showCountyLine: (props.customization && props.customization.showCountyLine),
      bulkUpload: (props['bulkUploadValue'] === 'true'),
      legrefLinkEnabled: (props.customization && props.customization.legrefLinkEnabled),

      customerName: props.customization.customerName,
      lastAppliedCustomerName: props['nameOverride'],

      allCounties: allCounties,
      counties: counties,
      lastAppliedCounties: counties,
      municipalCounties: municipalCounties,

      eReaderSubscribers: props['eReaderSubscribers'],
      lastAppliedSubscribers: props['eReaderSubscribers'],

      isIa2Admin: (props['isIa2Admin'] === 'true'),
      ia2Value: (props['ia2Value'] === 'true'),
      ia2ImportEnabled: (props.customization && props.customization.ia2ImportEnabled),

      isCodeReviewAdmin: (props['isCodeReviewAdmin'] === 'true'),
      isMixedContentAdmin: (props['isMixedContentAdmin'] === 'true'),
      codeReviewPhase: (props.customization.codeReviewPhase),

      remoteAuthEnabled: (props['remoteAuthEnabled'] === 'true'),
      allowRemoteAuth: (props.customization && props.customization.allowRemoteAuth),

      mixedContentEnabled: (props.customization && props.customization.mixedContentEnabled),


      codifiedLawViewEnabled: (props.customization && props.customization.codifiedLawViewEnabled),

      apiAdminEnable: props['apiAdminEnable'] === 'true',
      apiKeyView: props['apiKeyView'] === 'true',
      apiKeyCreate: props['apiKeyCreate'] === 'true',
      apiKeyModify: props['apiKeyModify'] === 'true',

      contentApiAdmin: props['contentApiAdminValue'] === 'true',
      searchApiAdmin: props['searchApiAdminValue'] === 'true',
      codeApiAdmin: props['codeApiAdminValue'] === 'true',

      sidebarLabel: props.customization.sidebarLabel,
      lastAppliedSidebarLabel: props.customization.sidebarLabel,

      isEreaderAdmin: (props['isEreaderAdmin'] === 'true'),

      openZoningStructureDialog: false,
      selectedZoningStructure: zoningStructure,

      pubDocsOnlyText: props.customization.pubDocsOnlyText,
      lastAppliedPubDocsOnlyText: props.customization.pubDocsOnlyText
    };
  }

  convertCountiesList = (oldList, isOptionList) => {
    let newList = [];
    for (let i = 0; i < oldList.length; i++) {
      let county = oldList[i];
      if (isOptionList) {
        newList.push({label: (county.name + ", " + county.state), value: county.id, countyName: county.name});
      } else {
        newList.push(county.id);
      }
    }
    return newList;
  };

  handlePrivateModeClick = (event) => {
    const privateView = event.target.checked;
    this.props.setPrivateMode(privateView);
    this.setState({privateView});
  };
  handleIa2ModeClick = (event) => {
    const ia2Value = event.target.checked;
    this.props.setIa2Mode(ia2Value);
    this.setState({ia2Value});
  };
  handleIa2ImportClick = (event) => {
    const ia2ImportEnabled = event.target.checked;
    this.props.setIa2Import(ia2ImportEnabled);
    this.setState({ia2ImportEnabled});
  };
  handleMixedContentClick = (event) => {
    const mixedContentEnabled = !event.target.checked;
    this.props.setMixedContentEnabled(mixedContentEnabled);
    this.setState({mixedContentEnabled});
  };
  handleCodifiedLawsClick = (event) => {
    const codifiedLawViewEnabled = event.target.checked;
    this.props.setCodifiedLawsEnabled(codifiedLawViewEnabled);
    this.setState({codifiedLawViewEnabled});
  };
  handleAllowRemoteAuthClick = (event) => {
    const allowRemoteAuth = event.target.checked;
    this.props.setAllowRemoteAuth(allowRemoteAuth);
    this.setState({allowRemoteAuth});
  };
  handleCodeReviewPhaseChange = (event) => {
    const codeReviewPhase = event.target.value;
    this.props.setCodeReviewPhase(codeReviewPhase);
    this.setState({codeReviewPhase});
  };
  handleShowCountyLineClick = (event) => {
    const showCountyLine = event.target.checked;
    this.props.setShowCountyLine(showCountyLine);
    this.setState({showCountyLine});
  };
  handleBulkUploadClick = (event) => {
    const bulkUpload = event.target.checked;
    this.props.setBulkUpload(bulkUpload);
    this.setState({bulkUpload});
  };
  handleLegrefLinkEnableClick = (event) => {
    const legrefLinkEnabled = event.target.checked;
    this.props.setLegrefLinkEnabled(legrefLinkEnabled);
    this.setState({legrefLinkEnabled});
  };
  handleContentApiAdminClick = (event) => {
    const contentApiAdmin = event.target.checked;
    this.props.setContentApiAdmin(contentApiAdmin);
    this.setState({contentApiAdmin});
  };
  handleSearchApiAdminClick = (event) => {
    const searchApiAdmin = event.target.checked;
    this.props.setSearchApiAdmin(searchApiAdmin);
    this.setState({searchApiAdmin});
  };
  handleCodeApiAdminClick = (event) => {
    const codeApiAdmin = event.target.checked;
    this.props.setCodeApiAdmin(codeApiAdmin);
    this.setState({codeApiAdmin});
  };
  handleCustomerNameChange = (event) => {
    this.setState({customerName: event.target.value});
  };
  handleApplyCustomerNameChangeClick = () => {
    this.props.applyCustomerName(this.state.customerName);
    this.setState({lastAppliedCustomerName: this.state.customerName});
  };
  handleSidebarLabelChange = (event) => {
    this.setState({sidebarLabel: event.target.value});
  }
  handleSidebarLabelChangeClick = () => {
    this.props.setSidebarLabel(this.state.sidebarLabel);
    this.setState({lastAppliedSidebarLabel: this.state.sidebarLabel});
  }
  handlePubDocsOnlyTextChange = (event) => {
    this.setState({pubDocsOnlyText: event.target.value});
  }
  handlePubDocsOnlyTextChangeClick = () => {
    this.props.setPubDocsOnlyText(this.state.pubDocsOnlyText);
    this.setState({lastAppliedPubDocsOnlyText: this.state.pubDocsOnlyText});
  }
  handleCountiesChange = (values) => {
    this.setState({
      counties: values.filter(value => value && value.value).map(value => (value ? value.value : [])),
    });
  };
  handleApplyCountiesChangeClick = () => {
    this.props.applyCounties(this.state.counties);
    this.setState({lastAppliedCounties: this.state.counties});
  };
  handleEReaderChange = (event) => {
    this.setState({eReaderSubscribers: event.target.value});
  };
  handleEReaderReset = () => {
    this.setState({eReaderSubscribers: this.state.lastAppliedSubscribers});
  };
  handleApplyEReaderClick = () => {
    this.props.applyEReader(this.state.eReaderSubscribers, this.eReaderOverlay);
    this.setState({lastAppliedSubscribers: this.state.eReaderSubscribers});
  };
  handleApplySendAllEReaderClick = () => {
    this.props.applySendAllEReader(this.state.eReaderSubscribers, this.eReaderOverlay);
    this.setState({lastAppliedSubscribers: this.state.eReaderSubscribers});
  };
  handleApplySendNewEReaderClick = () => {
    this.props.applySendNewEReader(this.state.eReaderSubscribers, this.eReaderOverlay);
    this.setState({lastAppliedSubscribers: this.state.eReaderSubscribers});
  };

  setMapUrl = (e) => {
    const {setMapUrl} = this.props;
    setMapUrl(e.target.value);
  };

  setDisplayDate = (e) => {
    const {setDisplayDate} = this.props;
    setDisplayDate(e.target.value);
  };

  openReferenceSelect = () => {
    this.setState({openZoningStructureDialog: true});
  };

  handleReferenceSelectKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.openReferenceSelect();
    }
  };

  closeReferenceSelect = () => {
    this.setState({openZoningStructureDialog: false});
  };

  onReferenceSelectSubmit = (reference) => {
    this.setState({selectedZoningStructure: reference});
    this.closeReferenceSelect();
  };

  clearZoningChapter = () => {
    this.setState({selectedZoningStructure: null});
  };

  updateZoningChapter = () => {
    const {selectedZoningStructure} = this.state;
    const zoningStructureGuid = (selectedZoningStructure === null ? null : selectedZoningStructure.guid);

    // Make call to update the backend
    const {setZoningChapter} = this.props;
    setZoningChapter(zoningStructureGuid);
  };

  render() {
    const { customerLevel, mapUrl, oldMapUrl, isDisplayDateAdmin, displayDate, oldDisplayDate,
      zoningStructure, zoningStructureLoading, isPubDocsOnly } = this.props;
    const { isCustomizationAdmin, isFeatureAdmin, canGrantPermissions, privateView, privateViewDefault, isIa2Admin,
      isCodeReviewAdmin, isMixedContentAdmin, ia2Value, ia2ImportEnabled, mixedContentEnabled, codifiedLawViewEnabled,
      codeReviewPhase, showCountyLine, bulkUpload, apiAdminEnable, contentApiAdmin, searchApiAdmin, codeApiAdmin,
      customerName, lastAppliedCustomerName, allCounties, counties, lastAppliedCounties, municipalCounties,
      eReaderSubscribers, lastAppliedSubscribers, remoteAuthEnabled, allowRemoteAuth, sidebarLabel,
      lastAppliedSidebarLabel, pubDocsOnlyText, lastAppliedPubDocsOnlyText, isEreaderAdmin, openZoningStructureDialog,
      selectedZoningStructure, legrefLinkEnabled
    } = this.state;
    const previousZoningGuid = zoningStructure === null ? null : zoningStructure.guid;
    const eReaderChanged = eReaderSubscribers !== lastAppliedSubscribers;
    let countiesPreviouslyClear = true;
    for (let i = 0; i < lastAppliedCounties.length; i++) {
      for (let j = 0; j < allCounties.length; j++) {
        if (allCounties[j].value === lastAppliedCounties[i]) {
          countiesPreviouslyClear = false;
          break;
        }
      }
    }
    let muniCountyName = "No County";
    if (municipalCounties.length !== 0 && municipalCounties[0].countyName.length !== 0) {
      muniCountyName = "";
      for (let i=0; i<municipalCounties.length; i++) {
        muniCountyName = muniCountyName + " " + municipalCounties[i].label;
      }
    }
    return (
      <BoxedSection title="GC Admin">
        <If test={isFeatureAdmin || isCustomizationAdmin || isDisplayDateAdmin}>
          <div className="gcAdminSettings-Code gcAdminSettings-section">
            <h3>Code Settings</h3>
            <If test={isMixedContentAdmin}>
              <FormControlLabel
                className="gcAdminSettingsList-CheckboxLabel"
                control={
                  <Checkbox
                    id="adminSettingsPage-hideMixedContent"
                    checked={!mixedContentEnabled}
                    onChange={this.handleMixedContentClick}
                  />
                }
                label="Hide Mixed Types in Schemes"
              />
            </If>
            <If test={isFeatureAdmin || isCustomizationAdmin}>
              <ul className="gcAdminSettingsList">
                <If test={isFeatureAdmin}>
                  <li>
                    <FormControlLabel
                      className="gcAdminSettingsList-CheckboxLabel"
                      control={
                        <Checkbox
                          id="adminSettingsPage-privateMode"
                          checked={privateView}
                          onChange={this.handlePrivateModeClick}
                        />
                      }
                      label={
                        <span>Private Mode (The current eCode level is <strong>{customerLevel}</strong> with default view permission of <strong>{privateViewDefault}</strong>).</span>
                      }
                    />
                  </li>
                </If>
                <If test={isCustomizationAdmin}>
                  <li>
                    <FormControlLabel
                      className="gcAdminSettingsList-CheckboxLabel"
                      control={
                        <Checkbox
                          id="adminSettingsPage-showCountyLine"
                          checked={showCountyLine}
                          onChange={this.handleShowCountyLineClick}
                        />
                      }
                      label="Show County Line in Banner Area"
                    />
                  </li>
                </If>
              </ul>
            </If>
            <If test={isCustomizationAdmin}>
              <div>
                <TextField
                  id="adminSettingsPage-customerName"
                  className="adminSettingsPage-customerName"
                  label="Code Name Override"
                  value={customerName}
                  onChange={this.handleCustomerNameChange}
                  helperText="** Publishing production will need to make the same code name change in the customer's XML in order for this change to persist after a supplement."
                  margin="normal"
                  fullWidth={true}
                />
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.handleApplyCustomerNameChangeClick}
                    disabled={customerName === lastAppliedCustomerName}
                    id="adminSettingsPage-customerNameApply"
                  >
                    Apply Code Name Override
                  </PrimaryButton>
                </ButtonGroup>
              </div>
            </If>
            <If test={isDisplayDateAdmin}>
              <div>
                <TextField
                  id="adminSettingsPage-displayDate"
                  className="adminSettingsPage-displayDate"
                  label="Code Display Date"
                  value={displayDate || ""}
                  onChange={this.setDisplayDate}
                  helperText="The text displayed at the top of the code, letting users know the last time the code was updated"
                  margin="normal"
                  fullWidth={true}
                />
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.props.updateDisplayDate}
                    id="adminSettingsPage-displayDateApply"
                    disabled={displayDate === oldDisplayDate}
                  >
                    Update
                  </PrimaryButton>
                </ButtonGroup>
              </div>
            </If>
            <If test={isCustomizationAdmin}>
              <div>
                <h5>County: {muniCountyName}</h5>
                <FormControl id="adminSettingsPage-countiesContainer" className="formControl">
                  <ReactSelect
                    multi={true}
                    clearable={false}
                    id="adminSettingsPage-counties"
                    className="adminSettingsPage-counties selectField"
                    name="adminSettingsPage-counties"
                    value={counties}
                    options={allCounties}
                    onChange={this.handleCountiesChange}
                    placeholder=""
                    textFieldProps={{
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                  />
                  <label id="adminSettingsPage-countiesLabel" htmlFor="adminSettingsPage-counties" className="Select-label">Additional Counties</label>
                </FormControl>
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.handleApplyCountiesChangeClick}
                    disabled={counties === lastAppliedCounties || (counties.length === 0 && countiesPreviouslyClear)}
                    id="adminSettingsPage-countiesApply"
                  >
                    Apply Counties Change
                  </PrimaryButton>
                </ButtonGroup>
              </div>
              <div id="adminSettingsPage-mapUrl-container">
                <TextField
                  id="adminSettingsPage-mapUrl"
                  className="adminSettingsPage-mapUrl"
                  label="Zoning Map URL"
                  value={mapUrl || ""}
                  onChange={this.setMapUrl}
                  helperText="URL where the Zoning Map can be accessed online"
                  margin="normal"
                  fullWidth={true}
                />
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.props.updateMapUrl}
                    id="adminSettingsPage-mapUrlApply"
                    disabled={mapUrl === oldMapUrl}
                  >
                    Update
                  </PrimaryButton>
                </ButtonGroup>
              </div>
              <div id="sidebarLabel">
                <TextField
                  id="adminSettingsPage-sidebarLabel"
                  className="adminSettingsPage-sidebarLabel"
                  label="Change Sidebar Label"
                  value={sidebarLabel}
                  onChange={this.handleSidebarLabelChange}
                  helperText="Change the sidebar label, default Code"
                  margin="normal"
                  fullWidth={true}
                />
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.handleSidebarLabelChangeClick}
                    disabled={sidebarLabel === lastAppliedSidebarLabel}
                    id="adminSettingsPage-sidebarLabelApply"
                  >
                    Update
                  </PrimaryButton>
                </ButtonGroup>
              </div>
              <If test={isPubDocsOnly}>
                <div id="pubDocsOnlyText">
                  <TextField
                    id="adminSettingsPage-pubDocsOnlyText"
                    className="adminSettingsPage-pubDocsOnlyText"
                    label="PubDocs Standalone Text"
                    value={pubDocsOnlyText}
                    onChange={this.handlePubDocsOnlyTextChange}
                    helperText="Change the text displayed for this PubDocs Standalone code"
                    margin="normal"
                    fullWidth={true}
                  />
                  <ButtonGroup>
                    <PrimaryButton
                      onClick={this.handlePubDocsOnlyTextChangeClick}
                      disabled={pubDocsOnlyText === lastAppliedPubDocsOnlyText}
                      id="adminSettingsPage-pubDocsOnlyTextApply"
                    >
                      Update
                    </PrimaryButton>
                  </ButtonGroup>
                </div>
              </If>
            </If>
          </div>
        </If>
        <If test={isFeatureAdmin && canGrantPermissions}>
          <div className="gcAdminSettings-Feature gcAdminSettings-section">
            <h3>Feature Settings</h3>
            <ul className="gcAdminSettingsList">
              <li>
                <FormControlLabel
                  className="gcAdminSettingsList-CheckboxLabel"
                  control={
                    <Checkbox
                      id="adminSettingsPage-codifiedLaws"
                      checked={codifiedLawViewEnabled}
                      onChange={this.handleCodifiedLawsClick}
                    />
                  }
                  label="Enable Past Laws"
                />
              </li>
              <li>
                <FormControlLabel
                  className="gcAdminSettingsList-CheckboxLabel"
                  control={
                    <Checkbox
                      id="adminSettingsPage-bulkUpload"
                      checked={bulkUpload}
                      onChange={this.handleBulkUploadClick}
                    />
                  }
                  label="PubDocs Bulk Uploader"
                />
              </li>
              <li>
                <FormControlLabel
                  className="gcAdminSettingsList-CheckboxLabel"
                  control={
                    <Checkbox
                      id="adminSettingsPage-legrefLink"
                      checked={legrefLinkEnabled}
                      onChange={this.handleLegrefLinkEnableClick}
                    />
                  }
                  label="Enable Legref Links"
                />
              </li>
              <If test={apiAdminEnable}>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-contentApi"
                        checked={contentApiAdmin}
                        onChange={this.handleContentApiAdminClick}
                      />
                    }
                    label="Content API"
                  />
                </li>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-searchApi"
                        checked={searchApiAdmin}
                        onChange={this.handleSearchApiAdminClick}
                      />
                    }
                    label="Search API"
                  />
                </li>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-codeApi"
                        checked={codeApiAdmin}
                        onChange={this.handleCodeApiAdminClick}
                      />
                    }
                    label="Code Finder API"
                  />
                </li>
              </If>
            </ul>
          </div>
        </If>
        <If test={isIa2Admin || remoteAuthEnabled || isCodeReviewAdmin}>
          <div className="gcAdminSettings-CodeReview gcAdminSettings-section">
            <h3>Code Review Settings</h3>
            <ul className="gcAdminSettingsList">
              <If test={isIa2Admin}>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-ia2Mode"
                        checked={ia2Value}
                        onChange={this.handleIa2ModeClick}
                      />
                    }
                    label="IA2 Mode"
                  />
                </li>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-ia2ImportDisable"
                        checked={ia2ImportEnabled}
                        onChange={this.handleIa2ImportClick}
                      />
                    }
                    label="Enable IA2 Import"
                  />
                </li>
              </If>
              <If test={remoteAuthEnabled}>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-allowRemoteAuth"
                        checked={allowRemoteAuth}
                        onChange={this.handleAllowRemoteAuthClick}
                      />
                    }
                    label="Manage Users On eCode360®"
                  />
                </li>
              </If>
            </ul>
            <If test={isCodeReviewAdmin}>
              <div>
                <FormControl className="formSelectControl" margin="normal">
                  <InputLabel id="adminSettingsPage-codeReviewPhase-label" htmlFor="adminSettingsPage-codeReviewPhase">
                    Code Review Phase
                  </InputLabel>
                  <Select
                    value={codeReviewPhase}
                    onChange={this.handleCodeReviewPhaseChange}
                    inputProps={{
                      id: 'adminSettingsPage-codeReviewPhase',
                      name: 'codeReviewPhase'
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="Manuscript">Manuscript</MenuItem>
                    <MenuItem value="Draft">Draft</MenuItem>
                    <MenuItem value="Second Draft">Second Draft</MenuItem>
                    <MenuItem value="Final Pending Adoption">Final Pending Adoption</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </If>
          </div>
        </If>
        <If test={isEreaderAdmin}>
          <div className="gcAdminSettings-eReader gcAdminSettings-section">
            <h3>eReader Settings</h3>
            <div>
              <TextField
                id="adminSettingsPage-manageEreader"
                label="Manage eReader Subscriptions"
                multiline
                rowsMax="6"
                fullWidth={true}
                value={eReaderSubscribers}
                onChange={this.handleEReaderChange}
                margin="normal"
              />
              <ButtonGroup>
                <Button id="adminSettingsPage-manageEreaderReset" onClick={this.handleEReaderReset} disabled={!eReaderChanged}>Reset</Button>
                <Button id="adminSettingsPage-manageEreaderApply" onClick={this.handleApplyEReaderClick} disabled={!eReaderChanged}>Apply</Button>
                <Button id="adminSettingsPage-manageEreaderApplySendAll" onClick={this.handleApplySendAllEReaderClick} disabled={!eReaderChanged}>Apply & Send to all users</Button>
                <PrimaryButton id="adminSettingsPage-manageEreaderApplySendNew" onClick={this.handleApplySendNewEReaderClick} disabled={!eReaderChanged}>Apply & Send to new users</PrimaryButton>
              </ButtonGroup>
            </div>
            <div id="eReaderOverlay" style={{display: 'none'}} ref={(div) => { this.eReaderOverlay = div; }}>
              <div className="lds-dual-ring"/>
            </div>
          </div>
        </If>
        <div className="gcAdminSettings-section">
          <h3>Taxonomy</h3>
          <SelectCodeReferenceDialogContainer
            isOpen={openZoningStructureDialog}
            onSubmit={this.onReferenceSelectSubmit}
            onClose={this.closeReferenceSelect}
          />
          <div id="adminSettingsPage-zoningChapter-container">
            <TextField
              id="adminSettingsPage-zoningChapter"
              className="adminSettingsPage-zoningChapter"
              label="Zoning Chapter"
              value={selectedZoningStructure === null || selectedZoningStructure.text === null ? '' : selectedZoningStructure.text}
              onClick={this.openReferenceSelect}
              onKeyDown={this.handleReferenceSelectKeyPress}
              margin="normal"
              fullWidth={true}
              InputProps={{readOnly: true}}
            />
            <ButtonGroup>
              <PrimaryButton
                onClick={this.clearZoningChapter}
                id="adminSettingsPage-zoningChapterClear"
                disabled={zoningStructureLoading || selectedZoningStructure === null}
              >
                Clear
              </PrimaryButton>
              <PrimaryButton
                onClick={this.updateZoningChapter}
                id="adminSettingsPage-zoningChapterApply"
                disabled={zoningStructureLoading || ((selectedZoningStructure === null && previousZoningGuid === null)
                  || (selectedZoningStructure !== null && selectedZoningStructure.guid === previousZoningGuid))}
              >
                Update
              </PrimaryButton>
            </ButtonGroup>
          </div>
        </div>
      </BoxedSection>
    );
  }
}

export default GeneralCodeAdminSection;
