import * as PropTypes from "prop-types";
import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {setSortOrderAndGetResults} from "../actions";

import {getSortOrder, isSearchLoading} from "../selectors";
import {toJS} from "../../common/utils";
import SortComponent from "../components/SortComponent";

const mapStateToProps = createStructuredSelector({
  sortOrder: getSortOrder,
  isSearchLoading: isSearchLoading
});

const mapDispatchToProps = (dispatch) => ({
  setSortOrder: (sortOrder) => {
    dispatch(setSortOrderAndGetResults(sortOrder));
  }
});

const SortContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(SortComponent);
SortContainer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  hasNaturalOrderSort: PropTypes.bool.isRequired,
  hasDateSort: PropTypes.bool.isRequired
};
export default SortContainer;