import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {hasIa2CompletePermission, hasIa2FinalizePermission, hasIa2PrintPermission, hasIa2StatusReportPermission} from "../../common/selectors";

import {toJS} from "../../common/utils";
import {checkAnalysis, clearAnalysisMessage, completeAnalysis, displayMoreQuestions, finalizeAnalysis,
  setCollapsed, setExpanded} from "../actions";
import Analysis from "../components/Analysis";
import {getAnalysis, getDisplayedQuestionCount, getDisplayedQuestionIds, getQuestions} from "../selectors";

const mapStateToProps = createStructuredSelector({
  analysis: getAnalysis,
  questions: getQuestions,
  displayedQuestionCount: getDisplayedQuestionCount,
  displayedQuestionIds: getDisplayedQuestionIds,
  hasFinalizePermission: hasIa2FinalizePermission,
  hasCompletePermission: hasIa2CompletePermission,
  hasPrintPermission: hasIa2PrintPermission,
  hasStatusReportPermission: hasIa2StatusReportPermission
});

const mapDispatchToProps = (dispatch) => ({
  checkAnalysis: (analysisId) => {
    dispatch(checkAnalysis(analysisId));
  },
  clearAnalysisMessage: (analysisId) => {
    dispatch(clearAnalysisMessage(analysisId));
  },
  completeAnalysis: (analysisId) => {
    dispatch(completeAnalysis(analysisId));
  },
  displayMoreQuestions: () => {
    dispatch(displayMoreQuestions());
  },
  expandQuestion: (questionId) => {
    dispatch(setExpanded(questionId));
  },
  collapseQuestion: (questionId) => {
    dispatch(setCollapsed(questionId));
  },
  finalizeAnalysis: (analysisId) => {
    dispatch(finalizeAnalysis(analysisId));
  },
});

const AnalysisContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(Analysis);
AnalysisContainer.propTypes = {
  analysisId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
export default AnalysisContainer;