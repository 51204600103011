/*
 * Copyright 2018 General Code
 */

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {DialogActions} from "../../common/components/dialog";
import IconButton from "../../common/components/IconButton";
import {Add as AddIcon} from "../../common/components/icons";
import {List, ListItem, ListItemText} from "../../common/components/list";
import Button from "../../common/components/Button";
import {If} from "../../common/containers";
import './PubDocsSidebar.css';
import PubDocTypeEditDialogContainer from "../containers/PubDocTypeEditDialogContainer";
import Link from '@material-ui/core/Link';
import ConfirmDialog from "../../common/components/ConfirmDialog";
import {Menu, MenuItem} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

class PubDocsSidebar extends Component {

  static propTypes = {
    custId: PropTypes.string,
    pubDocTypes: PropTypes.array,
    pubDocTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasPubDocTypeAddPermission: PropTypes.bool.isRequired,
    setEditOpen: PropTypes.func.isRequired,
    typeEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasPubDocTypeDeletePermission: PropTypes.bool.isRequired,
    hasPubDocTypeEditPermission: PropTypes.bool.isRequired,
    pubDocsSidebarName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      typeListDialogOpen: false
    };
  }

  onOpen = (event, typeId, typeTitle) => {
    this.setState({anchorEl: event.currentTarget});
    this.setState({currentTypeId: typeId});
    this.setState({currentTypeTitle: typeTitle});
  };

  onClose = () => {
    this.setState({anchorEl: null});
  };

  setEditDialogOpen = (typeId) => () => {
    this.setState({anchorEl: null});
    this.props.setEditOpen(true, typeId);
  };

  openAddTypeDialog = () => {
    this.props.setEditOpen(true);
  };

  closeAddTypeDialog = () => {
    this.props.setEditOpen(false);
  };

  handleOpenDelete = () => {
    this.setState({"open": true});
    this.setState({anchorEl: null});
  }

  handleCloseDelete = () => {
    this.setState({"open": false});
  }

  setTypeListDialogOpen = (open) => () => {
    this.setState({typeListDialogOpen: open});
  };

  deletePubDocType = () => {
    const {custId, deletePubDocType} = this.props;
    const {currentTypeId} = this.state;
    deletePubDocType(custId, currentTypeId);
    this.setState({anchorEl: null});
    this.setState({"open": false});
  };

  hoverEnter = (typeId) => () => {
    this.setState({hiddenId: typeId});
  }

  hoverLeave = () => {
    this.setState({hiddenId: null});
  }

  render() {
    const {custId, hasPubDocTypeAddPermission, pubDocTypeId, pubDocTypes, hasPubDocTypeDeletePermission,
      hasPubDocTypeEditPermission, pubDocsSidebarName, hasPubDocPrivateViewPermission} = this.props;
    const {typeListDialogOpen, anchorEl, currentTypeId, currentTypeTitle, open, hiddenId} = this.state;
    const selectedIndex = pubDocTypes.findIndex(pdt => pdt.id === pubDocTypeId);
    return (
      <div id="pubDocsSidebar">
        <div className="sidebarSpacer lineTitle customerTitleColor">
          <div className="lineTitleBeforeLine lineTitleLine"> </div>
          <div className="lineTitleTitle">
            {pubDocsSidebarName}
            <If test={hasPubDocTypeAddPermission}>
              <IconButton aria-label="Add a new public document type" className="addPubDocTypeButton" onClick={this.openAddTypeDialog}>
                <AddIcon title="Add a new public document type"/>
              </IconButton>
            </If>
          </div>
          <div className="lineTitleAfterLine lineTitleLine"> </div>
        </div>
        <div className="sidebar">
          {pubDocTypes.map((type, index) => (
            <If key={type.id} test={index <= 5 || (index === 6 && pubDocTypes.length === 7) || index === selectedIndex}>
              <div className="sideButton" onMouseEnter={this.hoverEnter(type.id)} onMouseLeave={this.hoverLeave} style={{display: "flex", paddingLeft: 0}}>
                <a href={"/" + custId + "/documents/" + encodeURIComponent(type.title)}
                  className={"sideButton withCount" + (type.id === pubDocTypeId ? " selectedSide" : "")}
                  title={type.title}
                >
                  {(type.id === pubDocTypeId) && <div id="sideSelectionIndicator"> </div>}
                  <span className="sideButtonTitle">{type.title}</span>&nbsp;<span className="sideButtonCount">({hasPubDocPrivateViewPermission? type.count : type.count - type.privateCount})</span>
                  {(type.id === pubDocTypeId) && <div id="sideArrowShadow"> </div>}
                  {(type.id === pubDocTypeId) && <div id="sideArrow"> </div>}
                </a>
                <If test={hiddenId === type.id && (hasPubDocTypeEditPermission || hasPubDocTypeDeletePermission)}>
                  <IconButton style={{marginLeft: "auto"}} onClick={e => this.onOpen(e,type.id, type.title)}>
                    <MoreVertIcon title="Edit this pub doc type"/>
                  </IconButton>
                </If>
              </div>
              <Menu
                disableAutoFocusItem
                anchorEl={anchorEl}
                className="pubDocTypeMenu"
                open={Boolean(anchorEl)}
                onClose={this.onClose}
              >
                <If test={hasPubDocTypeEditPermission}>
                  <MenuItem onClick={this.setEditDialogOpen(currentTypeId)}>
                    Edit Type
                  </MenuItem>
                </If>
                <If test={hasPubDocTypeDeletePermission}>
                  <MenuItem onClick={this.handleOpenDelete}>
                    Delete Type
                  </MenuItem>
                </If>
              </Menu>
            </If>
          ))}
          <If test={pubDocTypes.length > 7}>
            <a onClick={this.setTypeListDialogOpen(true)} className="sideButton" title="See all public document types">
              <span className="sideButtonTitle">Show more...</span>
            </a>
          </If>
        </div>
        <PubDocTypeEditDialogContainer
          onCancel={this.closeAddTypeDialog}
        />
        <Dialog id="doc-type-list-dialog"
          open={typeListDialogOpen}
          onClose={this.setTypeListDialogOpen(false)}
          aria-labelledby="confirm-dialog"
          maxWidth={"md"}
          fullWidth={true}
        >
          <DialogTitle>Public Document Types</DialogTitle>
          <DialogContent>
            <List className="pubDocTypes">
              {pubDocTypes.map((type) => (
                <ListItem key={type.id} button component={Link}
                  href={"/" + custId + "/documents/" + encodeURIComponent(type.title)} title={type.title}  className="pubDocType"
                >
                  <ListItemText primary={type.title} secondary={type.description} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.setTypeListDialogOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <ConfirmDialog
          title="Delete Type?"
          open={Boolean(open)}
          onCancel={this.handleCloseDelete}
          onConfirm={this.deletePubDocType}
        >
          Are you sure you want to delete Type named: {currentTypeTitle}?
        </ConfirmDialog>
      </div>
    );
  }
}
export default PubDocsSidebar;