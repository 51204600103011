import React from 'react'
import PropTypes from 'prop-types'

import './Message.css';

class Message extends React.Component {
  render() {
    const id = this.props.id;
    const body = React.cloneElement(this.props.body, {key: id}, React.Children
      .map(this.props.body.props.children, child => child.props ? React.cloneElement(child, {key: id}) : child));

    return (
      <div key={id} className={this.props.type + ' message'} onClick={this.props.onClick}>{body}</div>
    )
  }
}

Message.propTypes = {
  type: PropTypes.string.isRequired,
  body: PropTypes.element.isRequired
};

export default Message;