import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonGroup from "../../common/components/ButtonGroup";
import ConfirmDialog from "../../common/components/ConfirmDialog";
import IconButton from "../../common/components/IconButton";
import {Clear as ClearIcon} from "../../common/components/icons";
import PrimaryButton from "../../common/components/PrimaryButton";
import TextField from "../../common/components/TextField";
import If from "../../common/containers/If";

class QuestionNoteDialog extends React.Component {
  constructor(props) {
    super(props);
    const {questionNote} = this.props;
    // Don't call this.setState() here!
    this.state = {
      responseValue: (questionNote && questionNote.content) ? questionNote.content : "",
      files: [],
      confirmOpen: false,
      issueDialogOpen: false
    };
  }

  handleResponseTextChange = (event) => {
    const value = event.target.value;
    this.setState({responseValue: value});
  };

  handleResponseFileUpload =  (event) => {
    const file = event.target.files[0];
    let files = this.state.files;
    let newFile = {questionFile: {originalFilename: file.name}, localfile: file, delete: false};
    files.push(newFile);
    this.setState({files: files});
  };

  handleResponseFileDelete = (file) => () => {
    let files = this.state.files;
    if (file.localfile) {
      files = files.filter((f) => f !== file);
    } else {
      file.delete = true;
    }
    this.setState({files: files});
  };

  onDialogOpen = () => {
    const {questionNote} = this.props;
    let files = [];
    if (questionNote) {
      questionNote.noteFiles.forEach((f) => {
        let file = {questionFile: f, localfile: null, delete: false};
        files.push(file);
      });
    }
    this.setState({ responseValue: (questionNote && questionNote.content) ? questionNote.content : "", files: files});
  };

  cancelDelete = () => {
    this.setState({confirmOpen: false});
  };

  confirmDelete = () => {
    const {onDelete} = this.props;
    this.cancelDelete();
    onDelete();
  };

  handleDeleteClick = () => {
    this.setState({confirmOpen: true});
  };

  handleIssueDialogClose = () => {
    this.setState({issueDialogOpen: false});
  };

  handleSaveClick = () => {
    const {onConfirm} = this.props;
    if (this.state.responseValue.length > 0) {
      onConfirm(this.state.responseValue, this.state.files);
    } else {
      this.setState({issueDialogOpen: true});
    }
  };

  render() {
    const {open, title, questionId, questionNote, ia2FileTypes, isFinalized, onCancel} = this.props;
    return (
      <Dialog
        open={open}
        onClose={() => onCancel()}
        onEnter={this.onDialogOpen}
        aria-labelledby="confirm-dialog"
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle id="note-dialog">Note on Question: {title}</DialogTitle>
        <DialogContent>
          <ConfirmDialog
            title="Delete Note?"
            open={this.state.confirmOpen}
            onCancel={this.cancelDelete}
            onConfirm={this.confirmDelete}
          >
            Are you sure you want to delete this note?
          </ConfirmDialog>
          <Dialog open={this.state.issueDialogOpen} maxWidth={"sm"} onClose={this.handleIssueDialogClose}>
            <DialogContent>
              <div className="analysisMessage">Note cannot be blank.</div>
            </DialogContent>
            <DialogActions>
              <ButtonGroup>
                <PrimaryButton type="submit" onClick={this.handleIssueDialogClose}>OK</PrimaryButton>
              </ButtonGroup>
            </DialogActions>
          </Dialog>
          <TextField
            id={"note_text_field_" + questionId}
            className="optionText"
            variant="outlined"
            rows={4}
            disabled={isFinalized}
            value={(!this.state.responseValue) ? "" : this.state.responseValue}
            onChange={this.handleResponseTextChange}
            multiline
          />
          <If test={!isFinalized}>
            <div className="optionFileContainer">
              {!ia2FileTypes ?
                <input
                  id={"note_field_" + questionId}
                  className="optionFile"
                  type="file"
                  name="responseFile"
                  onChange={this.handleResponseFileUpload}
                /> :
                <input
                  id={"note_field_" + questionId}
                  className="optionFile"
                  type="file"
                  name="responseFile"
                  onChange={this.handleResponseFileUpload}
                  accept={ia2FileTypes}
                />
              }
              <label htmlFor={"note_field_" + questionId}>
                <Button variant="contained" color="primary" component="span">
                  Upload
                </Button>
              </label>
            </div>
          </If>
          <div className="optionFileList">
            {this.state.files.map((file, i) => (
              <If key={i} test={!file.delete}>
                <div>
                  {!file.localfile ?
                    <a href={"/user/file/" + file.questionFile.filePath} target="_blank" rel="noopener noreferrer">{file.questionFile.originalFilename}</a> :
                    <a href={URL.createObjectURL(file.localfile)} target="_blank" rel="noopener noreferrer">{file.localfile.name}</a>}
                  <If test={!isFinalized}>
                    <IconButton aria-label="Remove file" onClick={this.handleResponseFileDelete(file)}>
                      <ClearIcon />
                    </IconButton>
                  </If>
                </div>
              </If>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <If test={!isFinalized && questionNote}>
            <Button
              variant="contained"
              onClick={this.handleDeleteClick}
              color="default"
            >
              Delete
            </Button>
          </If>
          <Button
            variant="contained"
            onClick={() => onCancel()}
            color={isFinalized ? "primary" : "default"}
          >
            {isFinalized ? "Ok" : "Cancel"}
          </Button>
          <If test={!isFinalized}>
            <Button
              variant="contained"
              onClick={this.handleSaveClick}
              color="primary"
            >
              Save
            </Button>
          </If>
        </DialogActions>
      </Dialog>
    );
  }
}

export default QuestionNoteDialog;