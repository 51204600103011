import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {getLaws, getPastLaws} from "../selectors";
import {startEditing} from "../actions";
import {toJS} from "../../common/utils";
import LawTable from '../components/LawTable';


const mapStateToProps = createStructuredSelector({
  laws: getLaws,
  pastLaws: getPastLaws
});


const mapDispatchToProps = (dispatch) => ({
  startEditing: (documentId) => {
    return dispatch(startEditing(documentId));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(LawTable);

