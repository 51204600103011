/*
 * Copyright 2018 General Code
 */

import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import './Title.css';
import TextField from '../../common/components/TextField';
import { withStyles } from "../../common/theme";

const style = theme => ({
  root: {
    flex: '1 1 auto',
  },
  marginDense: {
    margin: 0,
  },
  labelDisabled: {
    display: 'none',
  },
  input: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 0,
    //otherwise the title jumps when you start typing
    minHeight: 30,
  },
  inputElement: {
    marginBottom: 0,
    paddingBottom: 0,
    '&:disabled': {
      color: theme.palette.secondary.dark,
    },
    //Otherwise we get a scrollbar if there is no title set yet
    minHeight: 30,
  },
});

const extractText = html => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.innerText;
};

const DisabledTitle = (rawTitle) => ({value, className}) => <div className={`noteTitleComponentInputInactive ${className}`} dangerouslySetInnerHTML={{__html: rawTitle}} />;

const Title = ({title, updateTitle, editing = false, type = "text", classes}) => (
  <div className={classNames({
    [classes.root]: true,
    'noteTitleComponentWrapper': true,
    'noteTitleComponentInactiveWrapper': !editing,
  })}>
    <TextField
      className={`noteTitleComponent`}
      fullWidth={true}
      multiline={true}
      margin="dense"
      type={type}
      placeholder="Title..."
      value={extractText(title) || ''}
      InputProps={{
        inputComponent: editing ? "input" : DisabledTitle(title),
        disableUnderline: !editing,
        classes: {
          root: `${classes.input} noteTitleComponentInput`,
          input: `${classes.inputElement} noteTitleComponentInputElement`,
        },
      }}
      disabled={!editing}
      onChange={updateTitle}
      autoFocus
    />
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  updateTitle: PropTypes.func.isRequired,
};

export default withStyles(style)(Title);
