import { connect } from 'react-redux'
import { toJS } from '../../common/utils/index'
import {removeMessage} from '../actions'
import MessageList from '../components/MessageList'
import {messagesSelector} from '../reducers'

const mapStateToProps = state => {
  return {
    messages: messagesSelector(state)
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onRemoveClick: id => {
      dispatch(removeMessage(id))
    }
  }
};

const MessageQueue = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(MessageList));

export default MessageQueue
