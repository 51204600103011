import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import If from "../containers/If";

const ConfirmDialog = (props) => {
  const { title, children, open, onCancel, onConfirm, maxWidth, fullWidth, ...otherProps } = props;
  return (
    <Dialog { ...otherProps }
      open={open}
      onClose={() => { if (!!onCancel) { onCancel(); } else { onConfirm(); } }}
      maxWidth={maxWidth ? maxWidth : 'sm'}
      fullWidth={!!fullWidth}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <If test={!!onCancel}>
          <Button
            variant="contained"
            onClick={() => onCancel()}
            color="default"
          >
            Cancel
          </Button>
        </If>
        <Button
          variant="contained"
          onClick={() => onConfirm()}
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;