import {createAction} from "redux-actions";
import {clearReferenceMatches} from "../../notes/actions";
import {getReferenceGroup} from "../ReferenceGroupFactory";
import {getJson} from "../utils";
import {registerError} from "./index";

const clearActiveReferenceNode = createAction('ACTIVE_REF_NODE/CLEAR/SUCCESS', () => ({}));

const fetchRootReferenceNodesStart = createAction('REF_NODES/FETCH/START', () => ({}));
const fetchRootReferenceNodesSuccess = createAction('REF_NODES/FETCH/SUCCESS', (nodes) => ({nodes}));
const fetchRootReferenceNodesFail = createAction('REF_NODES/FETCH/FAIL', (error) => ({error}));
const fetchRootReferenceNodesFinally = createAction('REF_NODES/FETCH/FINALLY', () => ({}));
const fetchRootReferenceNodes = () => async (dispatch) => {
  console.debug("Fetching root reference nodes... ");
  dispatch(fetchRootReferenceNodesStart());
  try {
    const refGroup = getReferenceGroup('code');
    const nodes = await getJson(refGroup.getRootNodesUrl());
    dispatch(fetchRootReferenceNodesSuccess(nodes.map((node, index) => ({
      ...node,
      name: refGroup.getNodeName(node),
      index: `${index}`,
      children: [],
    }))));
  } catch (error) {
    dispatch(registerError("Problem retrieving references tree", null, [], error));
    dispatch(fetchRootReferenceNodesFail(error));
  } finally {
    dispatch(fetchRootReferenceNodesFinally());
  }
};

const fetchReferenceMatchesStart = createAction('REF_MATCHES/FETCH/START', (query) => ({query}));
const fetchReferenceMatchesSuccess = createAction('REF_MATCHES/FETCH/SUCCESS', (matches) => ({matches}));
const fetchReferenceMatchesFail = createAction('REF_MATCHES/FETCH/FAIL', (query, error) => ({query, error}));
const fetchReferenceMatchesFinally = createAction('REF_MATCHES/FETCH/FINALLY', (query) => ({query}));
const fetchReferenceMatches = (query) => async (dispatch) => {
  if (!query || query.length === 0) {
    dispatch(clearReferenceMatches());
    return;
  }
  console.debug("Fetching reference matches... ", query);
  dispatch(fetchReferenceMatchesStart(query));
  try {
    const refGroup = getReferenceGroup('code');
    const matches = await getJson(refGroup.getMatchesUrl(query));
    console.debug('got results ...', matches);
    dispatch(fetchReferenceMatchesSuccess(matches));
  } catch (error) {
    dispatch(registerError("Problem retrieving matching references", null, [query], error));
    dispatch(fetchReferenceMatchesFail(query, error));
  } finally {
    dispatch(fetchReferenceMatchesFinally(query));
  }
};

const toggleReferenceNodeSuccess = createAction('REF_NODE/TOGGLE/SUCCESS', (node, toggled) => ({node, toggled}));
const fetchReferenceNodeChildrenStart = createAction('REF_NODE_CHILDREN/FETCH/START', (node) => ({node}));
const fetchReferenceNodeChildrenSuccess = createAction('REF_NODE_CHILDREN/FETCH/SUCCESS', (node, children) => ({node, children}));
const fetchReferenceNodeChildrenFail = createAction('REF_NODE_CHILDREN/FETCH/FAIL', (node, error) => ({node, error}));
const fetchReferenceNodeChildrenFinally = createAction('REF_NODE_CHILDREN/FETCH/FINALLY', (node) => ({node}));
const toggleReferenceNode = (node, toggled) => async (dispatch) => {
  console.debug("Toggling reference node... ", node, toggled);
  dispatch(toggleReferenceNodeSuccess(node, toggled));
  if (toggled && node.children && node.children.length === 0) {
    console.debug("Fetching reference node children... ", node);
    dispatch(fetchReferenceNodeChildrenStart(node));
    try {
      const refGroup = getReferenceGroup('code');
      const children = await getJson(refGroup.getNodeChildrenUrl(node));
      dispatch(fetchReferenceNodeChildrenSuccess(node, children.map((child, index) => ({
        ...child,
        name: refGroup.getNodeName(child),
        index: `${node.index}-${index}`,
        children: ['document', 'section'].indexOf(child.type) >= 0 ? null : [],
      }))));
    } catch (error) {
      dispatch(registerError("Problem expanding reference item", null, [node.title, toggled], error));
      dispatch(fetchReferenceNodeChildrenFail(node, error));
    } finally {
      dispatch(fetchReferenceNodeChildrenFinally(node));
    }
  }
};

export {
  clearActiveReferenceNode,
  fetchRootReferenceNodes,
  fetchRootReferenceNodesSuccess,
  fetchReferenceMatches,
  fetchReferenceMatchesStart,
  fetchReferenceMatchesSuccess,
  fetchReferenceMatchesFail,
  fetchReferenceMatchesFinally,
  toggleReferenceNode,
  toggleReferenceNodeSuccess,
  fetchReferenceNodeChildrenSuccess,
};
