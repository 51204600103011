import React, {Component} from 'react';
import IconButton from "../../common/components/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {Button, Checkbox, DialogActions, DialogContent, DialogTitle, Menu, MenuItem} from "@material-ui/core";
import * as PropTypes from 'prop-types';
import ConfirmDialog from "../../common/components/ConfirmDialog";
import Dialog from "@material-ui/core/Dialog";
import {If} from "../../common/containers";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import './PubDocCategory.css';

class PubDocCategoryLabel extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    hasPubDocCategoryAdminPermission: PropTypes.bool.isRequired,
    hasPubDocPrivateViewPermission: PropTypes.bool.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    selectedDocs: PropTypes.array.isRequired,
    deleteCategory: PropTypes.func.isRequired,
    setCategoryEditOpen: PropTypes.func.isRequired,
    setDocumentEditOpen: PropTypes.func.isRequired,
    setDocumentChecked: PropTypes.func.isRequired,
    setDocumentUnchecked: PropTypes.func.isRequired
  }

  state = {
    currentCategoryId: null,
    anchorElCategory: null,
    openCategoryDelete: false,
    deleteErrorOpen: false
  }

  onCategoryClose = () => {
    this.setState({anchorElCategory: null});
  }

  openEditCategoryMenu = (e, categoryEditId) => {
    this.setState({currentCategoryId: categoryEditId});
    this.setState({anchorElCategory: e.currentTarget});
  }

  openEditCategoryDialog = () => {
    this.setState({anchorElCategory: null});
    this.props.setCategoryEditOpen(true, this.state.currentCategoryId);
  }

  handleOpenDeleteCategory = () => {
    this.setState({anchorElCategory: null});
    const {documents} = this.props;
    if (documents.length === 0) {
      this.setState({openCategoryDelete: true});
    } else {
      this.setState({deleteErrorOpen: true});
    }
  }

  openEditDocumentDialog = () => {
    this.setState({anchorElCategory: null});
    this.props.setDocumentEditOpen(true, null);
  }

  handleCloseDeleteError = () => {
    this.setState({deleteErrorOpen: false});
  }

  handleCloseDeleteCategory = () => {
    this.setState({openCategoryDelete: false});
  }

  handleDeleteCategory = () => {
    const {deleteCategory, custId} = this.props;
    deleteCategory(custId);
    this.setState({openCategoryDelete: false});
  }

  handleCategoryCheckboxToggle = (e) => {
    const {documents, selectedDocs, setDocumentChecked, setDocumentUnchecked} = this.props;
    const check = e.target.checked;
    for (let i = 0; i < documents.length; i++) {
      const isChecked = selectedDocs.includes(documents[i].id);
      if (check && !isChecked) {
        setDocumentChecked(documents[i].id);
      } else if (!check && isChecked) {
        setDocumentUnchecked(documents[i].id);
      }
    }
  }

  render() {
    const {category, hasPubDocCategoryAdminPermission, hasPubDocPrivateViewPermission, hasDocumentAdminPermission, selectedDocs, documents} = this.props;
    const {anchorElCategory, openCategoryDelete, deleteErrorOpen} = this.state;
    const categoryChecked = !hasDocumentAdminPermission || documents.every(d => selectedDocs.includes(d.id));
    return (
      <div className={"pubDocCategoryContainer"}>
        <div className="topCategoryPaneRow">
          <div className="categoryTitleContainer">
            <span className="mediumTitle customerTitleColor pubDocCategoryTitle">
              <If test={hasDocumentAdminPermission && documents.length > 0}>
                <Checkbox className={"pubDocCheckbox"} onChange={this.handleCategoryCheckboxToggle} name={String(category.id)} checked={categoryChecked}/>
              </If>
              {category.title}
            </span>
            <If test={category.private && hasPubDocPrivateViewPermission}>
              <VisibilityOffIcon className={"pubDocCategoryVisiblityIcon"} titleAccess={"private"}/>
            </If>
          </div>
          <If test={hasPubDocCategoryAdminPermission}>
            <IconButton className={"pubDocCategoryEditButton"} onClick={(e) => this.openEditCategoryMenu(e, category.id)}>
              <MoreVertIcon/>
            </IconButton>
          </If>
          <Menu
            disableAutoFocusItem
            anchorEl={anchorElCategory}
            className="pubDocTypeMenu"
            open={Boolean(anchorElCategory)}
            onClose={this.onCategoryClose}
            MenuListProps={{
              'aria-labelledby': 'pubDocMenuOpen',
            }}
          >
            <MenuItem onClick={this.openEditCategoryDialog}>
              Edit Category
            </MenuItem>
            <MenuItem onClick={this.handleOpenDeleteCategory}>
              Delete Category
            </MenuItem>
            <MenuItem onClick={this.openEditDocumentDialog}>
              Add Document
            </MenuItem>
          </Menu>
        </div>
        <div className={"pubDocCategoryDescriptionContainer"}>
          <span className="pubDocCategoryDescription">{category.description}</span>
        </div>
        <ConfirmDialog
          title="Delete Category?"
          open={Boolean(openCategoryDelete)}
          onCancel={this.handleCloseDeleteCategory}
          onConfirm={this.handleDeleteCategory}
        >
          Are you sure you want to delete category: {category.title}
        </ConfirmDialog>
        <Dialog
          open={Boolean(deleteErrorOpen)}
          onClose={this.handleCloseDeleteError}
        >
          <DialogTitle>
            Error deleting category
          </DialogTitle>
          <DialogContent>
            Category cannot be deleted while it still has documents associated
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.handleCloseDeleteError}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default PubDocCategoryLabel;