import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import debounce from 'lodash/debounce';


import Button from '../../common/components/Button';
import Checkbox from '../../common/components/Checkbox';
import { Dialog, DialogContent, DialogTitle } from '../../common/components/dialog';
import FormControlLabel  from '../../common/components/FormControlLabel';
import TextField  from '../../common/components/TextField';
import PrimaryButton  from '../../common/components/PrimaryButton';
import { Stepper, Step, StepLabel, StepContent } from '../../common/components/stepper';
import Tooltip  from '../../common/components/Tooltip';
import Tree  from '../../common/components/Tree';
import { VirtualizedSelect } from '../../common/components/reactSelect';
import '../../common/components/SelectCodeReferenceDialog.css';
import {getReferenceGroup} from "../../common/ReferenceGroupFactory";


const doNothing = (options) => options;

class AddReferenceDialog extends Component {

  state = {
    referenceGroup: getReferenceGroup(this.props.groupName),
    activeStep: 0,
    query: '',
    selectedMatch: null,
    reference: {
      text: '',
      url: '',
      targetOption: '_self',
    },
  };

  initialState = {...this.state};

  onCancel = () => {
    this.setState(this.initialState);
    this.props.clearActiveReferenceNode();
    this.setState(this.initialState);
    this.props.onClose();
  };

  onInsert = () => {
    this.props.onInsert(this.state.reference);
    this.onCancel();
  };

  onTreeNodeToggle = (node, toggled) => {
    this.setState({selectedMatch: null});
    this.setState({query: ''});
    this.setState({reference: this.state.referenceGroup.getReferenceDetails(node)});
    this.props.toggleReferenceNode(node, toggled);
  };

  executeSearch = debounce((query) => {
    this.setState({query});
    this.props.fetchReferenceMatches(query);
  }, 500);

  onMatchClick = (selectedMatch) => {
    this.setState({selectedMatch});
    this.setState({query: ''});
    this.setState({reference: this.state.referenceGroup.getReferenceDetails({...selectedMatch, title: selectedMatch.titleData})});
    this.props.clearActiveReferenceNode();
  };

  onReferenceTextChange = (event) => {
    this.setState({reference: {...this.state.reference, text: event.target.value}});
  };

  onToggleNewWindow = (event) => {
    this.setState({reference: {...this.state.reference, targetOption: event.target.checked ? '_blank' : '_self'}});
  };

  onNextStep = () => {
    this.setState({activeStep: 1});
  };

  onPreviousStep = () => {
    this.setState({activeStep: 0});
  };

  toOption = (match) => ({...match,
    label:
      <Tooltip title={this.state.referenceGroup.getMatchOptionTitle(match)}>
        <span>
          <span className="addReferenceIdentifier">{match.context ? `${match.context}: `: ''}</span>
          <span className="addReferenceTitle">{match.title}</span>
        </span>
      </Tooltip>,
    value: `${match.type}:${match.key}`,
    titleData: match.title,
    title: '',
  });

  render() {
    const {groupName, selectedGroupName, nodes, matches, areMatchesLoading} = this.props;
    const {activeStep, query, selectedMatch, reference} = this.state;
    return (
      <Dialog
        classes={{
          paper: 'addReferenceModalPaper'
        }}
        open={groupName === selectedGroupName}
        onClose={this.onCancel}
        disableBackdropClick={true}
        style={{overflow: 'visible'}}
      >
        <DialogTitle>Add a reference link to your note</DialogTitle>
        <DialogContent className="addReferenceModalContents" style={{overflow: 'visible'}}>

          <Stepper activeStep={activeStep} orientation="vertical">

            <Step key='choose'>
              <StepLabel>Choose a location in the code</StepLabel>
              <StepContent classes={{transition: activeStep === 0 ? 'overflowingStep' : ''}}>
                <div className="addReferenceTreeContainer">
                  <Tree
                    data={nodes}
                    onToggle={this.onTreeNodeToggle}
                  />
                </div>
                <div className="addReferenceSeparatorText">OR...</div>
                <VirtualizedSelect
                  className="addReferenceSelect"
                  placeholder="Quick pick..."
                  value={selectedMatch}
                  isLoading={areMatchesLoading}
                  onChange={this.onMatchClick}
                  onInputChange={this.executeSearch}
                  options={matches.map(this.toOption)}
                  openOnFocus={true}
                  autoBlur={true}
                  maxHeight={200}
                  filterOptions={doNothing}
                  noResultsText={query === '' || areMatchesLoading ? false : 'No results found'}
                />
                <div className="stepActionContainer">
                  <Button onClick={this.onCancel}>Cancel</Button>
                  <PrimaryButton onClick={this.onNextStep} disabled={!reference.text}>Next</PrimaryButton>
                </div>
              </StepContent>
            </Step>

            <Step>
              <StepLabel>Customize your link text</StepLabel>
              <StepContent>
                <TextField
                  label="Reference Text"
                  value={reference.text}
                  onChange={this.onReferenceTextChange}
                  className="addReferenceTextField"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reference.targetOption === '_blank'}
                      onChange={this.onToggleNewWindow}
                    />
                  }
                  className="addReferenceTargetOption"
                  label="Open reference in new window"
                />
                <div>
                  <div className="addReferenceExampleLabel">Example:</div>
                  <a href={reference.url} target={reference.targetOption}><span>{reference.text} </span></a>
                </div>
                <div className="stepActionContainer">
                  <Button onClick={this.onCancel}>Cancel</Button>
                  <Button onClick={this.onPreviousStep}>Back</Button>
                  <PrimaryButton onClick={this.onInsert}>Insert</PrimaryButton>
                </div>
              </StepContent>
            </Step>

          </Stepper>

        </DialogContent>
      </Dialog>
    );
  }
}

AddReferenceDialog.propTypes = {
  id: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  selectedGroupName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onInsert: PropTypes.func.isRequired,
  fetchReferenceMatches: PropTypes.func.isRequired,
  toggleReferenceNode: PropTypes.func.isRequired,
  clearActiveReferenceNode: PropTypes.func.isRequired,
  nodes: PropTypes.array.isRequired,
  matches: PropTypes.array.isRequired,
  areMatchesLoading: PropTypes.bool.isRequired,
};

export default AddReferenceDialog;

