import {createAction} from "redux-actions";
import {patchJson} from "../../common/utils";
import {custId} from "../../common/utils/server-data";
import {registerError} from "../../common/actions";


const initLaw = createAction('LAWS/LAW/INIT', lawData => ({lawData}));
const initPastLaw = createAction('LAWS/PAST_LAW/INIT', lawData => ({lawData}));
const startEditing = createAction('LAWS/EDITING/START', documentId => ({documentId}));
const cancelEditing = createAction('LAWS/EDITING/CANCEL');

const saveEditingStart = createAction('LAWS/EDITING/SAVE/START', (documentId, title, comment) => ({documentId, title, comment}));
const saveEditingSuccess = createAction('LAWS/EDITING/SAVE/SUCCESS', (updatedLaw) => ({updatedLaw}));
const saveEditingFail = createAction('LAWS/EDITING/SAVE/FAIL', (documentId, error) => ({documentId, error}));
const saveEditingFinally = createAction('LAWS/EDITING/SAVE/FINALLY', (documentId) => ({documentId}));

const saveEditing = (documentId, title, comment) => async (dispatch) => {
  dispatch(saveEditingStart(documentId, title, comment));
  try {
    const updateData = {customTitle: title, comment: comment.replace(/[\r\n]+/g, " ")};
    const updatedLaw = await patchJson(`/api/${custId}/laws/${documentId}`, updateData);
    sessionStorage.setItem(`law-${documentId}`, JSON.stringify(updatedLaw));
    dispatch(saveEditingSuccess(updatedLaw));
  } catch (error) {
    dispatch(registerError("There was a problem saving this law", null, [documentId], error));
    dispatch(saveEditingFail(documentId, error));
  } finally {
    dispatch(saveEditingFinally(documentId));
  }
};

export {
  initLaw,
  initPastLaw,
  startEditing,
  cancelEditing,
  saveEditing,
  saveEditingStart,
  saveEditingSuccess,
  saveEditingFail,
  saveEditingFinally,
};