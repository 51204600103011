import { combineReducers } from 'redux-immutable';
import messagesReducer from './messages-reducer';

const reducer = combineReducers({
  messages: messagesReducer
});

export default reducer
export * from './selectors'

