import {ModuleKey} from "../ModuleKey";
import {
  pubDocsOnly, pageType, pageSubType, charterGuid, zoningGuid, noteCount, myNotesCount, lawCount, pastLawCount,
  hasDashboardAccess, warningMessage, searchResultsUrl, noteLocationData, newNav
} from "../utils/server-data";

const getAnalysisId = (state) => state[ModuleKey].analysisId;
const getArchiveId = (state) => state[ModuleKey].archiveId;
const getAssignedFilter = (state) => state[ModuleKey].assignedFilter;
const getAssignedToFilter = (state) => state[ModuleKey].assignedToFilter;
const getBodyTop = (state) => state[ModuleKey].bodyTop;
const getCustId = (state) => state[ModuleKey].custId;
const getCustomer = (state) => state[ModuleKey].customer;
const getCodeId = (state) => (getArchiveId(state) ? getArchiveId(state) : (getCustId(state) ? getCustId(state) : null));
const getCustomization = (state) => getCustomer(state) ? getCustomer(state).customization : null;
const getCodeLabel = (state) => getCustomization(state) && getCustomization(state).sidebarLabel
  ? getCustomization(state).sidebarLabel : "Code";
const getMapUrl = (state) => getCustomization(state) ? getCustomization(state).mapUrl : null;
const getDeferrableFilter = (state) => state[ModuleKey].deferrableFilter;
const getDisplayedQuestionIds = (state) => state[ModuleKey].displayedQuestionIds;
const getDueWarningDays = (state) => state[ModuleKey].dueWarningDays;
const getDueWarningIntervalHours = (state) => state[ModuleKey].dueWarningIntervalHours;
const getErrorMessages = (state) => state[ModuleKey].errorMessages;
const getErrorTitles = (state) => state[ModuleKey].errorTitles;
const getErrorRedirectUrl = (state) => state[ModuleKey].errorRedirectUrl;
const getFilterText = (state) => state[ModuleKey].filterText;
const getGroups = (state) => state[ModuleKey].groups;
const getHelpTopics = (state) => state[ModuleKey].helpTopics;
const getHelpVideos = (state) => state[ModuleKey].helpVideos;
const getNeedsReviewFilter = (state) => state[ModuleKey].needsReviewFilter;
const getQuestions = (state) => state[ModuleKey].questions;
const getReviewers = (state) => state[ModuleKey].reviewers;
const getSearchSuggestionWaitTime = (state) => state[ModuleKey].searchSuggestionWaitTime;
const getStatusFilter = (state) => state[ModuleKey].statusFilter;
const getTermsOfUseUrl = (state) => state[ModuleKey].termsOfUseUrl;
const getIa2FileTypes = (state) => state[ModuleKey].ia2FileTypes;
const getShowDiscussionText = (state) => state[ModuleKey].showDiscussionText;
const getHideDiscussionText = (state) => state[ModuleKey].hideDiscussionText;
const getMulticodeStates = (state) => state[ModuleKey].multicodeStates;
const getPubDocsFileTypes = (state) => state[ModuleKey].pubDocsFileTypes;
const getUsername = (state) => state[ModuleKey].username;
const isPrintLoading = (state) => state[ModuleKey].isPrintLoading;

const getWarningMessage = () => warningMessage;
const getPubDocsOnly = () => pubDocsOnly;
const getPageType = () => pageType;
const getPageSubType = () => pageSubType;
const getCharterGuid = () => charterGuid;
const getZoningGuid = () => zoningGuid;
const getNoteCount = () => noteCount;
const getMyNotesCount = () => myNotesCount;
const getLawCount = () => lawCount;
const getPastLawCount = () => pastLawCount;
const getSearchResultsUrl = () => searchResultsUrl;
const getNoteLocationData = () => noteLocationData;
const isNewNav = () => newNav;

const hasDashboardPermission = () => hasDashboardAccess;
const hasPermission = (state, permission) => state[ModuleKey].grantedPermissions.includes(permission);
const hasViewPermission = (state) => hasPermission(state, 'VIEW');
const hasIa2FinalizePermission = (state) => hasPermission(state, 'IA2/ANALYSIS/FINALIZE');
const hasIa2CompletePermission = (state) => hasPermission(state, 'IA2/FINALIZED/COMPLETE');
const hasIa2PrintPermission = (state) => hasPermission(state, 'IA2/QUESTION/PRINT');
const hasIa2StatusReportPermission = (state) => hasPermission(state, 'IA2/STATUS/VIEW');
const hasPublicNotesPermission = (state) => hasPermission(state, 'PUBLIC_ANNOTATION');
const hasIa2CommentAddPermission = (state) => hasPermission(state, 'IA2/COMMENT/ADD');
const hasIa2CommentUpdatePermission = (state) => hasPermission(state, 'IA2/COMMENT/UPDATE');
const hasIa2QuestionLockPermission = (state) => hasPermission(state, 'IA2/QUESTION/LOCK');
const hasIa2QuestionUnlockPermission = (state) => hasPermission(state, 'IA2/QUESTION/UNLOCK');
const hasAdvancedSearchPermission = (state) => hasPermission(state, 'SEARCH/QUERY/ADVANCED');
const hasMulticodePermission = (state) => hasPermission(state, 'MULTI');
const hasPubDocTypeAddPermission = (state) => hasPermission(state, 'PUBDOC/TYPE/ADD');
const hasPubDocTypeDeletePermission = (state) => hasPermission(state, 'PUBDOC/TYPE/DELETE');
const hasPubDocTypeEditPermission = (state) => hasPermission(state, 'PUBDOC/TYPE/EDIT');
const hasPubDocCategoryAdminPermission = (state) => hasPermission(state, 'CATEGORY_ADMIN');
const hasDocumentAdminPermission = (state) => hasPermission(state, 'DOCUMENT_ADMIN');
const hasBulkUploadPermission = (state) => hasPermission(state, 'BULK_UPLOAD');
const hasPubDocPrivateViewPermission = (state) => hasPermission(state, 'PUBDOC/PRIVATE/VIEW');
const hasIndexViewPermission = (state) => hasPermission(state, 'INDEX/VIEW');
const hasArchivesViewPermission = (state) => hasPermission(state, 'VERSION');
const hasLawViewPermission = (state) => hasPermission(state, 'LAW/ALL/VIEW');

export {
  getAnalysisId,
  getArchiveId,
  getAssignedFilter,
  getAssignedToFilter,
  getBodyTop,
  getCharterGuid,
  getCodeId,
  getCodeLabel,
  getCustId,
  getCustomer,
  getCustomization,
  getDeferrableFilter,
  getDisplayedQuestionIds,
  getDueWarningDays,
  getDueWarningIntervalHours,
  getErrorMessages,
  getErrorRedirectUrl,
  getErrorTitles,
  getFilterText,
  getGroups,
  getHelpTopics,
  getHelpVideos,
  getHideDiscussionText,
  getIa2FileTypes,
  getLawCount,
  getMapUrl,
  getMulticodeStates,
  getNeedsReviewFilter,
  getNoteCount,
  getMyNotesCount,
  getPageSubType,
  getPageType,
  getPastLawCount,
  getPubDocsFileTypes,
  getPubDocsOnly,
  getQuestions,
  getReviewers,
  getSearchResultsUrl,
  getSearchSuggestionWaitTime,
  getShowDiscussionText,
  getStatusFilter,
  getTermsOfUseUrl,
  getUsername,
  getWarningMessage,
  getZoningGuid,
  hasAdvancedSearchPermission,
  hasArchivesViewPermission,
  hasBulkUploadPermission,
  hasDashboardPermission,
  hasDocumentAdminPermission,
  hasIa2CommentAddPermission,
  hasIa2CommentUpdatePermission,
  hasIa2CompletePermission,
  hasIa2FinalizePermission,
  hasIa2PrintPermission,
  hasIa2QuestionLockPermission,
  hasIa2QuestionUnlockPermission,
  hasIa2StatusReportPermission,
  hasIndexViewPermission,
  hasLawViewPermission,
  hasMulticodePermission,
  hasPermission,
  hasPubDocCategoryAdminPermission,
  hasPubDocPrivateViewPermission,
  hasPubDocTypeAddPermission,
  hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission,
  hasPublicNotesPermission,
  hasViewPermission,
  isPrintLoading,
  getNoteLocationData,
  isNewNav
};
