import {connect} from 'react-redux';
import AddReferenceButton from '../components/AddReferenceButton';
import {createStructuredSelector} from 'reselect';
import {fetchRootReferenceNodes} from '../actions';
import {getAllReferenceNodes} from '../selectors';
import {compose} from 'recompose';
import {toJS} from "../../common/utils";

const mapStateToProps = createStructuredSelector({
  allNodes: getAllReferenceNodes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRootReferenceNodes: (groupName) => dispatch(fetchRootReferenceNodes(groupName)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(AddReferenceButton);
