import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import CodeIcon from './assets/code.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterListIcon from '@material-ui/icons/FilterList';
import HelpIcon from './assets/help.svg';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import NewLawIcon from './assets/new-law.svg';
import NoteIcon from './assets/note.svg';
import PdfIcon from './assets/pdf-outline.svg';
import PrintIcon from '@material-ui/icons/Print';
import PublicDocumentIcon from './assets/public-document.svg';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import ReportIcon from '@material-ui/icons/Report';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import WarningIcon from '@material-ui/icons/Warning';
import './icons.css';

import SvgIcon from './SvgIcon';

const AccountCircle = ({title, ...props}) => <AccountCircleIcon {...props} titleAccess={title ? title : "Account Actions"}/>;
const Add = ({title, ...props}) => <AddCircleIcon {...props} titleAccess={title ? title : "Add"}/>;
const Checkmark = ({title, ...props}) => <CheckIcon {...props} titleAccess={title ? title : "Complete"}/>;
const CheckmarkCircle = ({title, ...props}) => <CheckCircleIcon {...props} titleAccess={title ? title : "Complete"}/>;
const Circle = ({title, ...props}) => <RadioButtonUncheckedIcon {...props} titleAccess={title ? title : "Uncomplete"}/>;
const CircleX = ({title, ...props}) => <HighlightOffIcon {...props} titleAccess={title ? title : "Close"}/>;
const Clear = ({title, ...props}) => <CloseIcon {...props} titleAccess={title ? title : "Clear"}/>;
const Code = ({title, ...props}) => <img alt="code icon" className="medium svgIcon" src={CodeIcon} {...props}/>;
const Collapse = ({title, ...props}) => <RemoveIcon {...props} titleAccess={title ? title : "Collapse"}/>;
const Copy = ({title, ...props}) => <FileCopyIcon {...props} titleAccess={title ? title : "Copy"}/>;
const Delete = ({title, ...props}) => <DeleteIcon {...props} titleAccess={title ? title : "Delete"}/>;
const Edit = ({title, ...props}) => <EditIcon {...props} titleAccess={title ? title : "Edit"}/>;
const Email = ({title, ...props}) => <EmailIcon {...props} titleAccess={title ? title : "Email"}/>;
const Error = ({title, ...props}) => <ReportIcon {...props} titleAccess={title ? title : "Error"}/>;
const Expand = ({title, ...props}) => <AddIcon {...props} titleAccess={title ? title : "Expand"}/>;
const FilterList = ({title, ...props}) => <FilterListIcon {...props} titleAccess={title ? title : "Filter"}/>;
const Help = ({title, ...props}) => <SvgIcon {...props} className="svgIcon helpIcon" height={18} width={18} title={title ? title : "Help"} titleAccess={title ? title : "Help"} src={HelpIcon} viewBox="0 0 90 90" id="help"/>;
const More = ({title, ...props}) => <MoreIcon {...props} titleAccess={title ? title : "More"}/>;
const NewLaw = ({title, ...props}) => <img alt="new law icon" className="medium svgIcon" src={NewLawIcon} {...props}/>;
const Note = ({title, ...props}) => <img alt="note icon" className="medium svgIcon" src={NoteIcon} {...props}/>;
const Pdf = ({...props}) => <img alt="pdf file" className="small faded svgIcon" src={PdfIcon} {...props}/>;
const Play = ({title, ...props}) => <PlayIcon {...props} titleAccess={title ? title : "Play Video"}/>;
const Print = ({title, ...props}) => <PrintIcon {...props} titleAccess={title ? title : "Print"}/>;
const PublicDocument = ({title, ...props}) => <img alt="public document icon" className="medium svgIcon" src={PublicDocumentIcon} {...props}/>;
const Remove = ({title, ...props}) => <RemoveCircleIcon {...props} titleAccess={title ? title : "Remove"}/>;
const Save = ({title, ...props}) => <SaveIcon {...props} titleAccess={title ? title : "Save"}/>;
const Search = ({title, ...props}) => <SearchIcon {...props} titleAccess={title ? title : "Search"}/>;
const Warning = ({title, ...props}) => <WarningIcon {...props} titleAccess={title ? title : "Warning"}/>;

export {AccountCircle, Add, Checkmark, CheckmarkCircle, Circle, CircleX, Clear, Code, Collapse, Copy, Delete, Edit,
  Email, Error, Expand, ExpandMoreIcon, FilterList, Help, More, NewLaw, Note, Pdf, Play, Print, PublicDocument, Remove,
  Save, Search, Warning};
