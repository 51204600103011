import * as PropTypes from "prop-types";
import React from "react";
import {ReactSelect} from "../../common/components/reactSelect";
import "./Autocomplete.css";

class Autocomplete extends React.Component {

  static propTypes = {
    options: PropTypes.array.isRequired
  };

  getOptions = (option) => {
    const value = (option.value || option);
    const { options } = this.props;
    let newOptions = options.filter(option => !option.addedOption);
    if (value !== "" && options.indexOf(value) === -1 && options.filter(option => option.value === value).length === 0) {
      newOptions.push({value: value, label: value, addedOption: true});
    }
    return newOptions;
  };

  constructor(props) {
    super(props);
    this.state = {
      autoCompleteOptions: this.getOptions(this.props.value)
    };
  }

  onInputChange = (value) => {
    const { onInputChange } = this.props;
    if (value !== "") this.setState({autoCompleteOptions: this.getOptions(value)});
    if (onInputChange) onInputChange(value);
  };

  onChange = (option) => {
    const { onChange } = this.props;
    this.setState({autoCompleteOptions: this.getOptions(option)});
    if (onChange) onChange(option);
  };

  render() {
    const { options, value = "", onInputChange, onChange, ...otherProps } = this.props;
    const { autoCompleteOptions } = this.state;
    return (
      <ReactSelect {...otherProps}
        options={autoCompleteOptions}
        value={value}
        onInputChange={this.onInputChange}
        onChange={this.onChange}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        clearable={false}
      />
    );
  }
}
export default Autocomplete;