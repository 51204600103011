import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import {getReferenceGroup} from "../ReferenceGroupFactory";


import Button from './Button';
import { Dialog, DialogContent, DialogTitle } from './dialog';
import PrimaryButton  from './PrimaryButton';
import Tooltip  from './Tooltip';
import Tree  from './Tree';
import { VirtualizedSelect } from './reactSelect';
import './SelectCodeReferenceDialog.css';

const doNothing = (options) => options;

class SelectCodeReferenceDialog extends Component {

  state = {
    referenceGroup: getReferenceGroup('code'),
    query: '',
    selectedMatch: null,
    reference: {
      text: '',
      url: '',
      targetOption: '_self',
    },
  };

  initialState = {...this.state};

  onCancel = () => {
    this.props.clearActiveReferenceNode();
    this.setState(this.initialState);
    this.props.onClose();
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.reference);
  };

  onTreeNodeToggle = (node, toggled) => {
    this.setState({selectedMatch: null});
    this.setState({query: ''});
    this.setState({reference: this.state.referenceGroup.getReferenceDetails(node)});
    this.props.toggleReferenceNode(node, toggled);
  };

  executeSearch = debounce((query) => {
    this.setState({query});
    this.props.fetchReferenceMatches(query);
  }, 500);

  onMatchClick = (selectedMatch) => {
    this.setState({selectedMatch});
    this.setState({query: ''});
    this.setState({reference: this.state.referenceGroup.getReferenceDetails({...selectedMatch, title: selectedMatch.titleData})});
    this.props.clearActiveReferenceNode();
  };

  toOption = (match) => ({...match,
    label:
      <Tooltip title={this.state.referenceGroup.getMatchOptionTitle(match)}>
        <span>
          <span className="addReferenceIdentifier">{match.context ? `${match.context}: `: ''}</span>
          <span className="addReferenceTitle">{match.title}</span>
        </span>
      </Tooltip>,
    value: `${match.type}:${match.key}`,
    titleData: match.title,
    title: '',
  });

  render() {
    const {isOpen, nodes, matches, areMatchesLoading} = this.props;
    const {query, selectedMatch, reference} = this.state;
    return (
      <Dialog
        classes={{
          paper: 'addReferenceModalPaper'
        }}
        open={isOpen}
        onClose={this.onCancel}
        disableBackdropClick={true}
        style={{overflow: 'visible'}}
      >
        <DialogTitle>Select a reference</DialogTitle>
        <DialogContent className="addReferenceModalContents" style={{overflow: 'visible'}}>
          <div className="addReferenceTreeContainer">
            <Tree
              data={nodes}
              onToggle={this.onTreeNodeToggle}
            />
          </div>
          <div className="addReferenceSeparatorText">OR...</div>
          <VirtualizedSelect
            className="addReferenceSelect"
            placeholder="Quick pick..."
            value={selectedMatch}
            isLoading={areMatchesLoading}
            onChange={this.onMatchClick}
            onInputChange={this.executeSearch}
            options={matches.map(this.toOption)}
            openOnFocus={true}
            autoBlur={true}
            maxHeight={200}
            filterOptions={doNothing}
            noResultsText={query === '' || areMatchesLoading ? false : 'No results found'}
          />
          <div className="stepActionContainer">
            <Button onClick={this.onCancel}>Cancel</Button>
            <PrimaryButton onClick={this.onSubmit} disabled={!reference.text}>Select</PrimaryButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

SelectCodeReferenceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchReferenceMatches: PropTypes.func.isRequired,
  toggleReferenceNode: PropTypes.func.isRequired,
  clearActiveReferenceNode: PropTypes.func.isRequired,
  nodes: PropTypes.array.isRequired,
  matches: PropTypes.array.isRequired,
  areMatchesLoading: PropTypes.bool.isRequired,
};

export default SelectCodeReferenceDialog;

