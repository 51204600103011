/*
 * Copyright 2018 General Code
 */
import * as PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import Button from '../../common/components/Button';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '../../common/components/dialog';
import PrimaryButton from '../../common/components/PrimaryButton';
import ColorPickerContainer from '../containers/ColorPickerContainer';
import DisplayModePicker from '../containers/DisplayModePickerContainer';
import VisibilityPickerContainer from '../containers/VisibilityPickerContainer';
import "./EditControls.css";

class EditControls extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.any.isRequired,
    saveNote: PropTypes.func.isRequired,
    editNoteCancel: PropTypes.func.isRequired,
  };

  state = {
    contentErrors: [],
  };

  onSaveValidation = () => {
    const {title, content, saveNote} = this.props;
    const contentErrors = [];

    if (title === null || title.trim().length === 0) {
      contentErrors.push("Please add a title to the note before saving.");
    }

    //using has text instead of checking the plain text length because images and some other content has zero length
    //this does allow a user to enter all whitespace if they really want to
    if (!content.getCurrentContent().hasText()) {
      contentErrors.push("Please add something to the note before saving.");
    }

    if (title && title.length > 255) {
      contentErrors.push(`Please shorten the title of the note.  It must be ${title.length - 255} letters shorter`);
    }

    if (contentErrors.length === 0) {
      saveNote();
    }
    this.setState({contentErrors});
  };

  onSaveValidationClose = () => {
    this.setState({contentErrors: []});
  };

  render() {
    const {id, editNoteCancel} = this.props;
    const {contentErrors} = this.state;
    const isSaveValidationOpen = contentErrors.length > 0;

    return (
      <div className="noteEditControls">
        <ColorPickerContainer id={id} className="noteEditControl noteEditControls-colorPicker"/>
        <DisplayModePicker id={id} className="noteEditControl noteEditControls-displayModePicker"/>
        <VisibilityPickerContainer id={id} className="noteEditControl noteEditControls-visibilityPicker"/>
        <Button className="noteEditControls-cancel" onClick={editNoteCancel}>Cancel</Button>
        <PrimaryButton className="noteEditControls-save" onClick={this.onSaveValidation}>Save</PrimaryButton>
        <Dialog
          open={isSaveValidationOpen}
          onClose={this.onSaveValidationClose}
          disableBackdropClick={true}
        >
          <DialogTitle>Problems with note</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {contentErrors.map((text, index) => <div key={index}>{text}</div>)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryButton onClick={this.onSaveValidationClose}>Ok</PrimaryButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


export default EditControls;
