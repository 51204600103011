import Paper from "@material-ui/core/Paper";
import {range} from 'lodash';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import PubDocCategoryLabelContainer from "../containers/PubDocCategoryLabelContainer";
import PubDocChunk, {docChunkSize} from "../containers/PubDocChunk";
import './PubDocType.css';

class PubDocCategory extends Component {

  static propTypes = {
    category: PropTypes.object.isRequired,
    documents: PropTypes.array.isRequired,
    selectedFilters: PropTypes.object.isRequired,
    hasPubDocPrivateViewPermission: PropTypes.bool.isRequired,
    hasPubDocCategoryAdminPermission: PropTypes.bool.isRequired
  };

  componentDidMount() {
    window.scroll(0,5);
    window.scroll(0,-5);
  }

  render() {
    const {category, documents, selectedFilters, hasPubDocCategoryAdminPermission} = this.props;
    const yearFilter = selectedFilters.year == null ? "" : selectedFilters.year;
    const monthFilter = selectedFilters.month == null ? "" : selectedFilters.month;
    const dayFilter = selectedFilters.day == null ? "" : selectedFilters.day;
    const filtersAreEmpty = yearFilter === "" && monthFilter === "" && dayFilter === "";
    if ((hasPubDocCategoryAdminPermission && filtersAreEmpty) || documents.length > 0) {
      return (
        <div key={`pub-doc-category-${category.id}`} className={"pubDocSectionContainer"}>
          <Paper className={"clearfix pubDocCategoryPaper"} id={"category-" + category.id} square={true} elevation={2}>
            <PubDocCategoryLabelContainer category={category} documents={documents}/>
            {
              range(documents.length / docChunkSize).map((i) => (
                <PubDocChunk
                  key={`doc-chunk-${(documents[i * docChunkSize] || {id: 'start'}).id}-${(documents[i * docChunkSize + docChunkSize - 1] || {id: 'end'}).id}`}
                  documents={documents} chunkNumber={i} category={category} once={true}/>
              ))
            }
          </Paper>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default PubDocCategory;
