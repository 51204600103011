import isUndefined from 'lodash/isUndefined';
import mapValues from 'lodash/mapValues';
import {createAction} from "redux-actions";
import {post} from '../../common/utils/index';
import {custId} from "../utils/server-data";
import {API, BODY_TOP_SET, ERROR_ALL_DISMISS, ERROR_NON_FATAL_REGISTER, ERROR_REGISTER, EVENT_LOG, PRINT_LOADING_SET, SERVER_DATA_LOAD} from "./types";

const loadServerData = createAction(SERVER_DATA_LOAD, ({
  groups,
  username,
  publicAnnotation,
  archiveId,
  custId,
  customerName,
  customer,
  searchSuggestionWaitTime,
  analysisId,
  questions,
  reviewers,
  dueWarningDays,
  dueWarningIntervalHours,
  displayedQuestionIds,
  needsReviewFilter,
  assignedFilter,
  assignedToFilter,
  deferrableFilter,
  statusFilter,
  filterText,
  ia2FileTypes,
  pubDocsFileTypes,
  termsOfUseUrl,
  helpTopics,
  helpVideos,
  grantedPermissions,
  showDiscussionText,
  hideDiscussionText,
  multicodeStates
}) => mapValues({
  groups,
  username,
  publicAnnotation,
  archiveId,
  custId,
  customerName,
  customer,
  searchSuggestionWaitTime,
  analysisId,
  questions,
  reviewers,
  dueWarningDays,
  dueWarningIntervalHours,
  displayedQuestionIds,
  needsReviewFilter,
  assignedFilter,
  assignedToFilter,
  deferrableFilter,
  statusFilter,
  filterText,
  ia2FileTypes,
  pubDocsFileTypes,
  termsOfUseUrl,
  helpTopics,
  helpVideos,
  grantedPermissions,
  showDiscussionText,
  hideDiscussionText,
  multicodeStates
}, value => isUndefined(value) ? null : value));

const registerError = createAction(ERROR_REGISTER, (errorMessage, errorTitle, errorData = ['no-data'], error) => {
  try {
    console.error(`${errorMessage} [${errorData ? errorData.join(', ') : 'no-data'}]`, error);
    if (errorMessage) error.message = errorMessage;
    if (errorTitle) error.title = errorTitle;
    // noinspection JSUnresolvedVariable
    if (window && window.gtag) {
      window.gtag('event', 'exception', {
        description: errorMessage,
        fatal: true
      });
    }
  } catch (e) {
    console.error("Failed to process error", e);
  }
  return {error};
});

const registerNonFatalError = createAction(ERROR_NON_FATAL_REGISTER, (errorMessage, errorTitle, errorData = ['no-data'], error) => {
  try {
    console.error(`${errorMessage} [${errorData.join(', ')}]`, error);
    if (errorMessage) error.message = errorMessage;
    if (errorTitle) error.title = errorTitle;
    // noinspection JSUnresolvedVariable
    if (window && window.gtag) {
      //TODO: handle non fatal errors coming through here - maybe a different action for user error?
      window.gtag('event', 'exception', {
        description: errorMessage,
        fatal: false
      });
    }
  } catch (e) {
    console.error("Failed to process error", e);
  }
  return {error};
});


const logEvent = createAction(EVENT_LOG, (eventCategory, eventName, eventInfo) => {
  let eventInfoString = JSON.stringify(eventInfo);
  post(`/event?eventName=${eventName}&custId=${custId}&eventInfo=${encodeURIComponent(eventInfoString)}`, {});
  if (window && window.gtag) {
    window.gtag('event', eventName, {
      event_category: (eventCategory ? eventCategory : 'generic'),
      event_label: custId,
      custId: custId,
      cust_id: custId,
      event_info: eventInfoString
    });
  }
});

const dismissErrors = createAction(ERROR_ALL_DISMISS, () => ({}));

const setBodyTop = createAction(BODY_TOP_SET, (bodyTop) => ({bodyTop}));

const setPrintLoading = createAction(PRINT_LOADING_SET, (isLoading) => ({isLoading}));

/**
 * Api action
 * @typedef {Object} ApiAction
 * @property {string} type
 * @property {ApiPayload} payload
 */

/**
 * Api payload
 * @typedef {Object} ApiPayload
 * @param {string} label - The label to be associated with all generic dispatches
 * @param {string} method - One of GET/POST/PUT/DELETE/PATCH
 * @param {string} url - the url to invoke
 * @param {string} [errorMessage] - message to show to the user on failure
 * @property {object} [data]
 * @property {function} [onSuccess]
 * @property {function} [onFailure]
 */

/**
 * Create an api call action.  Creates generic start, end, error dispatches as well as dispatching the callbacks
 *
 * @param {ApiPayload} payload - additional options for the request
 *
 * @returns {ApiAction}
 */
const apiAction = createAction(API,
  ({label, method, url, data, errorMessage, onSuccess, onFailure}) => ({label, method, url, data, errorMessage, onSuccess, onFailure}));

export {
  apiAction,
  loadServerData,
  dismissErrors,
  registerError,
  registerNonFatalError,
  setBodyTop,
  setPrintLoading,
  logEvent
};
