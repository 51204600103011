/*
 * Copyright 2018 General Code
 */

import {List, OrderedMap, Record} from 'immutable';
import {handleActions} from 'redux-actions';
import * as actions from '../actions';


const State = Record({
  laws: OrderedMap(),
  pastLaws: OrderedMap(),
  editingId: null,
});

const initialState = State({});

const Person = Record({
  name: null,
  email: null,
});

const Disposition = Record({
  guid: "",
  name: "",
  sortId: 0
});

const CodeVersion = Record({
  id: "",
  publishingIdentifier: "",
  importTime: null,
  updatedDate: null
});
const convertDate = (date) => date.getFullYear ? date
  : new Date(date.date.year, date.date.month, date.date.day, date.time.hour, date.time.minute, date.time.second, date.time.nano);
const initCodeVersion = (codeVersionData) => codeVersionData == null ? null
  : CodeVersion({...codeVersionData,
    importTime: convertDate(codeVersionData.importTime),
    updatedDate: convertDate(codeVersionData.updatedDate)
  });

const Law = Record({
  documentId: "",
  title: "",
  customTitle: "",
  description: "",
  originalFilename: "",
  comment: "",
  adopted: "",
  dispositions: List(),
  subject: "",
  rank: null,
  updatedBy: Person(),
  updatedOn: null,
  version: null,
  suppedBy: null
});
const initLaw = (state, {payload: {lawData}}) => {
  const law = Law({...lawData, dispositions: List(lawData.dispositions.map(d => Disposition(d))), suppedBy: initCodeVersion(lawData.suppedBy)});
  return state.setIn(['laws', lawData.documentId], law);
};
const initPastLaw = (state, {payload: {lawData}}) => {
  const law = Law({...lawData, dispositions: List(lawData.dispositions.map(d => Disposition(d))), suppedBy: initCodeVersion(lawData.suppedBy)});
  return state.setIn(['pastLaws', lawData.documentId], law);
};
const startEditing = (state, {payload: {documentId}}) => state.set('editingId', documentId);
const cancelEditing = (state) => state.set('editingId', null);
const saveEditing = (state, {payload: {updatedLaw}}) => {
  if (!state.get('editingId')) return state;
  let lawsContainer = state.get('laws').get(state.get('editingId')) ? 'laws' : 'pastLaws';
  return state.updateIn([lawsContainer, state.get('editingId')], (law) => law
    .set('title', updatedLaw.title)
    .set('customTitle', updatedLaw.customTitle)
    .set('comment', updatedLaw.comment)
    .set('updatedOn', updatedLaw.updated)
    .set('updatedBy', Person(updatedLaw.updatedBy))
    .set('version', updatedLaw.version))
    .set('editingId', null);
};

const reducer = handleActions({
  [actions.initLaw]: initLaw,
  [actions.initPastLaw]: initPastLaw,
  [actions.startEditing]: startEditing,
  [actions.cancelEditing]: cancelEditing,
  [actions.saveEditingSuccess]: saveEditing,
}, initialState);

export {State, initialState, Law};
export default reducer;
