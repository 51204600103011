import React from "react";

import InfiniteScroll from '../../common/components/InfiniteScroll';
import {LinearProgress} from '../../common/components/progress';
import {Sticky, StickyContainer} from "react-sticky";
import If from "../../common/containers/If";
import SearchResultsPageTitleContainer from "../containers/SearchResultsPageTitleContainer";
import {custId} from "../../common/utils/server-data";
import FacetContainer from "../containers/FacetContainer";
import RelatedTermsContainer from "../containers/RelatedTermsContainer";
import ResultContainer from "../containers/ResultContainer";
import ScopeSelectorContainer from "../containers/ScopeSelectorContainer";
import SortContainer from "../containers/SortContainer";
import "./SearchResultsPage.css";

class SearchResultsPage extends React.Component {

  render() {
    const {customers, currentPage, maxPages, results, isSearchLoading, selectedCustIds, selectedCustomerFilters, scope,
      bodyTop, getNextPage} = this.props;
    const isMulticodeSearch = !custId || selectedCustomerFilters.length > 0 || selectedCustIds.length > 1
      || (selectedCustIds.length === 1 && selectedCustIds[0] !== custId);

    return (
      <StickyContainer id="main" className="clearfix">
        <div className="sidebarArea">
          <Sticky topOffset={-bodyTop}>
            {({style, isSticky}) => (
              <div id="scopeSelectorContainer" className={"sidebarItem " + (isSticky ? "isSticky" : "isNotSticky")} style={style}>
                <ScopeSelectorContainer id="scopeSelectorContent"/>
              </div>
            )}
          </Sticky>
          <SortContainer id={"sortFacet"} hasDateSort={scope === "doc"}
            hasNaturalOrderSort={scope === "code" && selectedCustIds.length === 1 && selectedCustomerFilters.length === 0}
          />
          <FacetContainer className="sidebarItem"/>
        </div>
        <div id="content">
          <div id="contentArea">
            <div id="text">
              <div id="contentContainer">
                <SearchResultsPageTitleContainer />
                <div id="searchResultsPageContainer">
                  <RelatedTermsContainer/>
                  <div id="resultsContainer">
                    <InfiniteScroll
                      pageStart={0}
                      initialLoad={false}
                      loadMore={getNextPage}
                      hasMore={(currentPage + 1) < maxPages}
                      threshold={800}
                    >
                      {
                        results.length > 0 ? results.map((result) => (
                          <ResultContainer key={result.id}
                            result={result}
                            customer={customers[result.custId]}
                            isMulticodeSearch={isMulticodeSearch}
                          />
                        )) : (!isSearchLoading ? "No results found..." : "")
                      }
                    </InfiniteScroll>
                    <If test={isSearchLoading}><LinearProgress/></If>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StickyContainer>
    );
  }
}
export default SearchResultsPage;
