import {Checkbox, ExpansionPanel, ExpansionPanelDetails, Menu, MenuItem, Tooltip} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import DescriptionIcon from "@material-ui/icons/Description";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import * as PropTypes from 'prop-types';
import React, {Component} from "react";
import AudioIcon from "../../common/components/AudioIcon";
import ConfirmDialog from "../../common/components/ConfirmDialog";
import IconButton from "../../common/components/IconButton";
import If from "../../common/containers/If";
import Metadata from "../../notes/components/Metadata";
import ExpansionPanelSummary from "./ExpansionPanelSummary";
import './PubDocType.css';

const rowHeight = 40;
const rowHeightExpanded = 96;

class PubDoc extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    hasPubDocPrivateViewPermission: PropTypes.bool.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    selectedDocs: PropTypes.array.isRequired,
    doc: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
    prevDoc: PropTypes.object
  }

  state = {
    expanded: false,
    documentAnchorEl: null,
    documentDeleteOpen: false
  }

  toggleExpanded = () => () => {
    const {expanded} = this.state;
    this.setState({expanded: !expanded});
  }

  documentCheckboxChange = (event) => {
    const {setDocumentChecked, setDocumentUnchecked} = this.props;
    if (event.target.checked) {
      setDocumentChecked();
    } else {
      setDocumentUnchecked();
    }
  }

  openDocumentMenu = (documentId) => (event) => {
    this.setState({
      documentAnchorEl: event.currentTarget,
      documentEditId: documentId
    });
  };

  closeDocumentMenu = () => {
    this.setState({documentAnchorEl: null});
  };

  openEditDocumentDialog = () => {
    this.setState({documentAnchorEl: null});
    this.setState({typeAnchorEl: null});
    this.props.setDocumentEditOpen(true);
  }

  openMoveDocumentDialog = () => {
    this.setState({documentAnchorEl: null});
    this.setState({typeAnchorEl: null});
    this.props.setDocumentMoveOpen(true);
  }

  handleOpenDeleteDocument = () => {
    this.setState({documentAnchorEl: null});
    this.setState({documentDeleteOpen: true});
  }

  handleCloseDeleteDocument = () => {
    this.setState({documentDeleteOpen: false});
  }

  deleteDocument = () => {
    this.setState({documentDeleteOpen: false});
    this.props.deleteDocument(this.props.custId);
  }

  handleDownloadOriginal = () => {
    const {custId, doc} = this.props;
    window.location.href = `/documents/${custId}/public/${doc.id}.${doc.origExtension}?download=true`;
  }

  render() {
    const {custId, hasPubDocPrivateViewPermission, hasDocumentAdminPermission, selectedDocs, category, doc, prevDoc} = this.props;
    const {expanded, documentAnchorEl, documentDeleteOpen} = this.state;
    const selected = selectedDocs.includes(doc.id);
    const documentYear = doc.year;
    const previousDocumentYear = prevDoc ? prevDoc.year : null;
    const displayYear = ((previousDocumentYear === null || documentYear !== previousDocumentYear) ? documentYear : null);
    return(
      <div>
        <If test={(category.groupByType === "Year_Ascending" || category.groupByType === "Year_Descending") && displayYear}>
          <Paper square={true} elevation={0} className={"pubDocYearPaper"}>
            <span className={"pubDocYearRow"}>{displayYear}</span>
          </Paper>
        </If>
        <Paper
          square={true}
          elevation={expanded ? 2 : 0}
          className={"pubDocRow" + (expanded ? " pubDocRowExpanded" : " pubDocRowCollapsed")}
        >
          <ExpansionPanel elevation={0} square={true} expanded={expanded} className={"pubDocExpansionPanel"}>
            <ExpansionPanelSummary
              classes={{content: "pubDocRowContent"}}
              className={"pubDocExpansionPanelSummary"}
              IconButtonProps={{onClick: this.toggleExpanded(doc.id)}}
              expanded={expanded}
            >
              <If test={hasDocumentAdminPermission}>
                <Checkbox className={"pubDocCheckbox"} onChange={this.documentCheckboxChange} name={String(doc.id)} checked={selected}/>
              </If>
              <div className={"pubDocTitleContainer"}>
                <If test={doc.type === "DOCUMENT"}>
                  <a className="smallTitle pubDocTitle" target={"_blank"} rel={"noreferrer noopener"}
                    href={"/" + custId + "/document/" + doc.id + "." + doc.extension}>{doc.title}</a>
                  <If test={!category.private && doc.private && hasPubDocPrivateViewPermission}>
                    <VisibilityOffIcon className={"pubDocVisibilityIcon"} titleAccess={"private"}/>
                  </If>
                  <Tooltip title="Public Document">
                    <DescriptionIcon className="pubDocIcon"/>
                  </Tooltip>
                </If>
                <If test={doc.type === "AUDIO_FILE"}>
                  <a className="smallTitle pubDocTitle" target={"_blank"} rel={"noreferrer noopener"}
                    href={"/" + custId + "/document/" + doc.id + "." + doc.extension}>{doc.title}</a>
                  <If test={!category.private && doc.private && hasPubDocPrivateViewPermission}>
                    <VisibilityOffIcon className={"pubDocVisibilityIcon"} titleAccess={"private"}/>
                  </If>
                  <Tooltip title="Audio File">
                    <AudioIcon className="pubDocIcon"/>
                  </Tooltip>
                </If>
                <If test={doc.type === "AUDIO_LINK"}>
                  <a className="smallTitle pubDocTitle" target={"_blank"} rel={"noreferrer noopener"} href={doc.url}>{doc.title}</a>
                  <If test={!category.private && doc.private && hasPubDocPrivateViewPermission}>
                    <VisibilityOffIcon className={"pubDocVisibilityIcon"} titleAccess={"private"}/>
                  </If>
                  <Tooltip title="Linked Audio">
                    <AudioIcon className="pubDocIcon"/>
                  </Tooltip>
                </If>
                <If test={doc.type === "VIDEO_LINK"}>
                  <a className="smallTitle pubDocTitle" target={"_blank"} rel={"noreferrer noopener"} href={doc.url}>{doc.title}</a>
                  <If test={!category.private && doc.private && hasPubDocPrivateViewPermission}>
                    <VisibilityOffIcon className={"pubDocVisibilityIcon"} titleAccess={"private"}/>
                  </If>
                  <Tooltip title="Linked Video">
                    <VideoLibraryIcon className="pubDocIcon"/>
                  </Tooltip>
                </If>
              </div>
              <If test={hasDocumentAdminPermission}>
                <IconButton className="pubDocRowButton" onClick={this.openDocumentMenu(doc.id)}>
                  <MoreVertIcon/>
                </IconButton>
                <Menu
                  anchorEl={documentAnchorEl}
                  className="pubDocTypeMenu"
                  open={documentAnchorEl !== null}
                  onClose={this.closeDocumentMenu}
                >
                  <MenuItem onClick={this.openEditDocumentDialog}>
                    Edit Document
                  </MenuItem>
                  <MenuItem onClick={this.openMoveDocumentDialog}>
                    Move Document
                  </MenuItem>
                  <MenuItem onClick={this.handleOpenDeleteDocument}>
                    Delete Document
                  </MenuItem>
                  <If test={doc.type === "DOCUMENT" || doc.type === "AUDIO_FILE"}>
                    <MenuItem onClick={this.handleDownloadOriginal}>
                      Download Original
                    </MenuItem>
                  </If>
                </Menu>
              </If>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={"pubDocExpansionPanelDetails"}>
              <If test={doc.description}>
                <div>{doc.description}</div>
              </If>
              <If test={doc.date}>
                <div><b>Date: </b>{doc.date ? doc.date.substr(0, 10) : ""}</div>
              </If>
              <Metadata
                className={"noteMetadataNoBottom"}
                editable={hasDocumentAdminPermission}
                createdOn={doc.created ? doc.created.substr(0, 10) : null}
                createdBy={{name: doc.createdBy}}
                updatedOn={doc.updated ? doc.updated.substr(0, 10) : null}
                updatedBy={{name: doc.updatedBy}}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <If test={documentDeleteOpen}>
            <ConfirmDialog
              title="Delete Public Document?"
              open={documentDeleteOpen}
              onCancel={this.handleCloseDeleteDocument}
              onConfirm={this.deleteDocument}
            >
              Are you sure you want to delete: {doc.title}?
            </ConfirmDialog>
          </If>
        </Paper>
      </div>
    );
  }
}

export {rowHeight, rowHeightExpanded};
export default PubDoc;
