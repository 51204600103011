/*
 * Copyright 2018 General Code
 */

import * as PropTypes from 'prop-types';
import React from 'react';
import AuditLine from '../../common/components/AuditLine';
import './Title.css';

/**
 * For the metadata if the user is not logged in or is not privileged they only see the last update date
 * If they are a fancy person they get to see the name and date at note creation as well as last
 * update if different.
 */
const Metadata = ({editable, createdOn, createdBy = {}, updatedBy = {}, updatedOn, className}) => (
  <AuditLine
    className={className? className : "noteMetadata"}
    entity={{createdOn, createdBy, updatedOn, updatedBy}}
    condensedMode={!editable}
  />
);

Metadata.propTypes = {
  createdBy: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  createdOn: PropTypes.string.isRequired,
  updatedBy: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  updatedOn: PropTypes.string,
};

export default Metadata;
