import * as PropTypes from "prop-types";
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "../../common/components/InputLabel";
import {MenuItem} from "../../common/components/menu";
import {CircularProgress} from "../../common/components/progress";
import Select from "../../common/components/Select";
import TextField from "../../common/components/TextField";
import FormControl from "../../common/components/FormControl";
import moment from "moment";
import {If} from "../../common/containers";
import {FormControlLabel, Switch} from "@material-ui/core";
import './PubDocEditDialog.css';

class PubDocEditDialog extends React.Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    categories: PropTypes.array,
    documents: PropTypes.array,
    documentEditOpen: PropTypes.bool.isRequired,
    documentCategoryEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    documentEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addDocument: PropTypes.func,
    editDocument: PropTypes.func,
    pubDocsFileTypes: PropTypes.string,
    date: PropTypes.string,
    documentEditLoading: PropTypes.bool.isRequired,
    hasBulkUploadPermission: PropTypes.bool.isRequired,
    editDocumentLinked: PropTypes.func,
    addDocumentLinked: PropTypes.func,
    setBulkUploaderOpen: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const {categories} = this.props;
    this.state = {
      name: "",
      categoryId: categories.length > 0 ? categories[0].id : "Uncategorized",
      fileTitle: null,
      fileExtension: null,
      file: null,
      description: null,
      date: null,
      createdBy: null,
      createdDate: null,
      updatedDate: null,
      isPrivate: false,
      documentType: null,
      url: "",
      errorDialogOpen: false,
      fileSizeErrorOpen: false,
      bulkUploaderOpen: false
    };
  }

  handleCategoryChange = (event) => {
    this.setState({categoryId: event.target.value});
  };

  handleNameChange = (event) => {
    this.setState({name: event.target.value});
  };

  handleFileChange = (event) => {
    const {name} = this.state;
    const file = (event.target.files && event.target.files.length > 0) ? event.target.files[0] : null;
    const filename = file ? file.name.substring(0, file.name.lastIndexOf('.')) : null;
    this.setState({
      fileTitle: file ? file.name.substring(0, file.name.lastIndexOf('.')) : null,
      fileExtension: file ? file.name.split('.').pop() : null,
      file: file,
      name: name ? name : filename
    });
  };

  handleDateChange = (e) => {
    this.setState({date: e.target.value});
  };

  handleDescriptionChange = (event) => {
    this.setState({description: event.target.value});
  };

  handleCancelClick = () => {
    let {categories, onCancel} = this.props;
    this.setState({
      name: "",
      categoryId: categories.length > 0 ? categories[0].id : "Uncategorized",
      fileTitle: null,
      fileExtension: null,
      file: null,
      description: null,
      date: null,
      createdBy: null,
      createdDate: null,
      updatedDate: null,
      isPrivate: false,
      documentType: "DOCUMENT",
      url: ""
    });
    onCancel();
  };

  handleCloseError = () => {
    this.setState({errorDialogOpen: false});
  };

  handleSaveClick = () => {
    const {custId, documentEditId, typeId, editDocument, addDocument, editDocumentLinked, addDocumentLinked, documents} = this.props;
    const {categoryId, name, description, file, date, isPrivate, documentType, url} = this.state;
    const dateString = date ? moment(date).format("YYYY-MM-DD") : null;
    if (documents.filter(currDoc => {return (currDoc.title === name && currDoc.date === dateString && currDoc.categoryId === categoryId && currDoc.id !== documentEditId);}).length > 0) {
      this.setState({errorDialogOpen: true});
      return;
    }
    if ((documentType === 'DOCUMENT' || documentType === 'AUDIO_FILE') && file) {
      if (file.size > 100000000) {
        this.setState({fileSizeErrorOpen: true});
        return;
      }
    }
    if (name.length > 0) {
      if (documentEditId) {
        if (documentType === 'DOCUMENT' || documentType === 'AUDIO_FILE') {
          editDocument(custId, documentEditId, categoryId, typeId, name, description, dateString, file, isPrivate, documentType);
        } else {
          editDocumentLinked(custId, documentEditId, categoryId, typeId, name, description, dateString, isPrivate, documentType, url);
        }
      } else {
        if (documentType === 'DOCUMENT' || documentType === 'AUDIO_FILE') {
          addDocument(custId, categoryId, typeId, name, description, dateString, file, isPrivate, documentType);
        } else {
          addDocumentLinked(custId, categoryId, typeId, name, description, dateString, isPrivate, documentType, url);
        }
      }
    }
  };

  handleChangePrivate = (event) => {
    this.setState({isPrivate: event.target.checked});
  };

  handleDocumentTypeChange = (event) => {
    this.setState({
      fileTitle: null,
      fileExtension: null,
      file: null,
      url: "",
      validUrl: false,
      documentType: event.target.value
    });
  };

  handleUrlChange = (event) => {
    const regex = new RegExp("(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&/=]*)");
    this.setState({
      url: event.target.value,
      validUrl: regex.test(event.target.value)
    });
  };

  handleCloseFileSizeError = () => {
    this.setState({fileSizeErrorOpen: false});
  };

  openBulkUploaderDialog = () => {
    const { setBulkUploaderOpen } = this.props;
    this.handleCancelClick();
    setBulkUploaderOpen();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEditOpen = prevProps.documentEditOpen;
    const {categories, documentEditOpen, documentEditId, documents, documentCategoryEditId} = this.props;
    const regex = new RegExp("(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&/=]*)");
    if (documentEditOpen !== prevEditOpen) {
      const pubDoc = documents.filter(document => {
        return document.id === documentEditId;
      })[0];
      if (pubDoc) {
        this.setState({
          name: pubDoc.title ? pubDoc.title : "",
          categoryId: pubDoc.categoryId ? pubDoc.categoryId : (categories.length > 0 ? categories[0].id : "Uncategorized"),
          file: null,
          fileTitle: pubDoc.title ? pubDoc.title : null,
          fileExtension: pubDoc.extension ? pubDoc.extension : null,
          description: pubDoc.description ? pubDoc.description : null,
          date: (!pubDoc.date ? null : ((typeof pubDoc.date === "string" || pubDoc.date instanceof String)
            ? (new Date(pubDoc.date + " 00:00")) : pubDoc.date)),
          createdBy: pubDoc.createdBy ? pubDoc.createdBy : null,
          createdDate: pubDoc.created ? pubDoc.created.substr(0, 10) : null,
          updatedDate: pubDoc.updated ? pubDoc.updated.substr(0, 10) : null,
          isPrivate: pubDoc.private ? pubDoc.private : false,
          documentType: pubDoc.type,
          url: pubDoc.url ? pubDoc.url : "",
          validUrl: pubDoc.url ? regex.test(pubDoc.url) : false
        });
      } else {
        let uncategorizedId = null;
        if (categories.length > 0) {
          let listOfUncategorized = categories.filter(currCat => {return (currCat.title === "(Uncategorized)");});
          if (listOfUncategorized.length > 0) {
            uncategorizedId = listOfUncategorized[0].id;
          }
        }

        this.setState({
          name: "",
          categoryId: documentCategoryEditId ? documentCategoryEditId : uncategorizedId,
          file: null,
          fileTitle: null,
          fileExtension: null,
          description: null,
          date: null,
          createdBy: null,
          createdDate: null,
          updatedDate: null,
          isPrivate: false,
          documentType: 'DOCUMENT',
          url: ""
        });
      }
      const name = this.state.name;
      if (documentEditId) {
        this.setState({"title": "Edit a Public Document " + name});
      } else {
        this.setState({"title": "Add a new Public Document " + name});
      }
    }
  }

  render() {
    const { documentEditOpen, pubDocTypeTitle, categories, pubDocsFileTypes, documentEditLoading, documentEditId,
      hasBulkUploadPermission } = this.props;
    const { title, name, categoryId, fileTitle, fileExtension, description, createdBy, createdDate, updatedDate,
      isPrivate, documentType, url, validUrl, errorDialogOpen, fileSizeErrorOpen } = this.state;
    const currentDate = new Date();
    const dateString = !this.state.date ? "" : ((typeof this.state.date === "string" || this.state.date instanceof String)
      ? this.state.date : moment(this.state.date).format("YYYY-MM-DD"));
    const date = !this.state.date ? null : ((typeof this.state.date === "string" || this.state.date instanceof String)
      ? new Date(dateString) : this.state.date);
    return (
      <Dialog id="doc-edit-dialog"
        open={documentEditOpen}
        onClose={this.handleCancelClick}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={`dialogContent pubDocEditDialogContent ${!documentEditLoading ? "" : "loading"}`}>
          <If test={documentEditLoading}>
            <div className="spinner">
              <CircularProgress size={80}/>
            </div>
          </If>
          <If test={!documentEditLoading}>
            <div className="dialogRow">
              <FormControl variant="outlined" className="outlined-control pubDocEditDialogTypeSelect">
                <InputLabel className="outlined-control-label">Document Type</InputLabel>
                <Select
                  value={documentType}
                  onChange={this.handleDocumentTypeChange}
                  inputProps={{
                    className: "outlined-control-input"
                  }}
                  SelectDisplayProps={{
                    className: "outlined-control-select"
                  }}
                >
                  <MenuItem value={'DOCUMENT'}>Document</MenuItem>
                  <MenuItem value={'AUDIO_FILE'}>Audio File</MenuItem>
                  <MenuItem value={'AUDIO_LINK'}>Audio Link</MenuItem>
                  <MenuItem value={'VIDEO_LINK'}>Video Link</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="dialogRow">
              <If test={documentType === 'DOCUMENT' || documentType === 'AUDIO_FILE'}>
                <div className="fileContainer">
                  <input
                    id="edit-doc-file"
                    className="pubDocFile pubDocEditDialogFileSelect"
                    name="pubDocFile"
                    type="file"
                    onChange={this.handleFileChange}
                    accept={documentType === 'AUDIO_FILE'? "audio/*" : (!pubDocsFileTypes ? "*" : pubDocsFileTypes)}
                  />
                  <label htmlFor="edit-doc-file">
                    <Button variant="contained" color="primary" component="span">
                      Upload
                    </Button>
                  </label>
                  <label htmlFor="edit-doc-file" className="fileInputLabel">{fileTitle && fileExtension ? fileTitle + "." + fileExtension : "No file uploaded"}</label>
                </div>
                <If test={!documentEditId && hasBulkUploadPermission}>
                  <Button variant="contained" className={"pubDocToolbarButton pubDocBulkUploaderButton"} onClick={this.openBulkUploaderDialog}>
                    Bulk Uploader
                  </Button>
                </If>
              </If>
              <If test={documentType === 'AUDIO_LINK' || documentType === 'VIDEO_LINK'}>
                <TextField label="URL" variant="outlined" onChange={this.handleUrlChange} value={url} fullWidth/>
                <If test={!validUrl}>
                  <span className={"pubDocEditDialogErrorText"}>*Please enter a valid url</span>
                </If>
              </If>
            </div>
            <div className="dialogRow">
              <label htmlFor="edit-doc-type" id="edit-doc-type-label" className={"pubDocEditDialogTypeLabel"}>Type:</label>
              <span id="edit-doc-type">{pubDocTypeTitle}</span>
            </div>
            <div className="dialogRow">
              <FormControl variant="outlined" className="outlined-control pubDocEditDialogCategorySelect">
                <InputLabel id="edit-doc-category-label" htmlFor="edit-doc-category" className="outlined-control-label">
                  Category
                </InputLabel>
                <Select
                  label="Category"
                  value={categoryId == null ? (categories.filter(cat => {return cat.title === "(Uncategorized)";}).length === 1 ? categories.filter(cat => {return cat.title === "(Uncategorized)";})[0].id : "Uncategorized") : categoryId}
                  onChange={this.handleCategoryChange}
                  inputProps={{
                    id: "edit-doc-category",
                    name: "edit-doc-category",
                    className: "outlined-control-input"
                  }}
                  SelectDisplayProps={{
                    id: "edit-doc-category-select",
                    name: "edit-doc-category-select",
                    className: "outlined-control-select"
                  }}
                  variant="outlined"
                >
                  {(categories.filter(currCat => {return currCat.title === "(Uncategorized)";}).length === 0) && (<MenuItem value="Uncategorized">(Uncategorized)</MenuItem>)}
                  {categories.map((category) => (<MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
            <div className="dialogRow">
              <TextField
                id="edit-doc-name"
                className="docName pubDocEditDialogNameField"
                label="Name"
                variant="outlined"
                value={!name ? "" : name}
                onChange={this.handleNameChange}
              />
            </div>
            <div className="dialogRow">
              <TextField
                id="edit-doc-description"
                className="editDocDescriptions pubDocEditDialogDescriptionField"
                label="Description"
                variant="outlined"
                rows={5}
                value={!description ? "" : description}
                onChange={this.handleDescriptionChange}
                placeholder={"This text is shown to the user when looking at details for the document. It is also used for finding the document when searching."}
                multiline
              />
            </div>
            <div className="dialogRow outlined-date-picker">
              <TextField type="date"
                label="Date"
                id="edit-doc-date"
                className={"pubDocEditDialogDateField"}
                value={dateString}
                onChange={this.handleDateChange}
                InputProps={{inputProps: {
                  min: "1500-01-01",
                  max: moment(new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDate())).format("YYYY-MM-DD")}
                }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <If test={createdBy}>
              <div className="dialogRow">
                <label htmlFor="edit-doc-created-by" id="edit-doc-created-by-label" className={"pubDocEditDialogMetadataLabel"}>Created By:</label>
                <span id="edit-doc-created-by">{createdBy}</span>
              </div>
            </If>
            <If test={createdDate}>
              <div className="dialogRow">
                <label htmlFor="edit-doc-created" id="edit-doc-created-label" className={"pubDocEditDialogMetadataLabel"}>Created:</label>
                <span id="edit-doc-created">{createdDate}</span>
              </div>
            </If>
            <If test={updatedDate}>
              <div className="dialogRow">
                <label htmlFor="edit-doc-updated" id="edit-doc-updated-label" className={"pubDocEditDialogMetadataLabel"}>Updated:</label>
                <span id="edit-doc-updated">{updatedDate}</span>
              </div>
            </If>
            <FormControlLabel control={
              <Switch checked={isPrivate} onChange={this.handleChangePrivate}/>
            }
            label="Private?"/>
          </If>
        </DialogContent>
        <If test={!documentEditLoading}>
          <DialogActions>
            <Button variant="contained" onClick={this.handleCancelClick} color="default"
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={this.handleSaveClick} color="primary"
              disabled={documentEditLoading || name.length < 1 || (fileTitle === null && !validUrl)}
            >
              Save
            </Button>
          </DialogActions>
        </If>
        <Dialog
          open={errorDialogOpen}
          onClose={this.handleCloseError}
        >
          <DialogTitle>
            Could not save public document
          </DialogTitle>
          <DialogContent>
            There already exists a public document with the name: {name} and date:  {date ? moment(date).format("M/D/YYYY") : "No Date"} in the selected category
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseError} color="primary" variant="contained">Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={fileSizeErrorOpen}
          onClose={this.handleCloseFileSizeError}
        >
          <DialogTitle>
            Failed to upload file
          </DialogTitle>
          <DialogContent>
            File size must be less than 100mb
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleCloseFileSizeError} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}
export default PubDocEditDialog;