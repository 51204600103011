/*
 * Copyright 2018 General Code
 */
import React, {Fragment} from 'react';
import * as PropTypes from 'prop-types';
import OpenIcon from './assets/OpenIcon';
import CloseIcon from './assets/CloseIcon';
import EditingIcon from './assets/EditingIcon';
import IconButton from '../../common/components/IconButton';
import {If} from '../../common/containers';
import './NoteIcon.css';

const NoteIcon = ({toggle, open, editing}) => (
  <Fragment>
    <If test={editing}>
      <IconButton className="noteIcon" onClick={toggle} disabled={true}><EditingIcon key="editing"/></IconButton>
    </If>
    <If test={!editing}>
      <IconButton className="noteIcon" onClick={toggle}>{open ? <CloseIcon key="close"/> : <OpenIcon key="open"/>} </IconButton>
    </If>
  </Fragment>
);

NoteIcon.propTypes = {
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default NoteIcon;
