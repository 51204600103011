import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import {
  loadPubDocs,
  setPubDocCategoryEditOpen,
  setPubDocEditOpen,
  loadPubDocsCategories,
  setDocumentMoveOpen
} from "../actions";
import {
  getFilteredCategories,
  getFilterOptions,
  getPubDocs,
  getPubDocsLoading,
  getPubDocTypeDescription,
  getPubDocTypeId,
  getPubDocTypeTitle
} from "../selectors";
import PubDocType from "../components/PubDocType";
import { getCustId, hasDocumentAdminPermission, hasBulkUploadPermission } from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  pubDocTypeId: getPubDocTypeId,
  pubDocTypeTitle: getPubDocTypeTitle,
  pubDocTypeDescription: getPubDocTypeDescription,
  documents: getPubDocs,
  documentsLoading: getPubDocsLoading,
  categories: getFilteredCategories,
  filterOptions: getFilterOptions,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  hasBulkUploadPermission: hasBulkUploadPermission
});

const mapDispatchToProps = (dispatch) => ({
  loadPubDocs: (custId, pubDocTypeId) => {
    dispatch(loadPubDocs(custId, pubDocTypeId));
  },
  loadPubDocCategories: (custId, pubDocTypeId) => {
    dispatch(loadPubDocsCategories(custId, pubDocTypeId));
  },
  setCategoryEditOpen: (categoryEditOpen, categoryEditId) => {
    dispatch(setPubDocCategoryEditOpen(categoryEditOpen, categoryEditId));
  },
  setDocumentEditOpen: (open, documentId, documentCategoryId) => {
    dispatch(setPubDocEditOpen(open, documentId, documentCategoryId));
  },
  setDocumentMoveOpen: (open) => {
    dispatch(setDocumentMoveOpen(open, null));
  }
});

const PubDocTypeContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocType);
export default PubDocTypeContainer;