import uniq from 'lodash/uniq';
import * as PropTypes from "prop-types";
import React, {Component} from 'react';

import PrimaryButton from './PrimaryButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ErrorDialog extends Component {

  static propTypes = {
    errorMessages: PropTypes.array,
    errorTitles: PropTypes.array,
    errorRedirectUrl: PropTypes.string,
    dismissErrors: PropTypes.func.isRequired
  };

  handleClose = (dismissErrors, redirectUrl) => () => {
    dismissErrors();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  render() {
    const {errorMessages, errorTitles, errorRedirectUrl, dismissErrors} = this.props;
    const title = (!errorTitles || errorTitles.length === 0) ? "There was an unexpected error - please try again" : errorTitles[0];
    return (
      <div>
        <Dialog
          className="errorDialog"
          open={errorMessages.length > 0}
          onClose={dismissErrors}
          disableBackdropClick={true}
          aria-labelledby="errorDialog-title"
        >
          <DialogTitle id="errorDialog-title">{title}</DialogTitle>
          <DialogContent>
            {uniq(errorMessages).map((errorMessage, index) => (
              <DialogContentText key={index}>{errorMessage}</DialogContentText>
            ))}
          </DialogContent>
          <DialogActions>
            <PrimaryButton onClick={this.handleClose(dismissErrors, errorRedirectUrl)}>Close</PrimaryButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default ErrorDialog;
