import {createStructuredSelector} from "reselect";
import {compose} from "recompose";
import {connect} from "react-redux";
import {toJS} from "../../common/utils";
import PubDocToolbar from "../components/PubDocToolbar";
import * as PropTypes from "prop-types";
import {getCustId, hasDocumentAdminPermission} from "../../common/selectors";
import {getSelectedDocs, getSelectedFilters} from "../selectors";
import {deleteMultipleDocuments, loadFilters, setDocumentMoveOpen, setPubDocEditOpen, updateFilterState} from "../actions";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  selectedDocs: getSelectedDocs,
  selectedFilters: getSelectedFilters
});

const mapDispatchToProps = (dispatch) => ({
  setDocumentEditOpen: (open, documentId, documentCategoryId) => {
    dispatch(setPubDocEditOpen(open, documentId, documentCategoryId));
  },
  setDocumentMoveOpen: (open) => {
    dispatch(setDocumentMoveOpen(open));
  },
  deleteMultipleDocuments: (custId, documentIdList) => {
    dispatch(deleteMultipleDocuments(custId, documentIdList));
  },
  updateFilterState: (custId, pubDocTypeId, selectedFilters) => {
    dispatch(updateFilterState(selectedFilters));
    dispatch(loadFilters(custId, pubDocTypeId));
  },
});

const PubDocToolbarContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocToolbar);
PubDocToolbarContainer.propTypes = {
  filterOptions: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired
};
export default PubDocToolbarContainer;