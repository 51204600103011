import 'nodelist-foreach-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {List, Map} from "immutable";
import PubDocsPageActionsContainer from "./containers/PubDocsPageActionsContainer";
import * as actions from './actions';
import PubDocTypeContainer from "./containers/PubDocTypeContainer";
import PubDocsSidebarContainer from "./containers/PubDocsSidebarContainer";
import {ModuleKey} from "./ModuleKey";
import reducer, {initialState, PubDocType} from "./reducers";
import * as selectors from './selectors';
import {initializeState, loadFilters} from './actions';
import {getQueryParamValue} from "../common/utils/query-params";
import {custId, newNav, pageSubType} from "../common/utils/server-data";
import {PUBLIC_DOCUMENTS} from "../common/utils/page-sub-types";
import typeId from "./utils/type-id";

export const getInitialState = (loadingState) => {
  if (loadingState == null) {
    throw new Error("Trying to load a null state");
  }
  let docsState = loadingState.get(ModuleKey) || initialState;
  return loadingState.set(ModuleKey, docsState.withMutations(loadingDocsState => {
    return loadingDocsState
      .set('pubDocTypeId', typeId)
      .set('pubDocTypes', window.SERVER_DATA.pubDocTypes ? List(window.SERVER_DATA.pubDocTypes.map(pdt => PubDocType(pdt))) : List())
      .set('suggestedPubDocTypes', window.SERVER_DATA.suggestedPubDocTypes ? Map(window.SERVER_DATA.suggestedPubDocTypes) : Map())
      .set('pubDocsSidebarName', window.SERVER_DATA.pubDocsSidebarName)
      .set('documents', List());
  }));
};

/**
 * @param {Component} Providers - Provider wrapper to use on created elements
 * @param {function} dispatch - Dispatch function for redux
 */
const init = (Providers, dispatch) => {
  if (window.SERVER_DATA && window.SERVER_DATA.newPubDocs) {
    let pubDocTypeContainer = document.getElementById('pubDocTypeContainer');
    if (pubDocTypeContainer) {
      let contentContainer = document.getElementById('contentContainer');
      ReactDOM.render(
        <Providers key="pub-docs-providers" name="pub-docs">
          <PubDocTypeContainer/>
        </Providers>,
        contentContainer
      );

      // Set up the history handler for filtering
      window.addEventListener("popstate",  handleHistoryStateChange(dispatch, custId, typeId));

      // Load the initial state
      const filterState = {
        selectedFilters: {
          category: getQueryParamValue('category'),
          year: getQueryParamValue('year'),
          month: getQueryParamValue('month'),
          day: getQueryParamValue('day')
        }
      };
      dispatch(initializeState(filterState, true));
      dispatch(loadFilters(custId, typeId));
    }
    let pubDocsSidebar = document.getElementById('pubDocsSidebar');
    if (pubDocsSidebar) {
      ReactDOM.render(
        <Providers key="pub-docs-sidebar-providers" name="pub-docs-sidebar">
          <PubDocsSidebarContainer/>
        </Providers>,
        pubDocsSidebar
      );
    }
    if (newNav && pageSubType === PUBLIC_DOCUMENTS) {
      const pageActions = document.getElementById("pageActions");
      if (pageActions) {
        ReactDOM.render(
          <Providers key="pub-docs-page-actions-providers" name="pub-docs-page-actions">
            <PubDocsPageActionsContainer/>
          </Providers>,
          pageActions
        );
      }
    }
  }
};
const handleHistoryStateChange = (dispatch, custId, typeId) => (event) => {
  if (event.state) {
    dispatch(initializeState(event.state, false));
    dispatch(loadFilters(custId, typeId));
  }
};

/**
 * Name of the module for use in reducers and selectors
 * @type {string}
 */
export {ModuleKey} from './ModuleKey';

export {
  init,
  actions,
  reducer,
  selectors
};
