/*
 * Copyright 2019 General Code
 */

export const API = "API";
export const API_START = "COMMON/API/START";
export const API_END = "COMMON/API/END";
export const API_ERROR = "COMMON/API/ERROR";
export const SERVER_DATA_LOAD = "COMMON/SERVER_DATA/LOAD";
export const ERROR_REGISTER = "COMMON/ERROR/REGISTER";
export const ERROR_NON_FATAL_REGISTER = "COMMON/ERROR/NON_FATAL/REGISTER";
export const ERROR_ALL_DISMISS = "COMMON/ERROR/ALL/DISMISS";
export const EVENT_LOG = "COMMON/EVENT/LOG";
export const BODY_TOP_SET = "COMMON/BODY/TOP/SET";
export const PRINT_LOADING_SET = "COMMON/PRINT/LOAD/SET";
