import React from 'react';
import {Help} from './icons';
import IconButton from './IconButton';
import {custId} from '../utils/server-data';

const baseUrl = custId ? `/help/${custId}` : '/help';

const HelpButton = ({topic, ...props}) => (
  <IconButton
    {...props}
    aria-label="Help"
    href={`${baseUrl}/${topic}`}
    rel="help"
    target="_blank"
  >
    <Help/>
  </IconButton>
);

export default HelpButton;

