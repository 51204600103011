import React from "react";
import ScopeType from "./ScopeType";
import {Card} from "../../common/components/card";

class ScopeSelector extends React.PureComponent {

  render() {
    const {id, scope, totalResultCount, scopeTypes, setScope} = this.props;
    return scopeTypes.length === 0 ? null : (
      <div id={id}>
        <Card elevation={3 /* the scope selector goes over the filter; paper is 1dp thick and 1dp of space to move was added*/} className="sidebarItemContent">
          <nav>
            <ScopeType key={0}
              isSelected={!scope || scope === "all"}
              id="all"
              name="All Results"
              title="All Results"
              count={totalResultCount}
              setScope={setScope}
            />
            {
              scopeTypes && scopeTypes.map((scopeType) => {
                return (
                  <ScopeType key={scopeType.id}
                    isSelected={scope && scope === scopeType.id}
                    id={scopeType.id}
                    name={scopeType.displayName}
                    title={scopeType.displayName}
                    count={scopeType.count}
                    setScope={setScope}
                  />
                );
              })
            }
          </nav>
        </Card>
      </div>
    );
  }
}
export default ScopeSelector;
