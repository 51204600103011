/**
 * Questions component
 * @module ecode/questions
 *
 * Copyright 2018 General Code
 */
import 'nodelist-foreach-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  getAnalysisId,
  getAssignedFilter,
  getAssignedToFilter,
  getDeferrableFilter,
  getDisplayedQuestionIds,
  getFilterText,
  getNeedsReviewFilter,
  getQuestions,
  getStatusFilter
} from "../common/selectors";
import {QUESTIONS} from "../common/utils/page-sub-types";
import QuestionsPageActionsContainer from "./containers/QuestionsPageActionsContainer";
import * as actions from './actions';
import {initAnalysis, initializeQuestionsState, initQuestion} from './actions';
import './components/Question.css';
import AnalysisContainer from "./containers/AnalysisContainer";
import QuestionsPageTitleContainer from "./containers/QuestionsPageTitleContainer";
import QuestionsSidebarContainer from "./containers/QuestionsSidebarContainer";
import {ModuleKey} from './ModuleKey';
import reducer, {initialState, updateWindowHistory} from './reducers';
import * as selectors from './selectors';
import QuestionFiltersContainer from "./containers/QuestionFiltersContainer";
import QuestionContainer from "./containers/QuestionContainer";
import {newNav, pageSubType} from "../common/utils/server-data";

export const getInitialState = (loadingState) => {
  if (loadingState == null) {
    throw new Error("Trying to load a null state");
  }
  const displayedQuestionIds = getDisplayedQuestionIds(loadingState);
  const needsReviewFilter = getNeedsReviewFilter(loadingState);
  const assignedFilter = getAssignedFilter(loadingState);
  const assignedToFilter = getAssignedToFilter(loadingState);
  const deferrableFilter = getDeferrableFilter(loadingState);
  const statusFilter = getStatusFilter(loadingState);
  const filterText = getFilterText(loadingState);
  const questionsMap = getQuestions(loadingState);

  let analyses = [];
  const analysisContainers = document.getElementsByClassName("analysisContainer");
  for (let i = 0; i < analysisContainers.length; i++) {
    const analysisContainer = analysisContainers[i];
    const analysisId = analysisContainer.dataset.analysisId;
    const created = analysisContainer.dataset.created;
    const questionIds = analysisContainer.dataset.questionIds.split(",").map(Number);
    const checked = analysisContainer.dataset.checked === "true";
    const dueDate = analysisContainer.dataset.dueDate;
    const finalized = analysisContainer.dataset.finalized;
    let analysis = {
      id: Number(analysisId),
      created: created,
      checked: checked,
      dueDate: dueDate,
      finalized: finalized,
      questionIds: questionIds,
      scrollQuestionId: (window.location.hash ? window.location.hash.substring(10) : null),
      analysisLoading: false
    };
    analyses.push(analysis);
  }

  let questionsState = loadingState.get(ModuleKey) || initialState;
  let loadedState = loadingState.set(ModuleKey, questionsState.withMutations(loadingQuestionsState => {
    let state = loadingQuestionsState;
    for (let questionData of questionsMap.values()) {
      state = reducer(state, initQuestion(questionData));
    }
    for (let analysis of analyses) {
      state = reducer(state, initAnalysis(analysis));
    }
    return state.set('displayedQuestionIds', displayedQuestionIds)
      .set('needsReviewFilter', needsReviewFilter)
      .set('assignedFilter', assignedFilter)
      .set('assignedToFilter', (assignedToFilter === '' ? null : assignedToFilter))
      .set('deferrableFilter', deferrableFilter)
      .set('statusFilter', statusFilter)
      .set('filterText', (filterText === '' ? null : filterText));
  }));
  if (document.body.id === "questionsPage") {
    updateWindowHistory(
      displayedQuestionIds,
      needsReviewFilter,
      assignedFilter,
      (assignedToFilter === '' ? null : assignedToFilter),
      deferrableFilter,
      statusFilter,
      (filterText === '' ? null : filterText),
      true
    );
  }
  return loadedState;
};

const handleHistoryStateChange = (dispatch) => (event) => {
  if (event.state) {
    dispatch(initializeQuestionsState(event.state));
  }
};

/**
 * @param {Component} Providers - Provider wrapper to use on created elements
 * @param {function} dispatch - Dispatch function for redux
 * @param {function} state - The redux state
 */
const init = (Providers, dispatch, state) => {

  // Initialize the page title if this is the questions page
  const isQuestionsPage = (document.body.id === "questionsPage");
  if (isQuestionsPage) {
    let pageTitleContainer = document.getElementById('pageTitleContainer');
    if (pageTitleContainer) {
      ReactDOM.render(
        <Providers key="question-page-title-container-providers" name="question-page-title-container">
          <QuestionsPageTitleContainer/>
        </Providers>,
        pageTitleContainer
      );
      pageTitleContainer.removeAttribute('id');
    }
  }

  // Initialize the question filters if they exist
  let questionFiltersContainer = document.getElementById('questionFiltersContainer');
  if (questionFiltersContainer) {
    ReactDOM.render(
      <Providers key="question-page-filter-container-providers" name="question-page-filter-container">
        <QuestionFiltersContainer/>
      </Providers>,
      questionFiltersContainer
    );
  }

  // Check if there are analyses on the page
  let analysisContainers = document.getElementsByClassName("analysisContainer");
  if (analysisContainers.length > 0) {
    for (let i = 0; i < analysisContainers.length; i++) {
      let analysisContainer = analysisContainers[i];
      let analysisId = Number(analysisContainer.dataset.analysisId);
      ReactDOM.render(
        <Providers key={`analysis-container-providers-${analysisId}`} name={`analysis-container-${analysisId}`}>
          <AnalysisContainer analysisId={analysisId}/>
        </Providers>,
        analysisContainer
      );
    }
  } else {
    // Initialize each question on the page
    let qset = selectors.getQuestions(state).sort((q1,q2) => {
      let p1 = Number.MAX_SAFE_INTEGER;
      let p2 = Number.MAX_SAFE_INTEGER;
      q1.references.forEach(ref => {
        if (ref.structure.position < p1) {
          p1 = ref.structure.position;
        }
      });
      q2.references.forEach(ref => {
        if (ref.structure.position < p2) {
          p2 = ref.structure.position;
        }
      });
      let cmp = p1 - p2;
      if (cmp === 0) {
        cmp = q1.number.localeCompare(q2.number);
      }
      return cmp;
    });
    for (let questionData of qset.valueSeq()) {
      for (let reference of questionData.references) {
        renderQuestion(Providers, questionData.id, reference.guid,
          document.getElementById('questions-' + reference.guid));
      }
    }
  }

  let questionsSidebarContainer = document.getElementById("questionsSidebar");
  if (questionsSidebarContainer) {
    let analysisId = Number(getAnalysisId(state));
    ReactDOM.render(
      <Providers key={`questions-sidebar-container-providers-${analysisId}`} name={`questions-sidebar-container-${analysisId}`}>
        <QuestionsSidebarContainer analysisId={analysisId} isQuestionsPage={isQuestionsPage}/>
      </Providers>,
      questionsSidebarContainer
    );
  }

  if (newNav && pageSubType === QUESTIONS) {
    const pageActions = document.getElementById("pageActions");
    if (pageActions) {
      ReactDOM.render(
        <Providers key="questions-page-actions-providers" name="questions-page-actions">
          <QuestionsPageActionsContainer/>
        </Providers>,
        pageActions
      );
    }
  }

  // Set up the history handler for filtering
  window.addEventListener("popstate",  handleHistoryStateChange(dispatch));
};

const renderQuestion = (Providers, questionId, guid, questionsContainer) => {

  const questionElementId = "question-" + (guid ? guid + "-" : "") + questionId;

  // First check if the question already exists
  let questionElement = document.getElementById('question-' + questionId)
    || document.getElementById('question-' + (guid ? guid + "-" : "") + questionId);

  // If it doesn't, we'll need to create one inside a questions container
  if (!questionElement) {

    // If a questions container doesn't exist, make one in the structure element
    if (!questionsContainer) {
      let structureEl = document.getElementById(guid + '_content')
        || document.getElementById(guid);
      if (structureEl) {
        questionsContainer = document.createElement("div");
        questionsContainer.classList.add("questions");
        if (structureEl.classList.contains("litem")) {
          // If the element is an litem, it goes inside of the content, before any levels
          structureEl = structureEl.querySelector(".litem_content");
          let levelEl = structureEl.querySelector(".level");
          if (levelEl) {
            structureEl.insertBefore(questionsContainer, levelEl);
          } else {
            structureEl.appendChild(questionsContainer);
          }
        } else if (structureEl.classList.contains("definition")) {
          // If the element is a definition, it goes after the term and before the definition text
          let defTextEl = structureEl.querySelector(".deftext");
          if (defTextEl) {
            structureEl.insertBefore(questionsContainer, defTextEl);
          } else {
            structureEl.appendChild(questionsContainer);
          }
        } else {
          // If it's anything else, put it at the beginning of the structure element
          structureEl.insertBefore(questionsContainer, structureEl.childNodes[0]);
        }
      }
    }

    // After we find or create a questions container, make a question element inside of that
    if (questionsContainer) {
      questionElement = document.createElement("div");
      questionElement.id = questionElementId;
      questionsContainer.appendChild(questionElement);
    }
  }

  // If we found or created a question element, create the question component inside of that
  if (questionElement) {
    const scrollTo = ('#' + questionElementId === window.location.hash);
    ReactDOM.render(
      <Providers key={`question-container-providers-${questionId}`} name={`question-container-${questionId}`}>
        <QuestionContainer questionId={questionId} scrollTo={scrollTo} inline={true}/>
      </Providers>,
      questionElement
    );
  }
};

/**
 * Name of the module for use in reducers and selectors
 * @type {string}
 */
export {ModuleKey} from './ModuleKey';

export {
  init,
  actions,
  reducer,
  selectors
};
