import * as PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import AuditLine from '../../common/components/AuditLine';
import Button from '../../common/components/Button';
import ButtonGroup from '../../common/components/ButtonGroup';
import Checkbox from '../../common/components/Checkbox';
import {Dialog, DialogContent, DialogTitle} from '../../common/components/dialog';
import FormControlLabel from '../../common/components/FormControlLabel';
import FormLabel from '../../common/components/FormLabel';
import HelpButton from '../../common/components/HelpButton';
import PrimaryButton from '../../common/components/PrimaryButton';
import TextField from '../../common/components/TextField';
import Typography from '../../common/components/Typography';
import If from "../../common/containers/If";
import './EditLawDialog.css';


class EditLawDialog extends PureComponent {
  static propTypes = {
    law: PropTypes.shape({
      documentId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      comment: PropTypes.string,
      description: PropTypes.string,
      originalFilename: PropTypes.string,
    }).isRequired,
    saveEditing: PropTypes.func.isRequired,
    cancelEditing: PropTypes.func.isRequired,
  };

  state = {
    useCustomTitle: this.props.law.customTitle !== "",
    title: this.props.law.title || "",
    comment: this.props.law.comment || "",
    titleError: false,
  };

  onTitleChange = event => {
    this.setState({title: event.target.value, titleError: false});
  };

  onCommentChange = event => {
    this.setState({comment: event.target.value});
  };

  onUseCustomTitleChange = event => {
    this.setState({useCustomTitle: event.target.checked, titleError: false});
  };

  validateAndSave = () => {
    const {useCustomTitle, title, comment} = this.state;
    const {saveEditing, law} = this.props;
    if (!useCustomTitle || title.trim() !== "") {
      saveEditing(law.documentId, useCustomTitle ? title.trim() : "", comment.trim());
    }
  };


  render() {
    const {law, cancelEditing} = this.props;
    const {description = "", originalFilename = ""} = law;
    const {useCustomTitle, title, titleError, comment} = this.state;
    return (
      <Dialog
        open={law !== null}
        onClose={cancelEditing}
        disableBackdropClick={true}
        classes={{paper: "editLawDialogPaper"}}
      >
        <DialogTitle className="editLawDialogTitle">
          <span>Customize new law display</span>
          <HelpButton className="editLawHelp" topic="laws#manage"/>
        </DialogTitle>
        <DialogContent>
          <div className="formSection">
            <FormLabel>Title</FormLabel>
            <Typography variant="subtitle1" className={useCustomTitle ? "textDisabled" : ""}>{description}</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useCustomTitle}
                  onChange={this.onUseCustomTitleChange}
                  style={{height: 'auto'}}
                />
              }
              label="Set a custom title"
              className="useCustomTitleLabel"
            />
            <TextField
              value={title}
              onChange={this.onTitleChange}
              fullWidth={true}
              className="editLawDialogField"
              required={true}
              disabled={!useCustomTitle}
              error={titleError}
              helperText={titleError ? "Please enter a title or uncheck the custom title option" : null}
            />
          </div>
          <div className="formSection">
            <TextField
              label="Comment"
              value={comment}
              onChange={this.onCommentChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={true}
              rowsMax={4}
            />
          </div>
          <If test={description !== "" || originalFilename !== ""}>
            <div className="formSection">
              <FormLabel>Information</FormLabel>
              <If test={description !== ""}>
                <Typography variant="subtitle1">Designation - {description}</Typography>
              </If>
              <If test={originalFilename !== ""}>
                <Typography variant="subtitle1">Original Filename - {originalFilename}</Typography>
              </If>
            </div>
          </If>
          <div className="bottomContainer">
            <ButtonGroup>
              <Button onClick={cancelEditing}>Cancel</Button>
              <PrimaryButton onClick={this.validateAndSave} disabled={useCustomTitle && !title}>Ok</PrimaryButton>
            </ButtonGroup>
            <AuditLine entity={law}/>
          </div>
        </DialogContent>

      </Dialog>
    );
  }
}

export default EditLawDialog;
